import { CommonModule, formatCurrency, formatDate, ViewportScroller } from '@angular/common';
import { AfterViewInit, Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { GoogleMapsModule } from '@angular/google-maps';
import { PRIMARY_OUTLET, Router, RouterLink } from '@angular/router';
import { NgbAlert, NgbAlertModule, NgbModal, NgbProgressbarModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subscription, first } from 'rxjs';
import { ProjectDetails } from 'src/app/core/models-two/ProjectDetails';
import { IpGeoService } from 'src/app/core/service/ip-geo.service';
import { PetroleumProductsService } from 'src/app/core/service/petroleum-products.service';
import { ProjectService } from 'src/app/core/service/project.service';
import { FooterComponent } from 'src/app/shared/footer/footer.component';
import { GiftSecondNavbarComponent } from 'src/app/shared/gift-second-navbar/gift-second-navbar.component';
import { NavbarComponent } from 'src/app/shared/navbar/navbar.component';
import { WidgetModule } from 'src/app/shared/widget/widget.module';
import { NgApexchartsModule } from 'ng-apexcharts';
import { ApexNonAxisChartSeries, ApexChart, ApexPlotOptions, ApexAxisChartSeries, ApexStroke, ApexDataLabels, ApexXAxis, ApexLegend, ApexFill, ApexGrid, ApexYAxis, ApexTooltip, ApexResponsive, ApexTitleSubtitle, ApexAnnotations, ApexMarkers, ApexStates, } from "ng-apexcharts";
import { FileStorageService } from 'src/app/core/service/file-storage.service';
import { AuthenticationService } from 'src/app/core/service/auth.service';
import { AppUser } from 'src/app/core/models-two/AppUser';
import { CompanyInvestment } from 'src/app/core/models-two/CompanyInvestment';
import { CompanyInvestmentItem } from 'src/app/core/models-two/CompanyInvestmentItem';
import { CompanySummary } from 'src/app/core/models-two/CompanySummary';
import { CompanyFinancing } from 'src/app/core/models-two/CompanyFinancing';
import { RemarkItem } from 'src/app/core/models-two/RemarkItem';
import { SensitivityAnalysis } from 'src/app/core/models-two/SensitivityAnalysis';
import { StationMapLocation } from 'src/app/core/models-two/StationMapLocation';
import { StationMapStreetView } from 'src/app/core/models-two/StationMapStreetView';
import { StationCompetitor } from 'src/app/core/models-two/StationCompetitor';
import { Volkhov } from 'src/assets/fonts/Volkhov-normal';

import jsPDF from 'jspdf';
import { DomSanitizer } from '@angular/platform-browser';
import { QuillModule, QuillModules } from 'ngx-quill';


@Component({
  selector: 'app-post-investment',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    WidgetModule,
    NgbAlertModule,
    NavbarComponent,
    FooterComponent,
    RouterLink,
    GiftSecondNavbarComponent,
    NgbProgressbarModule,
    NgbTooltipModule,
    GoogleMapsModule,
    QuillModule,
    NgApexchartsModule
  ],
  templateUrl: './post-investment.component.html',
  styleUrls: ['./post-investment.component.scss']
})
export class PostInvestmentComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild('selfClosingAlert', { static: false }) selfClosingAlert!: NgbAlert;
  show_alert: boolean;
  alert_message: string;
  alert_type: string;
  subscription: Subscription = new Subscription();
  yearPickerForm!: FormGroup;
  economicEnvironmentActualForm!: FormGroup;
  profitAndLossActualForm!: FormGroup;
  productSalesActualForm!: FormGroup;

  economicEnvironmentRemarkForm!: FormGroup;
  profitAndLossRemarkForm!: FormGroup;
  productSalesRemarkForm!: FormGroup;
  postInvestmentReportForm!: FormGroup;

  tree: any;
  g: any;
  s: any;
  projectId: any;
  loading: boolean = false;
  projectArray: ProjectDetails[];
  projectAppraisalNpv: number;
  projectAppraisalPaybackPeriod: number;
  projectAppraisalDiscountedPaybackPeriod: number;
  projectAppraisalAccountingRateOfReturn: number;
  projectName: string;
  projectLocationDescription: string;
  projectDescription: string;
  projectStartDate: number;
  projectEndDate: number;
  inflationRateArray: number[];
  showProfitAndLossEdit: boolean;
  showProductSalesVolumeEdit: boolean;
  showEconomicEnvironmentEdit: boolean;
  formSubmitted: boolean = false;
  yearArray: { name: string; year: number; }[];
  pickedYearInflationRate: number = 0;
  pickedYearExchangeRate: number = 0;
  pickedYearCorporateTaxRate: number = 0;
  pickedYearInterestRate: number = 0;
  pickedYearNetFuelMargin: number = 0;
  pickedYearDiversification: number = 0;
  pickedYearOtherRevenue: number = 0;
  pickedYearOperatingExpenses: number = 0;
  pickedYearRentalsAndFeesDue: number = 0;
  pickedYearEBITDA: number = 0;
  pickedYearInvestments: number = 0;
  pickedYearChangeInWorkingCapital: number = 0;
  pickedYearTaxes: number = 0;
  pickedYearOtherCashItems: number = 0;
  pickedYearNetCashAfterTax: number = 0;
  pickedYearFinancialResult: number = 0;
  pickedYearNetCashAfterTaxDiscounted: number = 0;
  pickedYearNetCashAfterTaxRealAndDiscounted: number = 0;

  // Actual
  pickedYearInflationRateActual: number = 0;
  pickedYearExchangeRateActual: number = 0;
  pickedYearCorporateTaxRateActual: number = 0;
  pickedYearInterestRateActual: number = 0;
  pickedYearNetFuelMarginActual: number = 0;
  pickedYearDiversificationActual: number = 0;
  pickedYearOtherRevenueActual: number = 0;
  pickedYearOperatingExpensesActual: number = 0;
  pickedYearRentalsAndFeesDueActual: number = 0;
  pickedYearEBITDAActual: number = 0;
  pickedYearInvestmentsActual: number = 0;
  pickedYearChangeInWorkingCapitalActual: number = 0;
  pickedYearTaxesActual: number = 0;
  pickedYearOtherCashItemsActual: number = 0;
  pickedYearNetCashAfterTaxActual: number = 0;
  pickedYearFinancialResultActual: number = 0;
  pickedYearNetCashAfterTaxDiscountedActual: number = 0;
  pickedYearNetCashAfterTaxRealAndDiscountedActual: number = 0;
  productOne: { volume: number; name: string; category: string; } = { volume: 0, name: "", category: "" };
  productTwo: { volume: number; name: string; category: string; } = { volume: 0, name: "", category: "" };
  productThree: { volume: number; name: string; category: string; } = { volume: 0, name: "", category: "" };

  productOneActualVolume: number = 0;
  productTwoActualVolume: number = 0;
  productThreeActualVolume: number = 0;
  pickedYear: any;
  updateInProgress: boolean = false;
  submitInProgress: boolean = false;
  pickedYearGasVolume: number = 0;
  pickedYearFuelAndLubricantVolume: number = 0;
  pickedYearGasVolumeActual: number = 0;
  pickedYearFuelAndLubricantVolumeActual: number = 0;
  dateToday: string;
  user: AppUser;
  loggedInUserDate: number;
  authState: boolean;
  loggedInUserLastName: any;
  loggedInUserRole: any;
  loggedInUserSurname: any;
  loggedInUserFirstName: any;
  profilePictureUrl: any;
  userName: string;
  userId: any;
  localCurrencyISOCode: string = "";
  yearPicked: string = "";
  economicEnvironmentRemarkArray: RemarkItem[];
  productSalesRemarkArray: RemarkItem[];
  profitAndLossRemarkArray: RemarkItem[];
  error: string;
  finalRemark: RemarkItem;
  postInvestmentRecommendations: RemarkItem;
  quillConfig: QuillModules = {};
  showPostInvestmentReportEdit: boolean = false;

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private scroller: ViewportScroller,
    private modalService: NgbModal,
    private toastr: ToastrService,
    private projectService: ProjectService,
    private authenticationService: AuthenticationService,
    private petroleumProductsService: PetroleumProductsService,
    private sanitizer: DomSanitizer,
  ) {
    this.tree = router.parseUrl(router.url);
    this.g = this.tree.root.children[PRIMARY_OUTLET];
    this.s = this.g.segments;

    this.loadingAnimationFourSeconds();

    this.subscription.add(authenticationService.currentUser.pipe().subscribe((res: AppUser) => {

      if (res) {
        this.user = res;
        this.authState = true;

        if (res[0]) {
          let firstName = "";
          let otherName = "";
          let surname = "";

          this.loggedInUserDate = (new Date()[Symbol.toPrimitive]('number'));
          if (res[0].userId) { this.userId = res[0].userId; };
          if (res[0].firstName) {
            this.loggedInUserFirstName = res[0].firstName;
            firstName = res[0].firstName;
          };
          if (res[0].surname) {
            this.loggedInUserSurname = res[0].surname;
            surname = res[0].surname;
          };
          if (res[0].otherName) {
            this.loggedInUserLastName = res[0].otherName;
            otherName = res[0].otherName;
          };
          if (res[0].role) { this.loggedInUserRole = res[0].role; };
          if (res[0].profilePicture) {
            if (res[0].profilePicture[0]) {
              if (res[0].profilePicture[0].url) {
                this.profilePictureUrl = res[0].profilePicture[0].url;
              } else {
                this.profilePictureUrl = "../../../assets/images/Male-Avatar.jpg";
              }
            } else {
              this.profilePictureUrl = "../../../assets/images/Male-Avatar.jpg";
            }
          } else {
            this.profilePictureUrl = "../../../assets/images/Male-Avatar.jpg";
          }

          this.userName = `${firstName} ${otherName} ${surname}`;
        } else {
          this.profilePictureUrl = "../../../assets/images/Male-Avatar.jpg";
        }

      } else {
        this.authState = false;
      }

    }));

    if (this.s[2].path) {
      if ((this.s[2].path.toString()).length === 22) {
        this.projectId = this.s[2].path.toString();

        this.subscription.add(
          this.projectService.getProjectArrayItem(this.s[2].path.toString()).pipe().subscribe((res: ProjectDetails[]) => {
            if (res) {
              this.projectArray = res;

              // projectAppraisalNpv
              if (res[0].netPresentValue) { this.projectAppraisalNpv = res[0].netPresentValue; };
              // projectAppraisalPaybackPeriod
              if (res[0].paybackPeriod) { this.projectAppraisalPaybackPeriod = res[0].paybackPeriod; };
              // projectAppraisalDiscountedPaybackPeriod
              if (res[0].paybackPeriodDiscounted) { this.projectAppraisalDiscountedPaybackPeriod = res[0].paybackPeriodDiscounted; };
              // projectAppraisalAccountingRateOfReturn
              if (res[0].internalRateOfReturn) { this.projectAppraisalAccountingRateOfReturn = res[0].internalRateOfReturn; };


              if (res[0].name) { this.projectName = res[0].name };
              if (res[0].locationDescription) { this.projectLocationDescription = res[0].locationDescription };
              if (res[0].description) { this.projectDescription = res[0].description };
              if (res[0].constructionRenovationStartDate) { this.projectStartDate = res[0].constructionRenovationStartDate };
              if (res[0].constructionRenovationEndDate) { this.projectEndDate = res[0].constructionRenovationEndDate };

              if (res[0].country) {
                if (res[0].country.localCurrencyISOCode) {
                  this.localCurrencyISOCode = res[0].country.localCurrencyISOCode;
                };
              };
              if (res[0].postInvestment) {
                if (res[0].postInvestment.economicEnvironmentRemark) {
                  this.economicEnvironmentRemarkArray = res[0].postInvestment.economicEnvironmentRemark;
                }
                if (res[0].postInvestment.productSalesRemark) {
                  this.productSalesRemarkArray = res[0].postInvestment.productSalesRemark;
                }
                if (res[0].postInvestment.profitAndLossRemark) {
                  this.profitAndLossRemarkArray = res[0].postInvestment.profitAndLossRemark;
                }
              }


            }

          }
          )
        )

      }
    }

  }

  loadingAnimationFourSeconds() {
    this.loading = true;
    setTimeout(() => {
      this.loading = false;
    }, 4000);
  }

  navigateTo(type: string) {

    if (type === "postInvestmentPDF") {
      // this.toastr.info(`PDF Download Feature under development`, "Info!");
      this.printPostInvestmentSummaryPDF();
    }

  }

  showPostInvestmentReportEditButton(a: boolean) {
    this.showPostInvestmentReportEdit = a;
    this.scrollToElement("stepFive");
  }

  showProfitAndLossEditButton(a: boolean) {
    this.showProfitAndLossEdit = a;
    this.scrollToElement("stepFour");
  }

  showProductSalesVolumeEditButton(a: boolean) {
    this.showProductSalesVolumeEdit = a;
    this.scrollToElement("stepThree");
  }

  showEconomicEnvironmentEditButton(a: boolean) {
    this.showEconomicEnvironmentEdit = a;
    this.scrollToElement("stepTwo");
  }

  scrollToElement(a: string) {

    this.scroller.scrollToAnchor(a);

    setTimeout(() => {
      window.scrollBy(0, -75);
    }, 500);

  }

  /**
   * convenience getter for easy access to form fields
   */

  get yearPickerFormValues() {
    return this.yearPickerForm.controls;
  }

  get profitAndLossActualFormValues() {
    return this.profitAndLossActualForm.controls;
  }

  get productSalesActualFormValues() {
    return this.productSalesActualForm.controls;
  }

  get economicEnvironmentActualFormValues() {
    return this.economicEnvironmentActualForm.controls;
  }

  get profitAndLossFormRemarkValues() {
    return this.profitAndLossRemarkForm.controls;
  }

  get economicEnvironmentRemarkFormValues() {
    return this.economicEnvironmentRemarkForm.controls;
  }

  get productSalesRemarkFormValues() {
    return this.productSalesRemarkForm.controls;
  }

  get postInvestmentReportFormValues() {
    return this.postInvestmentReportForm.controls;
  }


  ngOnInit(): void {

    this.quillConfig = {
      toolbar: [
        [{ font: [] }, { size: [] }],
        ["bold", "italic", "underline", "strike"],
        [{ color: [] }, { background: [] }],
        [{ script: "super" }, { script: "sub" }],
        [{ header: [!1, 1, 2, 3, 4, 5, 6] }, "blockquote", "code-block"],
        [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
        ["direction", { align: [] }],
        ["link", "image", "video"],
        ["clean"]
      ]
    };

    this.yearPickerForm = this.fb.group({
      year: ['', Validators.required],
    });

    this.economicEnvironmentRemarkForm = this.fb.group({
      remark: ["", Validators.required],
    });

    this.profitAndLossRemarkForm = this.fb.group({
      remark: ["", Validators.required],
    });

    this.productSalesRemarkForm = this.fb.group({
      remark: ["", Validators.required],
    });

    this.postInvestmentReportForm = this.fb.group({
      finalRemark: ["", Validators.required],
      recommendation: ["", Validators.required],
    });

    this.productSalesActualForm = this.fb.group({
      fuelAndLubricantVolumeActual: [''],
      gasVolumeActual: [''],
    });

    this.profitAndLossActualForm = this.fb.group({
      netFuelMargin: [''],
      diversification: [''],
      otherRevenue: [''],
      operatingExpenses: [''],
      rentalsAndFeesDue: [''],
      ebitda: [''],
      investments: [''],
      changeInWorkingCapital: [''],
      taxes: [''],
      otherCashItems: [''],
      netCashAfterTax: [''],
      FinancialResult: [''],
      netCashAfterTaxDiscounted: [''],
      netCashAfterTaxRealAndDiscounted: [''],
    });

    this.economicEnvironmentActualForm = this.fb.group({
      inflation: [''],
      interestRate: [''],
      corporateTaxRate: [''],
      exchangeRate: [''],
    });

    this.subscription.add(
      this.yearPickerForm.get("year").valueChanges.subscribe(x => {
        this.loadYearData(x);
        this.pickedYear = x;
        if (x === "1") { this.yearPicked = "Year 1"; };
        if (x === "2") { this.yearPicked = "Year 2"; };
        if (x === "3") { this.yearPicked = "Year 3"; };
        if (x === "4") { this.yearPicked = "Year 4"; };
        if (x === "5") { this.yearPicked = "Year 5"; };
      }));

    this.yearArray = [
      {
        "name": "Year 1",
        "year": 1
      },
      {
        "name": "Year 2",
        "year": 2
      },
      {
        "name": "Year 3",
        "year": 3
      },
      {
        "name": "Year 4",
        "year": 4
      },
      {
        "name": "Year 5",
        "year": 5
      },
    ]
  }

  loadYearData(x: any) {
    if (this.projectArray) {
      if (this.projectArray[0]) {

        if (this.projectArray[0].fuelVolumeAndMarginGrowth) {
          if (this.projectArray[0].fuelVolumeAndMarginGrowth.gasVolume) {
            if (this.projectArray[0].fuelVolumeAndMarginGrowth.gasVolume[x]) {
              this.pickedYearGasVolume = this.projectArray[0].fuelVolumeAndMarginGrowth.gasVolume[x]
            };
          };
        };

        if (this.projectArray[0].fuelVolumeAndMarginGrowth) {
          if (this.projectArray[0].fuelVolumeAndMarginGrowth.fuelAndLubricantVolume) {
            if (this.projectArray[0].fuelVolumeAndMarginGrowth.fuelAndLubricantVolume[x]) {
              this.pickedYearFuelAndLubricantVolume = this.projectArray[0].fuelVolumeAndMarginGrowth.fuelAndLubricantVolume[x]
            };
          };
        };

        if (this.projectArray[0].economicEnvironment) {
          if (this.projectArray[0].economicEnvironment.yearlyEscalation) {
            if (this.projectArray[0].economicEnvironment.yearlyEscalation.inflationRate) {
              if (this.projectArray[0].economicEnvironment.yearlyEscalation.inflationRate[x]) {

                this.pickedYearInflationRate = Number(this.projectArray[0].economicEnvironment.yearlyEscalation.inflationRate[x]);

              }
            }
          }
        }

        if (this.projectArray[0].country) {
          if (this.projectArray[0].country.corporateTaxRate) {
            this.pickedYearCorporateTaxRate = Number(this.projectArray[0].country.corporateTaxRate);
          }

          if (this.projectArray[0].country.usdFxRate) {
            this.pickedYearExchangeRate = Number(this.projectArray[0].country.usdFxRate);
          }
        }
        if (this.projectArray[0].companyFinancing.interestRate) {
          this.pickedYearInterestRate = Number(this.projectArray[0].companyFinancing.interestRate);
        }

        if (this.projectArray[0].companySummary) {
          if (this.projectArray[0].companySummary.yearlyEscalation) {
            if (this.projectArray[0].companySummary.yearlyEscalation.netFuelMargin) {
              if (this.projectArray[0].companySummary.yearlyEscalation.netFuelMargin[x]) {
                this.pickedYearNetFuelMargin = this.projectArray[0].companySummary.yearlyEscalation.netFuelMargin[x];
              }
            }
            if (this.projectArray[0].companySummary.yearlyEscalation.companyDiversificationMargin) {
              if (this.projectArray[0].companySummary.yearlyEscalation.companyDiversificationMargin[x]) {
                this.pickedYearDiversification = this.projectArray[0].companySummary.yearlyEscalation.companyDiversificationMargin[x];
              }
            }
            if (this.projectArray[0].companySummary.yearlyEscalation.companyOtherRevenue) {
              if (this.projectArray[0].companySummary.yearlyEscalation.companyOtherRevenue[x]) {
                this.pickedYearOtherRevenue = this.projectArray[0].companySummary.yearlyEscalation.companyOtherRevenue[x];
              }
            }
            if (this.projectArray[0].companySummary.yearlyEscalation.companyOperatingExpenses) {
              if (this.projectArray[0].companySummary.yearlyEscalation.companyOperatingExpenses[x]) {
                this.pickedYearOperatingExpenses = this.projectArray[0].companySummary.yearlyEscalation.companyOperatingExpenses[x];
              }
            }
            if (this.projectArray[0].companySummary.yearlyEscalation.companyRentalsAndFeesDue) {
              if (this.projectArray[0].companySummary.yearlyEscalation.companyRentalsAndFeesDue[x]) {
                this.pickedYearRentalsAndFeesDue = this.projectArray[0].companySummary.yearlyEscalation.companyRentalsAndFeesDue[x];
              }
            }
            if (this.projectArray[0].companySummary.yearlyEscalation.ebitda) {
              if (this.projectArray[0].companySummary.yearlyEscalation.ebitda[x]) {
                this.pickedYearEBITDA = this.projectArray[0].companySummary.yearlyEscalation.ebitda[x];
              }
            }
            if (this.projectArray[0].companySummary.yearlyEscalation.companyInvestment) {
              if (this.projectArray[0].companySummary.yearlyEscalation.companyInvestment[x]) {
                this.pickedYearInvestments = this.projectArray[0].companySummary.yearlyEscalation.companyInvestment[x];
              }
            }
            if (this.projectArray[0].companySummary.yearlyEscalation.changeInWorkingCapital) {
              if (this.projectArray[0].companySummary.yearlyEscalation.changeInWorkingCapital[x]) {
                this.pickedYearChangeInWorkingCapital = this.projectArray[0].companySummary.yearlyEscalation.changeInWorkingCapital[x];
              }
            }
            if (this.projectArray[0].companySummary.yearlyEscalation.taxAmount) {
              if (this.projectArray[0].companySummary.yearlyEscalation.taxAmount[x]) {
                this.pickedYearTaxes = this.projectArray[0].companySummary.yearlyEscalation.taxAmount[x];
              }
            }

            if (this.projectArray[0].companySummary.yearlyEscalation.otherCashItems) {
              if (this.projectArray[0].companySummary.yearlyEscalation.otherCashItems[x]) {
                this.pickedYearOtherCashItems = this.projectArray[0].companySummary.yearlyEscalation.otherCashItems[x];
              }
            }
            if (this.projectArray[0].companySummary.yearlyEscalation.netCashAfterTax) {
              if (this.projectArray[0].companySummary.yearlyEscalation.netCashAfterTax[x]) {
                this.pickedYearNetCashAfterTax = this.projectArray[0].companySummary.yearlyEscalation.netCashAfterTax[x];
              }
            }
            if (this.projectArray[0].companySummary.yearlyEscalation.financialResult) {
              if (this.projectArray[0].companySummary.yearlyEscalation.financialResult[x]) {
                this.pickedYearFinancialResult = this.projectArray[0].companySummary.yearlyEscalation.financialResult[x];
              }
            }
            if (this.projectArray[0].companySummary.yearlyEscalation.netCashAfterTaxDiscounted) {
              if (this.projectArray[0].companySummary.yearlyEscalation.netCashAfterTaxDiscounted[x]) {
                this.pickedYearNetCashAfterTaxDiscounted = this.projectArray[0].companySummary.yearlyEscalation.netCashAfterTaxDiscounted[x];
              }
            }
            if (this.projectArray[0].companySummary.yearlyEscalation.discountedNetCashAfterTaxReal) {
              if (this.projectArray[0].companySummary.yearlyEscalation.discountedNetCashAfterTaxReal[x]) {
                this.pickedYearNetCashAfterTaxRealAndDiscounted = this.projectArray[0].companySummary.yearlyEscalation.discountedNetCashAfterTaxReal[x];
              }
            }
          }
        }

        // Post-Investment
        if (this.projectArray[0].actualPerformance) {
          if (this.projectArray[0].actualPerformance.yearlyEscalation) {
            if (this.projectArray[0].actualPerformance.yearlyEscalation.netFuelMargin) {
              if (this.projectArray[0].actualPerformance.yearlyEscalation.netFuelMargin[x]) {
                this.pickedYearNetFuelMarginActual = this.projectArray[0].actualPerformance.yearlyEscalation.netFuelMargin[x];
              }
            }
            if (this.projectArray[0].actualPerformance.yearlyEscalation.companyDiversificationMargin) {
              if (this.projectArray[0].actualPerformance.yearlyEscalation.companyDiversificationMargin[x]) {
                this.pickedYearDiversificationActual = this.projectArray[0].actualPerformance.yearlyEscalation.companyDiversificationMargin[x];
              }
            }
            if (this.projectArray[0].actualPerformance.yearlyEscalation.companyOtherRevenue) {
              if (this.projectArray[0].actualPerformance.yearlyEscalation.companyOtherRevenue[x]) {
                this.pickedYearOtherRevenueActual = this.projectArray[0].actualPerformance.yearlyEscalation.companyOtherRevenue[x];
              }
            }
            if (this.projectArray[0].actualPerformance.yearlyEscalation.companyOperatingExpenses) {
              if (this.projectArray[0].actualPerformance.yearlyEscalation.companyOperatingExpenses[x]) {
                this.pickedYearOperatingExpensesActual = this.projectArray[0].actualPerformance.yearlyEscalation.companyOperatingExpenses[x];
              }
            }
            if (this.projectArray[0].actualPerformance.yearlyEscalation.companyRentalsAndFeesDue) {
              if (this.projectArray[0].actualPerformance.yearlyEscalation.companyRentalsAndFeesDue[x]) {
                this.pickedYearRentalsAndFeesDueActual = this.projectArray[0].actualPerformance.yearlyEscalation.companyRentalsAndFeesDue[x];
              }
            }
            if (this.projectArray[0].actualPerformance.yearlyEscalation.ebitda) {
              if (this.projectArray[0].actualPerformance.yearlyEscalation.ebitda[x]) {
                this.pickedYearEBITDA = this.projectArray[0].actualPerformance.yearlyEscalation.ebitda[x];
              }
            }
            if (this.projectArray[0].actualPerformance.yearlyEscalation.companyInvestment) {
              if (this.projectArray[0].actualPerformance.yearlyEscalation.companyInvestment[x]) {
                this.pickedYearInvestmentsActual = this.projectArray[0].actualPerformance.yearlyEscalation.companyInvestment[x];
              }
            }
            if (this.projectArray[0].actualPerformance.yearlyEscalation.changeInWorkingCapital) {
              if (this.projectArray[0].actualPerformance.yearlyEscalation.changeInWorkingCapital[x]) {
                this.pickedYearChangeInWorkingCapitalActual = this.projectArray[0].actualPerformance.yearlyEscalation.changeInWorkingCapital[x];
              }
            }
            if (this.projectArray[0].actualPerformance.yearlyEscalation.taxAmount) {
              if (this.projectArray[0].actualPerformance.yearlyEscalation.taxAmount[x]) {
                this.pickedYearTaxesActual = this.projectArray[0].actualPerformance.yearlyEscalation.taxAmount[x];
              }
            }

            if (this.projectArray[0].actualPerformance.yearlyEscalation.otherCashItems) {
              if (this.projectArray[0].actualPerformance.yearlyEscalation.otherCashItems[x]) {
                this.pickedYearOtherCashItemsActual = this.projectArray[0].actualPerformance.yearlyEscalation.otherCashItems[x];
              }
            }
            if (this.projectArray[0].actualPerformance.yearlyEscalation.netCashAfterTax) {
              if (this.projectArray[0].actualPerformance.yearlyEscalation.netCashAfterTax[x]) {
                this.pickedYearNetCashAfterTaxActual = this.projectArray[0].actualPerformance.yearlyEscalation.netCashAfterTax[x];
              }
            }
            if (this.projectArray[0].actualPerformance.yearlyEscalation.financialResult) {
              if (this.projectArray[0].actualPerformance.yearlyEscalation.financialResult[x]) {
                this.pickedYearFinancialResultActual = this.projectArray[0].actualPerformance.yearlyEscalation.financialResult[x];
              }
            }
            if (this.projectArray[0].actualPerformance.yearlyEscalation.netCashAfterTaxDiscounted) {
              if (this.projectArray[0].actualPerformance.yearlyEscalation.netCashAfterTaxDiscounted[x]) {
                this.pickedYearNetCashAfterTaxDiscountedActual = this.projectArray[0].actualPerformance.yearlyEscalation.netCashAfterTaxDiscounted[x];
              }
            }
            if (this.projectArray[0].actualPerformance.yearlyEscalation.discountedNetCashAfterTaxReal) {
              if (this.projectArray[0].actualPerformance.yearlyEscalation.discountedNetCashAfterTaxReal[x]) {
                this.pickedYearNetCashAfterTaxRealAndDiscountedActual = this.projectArray[0].actualPerformance.yearlyEscalation.discountedNetCashAfterTaxReal[x];
              }
            }
          }
        }

        // Product Volume - Actual
        // if (this.projectArray[0].actualVolume) {

        // }

        // Product Volume
        if (this.projectArray[0].fuelProduct) {
          let xy: any = [];

          xy = this.projectArray[0].fuelProduct;

          if (xy[0]) {
            let industryAbbreviation = "";
            let name = "";
            let displayName = "";
            let category = "";
            let volume = 0;

            if (xy[0].yearlyVolume) {
              volume = xy[0].yearlyVolume;
            }
            if (xy[0].yearlyVolume) {
              category = xy[0].petroleumProductCategory_name;
            }
            if (xy[0].industryAbbreviation) {
              industryAbbreviation = xy[0].industryAbbreviation;
            }
            if (xy[0].name) {
              name = xy[0].name;
            }

            displayName = `${name} (${industryAbbreviation})`;

            this.productOne = {
              "volume": volume,
              "name": displayName,
              "category": category
            };
          }

          if (xy[1]) {
            let industryAbbreviation = "";
            let name = "";
            let displayName = "";
            let category = "";
            let volume = 0;

            if (xy[1].yearlyVolume) {
              volume = xy[1].yearlyVolume;
            }
            if (xy[1].yearlyVolume) {
              category = xy[1].petroleumProductCategory_name;
            }
            if (xy[1].industryAbbreviation) {
              industryAbbreviation = xy[1].industryAbbreviation;
            }
            if (xy[1].name) {
              name = xy[1].name;
            }

            displayName = `${name} (${industryAbbreviation})`;

            this.productTwo = {
              "volume": volume,
              "name": displayName,
              "category": category
            };
          }

          if (xy[2]) {
            let industryAbbreviation = "";
            let name = "";
            let displayName = "";
            let category = "";
            let volume = 0;

            if (xy[2].yearlyVolume) {
              volume = xy[2].yearlyVolume;
            }
            if (xy[2].yearlyVolume) {
              category = xy[2].petroleumProductCategory_name;
            }
            if (xy[2].industryAbbreviation) {
              industryAbbreviation = xy[2].industryAbbreviation;
            }
            if (xy[2].name) {
              name = xy[2].name;
            }

            displayName = `${name} (${industryAbbreviation})`;

            this.productThree = {
              "volume": volume,
              "name": displayName,
              "category": category
            };
          }


        };


      }


    }
  };

  saveEconomicEnvironmentActual(form: any) {
    this.updateInProgress = true;

    // Check
    if (!this.pickedYear) {
      this.show_alert = true;
      this.updateInProgress = false;
      this.submitInProgress = false;
      this.alert_message = "Pick a year 1st!";
      this.alert_type = "danger";

      this.toastr.error('Pick a year 1st!', 'Error!');

      setTimeout(() => {
        this.show_alert = false;
        this.updateInProgress = false;
        this.submitInProgress = false;
        this.selfClosingAlert.close();
      }, 3000);
      return;
    }


  }

  saveProductSalesVolumeActual(form: any) {
    this.updateInProgress = true;

    // Check
    if (!this.pickedYear) {
      this.show_alert = true;
      this.updateInProgress = false;
      this.submitInProgress = false;
      this.alert_message = "Pick a year 1st!";
      this.alert_type = "danger";

      this.toastr.error('Pick a year 1st!', 'Error!');

      setTimeout(() => {
        this.show_alert = false;
        this.updateInProgress = false;
        this.submitInProgress = false;
        this.selfClosingAlert.close();
      }, 3000);
      return;
    }

  }

  printPostInvestmentSummaryPDF() {

    let date = new Date();
    let year = date.getFullYear();
    let month = ((date.getMonth() + 1).toString()).padStart(2, '0');
    let day = ((date.getDate()).toString()).padStart(2, '0');

    //
    this.dateToday = `${day}-${month}-${year}`;

    let tableOfContentArray = [];

    this.notify("Downloading Post Investment Summary ...");

    const param = {
      outputType: "save",
      returnJsPDFDocObject: false,
      fileName: `${this.projectName} - Post Investment Summary - ${this.dateToday}.pdf`,
      orientationLandscape: false,
      compress: true,
      pageEnable: true,
      pageLabel: "Page",
    };

    var doc = new jsPDF(param.orientationLandscape ? "landscape" : "p", "mm", "a4", param.compress);

    // add the font to jsPDF
    doc.addFileToVFS("Volkhov.ttf", Volkhov);
    doc.addFont('Volkhov.ttf', 'Volkhov', 'normal');

    var docWidth = doc.internal.pageSize.width;
    var docHeight = doc.internal.pageSize.height;

    var colorBlack = "#000000";
    var colorGray = "#4d4e53";
    var colorYellow = "#CDAE59";
    var colorBlue = "#353E59";
    var colorRed = "#fa5c7c";
    var colorGreen = "#0acf97";
    //starting at 15mm
    var currentHeight = 15;
    //var startPointRectPanel1 = currentHeight + 6;

    var pdfConfig = {
      headerTextSize: 16,
      labelTextSize: 12,
      fieldTextSize: 11,
      lineHeight: 9,
      subLineHeight: 5,
      headerLineHeight: 6,
      headerSubLineHeight: 4,
      headerFieldTextSize: 10,
    };

    var addHeader = function () {
      currentHeight = 15;
      // Logo Image
      var imageHeader = new Image();
      imageHeader.src = "../../../../assets/images/logo/logo_315_x_315.png";
      doc.addImage(
        imageHeader,
        "JPEG",
        10 + 0,
        currentHeight - 5 + 0,
        35,
        35
      );

      doc.setTextColor(colorGray);
      doc.setFontSize(pdfConfig.headerFieldTextSize);

      currentHeight += pdfConfig.headerSubLineHeight;
      doc.text("Delta Office Suites 2nd floor,", docWidth - 10, currentHeight, { align: 'right' });
      currentHeight += pdfConfig.headerSubLineHeight;
      doc.text("Block B Off Waiyaki Way &", docWidth - 10, currentHeight, { align: 'right' });
      currentHeight += pdfConfig.headerSubLineHeight;
      doc.text("Manyani West Road, Muthangari.", docWidth - 10, currentHeight, { align: 'right' });
      currentHeight += pdfConfig.headerSubLineHeight;
      currentHeight += pdfConfig.headerSubLineHeight;
      doc.text("+254 709 497 000", docWidth - 10, currentHeight, { align: 'right' });
      doc.setFontSize(pdfConfig.headerFieldTextSize);
      // doc.setTextColor(colorGray);
      currentHeight += pdfConfig.headerSubLineHeight;
      doc.text("info@galanaenergies.com", docWidth - 10, currentHeight, { align: 'right' });

      currentHeight += pdfConfig.headerSubLineHeight;
      doc.text("www.galanaenergies.com", docWidth - 10, currentHeight, { align: 'right' });

      //line breaker after logo & business info
      doc.line(10, 45, docWidth - 10, 45);
      currentHeight = 45;
    }

    addHeader();

    // Start
    doc.setFont('Volkhov', 'normal');
    doc.setTextColor(colorBlack);
    doc.setFontSize(12);

    currentHeight += pdfConfig.lineHeight;

    doc.setFontSize(16);
    doc.text(`Post Investment Summary`, docWidth / 2, currentHeight, { align: 'center' },);
    currentHeight += pdfConfig.subLineHeight;

    doc.line(25, currentHeight, docWidth - 25, currentHeight);

    doc.setFontSize(12);

    doc.line(5, currentHeight, docWidth - 5, currentHeight);

    currentHeight += pdfConfig.lineHeight;

    doc.setFontSize(14);
    doc.setTextColor(colorBlack);
    doc.text("1.", 10, currentHeight, { align: 'left' },);
    doc.text("Economic Environment.", 15, currentHeight, { align: 'left' },);
    doc.setFontSize(12);

    currentHeight += pdfConfig.subLineHeight;

    doc.line(5, currentHeight, docWidth - 5, currentHeight);

    currentHeight += pdfConfig.lineHeight;

    doc.text(`Projection - ${this.yearPicked}`, 90, currentHeight, { align: 'center' },);
    doc.text(`Actual  - ${this.yearPicked}`, 155, currentHeight, { align: 'center' },);

    currentHeight += pdfConfig.lineHeight;

    doc.text(`Inflation :`, 15, currentHeight, { align: 'left' },);

    doc.setDrawColor(colorGray);
    doc.roundedRect(60, (currentHeight - 4), 60, 5, 1, 1);
    doc.setDrawColor(0);
    if (this.pickedYearInflationRate) {
      doc.text(`${formatCurrency((this.pickedYearInflationRate * 100), 'en-US', '', '', '1.2-2')}`, 90, currentHeight, { align: 'center' },);
    } else { doc.text(`-`, 90, currentHeight, { align: 'center' },); };

    doc.setDrawColor(114, 124, 245);
    doc.roundedRect(125, (currentHeight - 4), 60, 5, 1, 1);
    doc.setDrawColor(0);
    if (this.pickedYearInflationRateActual) {
      doc.text(`${formatCurrency((this.pickedYearInflationRateActual), 'en-US', '', '', '1.2-2')}`, 155, currentHeight, { align: 'center' },);
    } else { doc.text(`-`, 155, currentHeight, { align: 'center' },); };

    currentHeight += pdfConfig.lineHeight;

    doc.text(`Interest Rate :`, 15, currentHeight, { align: 'left' },);

    doc.setDrawColor(colorGray);
    doc.roundedRect(60, (currentHeight - 4), 60, 5, 1, 1);
    doc.setDrawColor(0);
    if (this.pickedYearInterestRate) {
      doc.text(`${formatCurrency((this.pickedYearInterestRate), 'en-US', '', '', '1.2-2')}`, 90, currentHeight, { align: 'center' },);
    } else { doc.text(`-`, 90, currentHeight, { align: 'center' },); };

    doc.setDrawColor(114, 124, 245);
    doc.roundedRect(125, (currentHeight - 4), 60, 5, 1, 1);
    doc.setDrawColor(0);
    if (this.pickedYearInterestRateActual) {
      doc.text(`${formatCurrency((this.pickedYearInterestRateActual), 'en-US', '', '', '1.2-2')}`, 155, currentHeight, { align: 'center' },);
    } else { doc.text(`-`, 155, currentHeight, { align: 'center' },); };

    currentHeight += pdfConfig.lineHeight;

    doc.text(`Exchange Rate :`, 15, currentHeight, { align: 'left' },);

    doc.setDrawColor(colorGray);
    doc.roundedRect(60, (currentHeight - 4), 60, 5, 1, 1);
    doc.setDrawColor(0);
    if (this.pickedYearExchangeRate) {
      doc.text(`${formatCurrency((this.pickedYearExchangeRate), 'en-US', '', '', '1.2-2')}`, 90, currentHeight, { align: 'center' },);
    } else { doc.text(`-`, 90, currentHeight, { align: 'center' },); };

    doc.setDrawColor(114, 124, 245);
    doc.roundedRect(125, (currentHeight - 4), 60, 5, 1, 1);
    doc.setDrawColor(0);
    if (this.pickedYearExchangeRateActual) {
      doc.text(`${formatCurrency((this.pickedYearExchangeRateActual), 'en-US', '', '', '1.2-2')}`, 155, currentHeight, { align: 'center' },);
    } else { doc.text(`-`, 155, currentHeight, { align: 'center' },); };

    currentHeight += pdfConfig.lineHeight;

    doc.text(`Corporate Tax Rate :`, 15, currentHeight, { align: 'left' },);

    doc.setDrawColor(colorGray);
    doc.roundedRect(60, (currentHeight - 4), 60, 5, 1, 1);
    doc.setDrawColor(0);
    if (this.pickedYearCorporateTaxRate) {
      doc.text(`${formatCurrency((this.pickedYearCorporateTaxRate), 'en-US', '', '', '1.2-2')}`, 90, currentHeight, { align: 'center' },);
    } else { doc.text(`-`, 90, currentHeight, { align: 'center' },); };

    doc.setDrawColor(114, 124, 245);
    doc.roundedRect(125, (currentHeight - 4), 60, 5, 1, 1);
    doc.setDrawColor(0);
    if (this.pickedYearCorporateTaxRateActual) {
      doc.text(`${formatCurrency((this.pickedYearCorporateTaxRateActual), 'en-US', '', '', '1.2-2')}`, 155, currentHeight, { align: 'center' },);
    } else { doc.text(`-`, 155, currentHeight, { align: 'center' },); };

    currentHeight += pdfConfig.lineHeight;

    if (this.economicEnvironmentRemarkArray) {
      doc.line(50, currentHeight, docWidth - 50, currentHeight);

      currentHeight += pdfConfig.subLineHeight;
      doc.text(`Remarks :`, 15, currentHeight, { align: 'left' },);
      currentHeight += pdfConfig.subLineHeight;
      let x = [];
      let arrayLength = 0;
      let lastItemPosition;
      let secondLastItemPosition;
      x = this.economicEnvironmentRemarkArray;
      arrayLength = this.economicEnvironmentRemarkArray.length;

      if (arrayLength > 2) {
        lastItemPosition = (arrayLength - 1);
        secondLastItemPosition = (arrayLength - 1);
      } else {
        if (arrayLength === 1) {
          lastItemPosition = (arrayLength - 1);
        }
        if (arrayLength === 2) {
          lastItemPosition = (arrayLength - 1);
          secondLastItemPosition = (arrayLength - 1);
        }
      }

      if (secondLastItemPosition) {
        doc.setDrawColor(128, 128, 128);
        doc.roundedRect(20, (currentHeight - 7), (((docWidth / 3) * 2) - 30), 20, 1, 1);
        doc.setFillColor(235, 235, 235);
        doc.roundedRect(20, (currentHeight - 5), (((docWidth / 3) * 2) - 70), 16, 1, 1, "F");
        doc.setDrawColor(0);

        let firstName = "";
        let surname = "";
        let otherName = "";
        let displayName = "";

        if (x[secondLastItemPosition].from) {
          if (x[secondLastItemPosition].remark) {
            var textLines = doc.setFontSize(10).splitTextToSize(`${x[secondLastItemPosition].remark}`, 80);
            doc.text(textLines, ((docWidth / 3) + 15), currentHeight);
            // currentHeight += (pdfConfig.subLineHeight) * (textLines.length);
          };
          if (x[secondLastItemPosition].from.firstName) { firstName = x[secondLastItemPosition].from.firstName; };
          if (x[secondLastItemPosition].from.surname) { surname = x[secondLastItemPosition].from.surname; };
          if (x[secondLastItemPosition].from.otherName) { otherName = x[secondLastItemPosition].from.otherName };
          displayName = `${firstName} ${(surname).substring(0, 1)} ${(otherName).substring(0, 1)}`;
          if (displayName) {
            doc.setFontSize(10);
            doc.text(`${displayName}`, (docWidth - 40), currentHeight, { align: 'center' },);
            currentHeight += pdfConfig.subLineHeight;
            doc.setFontSize(12);
          }
          if (x[secondLastItemPosition].from.role) {
            doc.setTextColor(colorGray);
            doc.setFontSize(10);
            doc.text(`${x[secondLastItemPosition].from.role}`, (docWidth - 40), currentHeight, { align: 'center' },);
            currentHeight += pdfConfig.subLineHeight;
            doc.setFontSize(12);
            doc.setTextColor(colorBlack);
          };
          if (x[secondLastItemPosition].date) {
            doc.setTextColor(colorGray);
            doc.setFontSize(10);
            doc.text(`${formatDate(x[secondLastItemPosition].date, 'dd/MM/yy HH:mm', 'en-US')}`, (docWidth - 40), currentHeight, { align: 'center' },);
            currentHeight += pdfConfig.subLineHeight;
            doc.setFontSize(12);
            doc.setTextColor(colorBlack);
          };


        };
      }
      if (lastItemPosition) {
        doc.setDrawColor(128, 128, 128);
        doc.roundedRect(20, (currentHeight - 7), (((docWidth / 3) * 2) - 30), 20, 1, 1);
        doc.setFillColor(235, 235, 235);
        doc.roundedRect(20, (currentHeight - 5), (((docWidth / 3) * 2) - 70), 16, 1, 1, "F");
        doc.setDrawColor(0);

        let firstName = "";
        let surname = "";
        let otherName = "";
        let displayName = "";

        if (x[lastItemPosition].from) {
          if (x[lastItemPosition].remark) {
            var textLines = doc.setFontSize(10).splitTextToSize(`${x[lastItemPosition].remark}`, 80);
            doc.text(textLines, ((docWidth / 3) + 15), currentHeight);
            // currentHeight += (pdfConfig.subLineHeight) * (textLines.length);
          };
          if (x[lastItemPosition].from.firstName) { firstName = x[lastItemPosition].from.firstName; };
          if (x[lastItemPosition].from.surname) { surname = x[lastItemPosition].from.surname; };
          if (x[lastItemPosition].from.otherName) { otherName = x[lastItemPosition].from.otherName };
          displayName = `${firstName} ${(surname).substring(0, 1)} ${(otherName).substring(0, 1)}`;
          if (displayName) {
            doc.setFontSize(10);
            doc.text(`${displayName}`, (docWidth - 40), currentHeight, { align: 'center' },);
            currentHeight += pdfConfig.subLineHeight;
            doc.setFontSize(12);
          }
          if (x[lastItemPosition].from.role) {
            doc.setTextColor(colorGray);
            doc.setFontSize(10);
            doc.text(`${x[lastItemPosition].from.role}`, (docWidth - 40), currentHeight, { align: 'center' },);
            currentHeight += pdfConfig.subLineHeight;
            doc.setFontSize(12);
            doc.setTextColor(colorBlack);
          };
          if (x[lastItemPosition].date) {
            doc.setTextColor(colorGray);
            doc.setFontSize(10);
            doc.text(`${formatDate(x[lastItemPosition].date, 'dd/MM/yy HH:mm', 'en-US')}`, (docWidth - 40), currentHeight, { align: 'center' },);
            currentHeight += pdfConfig.subLineHeight;
            doc.setFontSize(12);
            doc.setTextColor(colorBlack);
          };


        };
      }


    } else {
      doc.line(50, currentHeight, docWidth - 50, currentHeight);

      currentHeight += pdfConfig.subLineHeight;
      doc.text(`Remarks :`, 27, currentHeight, { align: 'left' },);
      currentHeight += pdfConfig.subLineHeight;

      doc.setDrawColor(128, 128, 128);
      doc.roundedRect(60, (currentHeight - 7), 130, 20, 1, 1);
      doc.setFillColor(235, 235, 235);
      doc.roundedRect(62, (currentHeight - 5), 90, 16, 1, 1, "F");
      doc.setDrawColor(0);

      currentHeight += 20;

      doc.setDrawColor(128, 128, 128);
      doc.roundedRect(60, (currentHeight - 7), 130, 20, 1, 1);
      doc.setFillColor(235, 235, 235);
      doc.roundedRect(62, (currentHeight - 5), 90, 16, 1, 1, "F");
      doc.setDrawColor(0);

      currentHeight += 15;
    };

    // currentHeight += pdfConfig.subLineHeight;
    doc.line(5, currentHeight, docWidth - 5, currentHeight);

    currentHeight += pdfConfig.lineHeight;

    doc.setFontSize(14);
    doc.setTextColor(colorBlack);
    doc.text("2.", 10, currentHeight, { align: 'left' },);
    doc.text("Product Sales Volume.", 15, currentHeight, { align: 'left' },);
    doc.setFontSize(12);

    currentHeight += pdfConfig.subLineHeight;

    doc.line(5, currentHeight, docWidth - 5, currentHeight);

    currentHeight += pdfConfig.lineHeight;

    doc.text(`Projection - ${this.yearPicked}`, 90, currentHeight, { align: 'center' },);
    doc.text(`Actual  - ${this.yearPicked}`, 155, currentHeight, { align: 'center' },);

    currentHeight += pdfConfig.lineHeight;

    doc.text(`Fuel + Lubricant (m3):`, 15, currentHeight, { align: 'left' },);

    doc.setDrawColor(colorGray);
    doc.roundedRect(60, (currentHeight - 4), 60, 5, 1, 1);
    doc.setDrawColor(0);
    if (this.pickedYearFuelAndLubricantVolume) {
      doc.text(`${formatCurrency((this.pickedYearFuelAndLubricantVolume), 'en-US', '', '', '1.2-2')}`, 90, currentHeight, { align: 'center' },);
    } else { doc.text(`-`, 90, currentHeight, { align: 'center' },); };

    doc.setDrawColor(114, 124, 245);
    doc.roundedRect(125, (currentHeight - 4), 60, 5, 1, 1);
    doc.setDrawColor(0);
    if (this.pickedYearFuelAndLubricantVolumeActual) {
      doc.text(`${formatCurrency((this.pickedYearFuelAndLubricantVolumeActual), 'en-US', '', '', '1.2-2')}`, 155, currentHeight, { align: 'center' },);
    } else { doc.text(`-`, 155, currentHeight, { align: 'center' },); };

    currentHeight += pdfConfig.lineHeight;

    doc.text(`Gas (m3) :`, 15, currentHeight, { align: 'left' },);

    doc.setDrawColor(colorGray);
    doc.roundedRect(60, (currentHeight - 4), 60, 5, 1, 1);
    doc.setDrawColor(0);
    if (this.pickedYearGasVolume) {
      doc.text(`${formatCurrency((this.pickedYearGasVolume), 'en-US', '', '', '1.2-2')}`, 90, currentHeight, { align: 'center' },);
    } else { doc.text(`-`, 90, currentHeight, { align: 'center' },); };

    doc.setDrawColor(114, 124, 245);
    doc.roundedRect(125, (currentHeight - 4), 60, 5, 1, 1);
    doc.setDrawColor(0);
    if (this.pickedYearGasVolumeActual) {
      doc.text(`${formatCurrency((this.pickedYearGasVolumeActual), 'en-US', '', '', '1.2-2')}`, 155, currentHeight, { align: 'center' },);
    } else { doc.text(`-`, 155, currentHeight, { align: 'center' },); };

    currentHeight += pdfConfig.lineHeight;

    if (this.productSalesRemarkArray) {
      doc.line(50, currentHeight, docWidth - 50, currentHeight);

      currentHeight += pdfConfig.subLineHeight;
      doc.text(`Remarks :`, 15, currentHeight, { align: 'left' },);
      currentHeight += pdfConfig.subLineHeight;
      let x = [];
      let arrayLength = 0;
      let lastItemPosition;
      let secondLastItemPosition;
      x = this.productSalesRemarkArray;
      arrayLength = this.productSalesRemarkArray.length;

      if (arrayLength > 2) {
        lastItemPosition = (arrayLength - 1);
        secondLastItemPosition = (arrayLength - 1);
      } else {
        if (arrayLength === 1) {
          lastItemPosition = (arrayLength - 1);
        }
        if (arrayLength === 2) {
          lastItemPosition = (arrayLength - 1);
          secondLastItemPosition = (arrayLength - 1);
        }
      }

      if (secondLastItemPosition) {
        doc.setDrawColor(128, 128, 128);
        doc.roundedRect(60, (currentHeight - 7), 130, 20, 1, 1);
        doc.setFillColor(235, 235, 235);
        doc.roundedRect(62, (currentHeight - 5), 90, 16, 1, 1, "F");
        doc.setDrawColor(0);

        let firstName = "";
        let surname = "";
        let otherName = "";
        let displayName = "";

        if (x[secondLastItemPosition].from) {
          if (x[secondLastItemPosition].remark) {
            var textLines = doc.setFontSize(10).splitTextToSize(`${x[secondLastItemPosition].remark}`, 80);
            doc.text(textLines, ((docWidth / 3) + 15), currentHeight);
            // currentHeight += (pdfConfig.subLineHeight) * (textLines.length);
          };
          if (x[secondLastItemPosition].from.firstName) { firstName = x[secondLastItemPosition].from.firstName; };
          if (x[secondLastItemPosition].from.surname) { surname = x[secondLastItemPosition].from.surname; };
          if (x[secondLastItemPosition].from.otherName) { otherName = x[secondLastItemPosition].from.otherName };
          displayName = `${firstName} ${(surname).substring(0, 1)} ${(otherName).substring(0, 1)}`;
          if (displayName) {
            doc.setFontSize(10);
            doc.text(`${displayName}`, (docWidth - 40), currentHeight, { align: 'center' },);
            currentHeight += pdfConfig.subLineHeight;
            doc.setFontSize(12);
          }
          if (x[secondLastItemPosition].from.role) {
            doc.setTextColor(colorGray);
            doc.setFontSize(10);
            doc.text(`${x[secondLastItemPosition].from.role}`, (docWidth - 40), currentHeight, { align: 'center' },);
            currentHeight += pdfConfig.subLineHeight;
            doc.setFontSize(12);
            doc.setTextColor(colorBlack);
          };
          if (x[secondLastItemPosition].date) {
            doc.setTextColor(colorGray);
            doc.setFontSize(10);
            doc.text(`${formatDate(x[secondLastItemPosition].date, 'dd/MM/yy HH:mm', 'en-US')}`, (docWidth - 40), currentHeight, { align: 'center' },);
            currentHeight += pdfConfig.subLineHeight;
            doc.setFontSize(12);
            doc.setTextColor(colorBlack);
          };


        };
      }
      if (lastItemPosition) {
        doc.setDrawColor(128, 128, 128);
        doc.roundedRect(60, (currentHeight - 7), 130, 20, 1, 1);
        doc.setFillColor(235, 235, 235);
        doc.roundedRect(62, (currentHeight - 5), 90, 16, 1, 1, "F");
        doc.setDrawColor(0);

        let firstName = "";
        let surname = "";
        let otherName = "";
        let displayName = "";

        if (x[lastItemPosition].from) {
          if (x[lastItemPosition].remark) {
            var textLines = doc.setFontSize(10).splitTextToSize(`${x[lastItemPosition].remark}`, 80);
            doc.text(textLines, ((docWidth / 3) + 15), currentHeight);
            // currentHeight += (pdfConfig.subLineHeight) * (textLines.length);
          };
          if (x[lastItemPosition].from.firstName) { firstName = x[lastItemPosition].from.firstName; };
          if (x[lastItemPosition].from.surname) { surname = x[lastItemPosition].from.surname; };
          if (x[lastItemPosition].from.otherName) { otherName = x[lastItemPosition].from.otherName };
          displayName = `${firstName} ${(surname).substring(0, 1)} ${(otherName).substring(0, 1)}`;
          if (displayName) {
            doc.setFontSize(10);
            doc.text(`${displayName}`, (docWidth - 40), currentHeight, { align: 'center' },);
            currentHeight += pdfConfig.subLineHeight;
            doc.setFontSize(12);
          }
          if (x[lastItemPosition].from.role) {
            doc.setTextColor(colorGray);
            doc.setFontSize(10);
            doc.text(`${x[lastItemPosition].from.role}`, (docWidth - 40), currentHeight, { align: 'center' },);
            currentHeight += pdfConfig.subLineHeight;
            doc.setFontSize(12);
            doc.setTextColor(colorBlack);
          };
          if (x[lastItemPosition].date) {
            doc.setTextColor(colorGray);
            doc.setFontSize(10);
            doc.text(`${formatDate(x[lastItemPosition].date, 'dd/MM/yy HH:mm', 'en-US')}`, (docWidth - 40), currentHeight, { align: 'center' },);
            currentHeight += pdfConfig.subLineHeight;
            doc.setFontSize(12);
            doc.setTextColor(colorBlack);
          };


        };
      }


    } else {
      doc.line(50, currentHeight, docWidth - 50, currentHeight);

      currentHeight += pdfConfig.subLineHeight;
      doc.text(`Remarks :`, 27, currentHeight, { align: 'left' },);
      currentHeight += pdfConfig.subLineHeight;

      doc.setDrawColor(128, 128, 128);
      doc.roundedRect(60, (currentHeight - 7), 130, 20, 1, 1);
      doc.setFillColor(235, 235, 235);
      doc.roundedRect(62, (currentHeight - 5), 90, 16, 1, 1, "F");
      doc.setDrawColor(0);

      currentHeight += 20;

      doc.setDrawColor(128, 128, 128);
      doc.roundedRect(60, (currentHeight - 7), 130, 20, 1, 1);
      doc.setFillColor(235, 235, 235);
      doc.roundedRect(62, (currentHeight - 5), 90, 16, 1, 1, "F");
      doc.setDrawColor(0);

      currentHeight += 15;
    };

    // currentHeight += pdfConfig.subLineHeight;
    doc.line(20, currentHeight, docWidth - 20, currentHeight);

    // 
    doc.addPage();

    // Page 4

    addHeader();
    doc.setFontSize(12);
    doc.setTextColor(colorBlack);

    doc.line(5, currentHeight, docWidth - 5, currentHeight);

    currentHeight += pdfConfig.lineHeight;

    doc.setFontSize(14);
    doc.setTextColor(colorBlack);
    doc.text("3.", 10, currentHeight, { align: 'left' },);
    doc.text("Profit & Loss.", 15, currentHeight, { align: 'left' },);
    doc.setFontSize(12);

    currentHeight += pdfConfig.subLineHeight;

    doc.line(5, currentHeight, docWidth - 5, currentHeight);

    currentHeight += pdfConfig.lineHeight;

    doc.text(`Projection - ${this.yearPicked}`, 90, currentHeight, { align: 'center' },);
    doc.text(`Actual  - ${this.yearPicked}`, 155, currentHeight, { align: 'center' },);

    currentHeight += pdfConfig.lineHeight;

    doc.text(`Net Fuel Margin :`, 15, currentHeight, { align: 'left' },);

    doc.setDrawColor(colorGray);
    doc.roundedRect(60, (currentHeight - 4), 60, 5, 1, 1);
    doc.setDrawColor(0);
    if (this.pickedYearNetFuelMargin) {
      doc.text(`${formatCurrency((this.pickedYearNetFuelMargin), 'en-US', '', '', '1.2-2')}`, 90, currentHeight, { align: 'center' },);
    } else { doc.text(`-`, 90, currentHeight, { align: 'center' },); };

    doc.setDrawColor(114, 124, 245);
    doc.roundedRect(125, (currentHeight - 4), 60, 5, 1, 1);
    doc.setDrawColor(0);
    if (this.pickedYearNetFuelMarginActual) {
      doc.text(`${formatCurrency((this.pickedYearNetFuelMarginActual), 'en-US', '', '', '1.2-2')}`, 155, currentHeight, { align: 'center' },);
    } else { doc.text(`-`, 155, currentHeight, { align: 'center' },); };

    currentHeight += pdfConfig.lineHeight;

    doc.text(`Diversification :`, 15, currentHeight, { align: 'left' },);

    doc.setDrawColor(colorGray);
    doc.roundedRect(60, (currentHeight - 4), 60, 5, 1, 1);
    doc.setDrawColor(0);
    if (this.pickedYearDiversification) {
      doc.text(`${formatCurrency((this.pickedYearDiversification), 'en-US', '', '', '1.2-2')}`, 90, currentHeight, { align: 'center' },);
    } else { doc.text(`-`, 90, currentHeight, { align: 'center' },); };

    doc.setDrawColor(114, 124, 245);
    doc.roundedRect(125, (currentHeight - 4), 60, 5, 1, 1);
    doc.setDrawColor(0);
    if (this.pickedYearDiversificationActual) {
      doc.text(`${formatCurrency((this.pickedYearDiversificationActual), 'en-US', '', '', '1.2-2')}`, 155, currentHeight, { align: 'center' },);
    } else { doc.text(`-`, 155, currentHeight, { align: 'center' },); };

    currentHeight += pdfConfig.lineHeight;

    doc.text(`Other Revenues :`, 15, currentHeight, { align: 'left' },);

    doc.setDrawColor(colorGray);
    doc.roundedRect(60, (currentHeight - 4), 60, 5, 1, 1);
    doc.setDrawColor(0);
    if (this.pickedYearOtherRevenue) {
      doc.text(`${formatCurrency((this.pickedYearOtherRevenue), 'en-US', '', '', '1.2-2')}`, 90, currentHeight, { align: 'center' },);
    } else { doc.text(`-`, 90, currentHeight, { align: 'center' },); };

    doc.setDrawColor(114, 124, 245);
    doc.roundedRect(125, (currentHeight - 4), 60, 5, 1, 1);
    doc.setDrawColor(0);
    if (this.pickedYearOtherRevenueActual) {
      doc.text(`${formatCurrency((this.pickedYearOtherRevenueActual), 'en-US', '', '', '1.2-2')}`, 155, currentHeight, { align: 'center' },);
    } else { doc.text(`-`, 155, currentHeight, { align: 'center' },); };

    currentHeight += pdfConfig.lineHeight;

    doc.text(`Operating Expenses :`, 15, currentHeight, { align: 'left' },);

    doc.setDrawColor(colorGray);
    doc.roundedRect(60, (currentHeight - 4), 60, 5, 1, 1);
    doc.setDrawColor(0);
    if (this.pickedYearOperatingExpenses) {
      doc.text(`${formatCurrency((this.pickedYearOperatingExpenses), 'en-US', '', '', '1.2-2')}`, 90, currentHeight, { align: 'center' },);
    } else { doc.text(`-`, 90, currentHeight, { align: 'center' },); };

    doc.setDrawColor(114, 124, 245);
    doc.roundedRect(125, (currentHeight - 4), 60, 5, 1, 1);
    doc.setDrawColor(0);
    if (this.pickedYearOperatingExpensesActual) {
      doc.text(`${formatCurrency((this.pickedYearOperatingExpensesActual), 'en-US', '', '', '1.2-2')}`, 155, currentHeight, { align: 'center' },);
    } else { doc.text(`-`, 155, currentHeight, { align: 'center' },); };

    currentHeight += pdfConfig.lineHeight;

    doc.text(`Rentals & Fees Due :`, 15, currentHeight, { align: 'left' },);

    doc.setDrawColor(colorGray);
    doc.roundedRect(60, (currentHeight - 4), 60, 5, 1, 1);
    doc.setDrawColor(0);
    if (this.pickedYearRentalsAndFeesDue) {
      doc.text(`${formatCurrency((this.pickedYearRentalsAndFeesDue), 'en-US', '', '', '1.2-2')}`, 90, currentHeight, { align: 'center' },);
    } else { doc.text(`-`, 90, currentHeight, { align: 'center' },); };

    doc.setDrawColor(114, 124, 245);
    doc.roundedRect(125, (currentHeight - 4), 60, 5, 1, 1);
    doc.setDrawColor(0);
    if (this.pickedYearRentalsAndFeesDueActual) {
      doc.text(`${formatCurrency((this.pickedYearRentalsAndFeesDueActual), 'en-US', '', '', '1.2-2')}`, 155, currentHeight, { align: 'center' },);
    } else { doc.text(`-`, 155, currentHeight, { align: 'center' },); };

    currentHeight += pdfConfig.lineHeight;

    doc.text(`EBITDA :`, 10, currentHeight, { align: 'left' },);

    doc.setDrawColor(colorGray);
    doc.roundedRect(60, (currentHeight - 4), 60, 5, 1, 1);
    doc.setDrawColor(0);
    if (this.pickedYearEBITDA) {
      doc.text(`${formatCurrency((this.pickedYearEBITDA), 'en-US', '', '', '1.2-2')}`, 90, currentHeight, { align: 'center' },);
    } else { doc.text(`-`, 90, currentHeight, { align: 'center' },); };

    doc.setDrawColor(114, 124, 245);
    doc.roundedRect(125, (currentHeight - 4), 60, 5, 1, 1);
    doc.setDrawColor(0);
    if (this.pickedYearEBITDAActual) {
      doc.text(`${formatCurrency((this.pickedYearEBITDAActual), 'en-US', '', '', '1.2-2')}`, 155, currentHeight, { align: 'center' },);
    } else { doc.text(`-`, 155, currentHeight, { align: 'center' },); };

    currentHeight += pdfConfig.lineHeight;

    doc.text(`Investments :`, 15, currentHeight, { align: 'left' },);

    doc.setDrawColor(colorGray);
    doc.roundedRect(60, (currentHeight - 4), 60, 5, 1, 1);
    doc.setDrawColor(0);
    if (this.pickedYearInvestments) {
      doc.text(`${formatCurrency((this.pickedYearInvestments), 'en-US', '', '', '1.2-2')}`, 90, currentHeight, { align: 'center' },);
    } else { doc.text(`-`, 90, currentHeight, { align: 'center' },); };

    doc.setDrawColor(114, 124, 245);
    doc.roundedRect(125, (currentHeight - 4), 60, 5, 1, 1);
    doc.setDrawColor(0);
    if (this.pickedYearInvestmentsActual) {
      doc.text(`${formatCurrency((this.pickedYearInvestmentsActual), 'en-US', '', '', '1.2-2')}`, 155, currentHeight, { align: 'center' },);
    } else { doc.text(`-`, 155, currentHeight, { align: 'center' },); };

    currentHeight += pdfConfig.lineHeight;

    doc.text(`Change in Working :`, 15, currentHeight, { align: 'left' },);

    doc.setDrawColor(colorGray);
    doc.roundedRect(60, (currentHeight - 4), 60, 5, 1, 1);
    doc.setDrawColor(0);
    if (this.pickedYearChangeInWorkingCapital) {
      doc.text(`${formatCurrency((this.pickedYearChangeInWorkingCapital), 'en-US', '', '', '1.2-2')}`, 90, currentHeight, { align: 'center' },);
    } else { doc.text(`-`, 90, currentHeight, { align: 'center' },); };

    doc.setDrawColor(114, 124, 245);
    doc.roundedRect(125, (currentHeight - 4), 60, 5, 1, 1);
    doc.setDrawColor(0);
    if (this.pickedYearChangeInWorkingCapitalActual) {
      doc.text(`${formatCurrency((this.pickedYearChangeInWorkingCapitalActual), 'en-US', '', '', '1.2-2')}`, 155, currentHeight, { align: 'center' },);
    } else { doc.text(`-`, 155, currentHeight, { align: 'center' },); };
    currentHeight += pdfConfig.subLineHeight;
    doc.text(`Capital`, 17, currentHeight, { align: 'left' },);

    currentHeight += pdfConfig.lineHeight;

    doc.text(`Taxes :`, 15, currentHeight, { align: 'left' },);

    doc.setDrawColor(colorGray);
    doc.roundedRect(60, (currentHeight - 4), 60, 5, 1, 1);
    doc.setDrawColor(0);
    if (this.pickedYearTaxes) {
      doc.text(`${formatCurrency((this.pickedYearTaxes), 'en-US', '', '', '1.2-2')}`, 90, currentHeight, { align: 'center' },);
    } else { doc.text(`-`, 90, currentHeight, { align: 'center' },); };

    doc.setDrawColor(114, 124, 245);
    doc.roundedRect(125, (currentHeight - 4), 60, 5, 1, 1);
    doc.setDrawColor(0);
    if (this.pickedYearTaxesActual) {
      doc.text(`${formatCurrency((this.pickedYearTaxesActual), 'en-US', '', '', '1.2-2')}`, 155, currentHeight, { align: 'center' },);
    } else { doc.text(`-`, 155, currentHeight, { align: 'center' },); };

    currentHeight += pdfConfig.lineHeight;

    doc.text(`Other Cash Items :`, 15, currentHeight, { align: 'left' },);

    doc.setDrawColor(colorGray);
    doc.roundedRect(60, (currentHeight - 4), 60, 5, 1, 1);
    doc.setDrawColor(0);
    if (this.pickedYearOtherCashItems) {
      doc.text(`${formatCurrency((this.pickedYearOtherCashItems), 'en-US', '', '', '1.2-2')}`, 90, currentHeight, { align: 'center' },);
    } else { doc.text(`-`, 90, currentHeight, { align: 'center' },); };

    doc.setDrawColor(114, 124, 245);
    doc.roundedRect(125, (currentHeight - 4), 60, 5, 1, 1);
    doc.setDrawColor(0);
    if (this.pickedYearOtherCashItemsActual) {
      doc.text(`${formatCurrency((this.pickedYearOtherCashItemsActual), 'en-US', '', '', '1.2-2')}`, 155, currentHeight, { align: 'center' },);
    } else { doc.text(`-`, 155, currentHeight, { align: 'center' },); };

    currentHeight += pdfConfig.lineHeight;

    doc.text(`Net Cash After Tax :`, 10, currentHeight, { align: 'left' },);

    doc.setDrawColor(colorGray);
    doc.roundedRect(60, (currentHeight - 4), 60, 5, 1, 1);
    doc.setDrawColor(0);
    if (this.pickedYearNetCashAfterTax) {
      doc.text(`${formatCurrency((this.pickedYearNetCashAfterTax), 'en-US', '', '', '1.2-2')}`, 90, currentHeight, { align: 'center' },);
    } else { doc.text(`-`, 90, currentHeight, { align: 'center' },); };

    doc.setDrawColor(114, 124, 245);
    doc.roundedRect(125, (currentHeight - 4), 60, 5, 1, 1);
    doc.setDrawColor(0);
    if (this.pickedYearNetCashAfterTaxActual) {
      doc.text(`${formatCurrency((this.pickedYearNetCashAfterTaxActual), 'en-US', '', '', '1.2-2')}`, 155, currentHeight, { align: 'center' },);
    } else { doc.text(`-`, 155, currentHeight, { align: 'center' },); };

    currentHeight += pdfConfig.lineHeight;

    doc.text(`Financial Result :`, 15, currentHeight, { align: 'left' },);

    doc.setDrawColor(colorGray);
    doc.roundedRect(60, (currentHeight - 4), 60, 5, 1, 1);
    doc.setDrawColor(0);
    if (this.pickedYearFinancialResult) {
      doc.text(`${formatCurrency((this.pickedYearFinancialResult), 'en-US', '', '', '1.2-2')}`, 90, currentHeight, { align: 'center' },);
    } else { doc.text(`-`, 90, currentHeight, { align: 'center' },); };

    doc.setDrawColor(114, 124, 245);
    doc.roundedRect(125, (currentHeight - 4), 60, 5, 1, 1);
    doc.setDrawColor(0);
    if (this.pickedYearFinancialResultActual) {
      doc.text(`${formatCurrency((this.pickedYearFinancialResultActual), 'en-US', '', '', '1.2-2')}`, 155, currentHeight, { align: 'center' },);
    } else { doc.text(`-`, 155, currentHeight, { align: 'center' },); };

    currentHeight += pdfConfig.lineHeight;

    doc.text(`Net Cash After Tax :`, 10, currentHeight, { align: 'left' },);

    doc.setDrawColor(colorGray);
    doc.roundedRect(60, (currentHeight - 4), 60, 5, 1, 1);
    doc.setDrawColor(0);
    if (this.pickedYearNetCashAfterTaxDiscounted) {
      doc.text(`${formatCurrency((this.pickedYearNetCashAfterTaxDiscounted), 'en-US', '', '', '1.2-2')}`, 90, currentHeight, { align: 'center' },);
    } else { doc.text(`-`, 90, currentHeight, { align: 'center' },); };

    doc.setDrawColor(114, 124, 245);
    doc.roundedRect(125, (currentHeight - 4), 60, 5, 1, 1);
    doc.setDrawColor(0);
    if (this.pickedYearNetCashAfterTaxDiscountedActual) {
      doc.text(`${formatCurrency((this.pickedYearNetCashAfterTaxDiscountedActual), 'en-US', '', '', '1.2-2')}`, 155, currentHeight, { align: 'center' },);
    } else { doc.text(`-`, 155, currentHeight, { align: 'center' },); };

    currentHeight += pdfConfig.subLineHeight;
    doc.text(`(Discounted)`, 12, currentHeight, { align: 'left' },);

    currentHeight += pdfConfig.lineHeight;

    doc.text(`Net Cash After Tax :`, 10, currentHeight, { align: 'left' },);

    doc.setDrawColor(colorGray);
    doc.roundedRect(60, (currentHeight - 4), 60, 5, 1, 1);
    doc.setDrawColor(0);
    if (this.pickedYearNetCashAfterTaxRealAndDiscounted) {
      doc.text(`${formatCurrency((this.pickedYearNetCashAfterTaxRealAndDiscounted), 'en-US', '', '', '1.2-2')}`, 90, currentHeight, { align: 'center' },);
    } else { doc.text(`-`, 90, currentHeight, { align: 'center' },); };

    doc.setDrawColor(114, 124, 245);
    doc.roundedRect(125, (currentHeight - 4), 60, 5, 1, 1);
    doc.setDrawColor(0);
    if (this.pickedYearNetCashAfterTaxRealAndDiscountedActual) {
      doc.text(`${formatCurrency((this.pickedYearNetCashAfterTaxRealAndDiscountedActual), 'en-US', '', '', '1.2-2')}`, 155, currentHeight, { align: 'center' },);
    } else { doc.text(`-`, 155, currentHeight, { align: 'center' },); };

    currentHeight += pdfConfig.subLineHeight;
    doc.text(`(Real & Discounted)`, 12, currentHeight, { align: 'left' },);

    currentHeight += pdfConfig.lineHeight;

    if (this.productSalesRemarkArray) {
      doc.line(50, currentHeight, docWidth - 50, currentHeight);

      currentHeight += pdfConfig.subLineHeight;
      doc.text(`Remarks :`, 15, currentHeight, { align: 'left' },);
      currentHeight += pdfConfig.subLineHeight;
      let x = [];
      let arrayLength = 0;
      let lastItemPosition;
      let secondLastItemPosition;
      x = this.productSalesRemarkArray;
      arrayLength = this.productSalesRemarkArray.length;

      if (arrayLength > 2) {
        lastItemPosition = (arrayLength - 1);
        secondLastItemPosition = (arrayLength - 1);
      } else {
        if (arrayLength === 1) {
          lastItemPosition = (arrayLength - 1);
        }
        if (arrayLength === 2) {
          lastItemPosition = (arrayLength - 1);
          secondLastItemPosition = (arrayLength - 1);
        }
      }

      if (secondLastItemPosition) {
        doc.setDrawColor(128, 128, 128);
        doc.roundedRect(60, (currentHeight - 7), 130, 20, 1, 1);
        doc.setFillColor(235, 235, 235);
        doc.roundedRect(62, (currentHeight - 5), 90, 16, 1, 1, "F");
        doc.setDrawColor(0);

        let firstName = "";
        let surname = "";
        let otherName = "";
        let displayName = "";

        if (x[secondLastItemPosition].from) {
          if (x[secondLastItemPosition].remark) {
            var textLines = doc.setFontSize(10).splitTextToSize(`${x[secondLastItemPosition].remark}`, 80);
            doc.text(textLines, ((docWidth / 3) + 15), currentHeight);
            // currentHeight += (pdfConfig.subLineHeight) * (textLines.length);
          };
          if (x[secondLastItemPosition].from.firstName) { firstName = x[secondLastItemPosition].from.firstName; };
          if (x[secondLastItemPosition].from.surname) { surname = x[secondLastItemPosition].from.surname; };
          if (x[secondLastItemPosition].from.otherName) { otherName = x[secondLastItemPosition].from.otherName };
          displayName = `${firstName} ${(surname).substring(0, 1)} ${(otherName).substring(0, 1)}`;
          if (displayName) {
            doc.setFontSize(10);
            doc.text(`${displayName}`, (docWidth - 40), currentHeight, { align: 'center' },);
            currentHeight += pdfConfig.subLineHeight;
            doc.setFontSize(12);
          }
          if (x[secondLastItemPosition].from.role) {
            doc.setTextColor(colorGray);
            doc.setFontSize(10);
            doc.text(`${x[secondLastItemPosition].from.role}`, (docWidth - 40), currentHeight, { align: 'center' },);
            currentHeight += pdfConfig.subLineHeight;
            doc.setFontSize(12);
            doc.setTextColor(colorBlack);
          };
          if (x[secondLastItemPosition].date) {
            doc.setTextColor(colorGray);
            doc.setFontSize(10);
            doc.text(`${formatDate(x[secondLastItemPosition].date, 'dd/MM/yy HH:mm', 'en-US')}`, (docWidth - 40), currentHeight, { align: 'center' },);
            currentHeight += pdfConfig.subLineHeight;
            doc.setFontSize(12);
            doc.setTextColor(colorBlack);
          };


        };
      }
      if (lastItemPosition) {
        doc.setDrawColor(128, 128, 128);
        doc.roundedRect(60, (currentHeight - 7), 130, 20, 1, 1);
        doc.setFillColor(235, 235, 235);
        doc.roundedRect(62, (currentHeight - 5), 90, 16, 1, 1, "F");
        doc.setDrawColor(0);

        let firstName = "";
        let surname = "";
        let otherName = "";
        let displayName = "";

        if (x[lastItemPosition].from) {
          if (x[lastItemPosition].remark) {
            var textLines = doc.setFontSize(10).splitTextToSize(`${x[lastItemPosition].remark}`, 80);
            doc.text(textLines, ((docWidth / 3) + 15), currentHeight);
            // currentHeight += (pdfConfig.subLineHeight) * (textLines.length);
          };
          if (x[lastItemPosition].from.firstName) { firstName = x[lastItemPosition].from.firstName; };
          if (x[lastItemPosition].from.surname) { surname = x[lastItemPosition].from.surname; };
          if (x[lastItemPosition].from.otherName) { otherName = x[lastItemPosition].from.otherName };
          displayName = `${firstName} ${(surname).substring(0, 1)} ${(otherName).substring(0, 1)}`;
          if (displayName) {
            doc.setFontSize(10);
            doc.text(`${displayName}`, (docWidth - 40), currentHeight, { align: 'center' },);
            currentHeight += pdfConfig.subLineHeight;
            doc.setFontSize(12);
          }
          if (x[lastItemPosition].from.role) {
            doc.setTextColor(colorGray);
            doc.setFontSize(10);
            doc.text(`${x[lastItemPosition].from.role}`, (docWidth - 40), currentHeight, { align: 'center' },);
            currentHeight += pdfConfig.subLineHeight;
            doc.setFontSize(12);
            doc.setTextColor(colorBlack);
          };
          if (x[lastItemPosition].date) {
            doc.setTextColor(colorGray);
            doc.setFontSize(10);
            doc.text(`${formatDate(x[lastItemPosition].date, 'dd/MM/yy HH:mm', 'en-US')}`, (docWidth - 40), currentHeight, { align: 'center' },);
            currentHeight += pdfConfig.subLineHeight;
            doc.setFontSize(12);
            doc.setTextColor(colorBlack);
          };


        };
      }


    } else {
      doc.line(50, currentHeight, docWidth - 50, currentHeight);

      currentHeight += pdfConfig.subLineHeight;
      doc.text(`Remarks :`, 27, currentHeight, { align: 'left' },);
      currentHeight += pdfConfig.subLineHeight;

      doc.setDrawColor(128, 128, 128);
      doc.roundedRect(60, (currentHeight - 7), 130, 20, 1, 1);
      doc.setFillColor(235, 235, 235);
      doc.roundedRect(62, (currentHeight - 5), 90, 16, 1, 1, "F");
      doc.setDrawColor(0);

      currentHeight += 20;

      doc.setDrawColor(128, 128, 128);
      doc.roundedRect(60, (currentHeight - 7), 130, 20, 1, 1);
      doc.setFillColor(235, 235, 235);
      doc.roundedRect(62, (currentHeight - 5), 90, 16, 1, 1, "F");
      doc.setDrawColor(0);

      currentHeight += 15;
    };

    // currentHeight += pdfConfig.subLineHeight;
    doc.line(20, currentHeight, docWidth - 20, currentHeight);

    // 
    doc.addPage();

    // Page 4

    addHeader();
    doc.setFontSize(12);
    doc.setTextColor(colorBlack);

    doc.line(5, currentHeight, docWidth - 5, currentHeight);

    currentHeight += pdfConfig.lineHeight;

    doc.setFontSize(14);
    doc.setTextColor(colorBlack);
    doc.text("4.", 10, currentHeight, { align: 'left' },);
    doc.text("Post Investment Analysis - Report.", 15, currentHeight, { align: 'left' },);
    doc.setFontSize(12);

    currentHeight += pdfConfig.subLineHeight;

    doc.line(5, currentHeight, docWidth - 5, currentHeight);

    currentHeight += pdfConfig.lineHeight;

    // Final Remark

    if (this.finalRemark) {

      doc.text(`Final Remarks :`, 17, currentHeight, { align: 'left' },);
      currentHeight += 10;
      let x: any = {};
      x = this.finalRemark;

      doc.setDrawColor(128, 128, 128);
      doc.roundedRect(20, (currentHeight - 7), 170, 90, 1, 1);
      doc.setFillColor(235, 235, 235);
      doc.roundedRect(22, (currentHeight - 5), 166, 86, 1, 1, "F");
      doc.setDrawColor(0);

      let firstName = "";
      let surname = "";
      let otherName = "";
      let displayName = "";

      if (x.from) {
        if (x.remark) {
          var textLines = doc.setFontSize(10).splitTextToSize(`${x.remark}`, 80);
          doc.text(textLines, ((docWidth / 3) + 15), currentHeight);
          // currentHeight += (pdfConfig.subLineHeight) * (textLines.length);
        };
        if (x.from.firstName) { firstName = x.from.firstName; };
        if (x.from.surname) { surname = x.from.surname; };
        if (x.from.otherName) { otherName = x.from.otherName };
        displayName = `${firstName} ${(surname).substring(0, 1)} ${(otherName).substring(0, 1)}`;
        if (displayName) {
          doc.setFontSize(10);
          doc.text(`${displayName}`, (docWidth - 40), currentHeight, { align: 'center' },);
          currentHeight += pdfConfig.subLineHeight;
          doc.setFontSize(12);
        }
        if (x.from.role) {
          doc.setTextColor(colorGray);
          doc.setFontSize(10);
          doc.text(`${x.from.role}`, (docWidth - 40), currentHeight, { align: 'center' },);
          currentHeight += pdfConfig.subLineHeight;
          doc.setFontSize(12);
          doc.setTextColor(colorBlack);
        };
        if (x.date) {
          doc.setTextColor(colorGray);
          doc.setFontSize(10);
          doc.text(`${formatDate(x.date, 'dd/MM/yy HH:mm', 'en-US')}`, (docWidth - 40), currentHeight, { align: 'center' },);
          currentHeight += pdfConfig.subLineHeight;
          doc.setFontSize(12);
          doc.setTextColor(colorBlack);
        };
      }


    } else {

      doc.text(`Final Remarks :`, 17, currentHeight, { align: 'left' },);
      currentHeight += 10;

      doc.setDrawColor(128, 128, 128);
      doc.roundedRect(20, (currentHeight - 7), 170, 90, 1, 1);
      doc.setFillColor(235, 235, 235);
      doc.roundedRect(22, (currentHeight - 5), 166, 86, 1, 1, "F");
      doc.setDrawColor(0);
    };

    currentHeight += 90;

    if (this.postInvestmentRecommendations) {
      doc.text(`Recommendations :`, 17, currentHeight, { align: 'left' },);
      currentHeight += 10;
      let x: any = {};
      x = this.postInvestmentRecommendations;

      doc.setDrawColor(128, 128, 128);
      doc.roundedRect(20, (currentHeight - 7), 170, 90, 1, 1);
      doc.setFillColor(235, 235, 235);
      doc.roundedRect(22, (currentHeight - 5), 166, 86, 1, 1, "F");
      doc.setDrawColor(0);

      let firstName = "";
      let surname = "";
      let otherName = "";
      let displayName = "";

      if (x.from) {
        if (x.remark) {
          var textLines = doc.setFontSize(10).splitTextToSize(`${x.remark}`, 80);
          doc.text(textLines, ((docWidth / 3) + 15), currentHeight);
          // currentHeight += (pdfConfig.subLineHeight) * (textLines.length);
        };
        if (x.from.firstName) { firstName = x.from.firstName; };
        if (x.from.surname) { surname = x.from.surname; };
        if (x.from.otherName) { otherName = x.from.otherName };
        displayName = `${firstName} ${(surname).substring(0, 1)} ${(otherName).substring(0, 1)}`;
        if (displayName) {
          doc.setFontSize(10);
          doc.text(`${displayName}`, (docWidth - 40), currentHeight, { align: 'center' },);
          currentHeight += pdfConfig.subLineHeight;
          doc.setFontSize(12);
        }
        if (x.from.role) {
          doc.setTextColor(colorGray);
          doc.setFontSize(10);
          doc.text(`${x.from.role}`, (docWidth - 40), currentHeight, { align: 'center' },);
          currentHeight += pdfConfig.subLineHeight;
          doc.setFontSize(12);
          doc.setTextColor(colorBlack);
        };
        if (x.date) {
          doc.setTextColor(colorGray);
          doc.setFontSize(10);
          doc.text(`${formatDate(x.date, 'dd/MM/yy HH:mm', 'en-US')}`, (docWidth - 40), currentHeight, { align: 'center' },);
          currentHeight += pdfConfig.subLineHeight;
          doc.setFontSize(12);
          doc.setTextColor(colorBlack);
        };
      }


    } else {

      doc.text(`Recommendations :`, 17, currentHeight, { align: 'left' },);
      currentHeight += 10;

      doc.setDrawColor(128, 128, 128);
      doc.roundedRect(20, (currentHeight - 7), 170, 90, 1, 1);
      doc.setFillColor(235, 235, 235);
      doc.roundedRect(22, (currentHeight - 5), 166, 86, 1, 1, "F");
      doc.setDrawColor(0);
    };

    // Recommendations






    //#region Add num of pages at the bottom
    if (doc.getNumberOfPages() > 1) {
      for (let i = 1; i <= doc.getNumberOfPages(); i++) {
        doc.setPage(i);
        doc.setFontSize(pdfConfig.headerFieldTextSize);
        doc.setTextColor(colorGray);

        if (param.pageEnable) {
          var imageHeader = new Image();
          imageHeader.src = "../../../../assets/images/logo/accent_210_x_30.png";
          doc.addImage(
            imageHeader,
            "JPEG",
            1,
            doc.internal.pageSize.height - 30,
            210,
            30
          );

          // doc.line(10, docHeight - 15, docWidth - 10, docHeight - 15);
          if (this.userName) { doc.text(`Printed By: ${this.userName}`, 40, docHeight - 10, { align: 'left' }); };
          if (this.dateToday) { doc.text(`${this.dateToday}`, 40, docHeight - 5, { align: 'left' }); };
          doc.text("From: www.financialforecast.cloud", (docWidth / 2), docHeight - 5, { align: 'center' });
          doc.text(
            param.pageLabel + " " + i + " / " + doc.getNumberOfPages(),
            docWidth - 30,
            doc.internal.pageSize.height - 7,
            { align: 'left' }
          );
        }

      }
    }

    //#region Add num of first page at the bottom
    if (doc.getNumberOfPages() === 1 && param.pageEnable) {

      var imageHeader = new Image();
      imageHeader.src = "../../../../assets/images/logo/accent_210_x_30.png";
      doc.addImage(
        imageHeader,
        "JPEG",
        1,
        doc.internal.pageSize.height - 30,
        210,
        30
      );

      doc.setFontSize(pdfConfig.fieldTextSize);
      doc.setTextColor(colorGray);
      // doc.line(10, docHeight - 15, docWidth - 10, docHeight - 15);
      if (this.userName) { doc.text(`Printed By: ${this.userName}`, 40, docHeight - 10, { align: 'left' }); };
      if (this.dateToday) { doc.text(`${this.dateToday}`, 40, docHeight - 5, { align: 'left' }); };
      doc.text("www.financialforecast.cloud", (docWidth / 2), docHeight - 5, { align: 'center' });
      doc.text(
        param.pageLabel + " 1 / 1",
        docWidth - 30,
        doc.internal.pageSize.height - 7,
        { align: 'left' }
      );
    }
    //#endregion


    let returnObj = {
      pagesNumber: doc.getNumberOfPages(),
    };



    if (param.outputType === "save") {
      // this.disableDownloadButton = false;
      doc.save(param.fileName);
    }

    return returnObj;
  }


  saveProfitAndLossActual(form: any) {
    this.updateInProgress = true;

    // Check
    if (!this.pickedYear) {
      this.show_alert = true;
      this.updateInProgress = false;
      this.submitInProgress = false;
      this.alert_message = "Pick a year 1st!";
      this.alert_type = "danger";

      this.toastr.error('Pick a year 1st!', 'Error!');

      setTimeout(() => {
        this.show_alert = false;
        this.updateInProgress = false;
        this.submitInProgress = false;
        this.selfClosingAlert.close();
      }, 3000);
      return;
    }

  }

  savePostInvestmentReport(form: any) {
    this.updateInProgress = true;

    // Check
    if (!this.pickedYear) {
      this.show_alert = true;
      this.updateInProgress = false;
      this.submitInProgress = false;
      this.alert_message = "Pick a year 1st!";
      this.alert_type = "danger";

      this.toastr.error('Pick a year 1st!', 'Error!');

      setTimeout(() => {
        this.show_alert = false;
        this.updateInProgress = false;
        this.submitInProgress = false;
        this.selfClosingAlert.close();
      }, 3000);
      return;
    }

  }

  ngAfterViewInit(): void {


  };

  submitRemark(form: any, formName: string) {
    this.submitInProgress = true;

    // Check
    if (!(form.remark)) {
      this.show_alert = true;
      this.alert_message = "Remark is missing!";
      this.alert_type = "danger";

      this.toastr.error('Remark is missing!', 'Error!');

      this.loading = false;
      this.updateInProgress = false;
      this.submitInProgress = false;

      setTimeout(() => {
        this.show_alert = false;
        this.selfClosingAlert.close();
      }, 3000);
      return;
    }

    // Check
    if (!(this.projectId)) {
      this.show_alert = true;
      this.alert_message = "Project Id is missing!";
      this.alert_type = "danger";

      this.toastr.error('Project Id is missing!', 'Error!');

      this.loading = false;
      this.updateInProgress = false;
      this.submitInProgress = false;

      setTimeout(() => {
        this.show_alert = false;
        this.selfClosingAlert.close();
      }, 3000);
      return;
    }

    // Check
    if (!(this.userId)) {
      this.show_alert = true;
      this.alert_message = "User Id is missing!";
      this.alert_type = "danger";

      this.toastr.error('User Id is missing!', 'Error!');

      this.loading = false;
      this.updateInProgress = false;
      this.submitInProgress = false;

      setTimeout(() => {
        this.show_alert = false;
        this.selfClosingAlert.close();
      }, 3000);
      return;
    }

    this.subscription.add(
      this.projectService.submitPostInvestmentRemark(this.projectId, formName, form.remark, this.userId).pipe().subscribe((res: any) => {
        this.submitInProgress = false;
        this.toastr.success(`Remark - Submitted`, "Info!");
        // setTimeout(() => {
        //   this.scrollToElement("stepTwo");
        // }, 500);
      },
        (error: string) => {
          this.toastr.error(`Error: ${error}`, "Error!");
          this.error = error;
          this.submitInProgress = false;
        }));
  }

  notify(message: string) {
    this.toastr.info(`${message}`, "Info!");
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  };

}
