<div class="card bg-transparent">
    <app-ui-preloader [display]="loading"></app-ui-preloader>
    <!-- Header -->
    <div class="card-header">
        <!-- title-->
        <div class="row flex-grow-1">
            <div class="col">
                <div class="pricing-header px-3 py-3 pt-md-3 pb-md-2 mx-auto text-center">
                    <h1 class="display-6">Project</h1>
                    <p class="lead text-muted">Fill in the project parameters.</p>
                </div>
            </div>
        </div>
    </div>

    <!-- Summary -->
    <div class="card border border-primary">
        <div class="card-group">
            <div class="card mb-0">
                <div class="card-header text-center">
                    <span>Project Info</span>
                </div>
                <div class="card-body">
                    <div class="table-responsive-sm" *ngIf="projectArray">
                        <table class="table table-sm table-centered table-borderless mb-0" *ngIf="projectArray[0]">
                            <tbody>
                                <tr>
                                    <td class="p-0">
                                        Project Name
                                    </td>
                                    <td class="p-0">
                                        :
                                    </td>
                                    <td class="p-0">
                                        <span *ngIf="projectArray[0].name">{{projectArray[0].name}}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0">
                                        Country
                                    </td>
                                    <td class="p-0">
                                        :
                                    </td>
                                    <td class="p-0">
                                        <span *ngIf="projectArray[0].country">
                                            <span *ngIf="projectArray[0].country.name">
                                                {{projectArray[0].country.name}}
                                            </span>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0">
                                        1st Year of Investment
                                    </td>
                                    <td class="p-0">
                                        :
                                    </td>
                                    <td class="p-0">
                                        <span *ngIf="projectArray[0].startDate">{{projectArray[0].startDate}}</span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="card mb-0">
                <div class="card-header text-center">
                    <span>Results Summary</span>
                </div>
                <div class="card-body">
                    <div class="table-responsive-sm" *ngIf="projectArray">
                        <table class="table table-sm table-centered table-borderless mb-0" *ngIf="projectArray[0]">
                            <tbody>
                                <tr>
                                    <td class="p-0">
                                        NPV at 11%
                                    </td>
                                    <td class="p-0">
                                        :
                                    </td>
                                    <td class="p-0">
                                        <span
                                            *ngIf="projectArray[0].netPresentValue">{{projectArray[0].netPresentValue|number:"1.0-0"}}</span>
                                        <span *ngIf="!projectArray[0].netPresentValue">-</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0">
                                        IRR
                                    </td>
                                    <td class="p-0">
                                        :
                                    </td>
                                    <td class="p-0">
                                        <span
                                            *ngIf="projectArray[0].internalRateOfReturn">{{projectArray[0].internalRateOfReturn|number:"1.2-2"}}%</span>
                                        <span *ngIf="!projectArray[0].internalRateOfReturn">- %</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0">
                                        Payback Period
                                    </td>
                                    <td class="p-0">
                                        :
                                    </td>
                                    <td class="p-0">
                                        <span
                                            *ngIf="projectArray[0].paybackPeriodDiscounted">{{projectArray[0].paybackPeriodDiscounted|number:"1.2-2"}}</span>
                                        <span *ngIf="!projectArray[0].paybackPeriodDiscounted">-</span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <div class="card mb-0">
            <div class="card-header text-center">
                <span>Overall Progress</span>
            </div>
            <div class="card-body">
                <p class="mb-0">
                    <ngb-progressbar [showValue]="true" type="info" [striped]="true" [animated]="true"
                        [value]="progressPercentage"></ngb-progressbar>
                </p>
            </div>
        </div>
    </div>

    <!-- Section Break -->
    <div class="border border-secondary card">
        <div class="card-body text-center text-primary">
            <span>Section A - Company</span>
        </div>
    </div>

    <!-- Step 1. Project Parameters -->
    <div id="stepOne" class="border border-secondary card stepOne">
        <div class="card-header">
            <div class="table-responsive-sm" *ngIf="projectArray">
                <table class="table table-sm table-centered table-borderless mb-0" *ngIf="projectArray[0]">
                    <tbody>
                        <tr>
                            <td class="p-0" style="width:60%">
                                Step 1. Project Parameters
                            </td>
                            <td class="p-0" style="width:30%">
                                <p class="mb-0">
                                    Completion
                                    <ngb-progressbar [showValue]="true" type="info" [striped]="true" [animated]="true"
                                        [value]="projectSettingsProgressPercentage"></ngb-progressbar>
                                </p>
                            </td>
                            <td class="p-0 text-center text-primary" style="width:10%">
                                <i class="mdi mdi-help-circle-outline mdi-24px"></i>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="card-body" *ngIf="showProjectSettingsSection">
            <div class="card-body" *ngIf="showProjectSettingsEdit">
                <div class="row" name="project-settings-edit-form" [formGroup]="projectSettingsEditForm">
                    <div class="col-12 col-lg-6">
                        <div class="table-responsive-sm">
                            <table class="table table-sm table-centered table-borderless mb-0">
                                <tbody>
                                    <tr>
                                        <td class="p-0">
                                            Project Name
                                        </td>
                                        <td class="p-0">
                                            <div class="input-group input-group-sm input-group-merge">
                                                <input class="form-control form-control-sm " type="text" id="name"
                                                    formControlName="name"
                                                    [ngClass]="{'is-invalid': (formSubmitted && projectSettingsEditFormValues['name'].invalid)||(projectSettingsEditFormValues['name'].touched && projectSettingsEditFormValues['name'].invalid)}">
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="p-0">
                                            Country Name
                                        </td>
                                        <td class="p-0">
                                            <select class="form-select form-select-sm" name="id" formControlName="id"
                                                id="id" required
                                                [ngClass]="{'is-invalid': (formSubmitted && projectSettingsEditFormValues['id'].invalid)||(projectSettingsEditFormValues['id'].touched && projectSettingsEditFormValues['id'].invalid)}">
                                                <option *ngFor="let m of countryArray" [value]="m[0].id">
                                                    {{m[0].name}}
                                                </option>
                                            </select>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="p-0">
                                            USD Fx Rate
                                        </td>
                                        <td class="p-0">
                                            <div class="input-group input-group-sm input-group-merge">
                                                <input class="form-control form-control-sm " type="number"
                                                    id="usdFxRate" formControlName="usdFxRate"
                                                    [ngClass]="{'is-invalid': (formSubmitted && projectSettingsEditFormValues['usdFxRate'].invalid)||(projectSettingsEditFormValues['usdFxRate'].touched && projectSettingsEditFormValues['usdFxRate'].invalid)}">
                                                <div class="input-group-text">
                                                    <span><span *ngIf="localCurrencyISOCode">{{localCurrencyISOCode}}</span>/USD</span>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="p-0">
                                            Tax Carry Over Allowed For
                                        </td>
                                        <td class="p-0">
                                            <div class="input-group input-group-sm input-group-merge">
                                                <input class="form-control form-control-sm " type="number"
                                                    id="taxCarryOverPeriod" formControlName="taxCarryOverPeriod"
                                                    [ngClass]="{'is-invalid': (formSubmitted && projectSettingsEditFormValues['taxCarryOverPeriod'].invalid)||(projectSettingsEditFormValues['taxCarryOverPeriod'].touched && projectSettingsEditFormValues['taxCarryOverPeriod'].invalid)}">
                                                <div class="input-group-text">
                                                    <span>Years</span>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="p-0">
                                            Discounted Rate For Net Present Value (NPV)
                                        </td>
                                        <td class="p-0">
                                            <div class="input-group input-group-sm input-group-merge">
                                                <input class="form-control form-control-sm" type="number"
                                                    id="discountRateForNetPresentValue"
                                                    formControlName="discountRateForNetPresentValue"
                                                    [ngClass]="{'is-invalid': (formSubmitted && projectSettingsEditFormValues['discountRateForNetPresentValue'].invalid)||(projectSettingsEditFormValues['discountRateForNetPresentValue'].touched && projectSettingsEditFormValues['discountRateForNetPresentValue'].invalid)}">
                                                <div class="input-group-text">
                                                    <span>%</span>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="col-12 col-lg-6">
                        <div class="table-responsive-sm">
                            <table class="table table-sm table-centered table-borderless mb-0">
                                <tbody>
                                    <tr>
                                        <td class="p-0">
                                            Type of Investment
                                        </td>
                                        <td class="p-0">
                                            <select class="form-select form-select-sm" name="typeOfInvestment"
                                                formControlName="typeOfInvestment" id="typeOfInvestment" required
                                                [ngClass]="{'is-invalid': (formSubmitted && projectSettingsEditFormValues['typeOfInvestment'].invalid)||(projectSettingsEditFormValues['typeOfInvestment'].touched && projectSettingsEditFormValues['typeOfInvestment'].invalid)}">
                                                <option *ngFor="let m of typeOfInvestmentOptionArray" [value]="m">
                                                    {{m}}
                                                </option>
                                            </select>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="p-0">
                                            Months Before Operations
                                        </td>

                                        <td class="p-0">
                                            <div class="input-group input-group-sm input-group-merge">
                                                <input class="form-control" type="number" min="1" max="72"
                                                    id="monthsBeforeOperation" placeholder="10"
                                                    formControlName="monthsBeforeOperation"
                                                    [ngClass]="{'is-invalid': (formSubmitted && projectSettingsEditFormValues['monthsBeforeOperation'].invalid)||(projectSettingsEditFormValues['monthsBeforeOperation'].touched && projectSettingsEditFormValues['monthsBeforeOperation'].invalid)}">
                                                <div class="input-group-text">
                                                    <span>Months</span>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="p-0">
                                            Period of Operations
                                        </td>

                                        <td class="p-0">
                                            <div class="input-group input-group-sm input-group-merge">
                                                <select class="form-select form-select-sm" name="periodOfOperation"
                                                    formControlName="periodOfOperation" id="periodOfOperation" required
                                                    [ngClass]="{'is-invalid': (formSubmitted && projectSettingsEditFormValues['periodOfOperation'].invalid)||(projectSettingsEditFormValues['periodOfOperation'].touched && projectSettingsEditFormValues['periodOfOperation'].invalid)}">
                                                    <option *ngFor="let m of periodOfOperationOptionArray" [value]="m">
                                                        {{m}}
                                                    </option>
                                                </select>
                                                <div class="input-group-text">
                                                    <span>Years</span>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="p-0">
                                            Mode of Partnership
                                        </td>
                                        <td class="p-0">
                                            <select class="form-select form-select-sm" name="mode"
                                                formControlName="mode" id="mode" required
                                                [ngClass]="{'is-invalid': (formSubmitted && projectSettingsEditFormValues['mode'].invalid)||(projectSettingsEditFormValues['mode'].touched && projectSettingsEditFormValues['mode'].invalid)}">
                                                <option *ngFor="let m of modeOfPartnershipOptionArray" [value]="m">
                                                    {{m}}
                                                </option>
                                            </select>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="p-0">
                                            Corporate Tax Rate
                                        </td>
                                        <td class="p-0">
                                            <div class="input-group input-group-sm input-group-merge">
                                                <input class="form-control form-control-sm" type="number"
                                                    id="corporateTaxRate" formControlName="corporateTaxRate"
                                                    [ngClass]="{'is-invalid': (formSubmitted && projectSettingsEditFormValues['corporateTaxRate'].invalid)||(projectSettingsEditFormValues['corporateTaxRate'].touched && projectSettingsEditFormValues['corporateTaxRate'].invalid)}">
                                                <div class="input-group-text">
                                                    <span>%</span>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-body" *ngIf="!showProjectSettingsEdit">
                <div class="row" *ngIf="projectArray">
                    <div class="col-12 col-lg-6" *ngIf="projectArray[0]">
                        <div class="table-responsive-sm">
                            <table class="table table-sm table-centered table-borderless mb-0">
                                <tbody *ngIf="projectArray[0].country">
                                    <tr>
                                        <td class="p-0">
                                            Country Name
                                        </td>
                                        <td class="p-0">
                                            :
                                        </td>
                                        <td class="p-0 w-50 text-end">
                                            <span
                                                *ngIf="projectArray[0].country.name">{{projectArray[0].country.name}}</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="p-0">
                                            Local Currency
                                        </td>
                                        <td class="p-0">
                                            :
                                        </td>
                                        <td class="p-0 w-50 text-end">
                                            <span *ngIf="projectArray[0].country.localCurrencyName">
                                                {{projectArray[0].country.localCurrencyName}}
                                                <span *ngIf="projectArray[0].country.localCurrencyISOCode">
                                                    ({{projectArray[0].country.localCurrencyISOCode}})
                                                </span>
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="p-0">
                                            USD Fx Rate
                                        </td>
                                        <td class="p-0">
                                            :
                                        </td>
                                        <td class="p-0 w-50 text-end">
                                            <span
                                                *ngIf="projectArray[0].country.usdFxRate">{{projectArray[0].country.usdFxRate}}
                                                <span *ngIf="projectArray[0].country.localCurrencyISOCode">
                                                    {{projectArray[0].country.localCurrencyISOCode}}/USD
                                                </span>
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="p-0">
                                            Tax Carry Over Allowed For
                                        </td>
                                        <td class="p-0">
                                            :
                                        </td>
                                        <td class="p-0 w-50 text-end">
                                            <span
                                                *ngIf="projectArray[0].country.taxCarryOverPeriod">{{projectArray[0].country.taxCarryOverPeriod}}
                                                Years</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="p-0">
                                            Discounted Rate For Net Present Value (NPV)
                                        </td>
                                        <td class="p-0">
                                            :
                                        </td>
                                        <td class="p-0 w-50 text-end">
                                            <span
                                                *ngIf="projectArray[0].country.discountRateForNetPresentValue">{{projectArray[0].country.discountRateForNetPresentValue}}%</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="p-0">
                                            Corporate Tax Rate
                                        </td>
                                        <td class="p-0">
                                            :
                                        </td>
                                        <td class="p-0 w-50 text-end">
                                            <span
                                                *ngIf="projectArray[0].country.corporateTaxRate">{{projectArray[0].country.corporateTaxRate}}%</span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="col-12 col-lg-6" *ngIf="projectArray[0]">
                        <div class="table-responsive-sm">
                            <table class="table table-sm table-centered table-borderless mb-0">
                                <tbody>
                                    <tr>
                                        <td class="p-0">
                                            Type of Investment
                                        </td>
                                        <td class="p-0">
                                            :
                                        </td>
                                        <td class="p-0 w-50 text-end">
                                            <span
                                                *ngIf="projectArray[0].typeOfInvestment">{{projectArray[0].typeOfInvestment}}</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="p-0">
                                            Months Before Operations
                                        </td>
                                        <td class="p-0">
                                            :
                                        </td>
                                        <td class="p-0 w-50 text-end">
                                            <span
                                                *ngIf="projectArray[0].monthsBeforeOperation">{{projectArray[0].monthsBeforeOperation}}
                                                Months</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="p-0">
                                            Period of Operations
                                        </td>
                                        <td class="p-0">
                                            :
                                        </td>
                                        <td class="p-0 w-50 text-end">
                                            <span
                                                *ngIf="projectArray[0].periodOfOperation">{{projectArray[0].periodOfOperation}}
                                                Years</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="p-0">
                                            Mode of Partnership
                                        </td>
                                        <td class="p-0">
                                            :
                                        </td>
                                        <td class="p-0 w-50 text-end">
                                            <span *ngIf="projectArray[0].mode">{{projectArray[0].mode}}</span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card-footer" *ngIf="showProjectSettingsEdit">
            <div class="row">
                <div class="col-sm-12">
                    <div class="d-grid">
                        <button class="btn btn-sm btn-outline-primary"
                            (click)="updateProjectSettings(projectSettingsEditForm.value)" type="button"
                            *ngIf="!updateInProgress">
                            <i class=""></i>Update</button>
                        <button class="btn btn-sm btn-outline-primary" type="button" disabled *ngIf="updateInProgress">
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="card-footer" *ngIf="!showProjectSettingsSection">
            <div class="row">
                <div class="col-sm-6"></div>
                <div class="col-sm-6">
                    <div class="d-grid">
                        <button class="btn btn-sm btn-outline-secondary"
                            (click)="showProjectSettingsSectionButton(true)" type="button"><i class=""></i>View</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="card-footer" *ngIf="showProjectSettingsSection">
            <div class="row" *ngIf="showProjectSettingsEdit">
                <div class="col-sm-6">
                    <div class="d-grid">
                        <button class="btn btn-sm btn-outline-secondary" (click)="showProjectSettingsEditButton(false)"
                            type="button"><i class=""></i>Simple View</button>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="d-grid">
                        <button class="btn btn-sm btn-outline-success" (click)="submitProjectSettings()" type="button"
                            *ngIf="!submitInProgress && !updateInProgress"><i class=""></i>Submit</button>
                        <button class="btn btn-sm btn-outline-primary" type="button" disabled
                            *ngIf="submitInProgress || updateInProgress">
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            
                        </button>
                    </div>
                </div>
            </div>
            <div class="row" *ngIf="!showProjectSettingsEdit">
                <div class="col-sm-6">
                    <div class="d-grid">
                        <button class="btn btn-sm btn-outline-secondary" (click)="showProjectSettingsEditButton(true)"
                            type="button"><i class=""></i>Edit View</button>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="d-grid">
                        <button class="btn btn-sm btn-outline-success" (click)="submitProjectSettings()" type="button"
                            *ngIf="!submitInProgress && !updateInProgress"><i class=""></i>Submit</button>
                        <button class="btn btn-sm btn-outline-primary" type="button" disabled
                            *ngIf="submitInProgress || updateInProgress">
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Step 2. Product Margins -->
    <div id="stepTwo" class="border border-secondary card stepTwo">
        <div class="card-header">
            <div class="table-responsive-sm" *ngIf="projectArray">
                <table class="table table-sm table-centered table-borderless mb-0" *ngIf="projectArray[0]">
                    <tbody>
                        <tr>
                            <td class="p-0" style="width:60%">
                                Step 2. Product Margins
                            </td>
                            <td class="p-0" style="width:30%">
                                <p class="mb-0">
                                    Completion
                                    <ngb-progressbar [showValue]="true" type="info" [striped]="true" [animated]="true"
                                        [value]="projectMarginProgressPercentage"></ngb-progressbar>
                                </p>
                            </td>
                            <td class="p-0 text-center text-primary" style="width:10%">
                                <i class="mdi mdi-help-circle-outline mdi-24px"></i>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="card-body" *ngIf="showProjectProductMarginSection">
            <div class="card-body">
                <div class="card" *ngIf="showProjectProductMarginEdit">
                    <div class="card-header">
                        <span>Fuel Product - Edit</span>
                    </div>
                    <div class="card-body">
                        <div class="row mb-3">
                            <div class="board">
                                <table class="table table-sm table-bordered table-centered mb-0">
                                    <thead>
                                        <tr>
                                            <th class="p-0 px-1" style="min-width:150px">
                                                No.
                                            </th>
                                            <th class="p-0 px-1" style="min-width:150px">
                                                Name
                                            </th>
                                            <th class="p-0 px-1" style="min-width:150px">
                                                Industry Name
                                            </th>
                                            <th class="p-0 px-1" style="min-width:150px">
                                                Industry Abbreviation
                                            </th>
                                            <th class="p-0 px-1" style="min-width:150px">
                                                Global Price
                                            </th>
                                            <th class="p-0 px-1" style="min-width:150px">
                                                EPRA Margin
                                            </th>
                                            <th class="p-0 px-1" style="min-width:150px">
                                                DODO given from Dealer Margin
                                            </th>
                                            <th class="p-0 px-1" style="min-width:150px">
                                                Dealer Margin
                                            </th>
                                            <th class="p-0 px-1" style="min-width:150px">
                                                Discount Given (Galana's Client)
                                            </th>
                                            <th class="p-0 px-1" style="min-width:150px">
                                                Depot Cost
                                            </th>
                                            <th class="p-0 px-1" style="min-width:150px">
                                                Galana Pro
                                            </th>
                                            <th class="p-0 px-1" style="min-width:150px">
                                                Variable Exp.
                                            </th>
                                            <th class="p-0 px-1" style="min-width:150px">
                                                Unit GMVE
                                            </th>
                                            <th class="p-0 px-1" style="min-width:150px">
                                                Company Unit Margin
                                            </th>
                                            <th class="p-0 px-1" style="min-width:150px">
                                                Unit Supply Price
                                            </th>
                                            <th class="p-0 px-1" style="min-width:150px">
                                                Unitary Tax
                                            </th>
                                            <th class="p-0 px-1" style="min-width:150px">
                                                Unit Revenue
                                            </th>
                                            <th class="p-0 px-1" style="min-width:150px">
                                                Monthly Volume
                                            </th>
                                            <th class="p-0 px-1" style="min-width:150px">
                                                Yearly Volume
                                            </th>
                                            <th class="p-0 px-1" style="min-width:150px">
                                                GMVE
                                            </th>
                                            <th class="p-0 px-1" style="min-width:150px"></th>
                                        </tr>
                                    </thead>
                                    <thead>
                                        <tr>
                                            <th class="p-0 px-1"></th>
                                            <th class="p-0 px-1"></th>
                                            <th class="p-0 px-1"></th>
                                            <th class="p-0 px-1"></th>
                                            <th class="p-0 px-1"></th>
                                            <th class="p-0 px-1"></th>
                                            <th class="p-0 px-1"></th>
                                            <th class="p-0 px-1"></th>
                                            <th class="p-0 px-1"></th>
                                            <th class="p-0 px-1"></th>
                                            <th class="p-0 px-1"></th>
                                            <th class="p-0 px-1"></th>
                                            <th class="p-0 px-1"></th>
                                            <th class="p-0 px-1">
                                                <span *ngIf="localCurrencyISOCode">{{localCurrencyISOCode}}</span>/m3
                                            </th>
                                            <th class="p-0 px-1">
                                                <span *ngIf="localCurrencyISOCode">{{localCurrencyISOCode}}</span>/m3
                                            </th>
                                            <th class="p-0 px-1">
                                                <span *ngIf="localCurrencyISOCode">{{localCurrencyISOCode}}</span>/m3
                                            </th>
                                            <th class="p-0 px-1">
                                                <span *ngIf="localCurrencyISOCode">{{localCurrencyISOCode}}</span>/m3
                                            </th>
                                            <th class="p-0 px-1">
                                                m3/Month
                                            </th>
                                            <th class="p-0 px-1">
                                                m3/Year
                                            </th>
                                            <th class="p-0 px-1">
                                                <span *ngIf="localCurrencyISOCode">{{localCurrencyISOCode}}</span>
                                            </th>
                                            <th class="p-0 px-1"></th>
                                        </tr>
                                    </thead>
                                    <tbody class="table-group-divider">
                                        <tr *ngFor="let m of fuelProductArray;let i =index;">
                                            <td class="p-0 px-1">
                                                <span>
                                                    {{(i)+(1)}}
                                                </span>
                                            </td>
                                            <td class="p-0 ps-1">
                                                <span *ngIf="m.name">
                                                    {{m.name}}
                                                </span>
                                            </td>
                                            <td class="p-0 ps-1">
                                                <span *ngIf="m.industryName">
                                                    {{m.industryName}}
                                                </span>
                                            </td>
                                            <td class="p-0 ps-1">
                                                <span *ngIf="m.industryAbbreviation">
                                                    {{m.industryAbbreviation}}
                                                </span>
                                            </td>
                                            <td class="p-0 px-1">
                                                <span *ngIf="m.networkGlobalPrice">
                                                    {{m.networkGlobalPrice | number : '1.2-2'}}
                                                </span>
                                            </td>
                                            <td class="p-0 px-1">
                                                <span *ngIf="m.networkEPRAMargin">
                                                    {{m.networkEPRAMargin | number : '1.2-2'}}
                                                </span>
                                            </td>
                                            <td class="p-0 px-1">
                                                <span *ngIf="m.networkDODOMargin">
                                                    {{m.networkDODOMargin | number : '1.2-2'}}
                                                </span>
                                            </td>
                                            <td class="p-0 px-1">
                                                <span *ngIf="m.networkDealerMarginL">
                                                    {{m.networkDealerMarginL | number : '1.2-2'}}
                                                </span>
                                            </td>
                                            <td class="p-0 px-1">
                                                <span *ngIf="m.networkDiscountGiven">
                                                    {{m.networkDiscountGiven | number : '1.2-2'}}
                                                </span>
                                            </td>
                                            <td class="p-0 px-1">
                                                <span *ngIf="m.networkDepotCost">
                                                    {{m.networkDepotCost | number : '1.2-2'}}
                                                </span>
                                            </td>
                                            <td class="p-0 px-1">
                                                <span *ngIf="m.networkGalanaPro">
                                                    {{m.networkGalanaPro | number : '1.2-2'}}
                                                </span>
                                            </td>
                                            <td class="p-0 px-1">
                                                <span *ngIf="m.networkVarex">
                                                    {{m.networkVarex | number : '1.2-2'}}
                                                </span>
                                            </td>
                                            <td class="p-0 px-1">
                                                <span *ngIf="m.networkExpectedGMVE">
                                                    {{m.networkExpectedGMVE | number : '1.2-2'}}
                                                </span>
                                            </td>
                                            <td class="p-0 px-1">
                                                <span *ngIf="m.companyUnitMargin">
                                                    {{m.companyUnitMargin | number : '1.2-2'}}
                                                </span>
                                            </td>
                                            <td class="p-0 px-1">
                                                <span *ngIf="m.unitSupplyPrice">
                                                    {{m.unitSupplyPrice | number : '1.2-2'}}
                                                </span>
                                            </td>
                                            <td class="p-0 px-1">
                                                <span *ngIf="m.unitaryTax">
                                                    {{m.unitaryTax | number : '1.2-2'}}
                                                </span>
                                            </td>
                                            <td class="p-0 px-1">
                                                <span *ngIf="m.unitRevenue">
                                                    {{m.unitRevenue | number : '1.2-2'}}
                                                </span>
                                            </td>
                                            <td class="p-0 px-1">
                                                <span *ngIf="m.monthlyVolume">
                                                    {{m.monthlyVolume | number : '1.2-2'}}
                                                </span>
                                            </td>
                                            <td class="p-0 px-1">
                                                <span *ngIf="m.yearlyVolume">
                                                    {{m.yearlyVolume | number : '1.2-2'}}
                                                </span>
                                            </td>
                                            <td class="p-0 px-1">
                                                <span *ngIf="m.gMVE">
                                                    {{m.gMVE | number : '1.2-2'}}
                                                </span>
                                            </td>
                                            <td class="p-0 px-1">
                                                <button class="btn btn-sm btn-outline-danger"
                                                    *ngIf="!submitInProgress && !updateInProgress"
                                                    (click)="removeFuelLubricantGasProductItem('fuelProduct',i)"
                                                    type="button">X</button>
                                                <button class="btn btn-sm btn-outline-danger" type="button" disabled
                                                    *ngIf="submitInProgress || updateInProgress">
                                                    <span class="spinner-border spinner-border-sm" role="status"
                                                        aria-hidden="true"></span>
                                                </button>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody class="table-group-divider" [formGroup]="fuelProductForm">
                                        <tr>
                                            <td class="p-0 px-1">
                                                <span>
                                                    {{(fuelProductArray.length)+(1)}}
                                                </span>
                                            </td>
                                            <td class="p-0 px-1">
                                                <input type="text" class="form-control" placeholder="Industry Name"
                                                    id="industryName" aria-label="Sizing example input"
                                                    formControlName="industryName"
                                                    aria-describedby="inputGroup-sizing-sm">
                                            </td>
                                            <td class="p-0 px-1">
                                                <input type="text" class="form-control" placeholder="Name" id="name"
                                                    aria-label="Sizing example input" formControlName="name"
                                                    aria-describedby="inputGroup-sizing-sm">
                                            </td>
                                            <td class="p-0 px-1">
                                                <input type="text" class="form-control"
                                                    placeholder="Industry Abbreviation" id="industryAbbreviation"
                                                    aria-label="Sizing example input"
                                                    formControlName="industryAbbreviation"
                                                    aria-describedby="inputGroup-sizing-sm">
                                            </td>
                                            <td class="p-0 px-1">
                                                <input type="number" class="form-control" placeholder="Global Price"
                                                    id="networkGlobalPrice" aria-label="Sizing example input"
                                                    formControlName="networkGlobalPrice"
                                                    aria-describedby="inputGroup-sizing-sm">
                                            </td>
                                            <td class="p-0 px-1">
                                                <input type="number" class="form-control" placeholder="EPRA Margin"
                                                    id="networkEPRAMargin" aria-label="Sizing example input"
                                                    formControlName="networkEPRAMargin"
                                                    aria-describedby="inputGroup-sizing-sm">
                                            </td>
                                            <td class="p-0 px-1">
                                                <input type="number" class="form-control" placeholder="DODO Margin"
                                                    id="networkDODOMargin" aria-label="Sizing example input"
                                                    formControlName="networkDODOMargin"
                                                    aria-describedby="inputGroup-sizing-sm">
                                            </td>
                                            <td class="p-0 px-1">
                                                <input type="number" class="form-control" placeholder="Dealer Margin"
                                                    id="networkDealerMarginL" aria-label="Sizing example input"
                                                    formControlName="networkDealerMarginL"
                                                    aria-describedby="inputGroup-sizing-sm">
                                            </td>
                                            <td class="p-0 px-1">
                                                <input type="number" class="form-control" placeholder="Discount Given"
                                                    id="networkDiscountGiven" aria-label="Sizing example input"
                                                    formControlName="networkDiscountGiven"
                                                    aria-describedby="inputGroup-sizing-sm">
                                            </td>
                                            <td class="p-0 px-1">
                                                <input type="number" class="form-control" placeholder="Depot Cost"
                                                    id="networkDepotCost" aria-label="Sizing example input"
                                                    formControlName="networkDepotCost"
                                                    aria-describedby="inputGroup-sizing-sm">
                                            </td>
                                            <td class="p-0 px-1">
                                                <input type="number" class="form-control" placeholder="Galana Pro"
                                                    id="networkGalanaPro" aria-label="Sizing example input"
                                                    formControlName="networkGalanaPro"
                                                    aria-describedby="inputGroup-sizing-sm">
                                            </td>
                                            <td class="p-0 px-1">
                                                <input type="number" class="form-control" placeholder="Varex"
                                                    id="networkVarex" aria-label="Sizing example input"
                                                    formControlName="networkVarex"
                                                    aria-describedby="inputGroup-sizing-sm">
                                            </td>
                                            <td class="p-0 px-1">
                                                <input type="number" class="form-control" placeholder="Unit GMVE"
                                                    id="networkExpectedGMVE" aria-label="Sizing example input"
                                                    formControlName="networkExpectedGMVE"
                                                    aria-describedby="inputGroup-sizing-sm">
                                            </td>
                                            <td class="p-0 px-1">
                                                <input type="number" class="form-control"
                                                    placeholder="Company Unit Margin" id="companyUnitMargin"
                                                    aria-label="Sizing example input"
                                                    formControlName="companyUnitMargin" readonly
                                                    aria-describedby="inputGroup-sizing-sm">
                                            </td>
                                            <td class="p-0 px-1">
                                                <input type="number" class="form-control"
                                                    placeholder="Unit Supply Price" id="unitSupplyPrice"
                                                    aria-label="Sizing example input" formControlName="unitSupplyPrice"
                                                    aria-describedby="inputGroup-sizing-sm">
                                            </td>
                                            <td class="p-0 px-1">
                                                <input type="number" class="form-control" placeholder="Unitary Tax"
                                                    id="unitaryTax" aria-label="Sizing example input"
                                                    formControlName="unitaryTax"
                                                    aria-describedby="inputGroup-sizing-sm">
                                            </td>
                                            <td class="p-0 px-1">
                                                <input type="number" class="form-control" placeholder="Unit Revenue"
                                                    id="unitRevenue" aria-label="Sizing example input"
                                                    formControlName="unitRevenue" readonly
                                                    aria-describedby="inputGroup-sizing-sm">
                                            </td>
                                            <td class="p-0 px-1">
                                                <input type="number" class="form-control" placeholder="Monthly Volume"
                                                    id="monthlyVolume" aria-label="Sizing example input"
                                                    formControlName="monthlyVolume"
                                                    aria-describedby="inputGroup-sizing-sm">
                                            </td>
                                            <td class="p-0 px-1">
                                                <input type="number" class="form-control" placeholder="Yearly Volume"
                                                    id="yearlyVolume" aria-label="Sizing example input"
                                                    formControlName="yearlyVolume" readonly
                                                    aria-describedby="inputGroup-sizing-sm">
                                            </td>
                                            <td class="p-0 px-1">
                                                <input type="number" class="form-control" placeholder="GMVE" id="gMVE"
                                                    aria-label="Sizing example input" formControlName="gMVE" readonly
                                                    aria-describedby="inputGroup-sizing-sm">
                                            </td>
                                            <td class="p-0 px-1">
                                                <button class="btn btn-outline-success"
                                                    (click)="addFuelLubricantGasProductItem('fuelProduct',fuelProductForm.value)"
                                                    *ngIf="!submitInProgress && !updateInProgress" type="button">Add
                                                    Item</button>
                                                <button class="btn btn-sm btn-outline-success" type="button" disabled
                                                    *ngIf="submitInProgress || updateInProgress">
                                                    <span class="spinner-border spinner-border-sm" role="status"
                                                        aria-hidden="true"></span>
                                                </button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card" *ngIf="showProjectProductMarginEdit">
                    <div class="card-header">
                        <span>Lubricant Product - Edit</span>
                    </div>
                    <div class="card-body">
                        <div class="row mb-3">
                            <div class="board">
                                <table class="table table-sm table-bordered table-centered mb-0">
                                    <thead>
                                        <tr>
                                            <th class="p-0 px-1" style="min-width:150px">
                                                No.
                                            </th>
                                            <th class="p-0 px-1" style="min-width:150px">
                                                Name
                                            </th>
                                            <th class="p-0 px-1" style="min-width:150px">
                                                Industry Name
                                            </th>
                                            <th class="p-0 px-1" style="min-width:150px">
                                                Industry Abbreviation
                                            </th>
                                            <th class="p-0 px-1" style="min-width:150px">
                                                Dealer Unit Margin
                                            </th>
                                            <th class="p-0 px-1" style="min-width:150px">
                                                Unit GMVE
                                            </th>
                                            <th class="p-0 px-1" style="min-width:150px">
                                                Company Unit Margin
                                            </th>
                                            <th class="p-0 px-1" style="min-width:150px">
                                                Unit Supply Price
                                            </th>
                                            <th class="p-0 px-1" style="min-width:150px">
                                                Unitary Tax
                                            </th>
                                            <th class="p-0 px-1" style="min-width:150px">
                                                Unit Revenue
                                            </th>
                                            <th class="p-0 px-1" style="min-width:150px">
                                                Monthly Volume
                                            </th>
                                            <th class="p-0 px-1" style="min-width:150px">
                                                Yearly Volume
                                            </th>
                                            <th class="p-0 px-1" style="min-width:150px">
                                                GMVE
                                            </th>
                                            <th class="p-0 px-1" style="min-width:150px"></th>
                                        </tr>
                                    </thead>
                                    <thead>
                                        <tr>
                                            <th class="p-0 px-1">
                                            </th>
                                            <th class="p-0 px-1">
                                            </th>
                                            <th class="p-0 px-1">
                                            </th>
                                            <th class="p-0 px-1">
                                            </th>
                                            <th class="p-0 px-1">
                                                <span *ngIf="localCurrencyISOCode">{{localCurrencyISOCode}}</span>/m3
                                            </th>
                                            <th class="p-0 px-1">
                                                <span *ngIf="localCurrencyISOCode">{{localCurrencyISOCode}}</span>/m3
                                            </th>
                                            <th class="p-0 px-1">
                                                <span *ngIf="localCurrencyISOCode">{{localCurrencyISOCode}}</span>/m3
                                            </th>
                                            <th class="p-0 px-1">
                                                <span *ngIf="localCurrencyISOCode">{{localCurrencyISOCode}}</span>/m3
                                            </th>
                                            <th class="p-0 px-1">
                                                <span *ngIf="localCurrencyISOCode">{{localCurrencyISOCode}}</span>/m3
                                            </th>
                                            <th class="p-0 px-1">
                                                <span *ngIf="localCurrencyISOCode">{{localCurrencyISOCode}}</span>/m3
                                            </th>
                                            <th class="p-0 px-1">
                                                m3/month
                                            </th>
                                            <th class="p-0 px-1">
                                                m3/year
                                            </th>
                                            <th class="p-0 px-1">
                                                <span *ngIf="localCurrencyISOCode">{{localCurrencyISOCode}}</span>
                                            </th>
                                            <th class="p-0 px-1"></th>
                                        </tr>
                                    </thead>
                                    <tbody class="table-group-divider">
                                        <tr *ngFor="let m of lubricantProductArray;let i = index;">
                                            <td class="p-0 px-1">
                                                <span>
                                                    {{(i)+(1)}}
                                                </span>
                                            </td>
                                            <td class="p-0 px-1">
                                                <span *ngIf="m.name">
                                                    {{m.name}}
                                                </span>
                                            </td>
                                            <td class="p-0 px-1">
                                                <span *ngIf="m.industryName">
                                                    {{m.industryName}}
                                                </span>
                                            </td>
                                            <td class="p-0 px-1">
                                                <span *ngIf="m.industryAbbreviation">
                                                    {{m.industryAbbreviation}}
                                                </span>
                                            </td>
                                            <td class="p-0 px-1">
                                                <span *ngIf="m.networkDealerMarginM3">
                                                    {{m.networkDealerMarginM3 | number : '1.2-2'}}
                                                </span>
                                            </td>
                                            <td class="p-0 px-1">
                                                <span *ngIf="m.unitGMVE">
                                                    {{m.unitGMVE | number : '1.2-2'}}
                                                </span>
                                            </td>

                                            <td class="p-0 px-1">
                                                <span *ngIf="m.companyUnitMargin">
                                                    {{m.companyUnitMargin | number : '1.2-2'}}
                                                </span>
                                            </td>
                                            <td class="p-0 px-1">
                                                <span *ngIf="m.unitSupplyPrice">
                                                    {{m.unitSupplyPrice | number : '1.2-2'}}
                                                </span>
                                            </td>
                                            <td class="p-0 px-1">
                                                <span *ngIf="m.unitaryTax">
                                                    {{m.unitaryTax | number : '1.2-2'}}
                                                </span>
                                            </td>
                                            <td class="p-0 px-1">
                                                <span *ngIf="m.unitRevenue">
                                                    {{m.unitRevenue | number : '1.2-2'}}
                                                </span>
                                            </td>
                                            <td class="p-0 px-1">
                                                <span *ngIf="m.monthlyVolume">
                                                    {{m.monthlyVolume | number : '1.2-2'}}
                                                </span>
                                            </td>
                                            <td class="p-0 px-1">
                                                <span *ngIf="m.yearlyVolume">
                                                    {{m.yearlyVolume | number : '1.2-2'}}
                                                </span>
                                            </td>
                                            <td class="p-0 px-1">
                                                <span *ngIf="m.gMVE">
                                                    {{m.gMVE | number : '1.2-2'}}
                                                </span>
                                            </td>
                                            <td class="p-0 px-1">
                                                <button class="btn btn-sm btn-outline-danger"
                                                    *ngIf="!submitInProgress && !updateInProgress"
                                                    (click)="removeFuelLubricantGasProductItem('lubricantProduct',i)"
                                                    type="button">X</button>
                                                <button class="btn btn-sm btn-outline-danger" type="button" disabled
                                                    *ngIf="submitInProgress || updateInProgress">
                                                    <span class="spinner-border spinner-border-sm" role="status"
                                                        aria-hidden="true"></span>
                                                </button>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody class="table-group-divider" [formGroup]="lubricantProductForm">
                                        <tr>
                                            <td class="p-0 px-1">
                                                <span>
                                                    {{(lubricantProductArray.length)+(1)}}
                                                </span>
                                            </td>
                                            <td class="p-0 px-1">
                                                <input type="text" class="form-control" placeholder="Industry Name"
                                                    id="industryName" aria-label="Sizing example input"
                                                    formControlName="industryName"
                                                    aria-describedby="inputGroup-sizing-sm">
                                            </td>
                                            <td class="p-0 px-1">
                                                <input type="text" class="form-control" placeholder="Name" id="name"
                                                    aria-label="Sizing example input" formControlName="name"
                                                    aria-describedby="inputGroup-sizing-sm">
                                            </td>
                                            <td class="p-0 px-1">
                                                <input type="text" class="form-control"
                                                    placeholder="Industry Abbreviation" id="industryAbbreviation"
                                                    aria-label="Sizing example input"
                                                    formControlName="industryAbbreviation"
                                                    aria-describedby="inputGroup-sizing-sm">
                                            </td>
                                            <td class="p-0 px-1">
                                                <input type="number" class="form-control"
                                                    placeholder="Dealer Unit Margin" id="networkDealerMarginM3"
                                                    aria-label="Sizing example input"
                                                    formControlName="networkDealerMarginM3"
                                                    aria-describedby="inputGroup-sizing-sm">
                                            </td>
                                            <td class="p-0 px-1">
                                                <input type="number" class="form-control" placeholder="Unit GMVE"
                                                    id="unitGMVE" aria-label="Sizing example input"
                                                    formControlName="unitGMVE" aria-describedby="inputGroup-sizing-sm">
                                            </td>
                                            <td class="p-0 px-1">
                                                <input type="number" class="form-control"
                                                    placeholder="Company Unit Margin" id="companyUnitMargin"
                                                    aria-label="Sizing example input"
                                                    formControlName="companyUnitMargin" readonly
                                                    aria-describedby="inputGroup-sizing-sm">
                                            </td>
                                            <td class="p-0 px-1">
                                                <input type="number" class="form-control"
                                                    placeholder="Unit Supply Price" id="unitSupplyPrice"
                                                    aria-label="Sizing example input" formControlName="unitSupplyPrice"
                                                    aria-describedby="inputGroup-sizing-sm">
                                            </td>
                                            <td class="p-0 px-1">
                                                <input type="number" class="form-control" placeholder="Unitary Tax"
                                                    id="unitaryTax" aria-label="Sizing example input"
                                                    formControlName="unitaryTax"
                                                    aria-describedby="inputGroup-sizing-sm">
                                            </td>
                                            <td class="p-0 px-1">
                                                <input type="number" class="form-control" placeholder="Unit Revenue"
                                                    id="unitRevenue" aria-label="Sizing example input"
                                                    formControlName="unitRevenue" readonly
                                                    aria-describedby="inputGroup-sizing-sm">
                                            </td>
                                            <td class="p-0 px-1">
                                                <input type="number" class="form-control" placeholder="Monthly Volume"
                                                    id="monthlyVolume" aria-label="Sizing example input"
                                                    formControlName="monthlyVolume"
                                                    aria-describedby="inputGroup-sizing-sm">
                                            </td>
                                            <td class="p-0 px-1">
                                                <input type="number" class="form-control" placeholder="Yearly Volume"
                                                    id="yearlyVolume" aria-label="Sizing example input"
                                                    formControlName="yearlyVolume" readonly
                                                    aria-describedby="inputGroup-sizing-sm">
                                            </td>
                                            <td class="p-0 px-1">
                                                <input type="number" class="form-control" placeholder="GMVE" id="gMVE"
                                                    aria-label="Sizing example input" formControlName="gMVE" readonly
                                                    aria-describedby="inputGroup-sizing-sm">
                                            </td>
                                            <td class="p-0 px-1">
                                                <button class="btn btn-outline-success"
                                                    (click)="addFuelLubricantGasProductItem('lubricantProduct',lubricantProductForm.value)"
                                                    *ngIf="!submitInProgress && !updateInProgress" type="button">Add
                                                    Item</button>
                                                <button class="btn btn-sm btn-outline-success" type="button" disabled
                                                    *ngIf="submitInProgress || updateInProgress">
                                                    <span class="spinner-border spinner-border-sm" role="status"
                                                        aria-hidden="true"></span>
                                                </button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card" *ngIf="showProjectProductMarginEdit">
                    <div class="card-header">
                        <span>Gas Product - Edit</span>
                    </div>
                    <div class="card-body">
                        <div class="row mb-3">
                            <div class="board">
                                <table class="table table-sm table-bordered table-centered mb-0">
                                    <thead>
                                        <tr>
                                            <th class="p-0 px-1" style="min-width:150px">
                                                No.
                                            </th>
                                            <th class="p-0 px-1" style="min-width:150px">
                                                Name
                                            </th>
                                            <th class="p-0 px-1" style="min-width:150px">
                                                Industry Name
                                            </th>
                                            <th class="p-0 px-1" style="min-width:150px">
                                                Industry Abbreviation
                                            </th>
                                            <th class="p-0 px-1" style="min-width:150px">
                                                Dealer Unit Margin
                                            </th>
                                            <th class="p-0 px-1" style="min-width:150px">
                                                Unit GMVE
                                            </th>
                                            <th class="p-0 px-1" style="min-width:150px">
                                                Company Unit Margin
                                            </th>
                                            <th class="p-0 px-1" style="min-width:150px">
                                                Unit Supply Price
                                            </th>
                                            <th class="p-0 px-1" style="min-width:150px">
                                                Unitary Tax
                                            </th>
                                            <th class="p-0 px-1" style="min-width:150px">
                                                Unit Revenue
                                            </th>
                                            <th class="p-0 px-1" style="min-width:150px">
                                                Monthly Volume
                                            </th>
                                            <th class="p-0 px-1" style="min-width:150px">
                                                Yearly Volume
                                            </th>
                                            <th class="p-0 px-1" style="min-width:150px">
                                                GMVE
                                            </th>
                                            <th class="p-0 px-1" style="min-width:150px"></th>
                                        </tr>
                                    </thead>
                                    <thead>
                                        <tr>
                                            <th class="p-0 px-1">
                                            </th>
                                            <th class="p-0 px-1">
                                            </th>
                                            <th class="p-0 px-1">
                                            </th>
                                            <th class="p-0 px-1">
                                            </th>
                                            <th class="p-0 px-1">
                                                <span *ngIf="localCurrencyISOCode">{{localCurrencyISOCode}}</span>/m3
                                            </th>
                                            <th class="p-0 px-1">
                                                <span *ngIf="localCurrencyISOCode">{{localCurrencyISOCode}}</span>/m3
                                            </th>
                                            <th class="p-0 px-1">
                                                <span *ngIf="localCurrencyISOCode">{{localCurrencyISOCode}}</span>/m3
                                            </th>
                                            <th class="p-0 px-1">
                                                <span *ngIf="localCurrencyISOCode">{{localCurrencyISOCode}}</span>/m3
                                            </th>
                                            <th class="p-0 px-1">
                                                <span *ngIf="localCurrencyISOCode">{{localCurrencyISOCode}}</span>/m3
                                            </th>
                                            <th class="p-0 px-1">
                                                <span *ngIf="localCurrencyISOCode">{{localCurrencyISOCode}}</span>/m3
                                            </th>
                                            <th class="p-0 px-1">
                                                m3/month
                                            </th>
                                            <th class="p-0 px-1">
                                                m3/year
                                            </th>
                                            <th class="p-0 px-1">
                                                <span *ngIf="localCurrencyISOCode">{{localCurrencyISOCode}}</span>
                                            </th>
                                            <th class="p-0 px-1"></th>
                                        </tr>
                                    </thead>
                                    <tbody class="table-group-divider">
                                        <tr *ngFor="let m of gasProductArray;let i = index;">
                                            <td class="p-0 px-1">
                                                <span>
                                                    {{(i)+(1)}}
                                                </span>
                                            </td>
                                            <td class="p-0 px-1">
                                                <span *ngIf="m.name">
                                                    {{m.name}}
                                                </span>
                                            </td>
                                            <td class="p-0 px-1">
                                                <span *ngIf="m.industryName">
                                                    {{m.industryName}}
                                                </span>
                                            </td>
                                            <td class="p-0 px-1">
                                                <span *ngIf="m.industryAbbreviation">
                                                    {{m.industryAbbreviation}}
                                                </span>
                                            </td>
                                            <td class="p-0 px-1">
                                                <span *ngIf="m.networkDealerMarginM3">
                                                    {{m.networkDealerMarginM3 | number : '1.2-2'}}
                                                </span>
                                            </td>
                                            <td class="p-0 px-1">
                                                <span *ngIf="m.unitGMVE">
                                                    {{m.unitGMVE | number : '1.2-2'}}
                                                </span>
                                            </td>

                                            <td class="p-0 px-1">
                                                <span *ngIf="m.companyUnitMargin">
                                                    {{m.companyUnitMargin | number : '1.2-2'}}
                                                </span>
                                            </td>
                                            <td class="p-0 px-1">
                                                <span *ngIf="m.unitSupplyPrice">
                                                    {{m.unitSupplyPrice | number : '1.2-2'}}
                                                </span>
                                            </td>
                                            <td class="p-0 px-1">
                                                <span *ngIf="m.unitaryTax">
                                                    {{m.unitaryTax | number : '1.2-2'}}
                                                </span>
                                            </td>
                                            <td class="p-0 px-1">
                                                <span *ngIf="m.unitRevenue">
                                                    {{m.unitRevenue | number : '1.2-2'}}
                                                </span>
                                            </td>
                                            <td class="p-0 px-1">
                                                <span *ngIf="m.monthlyVolume">
                                                    {{m.monthlyVolume | number : '1.2-2'}}
                                                </span>
                                            </td>
                                            <td class="p-0 px-1">
                                                <span *ngIf="m.yearlyVolume">
                                                    {{m.yearlyVolume | number : '1.2-2'}}
                                                </span>
                                            </td>
                                            <td class="p-0 px-1">
                                                <span *ngIf="m.gMVE">
                                                    {{m.gMVE | number : '1.2-2'}}
                                                </span>
                                            </td>
                                            <td class="p-0 px-1">
                                                <button class="btn btn-sm btn-outline-danger"
                                                    *ngIf="!submitInProgress && !updateInProgress"
                                                    (click)="removeFuelLubricantGasProductItem('gasProduct',i)"
                                                    type="button">X</button>
                                                <button class="btn btn-sm btn-outline-danger" type="button" disabled
                                                    *ngIf="submitInProgress || updateInProgress">
                                                    <span class="spinner-border spinner-border-sm" role="status"
                                                        aria-hidden="true"></span>
                                                </button>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody class="table-group-divider" [formGroup]="gasProductForm">
                                        <tr>
                                            <td class="p-0 px-1">
                                                <span>
                                                    {{(gasProductArray.length)+(1)}}
                                                </span>
                                            </td>
                                            <td class="p-0 px-1">
                                                <input type="text" class="form-control" placeholder="Industry Name"
                                                    id="industryName" aria-label="Sizing example input"
                                                    formControlName="industryName"
                                                    aria-describedby="inputGroup-sizing-sm">
                                            </td>
                                            <td class="p-0 px-1">
                                                <input type="text" class="form-control" placeholder="Name" id="name"
                                                    aria-label="Sizing example input" formControlName="name"
                                                    aria-describedby="inputGroup-sizing-sm">
                                            </td>
                                            <td class="p-0 px-1">
                                                <input type="text" class="form-control"
                                                    placeholder="Industry Abbreviation" id="industryAbbreviation"
                                                    aria-label="Sizing example input"
                                                    formControlName="industryAbbreviation"
                                                    aria-describedby="inputGroup-sizing-sm">
                                            </td>
                                            <td class="p-0 px-1">
                                                <input type="number" class="form-control"
                                                    placeholder="Dealer Unit Margin" id="networkDealerMarginM3"
                                                    aria-label="Sizing example input"
                                                    formControlName="networkDealerMarginM3"
                                                    aria-describedby="inputGroup-sizing-sm">
                                            </td>
                                            <td class="p-0 px-1">
                                                <input type="number" class="form-control" placeholder="Unit GMVE"
                                                    id="unitGMVE" aria-label="Sizing example input"
                                                    formControlName="unitGMVE" aria-describedby="inputGroup-sizing-sm">
                                            </td>
                                            <td class="p-0 px-1">
                                                <input type="number" class="form-control"
                                                    placeholder="Company Unit Margin" id="companyUnitMargin"
                                                    aria-label="Sizing example input"
                                                    formControlName="companyUnitMargin" readonly
                                                    aria-describedby="inputGroup-sizing-sm">
                                            </td>
                                            <td class="p-0 px-1">
                                                <input type="number" class="form-control"
                                                    placeholder="Unit Supply Price" id="unitSupplyPrice"
                                                    aria-label="Sizing example input" formControlName="unitSupplyPrice"
                                                    aria-describedby="inputGroup-sizing-sm">
                                            </td>
                                            <td class="p-0 px-1">
                                                <input type="number" class="form-control" placeholder="Unitary Tax"
                                                    id="unitaryTax" aria-label="Sizing example input"
                                                    formControlName="unitaryTax"
                                                    aria-describedby="inputGroup-sizing-sm">
                                            </td>
                                            <td class="p-0 px-1">
                                                <input type="number" class="form-control" placeholder="Unit Revenue"
                                                    id="unitRevenue" aria-label="Sizing example input"
                                                    formControlName="unitRevenue" readonly
                                                    aria-describedby="inputGroup-sizing-sm">
                                            </td>
                                            <td class="p-0 px-1">
                                                <input type="number" class="form-control" placeholder="Monthly Volume"
                                                    id="monthlyVolume" aria-label="Sizing example input"
                                                    formControlName="monthlyVolume"
                                                    aria-describedby="inputGroup-sizing-sm">
                                            </td>
                                            <td class="p-0 px-1">
                                                <input type="number" class="form-control" placeholder="Yearly Volume"
                                                    id="yearlyVolume" aria-label="Sizing example input"
                                                    formControlName="yearlyVolume" readonly
                                                    aria-describedby="inputGroup-sizing-sm">
                                            </td>
                                            <td class="p-0 px-1">
                                                <input type="number" class="form-control" placeholder="GMVE" id="gMVE"
                                                    aria-label="Sizing example input" formControlName="gMVE" readonly
                                                    aria-describedby="inputGroup-sizing-sm">
                                            </td>
                                            <td class="p-0 px-1">
                                                <button class="btn btn-outline-success"
                                                    (click)="addFuelLubricantGasProductItem('gasProduct',gasProductForm.value)"
                                                    *ngIf="!submitInProgress && !updateInProgress" type="button">Add
                                                    Item</button>
                                                <button class="btn btn-sm btn-outline-success" type="button" disabled
                                                    *ngIf="submitInProgress || updateInProgress">
                                                    <span class="spinner-border spinner-border-sm" role="status"
                                                        aria-hidden="true"></span>
                                                </button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card" *ngIf="showProjectProductMarginEdit">
                    <div class="card-header text-center mb-0">
                        <span>
                            Edit Product
                        </span>
                    </div>
                    <div class="card-body">

                        <form class="form-horizontal" name="product-form" [formGroup]="productForm">
                            <div class="row">
                                <div class="col-12">
                                    <div class="row mb-0">
                                        <label for="productId" class="col-1 col-form-label col-form-label-sm">
                                            <span><span class="text-danger"></span></span>
                                        </label>
                                        <label for="productId" class="col-3 col-form-label col-form-label-sm">
                                            <span>Product<span class="text-danger"> *</span></span>
                                        </label>
                                        <div class="col-8">
                                            <select class="form-select form-select-sm" name="productId"
                                                formControlName="productId" id="productId" required
                                                [ngClass]="{'is-invalid': (formSubmitted && productFormValues['productId'].invalid)||(productFormValues['productId'].touched && productFormValues['productId'].invalid)}">
                                                <option *ngFor="let m of petroleumProductArray" [value]="m[0].id">
                                                    {{m[0].name}}
                                                </option>
                                            </select>
                                        </div>
                                        <div *ngIf="(formSubmitted && productFormValues['productId'].invalid)||(productFormValues['productId'].touched && productFormValues['productId'].invalid)"
                                            class="invalid-tooltip">
                                            <div *ngIf="productFormValues['productId'].errors?.['required']">
                                                Product is required.
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mb-3">
                                        <label for="productCategory" class="col-1 col-form-label col-form-label-sm">
                                            <span><span class="text-danger"></span></span>
                                        </label>
                                        <label for="productCategory" class="col-3 col-form-label col-form-label-sm">
                                            <span>Category<span class="text-danger"></span></span>
                                        </label>
                                        <div class="col-8">
                                            <input class="form-control form-control-sm form-control-plaintext"
                                                type="text" id="productCategory" placeholder="Fuel" readonly
                                                formControlName="productCategory">
                                        </div>
                                        <div *ngIf="(formSubmitted && productFormValues['productCategory'].invalid)||(productFormValues['productCategory'].touched && productFormValues['productCategory'].invalid)"
                                            class="invalid-tooltip">
                                            <div *ngIf="productFormValues['productCategory'].errors?.['required']">
                                                Product Category is required.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>

                        <form class="form-horizontal" name="fuel-product-form" [formGroup]="fuelProductForm">

                            <div *ngIf="showPetroleumProductForm && (pickedPetroleumProductCategory === 'Fuel')">

                                <div class="row">
                                    <div class="col-12">

                                        <div class="row mb-0">
                                            <label for="networkGlobalPrice"
                                                class="col-1 col-form-label col-form-label-sm">
                                                <span>i.</span>
                                            </label>
                                            <label for="networkGlobalPrice"
                                                class="col-5 col-form-label col-form-label-sm">
                                                <span>Global Price<span class="text-danger">
                                                        *</span></span>
                                            </label>
                                            <div class="col-6">
                                                <div class="input-group input-group-sm input-group-merge">
                                                    <input class="form-control form-control-sm" type="number" min="1"
                                                        id="networkGlobalPrice" placeholder="12.39"
                                                        formControlName="networkGlobalPrice">
                                                    <div class="input-group-text">
                                                        <span *ngIf="localCurrencyISOCode">
                                                            {{localCurrencyISOCode}}/L
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngIf="(formSubmitted && fuelProductFormValues['networkGlobalPrice'].invalid)||(fuelProductFormValues['networkGlobalPrice'].touched && fuelProductFormValues['networkGlobalPrice'].invalid)"
                                                class="invalid-tooltip">
                                                <div
                                                    *ngIf="fuelProductFormValues['networkGlobalPrice'].errors?.['required']">
                                                    Global Price is required.
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row mb-0">
                                            <label for="networkEPRAMargin"
                                                class="col-1 col-form-label col-form-label-sm">
                                                <span></span>
                                            </label>
                                            <label for="networkEPRAMargin"
                                                class="col-5 col-form-label col-form-label-sm">
                                                <span>EPRA Margin<span class="text-danger"></span></span>
                                            </label>
                                            <div class="col-6">
                                                <div class="input-group input-group-sm input-group-merge">
                                                    <input class="form-control form-control-sm form-control-plaintext"
                                                        type="number" min="1" id="networkEPRAMargin" placeholder="8.25"
                                                        readonly formControlName="networkEPRAMargin">
                                                    <div class="input-group-text">
                                                        <span *ngIf="localCurrencyISOCode">
                                                            {{localCurrencyISOCode}}/L
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngIf="(formSubmitted && fuelProductFormValues['networkEPRAMargin'].invalid)||(fuelProductFormValues['networkEPRAMargin'].touched && fuelProductFormValues['networkEPRAMargin'].invalid)"
                                                class="invalid-tooltip">
                                                <div
                                                    *ngIf="fuelProductFormValues['networkEPRAMargin'].errors?.['required']">
                                                    EPRA Margin is required.
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row mb-0">
                                            <label for="networkDODOMargin"
                                                class="col-1 col-form-label col-form-label-sm">
                                                <span>ii.</span>
                                            </label>
                                            <label for="networkDODOMargin"
                                                class="col-5 col-form-label col-form-label-sm">
                                                <span>DODO Margin<span class="text-danger"> *</span></span>
                                            </label>
                                            <div class="col-6">
                                                <div class="input-group input-group-sm input-group-merge">
                                                    <input class="form-control form-control-sm" type="number" min="1"
                                                        id="networkDODOMargin" placeholder="12.39"
                                                        formControlName="networkDODOMargin">
                                                    <div class="input-group-text">
                                                        <span *ngIf="localCurrencyISOCode">
                                                            {{localCurrencyISOCode}}/L
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngIf="(formSubmitted && fuelProductFormValues['networkDODOMargin'].invalid)||(fuelProductFormValues['networkDODOMargin'].touched && fuelProductFormValues['networkDODOMargin'].invalid)"
                                                class="invalid-tooltip">
                                                <div
                                                    *ngIf="fuelProductFormValues['networkDODOMargin'].errors?.['required']">
                                                    DODO Margin is required.
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row mb-0">
                                            <label for="networkDealerMarginL"
                                                class="col-1 col-form-label col-form-label-sm">
                                                <span>iii.</span>
                                            </label>
                                            <label for="networkDealerMarginL"
                                                class="col-5 col-form-label col-form-label-sm">
                                                <span>Dealer Margin<span class="text-danger"> *</span></span>
                                            </label>
                                            <div class="col-6">
                                                <div class="input-group input-group-sm input-group-merge">
                                                    <input class="form-control form-control-sm" type="number" min="1"
                                                        id="networkDealerMarginL" placeholder="12.39"
                                                        formControlName="networkDealerMarginL">
                                                    <div class="input-group-text">
                                                        <span *ngIf="localCurrencyISOCode">
                                                            {{localCurrencyISOCode}}/L
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngIf="(formSubmitted && fuelProductFormValues['networkDealerMarginL'].invalid)||(fuelProductFormValues['networkDealerMarginL'].touched && fuelProductFormValues['networkDealerMarginL'].invalid)"
                                                class="invalid-tooltip">
                                                <div
                                                    *ngIf="fuelProductFormValues['networkDealerMarginL'].errors?.['required']">
                                                    Dealer Margin is required.
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row mb-0">
                                            <label for="networkDiscountGiven"
                                                class="col-1 col-form-label col-form-label-sm">
                                                <span>iv.</span>
                                            </label>
                                            <label for="networkDiscountGiven"
                                                class="col-5 col-form-label col-form-label-sm">
                                                <span>Discount Given<span class="text-danger"> *</span></span>
                                            </label>
                                            <div class="col-6">
                                                <div class="input-group input-group-sm input-group-merge">
                                                    <input class="form-control form-control-sm form-control-plaintext"
                                                        type="number" min="1" id="networkDiscountGiven"
                                                        placeholder="12.39" readonly
                                                        formControlName="networkDiscountGiven">
                                                    <div class="input-group-text">
                                                        <span *ngIf="localCurrencyISOCode">
                                                            {{localCurrencyISOCode}}/L
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngIf="(formSubmitted && fuelProductFormValues['networkDiscountGiven'].invalid)||(fuelProductFormValues['networkDiscountGiven'].touched && fuelProductFormValues['networkDiscountGiven'].invalid)"
                                                class="invalid-tooltip">
                                                <div
                                                    *ngIf="fuelProductFormValues['networkDiscountGiven'].errors?.['required']">
                                                    Discount Given is required.
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row mb-0">
                                            <label for="networkDepotCost"
                                                class="col-1 col-form-label col-form-label-sm">
                                                <span>v.</span>
                                            </label>
                                            <label for="networkDepotCost"
                                                class="col-5 col-form-label col-form-label-sm">
                                                <span>Depot Cost<span class="text-danger"> *</span></span>
                                            </label>
                                            <div class="col-6">
                                                <div class="input-group input-group-sm input-group-merge">
                                                    <input class="form-control form-control-sm" type="number" min="0"
                                                        id="networkDepotCost" placeholder="12.39"
                                                        formControlName="networkDepotCost">
                                                    <div class="input-group-text">
                                                        <span *ngIf="localCurrencyISOCode">
                                                            {{localCurrencyISOCode}}/L
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngIf="(formSubmitted && fuelProductFormValues['networkDepotCost'].invalid)||(fuelProductFormValues['networkDepotCost'].touched && fuelProductFormValues['networkDepotCost'].invalid)"
                                                class="invalid-tooltip">
                                                <div
                                                    *ngIf="fuelProductFormValues['networkDepotCost'].errors?.['required']">
                                                    Depot Cost is required.
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row mb-0">
                                            <label for="networkGalanaPro"
                                                class="col-1 col-form-label col-form-label-sm">
                                                <span>vi.</span>
                                            </label>
                                            <label for="networkGalanaPro"
                                                class="col-5 col-form-label col-form-label-sm">
                                                <span>Galana Pro<span class="text-danger"> *</span></span>
                                            </label>
                                            <div class="col-6">
                                                <div class="input-group input-group-sm input-group-merge">
                                                    <input class="form-control form-control-sm" type="number" min="0"
                                                        id="networkGalanaPro" placeholder="12.39"
                                                        formControlName="networkGalanaPro">
                                                    <div class="input-group-text">
                                                        <span *ngIf="localCurrencyISOCode">
                                                            {{localCurrencyISOCode}}/L
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngIf="(formSubmitted && fuelProductFormValues['networkGalanaPro'].invalid)||(fuelProductFormValues['networkGalanaPro'].touched && fuelProductFormValues['networkGalanaPro'].invalid)"
                                                class="invalid-tooltip">
                                                <div
                                                    *ngIf="fuelProductFormValues['networkGalanaPro'].errors?.['required']">
                                                    Galana Pro is required.
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row mb-0">
                                            <label for="networkVarex" class="col-1 col-form-label col-form-label-sm">
                                                <span>vii.</span>
                                            </label>
                                            <label for="networkVarex" class="col-5 col-form-label col-form-label-sm">
                                                <span>Varex<span class="text-danger"> *</span></span>
                                            </label>
                                            <div class="col-6">
                                                <div class="input-group input-group-sm input-group-merge">
                                                    <input class="form-control form-control-sm" type="number" min="0"
                                                        id="networkVarex" placeholder="12.39"
                                                        formControlName="networkVarex">
                                                    <div class="input-group-text">
                                                        <span *ngIf="localCurrencyISOCode">
                                                            {{localCurrencyISOCode}}/L
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngIf="(formSubmitted && fuelProductFormValues['networkVarex'].invalid)||(fuelProductFormValues['networkVarex'].touched && fuelProductFormValues['networkVarex'].invalid)"
                                                class="invalid-tooltip">
                                                <div *ngIf="fuelProductFormValues['networkVarex'].errors?.['required']">
                                                    Varex is required.
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row mb-0">
                                            <label for="networkExpectedGMVE"
                                                class="col-1 col-form-label col-form-label-sm">
                                                <span></span>
                                            </label>
                                            <label for="networkExpectedGMVE"
                                                class="col-5 col-form-label col-form-label-sm">
                                                <span>Unit GMVE<span class="text-danger"></span></span>
                                            </label>
                                            <div class="col-6">
                                                <div class="input-group input-group-sm input-group-merge">
                                                    <input class="form-control form-control-sm form-control-plaintext"
                                                        type="number" min="1" id="networkExpectedGMVE"
                                                        placeholder="12.39" readonly
                                                        formControlName="networkExpectedGMVE">
                                                    <div class="input-group-text">
                                                        <span *ngIf="localCurrencyISOCode">
                                                            {{localCurrencyISOCode}}/L
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngIf="(formSubmitted && fuelProductFormValues['networkExpectedGMVE'].invalid)||(fuelProductFormValues['networkExpectedGMVE'].touched && fuelProductFormValues['networkExpectedGMVE'].invalid)"
                                                class="invalid-tooltip">
                                                <div
                                                    *ngIf="fuelProductFormValues['networkExpectedGMVE'].errors?.['required']">
                                                    Unit GMVE is required.
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row mb-0">
                                            <label for="companyUnitMargin"
                                                class="col-1 col-form-label col-form-label-sm">
                                                <span></span>
                                            </label>
                                            <label for="companyUnitMargin"
                                                class="col-5 col-form-label col-form-label-sm">
                                                <span>Company Unit Margin<span class="text-danger">
                                                    </span></span>
                                            </label>
                                            <div class="col-6">
                                                <div class="input-group input-group-sm input-group-merge">
                                                    <input class="form-control form-control-sm form-control-plaintext"
                                                        type="number" readonly min="1" id="companyUnitMargin"
                                                        placeholder="12.39" formControlName="companyUnitMargin">
                                                    <div class="input-group-text">
                                                        <span *ngIf="localCurrencyISOCode">
                                                            {{localCurrencyISOCode}}/m3
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngIf="(formSubmitted && fuelProductFormValues['companyUnitMargin'].invalid)||(fuelProductFormValues['companyUnitMargin'].touched && fuelProductFormValues['companyUnitMargin'].invalid)"
                                                class="invalid-tooltip">
                                                <div
                                                    *ngIf="fuelProductFormValues['companyUnitMargin'].errors?.['required']">
                                                    Company Unit Margin is required.
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row mb-0">
                                            <label for="unitSupplyPrice" class="col-1 col-form-label col-form-label-sm">
                                                <span>viii.</span>
                                            </label>
                                            <label for="unitSupplyPrice" class="col-5 col-form-label col-form-label-sm">
                                                <span>Unit Supply Price<span class="text-danger">
                                                        *</span></span>
                                            </label>
                                            <div class="col-6">
                                                <div class="input-group input-group-sm input-group-merge">
                                                    <input class="form-control form-control-sm" type="number" min="1"
                                                        id="unitSupplyPrice" placeholder="12.39"
                                                        formControlName="unitSupplyPrice">
                                                    <div class="input-group-text">
                                                        <span *ngIf="localCurrencyISOCode">
                                                            {{localCurrencyISOCode}}/m3
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngIf="(formSubmitted && fuelProductFormValues['unitSupplyPrice'].invalid)||(fuelProductFormValues['unitSupplyPrice'].touched && fuelProductFormValues['unitSupplyPrice'].invalid)"
                                                class="invalid-tooltip">
                                                <div
                                                    *ngIf="fuelProductFormValues['unitSupplyPrice'].errors?.['required']">
                                                    Unit Supply Price is required.
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row mb-0">
                                            <label for="unitaryTax" class="col-1 col-form-label col-form-label-sm">
                                                <span>ix.</span>
                                            </label>
                                            <label for="unitaryTax" class="col-5 col-form-label col-form-label-sm">
                                                <span>Unitary Tax<span class="text-danger">
                                                        *</span></span>
                                            </label>
                                            <div class="col-6">
                                                <div class="input-group input-group-sm input-group-merge">
                                                    <input class="form-control form-control-sm" type="number" min="1"
                                                        id="unitaryTax" placeholder="12.39"
                                                        formControlName="unitaryTax">
                                                    <div class="input-group-text">
                                                        <span *ngIf="localCurrencyISOCode">
                                                            {{localCurrencyISOCode}}/m3
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngIf="(formSubmitted && fuelProductFormValues['unitaryTax'].invalid)||(fuelProductFormValues['unitaryTax'].touched && fuelProductFormValues['unitaryTax'].invalid)"
                                                class="invalid-tooltip">
                                                <div *ngIf="fuelProductFormValues['unitaryTax'].errors?.['required']">
                                                    Unitary Tax is required.
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row mb-0">
                                            <label for="unitRevenue" class="col-1 col-form-label col-form-label-sm">
                                                <span></span>
                                            </label>
                                            <label for="unitRevenue" class="col-5 col-form-label col-form-label-sm">
                                                <span>Unit Revenue<span class="text-danger"></span></span>
                                            </label>
                                            <div class="col-6">
                                                <div class="input-group input-group-sm input-group-merge">
                                                    <input class="form-control form-control-sm form-control-plaintext"
                                                        type="number" min="1" id="unitRevenue" placeholder="12.39"
                                                        readonly formControlName="unitRevenue">
                                                    <div class="input-group-text">
                                                        <span *ngIf="localCurrencyISOCode">
                                                            {{localCurrencyISOCode}}/m3
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngIf="(formSubmitted && fuelProductFormValues['unitRevenue'].invalid)||(fuelProductFormValues['unitRevenue'].touched && fuelProductFormValues['unitRevenue'].invalid)"
                                                class="invalid-tooltip">
                                                <div *ngIf="fuelProductFormValues['unitRevenue'].errors?.['required']">
                                                    Unit Revenue is required.
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row mb-0">
                                            <label for="monthlyVolume" class="col-1 col-form-label col-form-label-sm">
                                                <span>x.</span>
                                            </label>
                                            <label for="monthlyVolume" class="col-5 col-form-label col-form-label-sm">
                                                <span>Monthly Volume<span class="text-danger"> *</span></span>
                                            </label>
                                            <div class="col-6">
                                                <div class="input-group input-group-sm input-group-merge">
                                                    <input class="form-control form-control-sm" type="number" min="1"
                                                        id="monthlyVolume" placeholder="12.39"
                                                        formControlName="monthlyVolume">
                                                    <div class="input-group-text">
                                                        <span>
                                                            m3
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngIf="(formSubmitted && fuelProductFormValues['monthlyVolume'].invalid)||(fuelProductFormValues['monthlyVolume'].touched && fuelProductFormValues['monthlyVolume'].invalid)"
                                                class="invalid-tooltip">
                                                <div
                                                    *ngIf="fuelProductFormValues['monthlyVolume'].errors?.['required']">
                                                    Monthly Volume is required.
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row mb-0">
                                            <label for="yearlyVolume" class="col-1 col-form-label col-form-label-sm">
                                                <span></span>
                                            </label>
                                            <label for="yearlyVolume" class="col-5 col-form-label col-form-label-sm">
                                                <span>Yearly Volume<span class="text-danger"></span></span>
                                            </label>
                                            <div class="col-6">
                                                <div class="input-group input-group-sm input-group-merge">
                                                    <input class="form-control form-control-sm form-control-plaintext"
                                                        type="number" min="1" id="yearlyVolume" placeholder="12.39"
                                                        readonly formControlName="yearlyVolume">
                                                    <div class="input-group-text">
                                                        <span>
                                                            m3
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngIf="(formSubmitted && fuelProductFormValues['yearlyVolume'].invalid)||(fuelProductFormValues['yearlyVolume'].touched && fuelProductFormValues['yearlyVolume'].invalid)"
                                                class="invalid-tooltip">
                                                <div *ngIf="fuelProductFormValues['yearlyVolume'].errors?.['required']">
                                                    Yearly Volume is required.
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row mb-3">
                                            <label for="gMVE" class="col-1 col-form-label col-form-label-sm">
                                                <span></span>
                                            </label>
                                            <label for="gMVE" class="col-5 col-form-label col-form-label-sm">
                                                <span>GMVE<span class="text-danger"></span></span>
                                            </label>
                                            <div class="col-6">
                                                <div class="input-group input-group-sm input-group-merge">
                                                    <input class="form-control form-control-sm form-control-plaintext"
                                                        type="number" min="1" id="gMVE" readonly formControlName="gMVE">
                                                    <div class="input-group-text">
                                                        <span *ngIf="localCurrencyISOCode">
                                                            {{localCurrencyISOCode}}/m3
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngIf="(formSubmitted && fuelProductFormValues['gMVE'].invalid)||(fuelProductFormValues['gMVE'].touched && fuelProductFormValues['gMVE'].invalid)"
                                                class="invalid-tooltip">
                                                <div *ngIf="fuelProductFormValues['gMVE'].errors?.['required']">
                                                    GMVE is required.
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-6">
                                                <div class="d-grid">
                                                    <button class="btn btn-secondary mt-2" (click)="hideProductMenu()"
                                                        type="submit"><i class=""></i>Cancel Edit</button>
                                                </div>
                                            </div>
                                            <div class="col-6">
                                                <div class="d-grid" *ngIf="productExists_Update">
                                                    <button class="btn btn-success mt-2" *ngIf="!updateInProgress"
                                                        (click)="addProduct(fuelProductForm.value)" type="submit"><i
                                                            class=""></i>Update Product</button>
                                                    <button class="btn btn-success mt-2" type="button" disabled
                                                        *ngIf="updateInProgress">
                                                        <span class="spinner-border spinner-border-sm"
                                                            role="status" aria-hidden="true"></span>
                                                        
                                                    </button>
                                                </div>
                                                <div class="d-grid" *ngIf="!productExists_Update">
                                                    <button class="btn btn-success mt-2" *ngIf="!updateInProgress"
                                                        (click)="addProduct(fuelProductForm.value)" type="submit"><i
                                                            class=""></i>Add Product</button>
                                                    <button class="btn btn-success mt-2" type="button" disabled
                                                        *ngIf="updateInProgress">
                                                        <span class="spinner-border spinner-border-sm"
                                                            role="status" aria-hidden="true"></span>
                                                        
                                                    </button>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </form>

                        <form class="form-horizontal" name="lubricant-product-form" [formGroup]="lubricantProductForm">
                            <div *ngIf="showPetroleumProductForm && (pickedPetroleumProductCategory === 'Lubricant')">

                                <div class="row">
                                    <div class="col-12">

                                        <div class="row mb-0">
                                            <label for="networkDealerMarginM3"
                                                class="col-1 col-form-label col-form-label-sm">
                                                <span>i.</span>
                                            </label>
                                            <label for="networkDealerMarginM3"
                                                class="col-5 col-form-label col-form-label-sm">
                                                <span>Dealer Margin<span class="text-danger"> *</span></span>
                                            </label>
                                            <div class="col-6">
                                                <div class="input-group input-group-sm input-group-merge">
                                                    <input class="form-control form-control-sm" type="number" min="1"
                                                        id="networkDealerMarginM3" placeholder="40,000"
                                                        formControlName="networkDealerMarginM3">
                                                    <div class="input-group-text">
                                                        <span *ngIf="localCurrencyISOCode">
                                                            {{localCurrencyISOCode}}/m3
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngIf="(formSubmitted && lubricantProductFormValues['networkDealerMarginM3'].invalid)||(lubricantProductFormValues['networkDealerMarginM3'].touched && lubricantProductFormValues['networkDealerMarginM3'].invalid)"
                                                class="invalid-tooltip">
                                                <div
                                                    *ngIf="lubricantProductFormValues['networkDealerMarginM3'].errors?.['required']">
                                                    Dealer Margin is required.
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row mb-0">
                                            <label for="unitGMVE" class="col-1 col-form-label col-form-label-sm">
                                                <span>ii.</span>
                                            </label>
                                            <label for="unitGMVE" class="col-5 col-form-label col-form-label-sm">
                                                <span>Unit GMVE<span class="text-danger"> *</span></span>
                                            </label>
                                            <div class="col-6">
                                                <div class="input-group input-group-sm input-group-merge">
                                                    <input class="form-control form-control-sm" type="number" min="1"
                                                        id="unitGMVE" placeholder="166,021" formControlName="unitGMVE">
                                                    <div class="input-group-text">
                                                        <span *ngIf="localCurrencyISOCode">
                                                            {{localCurrencyISOCode}}/m3
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngIf="(formSubmitted && lubricantProductFormValues['unitGMVE'].invalid)||(lubricantProductFormValues['unitGMVE'].touched && lubricantProductFormValues['unitGMVE'].invalid)"
                                                class="invalid-tooltip">
                                                <div
                                                    *ngIf="lubricantProductFormValues['unitGMVE'].errors?.['required']">
                                                    Unit GMVE is required.
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row mb-0">
                                            <label for="ompanyUnitMargin"
                                                class="col-1 col-form-label col-form-label-sm">
                                                <span></span>
                                            </label>
                                            <label for="companyUnitMargin"
                                                class="col-5 col-form-label col-form-label-sm">
                                                <span>Company Unit Margin<span class="text-danger"></span></span>
                                            </label>
                                            <div class="col-6">
                                                <div class="input-group input-group-sm input-group-merge">
                                                    <input class="form-control form-control-sm form-control-plaintext"
                                                        type="number" readonly min="1" id="companyUnitMargin"
                                                        formControlName="companyUnitMargin">
                                                    <div class="input-group-text">
                                                        <span *ngIf="localCurrencyISOCode">
                                                            {{localCurrencyISOCode}}/m3
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngIf="(formSubmitted && lubricantProductFormValues['companyUnitMargin'].invalid)||(lubricantProductFormValues['companyUnitMargin'].touched && lubricantProductFormValues['companyUnitMargin'].invalid)"
                                                class="invalid-tooltip">
                                                <div
                                                    *ngIf="lubricantProductFormValues['companyUnitMargin'].errors?.['required']">
                                                    Company Unit Margin is required.
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row mb-0">
                                            <label for="unitSupplyPrice" class="col-1 col-form-label col-form-label-sm">
                                                <span>iii.</span>
                                            </label>
                                            <label for="unitSupplyPrice" class="col-5 col-form-label col-form-label-sm">
                                                <span>Unit Supply Price<span class="text-danger"> *</span></span>
                                            </label>
                                            <div class="col-6">
                                                <div class="input-group input-group-sm input-group-merge">
                                                    <input class="form-control form-control-sm" type="number" min="1"
                                                        id="unitSupplyPrice" placeholder="175,325"
                                                        formControlName="unitSupplyPrice">
                                                    <div class="input-group-text">
                                                        <span *ngIf="localCurrencyISOCode">
                                                            {{localCurrencyISOCode}}/m3
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngIf="(formSubmitted && lubricantProductFormValues['unitSupplyPrice'].invalid)||(lubricantProductFormValues['unitSupplyPrice'].touched && lubricantProductFormValues['unitSupplyPrice'].invalid)"
                                                class="invalid-tooltip">
                                                <div
                                                    *ngIf="lubricantProductFormValues['unitSupplyPrice'].errors?.['required']">
                                                    Unit Supply Price is required.
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row mb-0">
                                            <label for="unitaryTax" class="col-1 col-form-label col-form-label-sm">
                                                <span>iv.</span>
                                            </label>
                                            <label for="unitaryTax" class="col-5 col-form-label col-form-label-sm">
                                                <span>Unitary Tax<span class="text-danger"> *</span></span>
                                            </label>
                                            <div class="col-6">
                                                <div class="input-group input-group-sm input-group-merge">
                                                    <input class="form-control form-control-sm" type="number" min="1"
                                                        id="unitaryTax" placeholder="21,039"
                                                        formControlName="unitaryTax">
                                                    <div class="input-group-text">
                                                        <span *ngIf="localCurrencyISOCode">
                                                            {{localCurrencyISOCode}}/m3
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngIf="(formSubmitted && lubricantProductFormValues['unitaryTax'].invalid)||(lubricantProductFormValues['unitaryTax'].touched && lubricantProductFormValues['unitaryTax'].invalid)"
                                                class="invalid-tooltip">
                                                <div
                                                    *ngIf="lubricantProductFormValues['unitaryTax'].errors?.['required']">
                                                    Unitary Tax is required.
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row mb-0">
                                            <label for="unitRevenue" class="col-1 col-form-label col-form-label-sm">
                                                <span></span>
                                            </label>
                                            <label for="unitRevenue" class="col-5 col-form-label col-form-label-sm">
                                                <span>Unit Revenue<span class="text-danger"></span></span>
                                            </label>
                                            <div class="col-6">
                                                <div class="input-group input-group-sm input-group-merge">
                                                    <input class="form-control form-control-sm form-control-plaintext"
                                                        type="number" min="1" id="unitRevenue" readonly
                                                        formControlName="unitRevenue">
                                                    <div class="input-group-text">
                                                        <span *ngIf="localCurrencyISOCode">
                                                            {{localCurrencyISOCode}}/m3
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngIf="(formSubmitted && lubricantProductFormValues['unitRevenue'].invalid)||(lubricantProductFormValues['unitRevenue'].touched && lubricantProductFormValues['unitRevenue'].invalid)"
                                                class="invalid-tooltip">
                                                <div
                                                    *ngIf="lubricantProductFormValues['unitRevenue'].errors?.['required']">
                                                    Unit Revenue is required.
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row mb-0">
                                            <label for="monthlyVolume" class="col-1 col-form-label col-form-label-sm">
                                                <span>v.</span>
                                            </label>
                                            <label for="monthlyVolume" class="col-5 col-form-label col-form-label-sm">
                                                <span>Monthly Volume<span class="text-danger"> *</span></span>
                                            </label>
                                            <div class="col-6">
                                                <div class="input-group input-group-sm input-group-merge">
                                                    <input class="form-control form-control-sm" type="number" min="1"
                                                        id="monthlyVolume" placeholder="175"
                                                        formControlName="monthlyVolume">
                                                    <div class="input-group-text">
                                                        <span>
                                                            m3
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngIf="(formSubmitted && lubricantProductFormValues['monthlyVolume'].invalid)||(lubricantProductFormValues['monthlyVolume'].touched && lubricantProductFormValues['monthlyVolume'].invalid)"
                                                class="invalid-tooltip">
                                                <div
                                                    *ngIf="lubricantProductFormValues['monthlyVolume'].errors?.['required']">
                                                    Monthly Volume is required.
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row mb-0">
                                            <label for="yearlyVolume" class="col-1 col-form-label col-form-label-sm">
                                                <span></span>
                                            </label>
                                            <label for="yearlyVolume" class="col-5 col-form-label col-form-label-sm">
                                                <span>Yearly Volume<span class="text-danger"></span></span>
                                            </label>
                                            <div class="col-6">
                                                <div class="input-group input-group-sm input-group-merge">
                                                    <input class="form-control form-control-sm form-control-plaintext"
                                                        type="number" min="1" id="yearlyVolume" readonly
                                                        formControlName="yearlyVolume">
                                                    <div class="input-group-text">
                                                        <span>
                                                            m3
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngIf="(formSubmitted && lubricantProductFormValues['yearlyVolume'].invalid)||(lubricantProductFormValues['yearlyVolume'].touched && lubricantProductFormValues['yearlyVolume'].invalid)"
                                                class="invalid-tooltip">
                                                <div
                                                    *ngIf="lubricantProductFormValues['yearlyVolume'].errors?.['required']">
                                                    Yearly Volume is required.
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row mb-3">
                                            <label for="gMVE" class="col-1 col-form-label col-form-label-sm">
                                                <span></span>
                                            </label>
                                            <label for="gMVE" class="col-5 col-form-label col-form-label-sm">
                                                <span>GMVE<span class="text-danger"></span></span>
                                            </label>
                                            <div class="col-6">
                                                <div class="input-group input-group-sm input-group-merge">
                                                    <input class="form-control form-control-sm form-control-plaintext"
                                                        type="number" min="1" id="gMVE" readonly formControlName="gMVE">
                                                    <div class="input-group-text">
                                                        <span *ngIf="localCurrencyISOCode">
                                                            {{localCurrencyISOCode}}/m3
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngIf="(formSubmitted && lubricantProductFormValues['gMVE'].invalid)||(lubricantProductFormValues['gMVE'].touched && lubricantProductFormValues['gMVE'].invalid)"
                                                class="invalid-tooltip">
                                                <div *ngIf="lubricantProductFormValues['gMVE'].errors?.['required']">
                                                    GMVE is required.
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-6">
                                                <div class="d-grid">
                                                    <button class="btn btn-secondary mt-2" (click)="hideProductMenu()"
                                                        type="submit"><i class=""></i>Cancel Edit</button>
                                                </div>
                                            </div>
                                            <div class="col-6">
                                                <div class="d-grid" *ngIf="productExists_Update">
                                                    <button class="btn btn-success mt-2" *ngIf="!updateInProgress"
                                                        (click)="addProduct(lubricantProductForm.value)"
                                                        type="submit"><i class=""></i>Update Product</button>
                                                    <button class="btn btn-success mt-2" type="button" disabled
                                                        *ngIf="updateInProgress">
                                                        <span class="spinner-border spinner-border-sm"
                                                            role="status" aria-hidden="true"></span>
                                                        
                                                    </button>
                                                </div>
                                                <div class="d-grid" *ngIf="!productExists_Update">
                                                    <button class="btn btn-success mt-2" *ngIf="!updateInProgress"
                                                        (click)="addProduct(lubricantProductForm.value)"
                                                        type="submit"><i class=""></i>Add Product</button>
                                                    <button class="btn btn-success mt-2" type="button" disabled
                                                        *ngIf="updateInProgress">
                                                        <span class="spinner-border spinner-border-sm"
                                                            role="status" aria-hidden="true"></span>
                                                        
                                                    </button>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </form>

                        <form class="form-horizontal" name="gas-product-form" [formGroup]="gasProductForm">

                            <div *ngIf="showPetroleumProductForm && (pickedPetroleumProductCategory === 'Gas')">

                                <div class="row">
                                    <div class="col-12">

                                        <div class="row mb-0">
                                            <label for="networkDealerMarginM3"
                                                class="col-1 col-form-label col-form-label-sm">
                                                <span>i.</span>
                                            </label>
                                            <label for="networkDealerMarginM3"
                                                class="col-5 col-form-label col-form-label-sm">
                                                <span>Dealer Unit Margin<span class="text-danger"> *</span></span>
                                            </label>
                                            <div class="col-6">
                                                <div class="input-group input-group-sm input-group-merge">
                                                    <input class="form-control form-control-sm" type="number" min="1"
                                                        id="networkDealerMarginM3" placeholder="15,000"
                                                        formControlName="networkDealerMarginM3">
                                                    <div class="input-group-text">
                                                        <span *ngIf="localCurrencyISOCode">
                                                            {{localCurrencyISOCode}}/m3
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngIf="(formSubmitted && gasProductFormValues['networkDealerMarginM3'].invalid)||(gasProductFormValues['networkDealerMarginM3'].touched && gasProductFormValues['networkDealerMarginM3'].invalid)"
                                                class="invalid-tooltip">
                                                <div
                                                    *ngIf="gasProductFormValues['networkDealerMarginM3'].errors?.['required']">
                                                    Dealer Unit Margin is required.
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row mb-0">
                                            <label for="unitGMVE" class="col-1 col-form-label col-form-label-sm">
                                                <span>ii.</span>
                                            </label>
                                            <label for="unitGMVE" class="col-5 col-form-label col-form-label-sm">
                                                <span>Unit GMVE<span class="text-danger"> *</span></span>
                                            </label>
                                            <div class="col-6">
                                                <div class="input-group input-group-sm input-group-merge">
                                                    <input class="form-control form-control-sm" type="number" min="1"
                                                        id="unitGMVE" placeholder="54281.28" formControlName="unitGMVE">
                                                    <div class="input-group-text">
                                                        <span *ngIf="localCurrencyISOCode">
                                                            {{localCurrencyISOCode}}/m3
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngIf="(formSubmitted && gasProductFormValues['unitGMVE'].invalid)||(gasProductFormValues['unitGMVE'].touched && gasProductFormValues['unitGMVE'].invalid)"
                                                class="invalid-tooltip">
                                                <div *ngIf="gasProductFormValues['unitGMVE'].errors?.['required']">
                                                    Unit GMVE is required.
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row mb-0">
                                            <label for="companyUnitMargin"
                                                class="col-1 col-form-label col-form-label-sm">
                                                <span></span>
                                            </label>
                                            <label for="companyUnitMargin"
                                                class="col-5 col-form-label col-form-label-sm">
                                                <span>Company Unit Margin<span class="text-danger">
                                                    </span></span>
                                            </label>
                                            <div class="col-6">
                                                <div class="input-group input-group-sm input-group-merge">
                                                    <input class="form-control form-control-sm form-control-plaintext"
                                                        type="number" readonly min="1" id="companyUnitMargin"
                                                        formControlName="companyUnitMargin">
                                                    <div class="input-group-text">
                                                        <span *ngIf="localCurrencyISOCode">
                                                            {{localCurrencyISOCode}}/m3
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngIf="(formSubmitted && gasProductFormValues['companyUnitMargin'].invalid)||(gasProductFormValues['companyUnitMargin'].touched && gasProductFormValues['companyUnitMargin'].invalid)"
                                                class="invalid-tooltip">
                                                <div
                                                    *ngIf="gasProductFormValues['companyUnitMargin'].errors?.['required']">
                                                    Company Unit Margin is required.
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row mb-0">
                                            <label for="unitSupplyPrice" class="col-1 col-form-label col-form-label-sm">
                                                <span>iii.</span>
                                            </label>
                                            <label for="unitSupplyPrice" class="col-5 col-form-label col-form-label-sm">
                                                <span>Unit Supply Price<span class="text-danger">
                                                        *</span></span>
                                            </label>
                                            <div class="col-6">
                                                <div class="input-group input-group-sm input-group-merge">
                                                    <input class="form-control form-control-sm" type="number" min="1"
                                                        id="unitSupplyPrice" placeholder="82019.99"
                                                        formControlName="unitSupplyPrice">
                                                    <div class="input-group-text">
                                                        <span *ngIf="localCurrencyISOCode">
                                                            {{localCurrencyISOCode}}/m3
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngIf="(formSubmitted && gasProductFormValues['unitSupplyPrice'].invalid)||(gasProductFormValues['unitSupplyPrice'].touched && gasProductFormValues['unitSupplyPrice'].invalid)"
                                                class="invalid-tooltip">
                                                <div
                                                    *ngIf="gasProductFormValues['unitSupplyPrice'].errors?.['required']">
                                                    Unit Supply Price is required.
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row mb-0">
                                            <label for="unitaryTax" class="col-1 col-form-label col-form-label-sm">
                                                <span>iv.</span>
                                            </label>
                                            <label for="unitaryTax" class="col-5 col-form-label col-form-label-sm">
                                                <span>Unitary Tax<span class="text-danger">
                                                        *</span></span>
                                            </label>
                                            <div class="col-6">
                                                <div class="input-group input-group-sm input-group-merge">
                                                    <input class="form-control form-control-sm" type="number" min="1"
                                                        id="unitaryTax" placeholder="400" formControlName="unitaryTax">
                                                    <div class="input-group-text">
                                                        <span *ngIf="localCurrencyISOCode">
                                                            {{localCurrencyISOCode}}/m3
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngIf="(formSubmitted && gasProductFormValues['unitaryTax'].invalid)||(gasProductFormValues['unitaryTax'].touched && gasProductFormValues['unitaryTax'].invalid)"
                                                class="invalid-tooltip">
                                                <div *ngIf="gasProductFormValues['unitaryTax'].errors?.['required']">
                                                    Unitary Tax is required.
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row mb-0">
                                            <label for="unitRevenue" class="col-1 col-form-label col-form-label-sm">
                                                <span></span>
                                            </label>
                                            <label for="unitRevenue" class="col-5 col-form-label col-form-label-sm">
                                                <span>Unit Revenue<span class="text-danger">
                                                    </span></span>
                                            </label>
                                            <div class="col-6">
                                                <div class="input-group input-group-sm input-group-merge">
                                                    <input class="form-control form-control-sm form-control-plaintext"
                                                        type="number" min="1" id="unitRevenue" readonly
                                                        formControlName="unitRevenue">
                                                    <div class="input-group-text">
                                                        <span *ngIf="localCurrencyISOCode">
                                                            {{localCurrencyISOCode}}/m3
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngIf="(formSubmitted && gasProductFormValues['unitRevenue'].invalid)||(gasProductFormValues['unitRevenue'].touched && gasProductFormValues['unitRevenue'].invalid)"
                                                class="invalid-tooltip">
                                                <div *ngIf="gasProductFormValues['unitRevenue'].errors?.['required']">
                                                    Unit Revenue is required.
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row mb-0">
                                            <label for="monthlyVolume" class="col-1 col-form-label col-form-label-sm">
                                                <span>v.</span>
                                            </label>
                                            <label for="monthlyVolume" class="col-5 col-form-label col-form-label-sm">
                                                <span>Monthly Volume<span class="text-danger">
                                                        *</span></span>
                                            </label>
                                            <div class="col-6">
                                                <div class="input-group input-group-sm input-group-merge">
                                                    <input class="form-control form-control-sm" type="number" min="1"
                                                        id="monthlyVolume" placeholder="5"
                                                        formControlName="monthlyVolume">
                                                    <div class="input-group-text">
                                                        <span>
                                                            m3
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngIf="(formSubmitted && gasProductFormValues['monthlyVolume'].invalid)||(gasProductFormValues['monthlyVolume'].touched && gasProductFormValues['monthlyVolume'].invalid)"
                                                class="invalid-tooltip">
                                                <div *ngIf="gasProductFormValues['monthlyVolume'].errors?.['required']">
                                                    Monthly Volume is required.
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row mb-0">
                                            <label for="yearlyVolume" class="col-1 col-form-label col-form-label-sm">
                                                <span></span>
                                            </label>
                                            <label for="yearlyVolume" class="col-5 col-form-label col-form-label-sm">
                                                <span>Yearly Volume<span class="text-danger"></span></span>
                                            </label>
                                            <div class="col-6">
                                                <div class="input-group input-group-sm input-group-merge">
                                                    <input class="form-control form-control-sm form-control-plaintext"
                                                        type="number" min="1" id="yearlyVolume" readonly
                                                        formControlName="yearlyVolume">
                                                    <div class="input-group-text">
                                                        <span>
                                                            m3
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngIf="(formSubmitted && gasProductFormValues['yearlyVolume'].invalid)||(gasProductFormValues['yearlyVolume'].touched && gasProductFormValues['yearlyVolume'].invalid)"
                                                class="invalid-tooltip">
                                                <div *ngIf="gasProductFormValues['yearlyVolume'].errors?.['required']">
                                                    Yearly Volume is required.
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row mb-3">
                                            <label for="gMVE" class="col-1 col-form-label col-form-label-sm">
                                                <span></span>
                                            </label>
                                            <label for="gMVE" class="col-5 col-form-label col-form-label-sm">
                                                <span>GMVE<span class="text-danger"></span></span>
                                            </label>
                                            <div class="col-6">
                                                <div class="input-group input-group-sm input-group-merge">
                                                    <input class="form-control form-control-sm form-control-plaintext"
                                                        type="number" min="1" id="gMVE" readonly formControlName="gMVE">
                                                    <div class="input-group-text">
                                                        <span *ngIf="localCurrencyISOCode">
                                                            {{localCurrencyISOCode}}/m3
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngIf="(formSubmitted && gasProductFormValues['gMVE'].invalid)||(gasProductFormValues['gMVE'].touched && gasProductFormValues['gMVE'].invalid)"
                                                class="invalid-tooltip">
                                                <div *ngIf="gasProductFormValues['gMVE'].errors?.['required']">
                                                    GMVE is required.
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-6">
                                                <div class="d-grid">
                                                    <button class="btn btn-secondary mt-2" (click)="hideProductMenu()"
                                                        type="submit"><i class=""></i>Cancel Edit</button>
                                                </div>
                                            </div>
                                            <div class="col-6">
                                                <div class="d-grid" *ngIf="productExists_Update">
                                                    <button class="btn btn-success mt-2" *ngIf="!updateInProgress"
                                                        (click)="addProduct(gasProductForm.value)" type="submit"><i
                                                            class=""></i>Update Product</button>
                                                    <button class="btn btn-success mt-2" type="button" disabled
                                                        *ngIf="updateInProgress">
                                                        <span class="spinner-border spinner-border-sm"
                                                            role="status" aria-hidden="true"></span>
                                                        
                                                    </button>
                                                </div>
                                                <div class="d-grid" *ngIf="!productExists_Update">
                                                    <button class="btn btn-success mt-2" *ngIf="!updateInProgress"
                                                        (click)="addProduct(gasProductForm.value)" type="submit"><i
                                                            class=""></i>Add Product</button>
                                                    <button class="btn btn-success mt-2" type="button" disabled
                                                        *ngIf="updateInProgress">
                                                        <span class="spinner-border spinner-border-sm"
                                                            role="status" aria-hidden="true"></span>
                                                        
                                                    </button>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </form>

                    </div>
                </div>
                <div class="card">
                    <div class="card-header mb-0">
                        <span>
                            Products
                        </span>
                    </div>
                    <div class="card-body">

                        <div class="row mb-3" *ngIf="fuelProductArray">
                            <div class="board">
                                <table class="table table-sm table-bordered table-centered mb-0">
                                    <thead>
                                        <tr>
                                            <th class="p-0">
                                            </th>
                                            <th class="p-0">
                                            </th>
                                            <!-- <th class="p-0">
                                                Global Price
                                            </th>
                                            <th class="p-0">
                                                EPRA Margin
                                            </th>
                                            <th class="p-0">
                                                DODO given from Dealer Margin
                                            </th>
                                            <th class="p-0">
                                                Dealer Margin
                                            </th>
                                            <th class="p-0">
                                                Discount Given (Galana's Client)
                                            </th>
                                            <th class="p-0">
                                                Depot Cost
                                            </th>
                                            <th class="p-0">
                                                Galana Pro
                                            </th>
                                            <th class="p-0">
                                               Variable Exp.
                                            </th> -->
                                            <th class="p-0">
                                                Yearly Volume
                                            </th>
                                            <th class="p-0">
                                                Unit Revenue
                                            </th>
                                            <th class="p-0">
                                                Unit Supply Price
                                            </th>
                                            <th class="p-0">
                                                Unitary Tax
                                            </th>
                                            <th class="p-0">
                                                Company Unit Margin
                                            </th>
                                            <th class="p-0">
                                                Unit GMVE
                                            </th>
                                            <th class="p-0">
                                                GMVE
                                            </th>
                                        </tr>
                                    </thead>
                                    <thead>
                                        <tr>
                                            <th class="p-0">
                                            </th>
                                            <th class="p-0">
                                            </th>
                                            <!-- <th class="p-0">
                                                Global Price
                                            </th>
                                            <th class="p-0">
                                                EPRA Margin
                                            </th>
                                            <th class="p-0">
                                                DODO given from Dealer Margin
                                            </th>
                                            <th class="p-0">
                                                Dealer Margin
                                            </th>
                                            <th class="p-0">
                                                Discount Given (Galana's Client)
                                            </th>
                                            <th class="p-0">
                                                Depot Cost
                                            </th>
                                            <th class="p-0">
                                                Galana Pro
                                            </th>
                                            <th class="p-0">
                                               Variable Exp.
                                            </th> -->
                                            <th class="p-0">
                                                m3/year
                                            </th>
                                            <th class="p-0">
                                                <span *ngIf="localCurrencyISOCode">{{localCurrencyISOCode}}</span>/m3
                                            </th>
                                            <th class="p-0">
                                                <span *ngIf="localCurrencyISOCode">{{localCurrencyISOCode}}</span>/m3
                                            </th>
                                            <th class="p-0">
                                                <span *ngIf="localCurrencyISOCode">{{localCurrencyISOCode}}</span>/m3
                                            </th>
                                            <th class="p-0">
                                                <span *ngIf="localCurrencyISOCode">{{localCurrencyISOCode}}</span>/m3
                                            </th>
                                            <th class="p-0">
                                                <span *ngIf="localCurrencyISOCode">{{localCurrencyISOCode}}</span>/m3
                                            </th>
                                            <th class="p-0">
                                                <span *ngIf="localCurrencyISOCode">{{localCurrencyISOCode}}</span>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody class="table-group-divider">
                                        <tr *ngFor="let m of fuelProductArray;let i =index;">
                                            <td class="p-0">
                                                <span>

                                                </span>
                                            </td>
                                            <td class="p-0 ps-1">
                                                <span *ngIf="m.name">
                                                    {{m.name}}
                                                    <span *ngIf="m.industryAbbreviation">
                                                        ({{m.industryAbbreviation}})
                                                    </span>
                                                </span>
                                            </td>
                                            <!-- <td class="p-0">
                                                <span *ngIf="m.networkGlobalPrice">
                                                    {{m.networkGlobalPrice | number : '1.2-2'}}
                                                </span>
                                            </td>
                                            <td class="p-0">
                                                <span *ngIf="m.networkEPRAMargin">
                                                    {{m.networkEPRAMargin | number : '1.2-2'}}
                                                </span>
                                            </td>
                                            <td class="p-0">
                                                <span *ngIf="m.networkDODOMargin">
                                                    {{m.networkDODOMargin | number : '1.2-2'}}
                                                </span>
                                            </td>
                                            <td class="p-0">
                                                <span *ngIf="m.networkDealerMarginL">
                                                    {{m.networkDealerMarginL | number : '1.2-2'}}
                                                </span>
                                            </td>
                                            <td class="p-0">
                                                <span *ngIf="m.networkDiscountGiven">
                                                    {{m.networkDiscountGiven | number : '1.2-2'}}
                                                </span>
                                            </td>
                                            <td class="p-0">
                                                <span *ngIf="m.networkDepotCost">
                                                    {{m.networkDepotCost | number : '1.2-2'}}
                                                </span>
                                            </td>
                                            <td class="p-0">
                                                <span *ngIf="m.networkGalanaPro">
                                                    {{m.networkGalanaPro | number : '1.2-2'}}
                                                </span>
                                            </td>
                                            <td class="p-0">
                                                <span *ngIf="m.networkVarex">
                                                    {{m.networkVarex | number : '1.2-2'}}
                                                </span>
                                            </td> -->
                                            <td class="p-0">
                                                <span *ngIf="m.yearlyVolume">
                                                    {{m.yearlyVolume | number : '1.2-2'}}
                                                </span>
                                            </td>
                                            <td class="p-0">
                                                <span *ngIf="m.unitRevenue">
                                                    {{m.unitRevenue | number : '1.2-2'}}
                                                </span>
                                            </td>
                                            <td class="p-0">
                                                <span *ngIf="m.unitSupplyPrice">
                                                    {{m.unitSupplyPrice | number : '1.2-2'}}
                                                </span>
                                            </td>
                                            <td class="p-0">
                                                <span *ngIf="m.unitaryTax">
                                                    {{m.unitaryTax | number : '1.2-2'}}
                                                </span>
                                            </td>
                                            <td class="p-0">
                                                <span *ngIf="m.companyUnitMargin">
                                                    {{m.companyUnitMargin | number : '1.2-2'}}
                                                </span>
                                            </td>
                                            <td class="p-0">
                                                <span *ngIf="m.companyUnitMargin">
                                                    {{m.companyUnitMargin | number : '1.2-2'}}
                                                </span>
                                            </td>
                                            <!-- <td class="p-0">
                                                <span *ngIf="m.unitGMVE">
                                                    {{m.unitGMVE | number : '1.2-2'}}
                                                </span>
                                            </td> -->
                                            <!-- <td class="p-0">
                                                <span *ngIf="m.yearlyVolume">
                                                    {{m.yearlyVolume | number : '1.2-2'}}
                                                </span>
                                            </td> -->
                                            <td class="p-0">
                                                <span *ngIf="m.gMVE">
                                                    {{m.gMVE | number : '1.2-2'}}
                                                </span>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody class="table-group-divider" *ngIf="fuelProductTotal">
                                        <tr>
                                            <td class="p-0">
                                            </td>
                                            <td class="p-0">
                                                <span>
                                                    <p class="mb-0"><strong>Fuels</strong></p>
                                                </span>
                                            </td>
                                            <!-- <td class="p-0">
                                            </td>
                                            <td class="p-0">
                                            </td>
                                            <td class="p-0">
                                            </td>
                                            <td class="p-0">
                                            </td>
                                            <td class="p-0">
                                            </td>
                                            <td class="p-0">
                                            </td>
                                            <td class="p-0">
                                            </td>
                                            <td class="p-0">
                                            </td> -->
                                            <td class="p-0">
                                                <span *ngIf="fuelProductTotal.yearlyVolume">
                                                    {{fuelProductTotal.yearlyVolume | number : '1.2-2'}}
                                                </span>
                                            </td>
                                            <td class="p-0">
                                                <span *ngIf="fuelProductTotal.unitRevenue">
                                                    {{fuelProductTotal.unitRevenue | number : '1.2-2'}}
                                                </span>
                                            </td>
                                            <td class="p-0">
                                                <span *ngIf="fuelProductTotal.unitSupplyPrice">
                                                    {{fuelProductTotal.unitSupplyPrice | number : '1.2-2'}}
                                                </span>
                                            </td>
                                            <td class="p-0">
                                                <span *ngIf="fuelProductTotal.unitaryTax">
                                                    {{fuelProductTotal.unitaryTax | number : '1.2-2'}}
                                                </span>
                                            </td>
                                            <td class="p-0">
                                                <span *ngIf="fuelProductTotal.companyUnitMargin">
                                                    {{fuelProductTotal.companyUnitMargin | number : '1.2-2'}}
                                                </span>
                                            </td>
                                            <td class="p-0">
                                                <span *ngIf="fuelProductTotal.companyUnitMargin">
                                                    {{fuelProductTotal.companyUnitMargin | number : '1.2-2'}}
                                                </span>
                                            </td>
                                            <!-- <td class="p-0">
                                                <span *ngIf="fuelProductTotal.unitGMVE">
                                                    {{fuelProductTotal.unitGMVE | number : '1.2-2'}}
                                                </span>
                                            </td> -->
                                            <!-- <td class="p-0">
                                                <span *ngIf="fuelProductTotal.yearlyVolume">
                                                    {{fuelProductTotal.yearlyVolume | number : '1.2-2'}}
                                                </span>
                                            </td> -->
                                            <td class="p-0">
                                                <span *ngIf="fuelProductTotal.gMVE">
                                                    {{fuelProductTotal.gMVE | number : '1.2-2'}}
                                                </span>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody class="table-group-divider" *ngIf="lubricantProductArray">
                                        <tr *ngFor="let m of lubricantProductArray;let i = index;">
                                            <td class="p-0">
                                                <span>

                                                </span>
                                            </td>
                                            <td class="p-0 ps-1">
                                                <span *ngIf="m.name">
                                                    {{m.name}}
                                                    <span *ngIf="m.industryAbbreviation">
                                                        ({{m.industryAbbreviation}})
                                                    </span>
                                                </span>
                                            </td>
                                            <!-- <td class="p-0"></td>
                                            <td class="p-0"></td>
                                            <td class="p-0"></td>
                                            <td class="p-0"></td>
                                            <td class="p-0"></td>
                                            <td class="p-0"></td>
                                            <td class="p-0"></td>
                                            <td class="p-0"></td> -->
                                            <td class="p-0">
                                                <span *ngIf="m.yearlyVolume">
                                                    {{m.yearlyVolume | number : '1.2-2'}}
                                                </span>
                                            </td>
                                            <td class="p-0">
                                                <span *ngIf="m.unitRevenue">
                                                    {{m.unitRevenue | number : '1.2-2'}}
                                                </span>
                                            </td>
                                            <td class="p-0">
                                                <span *ngIf="m.unitSupplyPrice">
                                                    {{m.unitSupplyPrice | number : '1.2-2'}}
                                                </span>
                                            </td>
                                            <td class="p-0">
                                                <span *ngIf="m.unitaryTax">
                                                    {{m.unitaryTax | number : '1.2-2'}}
                                                </span>
                                            </td>
                                            <td class="p-0">
                                                <span *ngIf="m.companyUnitMargin">
                                                    {{m.companyUnitMargin | number : '1.2-2'}}
                                                </span>
                                            </td>
                                            <td class="p-0">
                                                <span *ngIf="m.companyUnitMargin">
                                                    {{m.companyUnitMargin | number : '1.2-2'}}
                                                </span>
                                            </td>
                                            <!-- <td class="p-0">
                                                <span *ngIf="m.networkDealerMarginM3">
                                                    {{m.networkDealerMarginM3 | number : '1.2-2'}}
                                                </span>
                                            </td> -->
                                            <!-- <td class="p-0">
                                                <span *ngIf="m.unitGMVE">
                                                    {{m.unitGMVE | number : '1.2-2'}}
                                                </span>
                                            </td> -->
                                            <!-- <td class="p-0">
                                                <span *ngIf="m.yearlyVolume">
                                                    {{m.yearlyVolume | number : '1.2-2'}}
                                                </span>
                                            </td> -->

                                            <td class="p-0">
                                                <span *ngIf="m.gMVE">
                                                    {{m.gMVE | number : '1.2-2'}}
                                                </span>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody class="table-group-divider" *ngIf="fuelAndLubricantProductTotal">
                                        <tr>
                                            <td class="p-0">
                                            </td>
                                            <td class="p-0">
                                                <span>
                                                    <p class="mb-0"><strong>Fuels & Lubs</strong></p>
                                                </span>
                                            </td>
                                            <!-- <td class="p-0"></td>
                                            <td class="p-0"></td>
                                            <td class="p-0"></td>
                                            <td class="p-0"></td>
                                            <td class="p-0"></td>
                                            <td class="p-0"></td>
                                            <td class="p-0"></td>
                                            <td class="p-0"></td> -->
                                            <td class="p-0">
                                                <span *ngIf="fuelAndLubricantProductTotal.yearlyVolume">
                                                    {{fuelAndLubricantProductTotal.yearlyVolume | number :
                                                    '1.2-2'}}
                                                </span>
                                            </td>
                                            <td class="p-0">
                                                <span *ngIf="fuelAndLubricantProductTotal.unitRevenue">
                                                    {{fuelAndLubricantProductTotal.unitRevenue | number :
                                                    '1.2-2'}}
                                                </span>
                                            </td>
                                            <td class="p-0">
                                                <span *ngIf="fuelAndLubricantProductTotal.unitSupplyPrice">
                                                    {{fuelAndLubricantProductTotal.unitSupplyPrice | number :
                                                    '1.2-2'}}
                                                </span>
                                            </td>
                                            <td class="p-0">
                                                <span *ngIf="fuelAndLubricantProductTotal.unitaryTax">
                                                    {{fuelAndLubricantProductTotal.unitaryTax | number :
                                                    '1.2-2'}}
                                                </span>
                                            </td>
                                            <td class="p-0">
                                                <span *ngIf="fuelAndLubricantProductTotal.companyUnitMargin">
                                                    {{fuelAndLubricantProductTotal.companyUnitMargin | number :
                                                    '1.2-2'}}
                                                </span>
                                            </td>
                                            <td class="p-0">
                                                <span *ngIf="fuelAndLubricantProductTotal.companyUnitMargin">
                                                    {{fuelAndLubricantProductTotal.companyUnitMargin | number :
                                                    '1.2-2'}}
                                                </span>
                                            </td>
                                            <!-- <td class="p-0">
                                                <span *ngIf="fuelAndLubricantProductTotal.networkDealerMarginM3">
                                                    {{fuelAndLubricantProductTotal.networkDealerMarginM3 | number : '1.2-2'}}
                                                </span>
                                            </td> -->
                                            <!-- <td class="p-0">
                                                <span *ngIf="fuelAndLubricantProductTotal.unitGMVE">
                                                    {{fuelAndLubricantProductTotal.unitGMVE | number : '1.2-2'}}
                                                </span>
                                            </td> -->
                                            <!-- <td class="p-0">
                                                <span *ngIf="fuelAndLubricantProductTotal.yearlyVolume">
                                                    {{fuelAndLubricantProductTotal.yearlyVolume | number : '1.2-2'}}
                                                </span>
                                            </td> -->

                                            <td class="p-0">
                                                <span *ngIf="fuelAndLubricantProductTotal.gMVE">
                                                    {{fuelAndLubricantProductTotal.gMVE | number : '1.2-2'}}
                                                </span>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody class="table-group-divider">
                                        <tr *ngFor="let m of gasProductArray;let i = index;">
                                            <td class="p-0">
                                                <span>

                                                </span>
                                            </td>
                                            <td class="p-0 ps-1">
                                                <span *ngIf="m.name">
                                                    {{m.name}}
                                                    <span *ngIf="m.industryAbbreviation">
                                                        ({{m.industryAbbreviation}})
                                                    </span>
                                                </span>
                                            </td>
                                            <!-- <td class="p-0"></td>
                                            <td class="p-0"></td>
                                            <td class="p-0"></td>
                                            <td class="p-0"></td>
                                            <td class="p-0"></td>
                                            <td class="p-0"></td>
                                            <td class="p-0"></td>
                                            <td class="p-0"></td> -->
                                            <td class="p-0">
                                                <span *ngIf="m.yearlyVolume">
                                                    {{m.yearlyVolume | number : '1.2-2'}}
                                                </span>
                                            </td>
                                            <td class="p-0">
                                                <span *ngIf="m.unitRevenue">
                                                    {{m.unitRevenue | number : '1.2-2'}}
                                                </span>
                                            </td>
                                            <td class="p-0">
                                                <span *ngIf="m.unitSupplyPrice">
                                                    {{m.unitSupplyPrice | number : '1.2-2'}}
                                                </span>
                                            </td>
                                            <td class="p-0">
                                                <span *ngIf="m.unitaryTax">
                                                    {{m.unitaryTax | number : '1.2-2'}}
                                                </span>
                                            </td>
                                            <td class="p-0">
                                                <span *ngIf="m.companyUnitMargin">
                                                    {{m.companyUnitMargin | number : '1.2-2'}}
                                                </span>
                                            </td>
                                            <td class="p-0">
                                                <span *ngIf="m.companyUnitMargin">
                                                    {{m.companyUnitMargin | number : '1.2-2'}}
                                                </span>
                                            </td>
                                            <!-- <td class="p-0">
                                                <span *ngIf="m.networkDealerMarginM3">
                                                    {{m.networkDealerMarginM3 | number : '1.2-2'}}
                                                </span>
                                            </td> -->
                                            <!-- <td class="p-0">
                                                <span *ngIf="m.unitGMVE">
                                                    {{m.unitGMVE | number : '1.2-2'}}
                                                </span>
                                            </td> -->
                                            <!-- <td class="p-0">
                                                <span *ngIf="m.yearlyVolume">
                                                    {{m.yearlyVolume | number : '1.2-2'}}
                                                </span>
                                            </td> -->
                                            <td class="p-0">
                                                <span *ngIf="m.gMVE">
                                                    {{m.gMVE | number : '1.2-2'}}
                                                </span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                    </div>
                </div>
                <div class="card">
                    <div class="card-header mb-0">
                        <span>
                            Fuel Volume & Margin - Overview (Before Bonus & Discount)
                        </span>
                    </div>
                    <div class="card-body">

                        <div class="row mb-3" *ngIf="fuelProductArray">
                            <div class="table-responsive-xxl">
                                <table class="table table-sm table-borderless table-centered mb-0">
                                    <thead>
                                        <tr>
                                            <th class="p-0">
                                            </th>
                                            <th class="p-0">
                                            </th>
                                            <th class="p-0">
                                            </th>
                                            <th class="p-0">
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody class="table-group-divider">
                                        <tr>
                                            <td class="p-0">
                                                <span>
                                                    Fuel - Monthly Volume
                                                </span>
                                            </td>
                                            <td class="p-0">
                                                <span>
                                                    :
                                                </span>
                                            </td>
                                            <td class="p-0">
                                                <span *ngIf="fuelProductTotal">
                                                    <span *ngIf="fuelProductTotal.monthlyVolume">
                                                        {{fuelProductTotal.monthlyVolume | number : '1.2-2'}}
                                                    </span>
                                                </span>
                                            </td>
                                            <td class="p-0">
                                                <span>
                                                    m3/month
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="p-0">
                                                <span>
                                                    Fuel - Yearly Volume
                                                </span>
                                            </td>
                                            <td class="p-0">
                                                <span>
                                                    :
                                                </span>
                                            </td>
                                            <td class="p-0">
                                                <span *ngIf="fuelProductTotal">
                                                    <span *ngIf="fuelProductTotal.yearlyVolume">
                                                        {{fuelProductTotal.yearlyVolume | number : '1.2-2'}}
                                                    </span>
                                                </span>
                                            </td>
                                            <td class="p-0">
                                                <span>
                                                    m3/year
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="p-0">
                                                <span>
                                                    Fuel - Yearly Gross Margin
                                                </span>
                                            </td>
                                            <td class="p-0">
                                                <span>
                                                    :
                                                </span>
                                            </td>
                                            <td class="p-0">
                                                <span *ngIf="fuelProductTotal">
                                                    <span *ngIf="fuelProductTotal.yearlyGrossMargin">
                                                        {{fuelProductTotal.yearlyGrossMargin | number :
                                                        '1.2-2'}}
                                                    </span>
                                                </span>
                                            </td>
                                            <td class="p-0">
                                                <span>
                                                    <span *ngIf="localCurrencyISOCode">{{localCurrencyISOCode}}</span>
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="p-0">
                                                <span>
                                                    Fuel - Average Unit Margin
                                                </span>
                                            </td>
                                            <td class="p-0">
                                                <span>
                                                    :
                                                </span>
                                            </td>
                                            <td class="p-0">
                                                <span *ngIf="fuelProductTotal">
                                                    <span *ngIf="fuelProductTotal.averageUnitMargin">
                                                        {{fuelProductTotal.averageUnitMargin | number :
                                                        '1.2-2'}}
                                                    </span>
                                                </span>
                                            </td>
                                            <td class="p-0">
                                                <span>
                                                    <span *ngIf="localCurrencyISOCode">{{localCurrencyISOCode}}</span>/m3
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="p-0">
                                                <span>
                                                    Lubricants - Yearly Volume
                                                </span>
                                            </td>
                                            <td class="p-0">
                                                <span>
                                                    :
                                                </span>
                                            </td>
                                            <td class="p-0">
                                                <span *ngIf="lubricantProductTotal">
                                                    <span *ngIf="lubricantProductTotal.yearlyVolume">
                                                        {{lubricantProductTotal.yearlyVolume | number :
                                                        '1.2-2'}}
                                                    </span>
                                                </span>
                                            </td>
                                            <td class="p-0">
                                                <span>
                                                    m3/year
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="p-0">
                                                <span>
                                                    Fuels & Lubs - Yearly Volume
                                                </span>
                                            </td>
                                            <td class="p-0">
                                                <span>
                                                    :
                                                </span>
                                            </td>
                                            <td class="p-0">
                                                <span *ngIf="fuelAndLubricantProductTotal">
                                                    <span *ngIf="fuelAndLubricantProductTotal.yearlyVolume">
                                                        {{fuelAndLubricantProductTotal.yearlyVolume | number :
                                                        '1.2-2'}}
                                                    </span>
                                                </span>
                                            </td>
                                            <td class="p-0">
                                                <span>
                                                    m3/year
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="p-0">
                                                <span>
                                                    Fuels & Lubs - Yearly Gross Margin
                                                </span>
                                            </td>
                                            <td class="p-0">
                                                <span>
                                                    :
                                                </span>
                                            </td>
                                            <td class="p-0">
                                                <span *ngIf="fuelAndLubricantProductTotal">
                                                    <span *ngIf="fuelAndLubricantProductTotal.yearlyGrossMargin">
                                                        {{fuelAndLubricantProductTotal.yearlyGrossMargin |
                                                        number :
                                                        '1.2-2'}}
                                                    </span>
                                                </span>
                                            </td>
                                            <td class="p-0">
                                                <span>
                                                    <span *ngIf="localCurrencyISOCode">{{localCurrencyISOCode}}</span>
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="p-0">
                                                <span>
                                                    Fuels & Lubs - Average Unit Margin
                                                </span>
                                            </td>
                                            <td class="p-0">
                                                <span>
                                                    :
                                                </span>
                                            </td>
                                            <td class="p-0">
                                                <span *ngIf="fuelAndLubricantProductTotal">
                                                    <span *ngIf="fuelAndLubricantProductTotal.averageUnitMargin">
                                                        {{fuelAndLubricantProductTotal.averageUnitMargin |
                                                        number :
                                                        '1.2-2'}}
                                                    </span>
                                                </span>
                                            </td>
                                            <td class="p-0">
                                                <span>
                                                    <span *ngIf="localCurrencyISOCode">{{localCurrencyISOCode}}</span>/m3
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="p-0">
                                                <span>
                                                    Gas in Bottle - Yearly Gross Margin
                                                </span>
                                            </td>
                                            <td class="p-0">
                                                <span>
                                                    :
                                                </span>
                                            </td>
                                            <td class="p-0">
                                                <span *ngIf="gasProductTotal">
                                                    <span *ngIf="gasProductTotal.yearlyGrossMargin">
                                                        {{gasProductTotal.yearlyGrossMargin | number : '1.2-2'}}
                                                    </span>
                                                </span>
                                            </td>
                                            <td class="p-0">
                                                <span>
                                                    <span *ngIf="localCurrencyISOCode">{{localCurrencyISOCode}}</span>
                                                </span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <div class="card-footer" *ngIf="!showProjectProductMarginSection">
            <div class="row">
                <div class="col-sm-6"></div>
                <div class="col-sm-6">
                    <div class="d-grid">
                        <button class="btn btn-sm btn-outline-secondary"
                            (click)="showProjectProductMarginSectionButton(true)" type="button"><i
                                class=""></i>View</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="card-footer" *ngIf="showProjectProductMarginSection">
            <div class="row" *ngIf="showProjectProductMarginEdit">
                <div class="col-sm-6">
                    <div class="d-grid">
                        <button class="btn btn-sm btn-outline-secondary"
                            (click)="showProjectProductMarginEditButton(false)" type="button"><i class=""></i>Simple
                            View</button>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="d-grid">
                        <button class="btn btn-sm btn-outline-success" (click)="submitProjectProductMargin()"
                            type="button" *ngIf="!submitInProgress && !updateInProgress"><i class=""></i>Submit</button>
                        <button class="btn btn-sm btn-outline-primary" type="button" disabled
                            *ngIf="submitInProgress || updateInProgress">
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            
                        </button>
                    </div>
                </div>
            </div>
            <div class="row" *ngIf="!showProjectProductMarginEdit">
                <div class="col-sm-6">
                    <div class="d-grid">
                        <button class="btn btn-sm btn-outline-secondary"
                            (click)="showProjectProductMarginEditButton(true)" type="button"><i class=""></i>Edit
                            View</button>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="d-grid">
                        <button class="btn btn-sm btn-outline-success" (click)="submitProjectProductMargin()"
                            type="button" *ngIf="!submitInProgress && !updateInProgress"><i class=""></i>Submit</button>
                        <button class="btn btn-sm btn-outline-primary" type="button" disabled
                            *ngIf="submitInProgress || updateInProgress">
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Step 3. Volume Growth -->
    <div id="stepThree" class="border border-secondary card stepThree">
        <div class="card-header">
            <div class="table-responsive-sm" *ngIf="projectArray">
                <table class="table table-sm table-centered table-borderless mb-0" *ngIf="projectArray[0]">
                    <tbody>
                        <tr>
                            <td class="p-0" style="width:60%">
                                Step 3. Fuel Volume Growth
                            </td>
                            <td class="p-0" style="width:30%">
                                <p class="mb-0">
                                    Completion
                                    <ngb-progressbar [showValue]="true" type="info" [striped]="true" [animated]="true"
                                        [value]="projectFuelVolumeGrowthProgressPercentage"></ngb-progressbar>
                                </p>
                            </td>
                            <td class="p-0 text-center text-primary" style="width:10%">
                                <i class="mdi mdi-help-circle-outline mdi-24px"></i>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div> <!-- end table-responsive-->
        </div>
        <div class="card-body" *ngIf="showProjectFuelVolumeGrowthSection">
            <div class="card">
                <div class="card-header mb-0">
                    <h5>
                        <span>
                            Yearly Escalation
                        </span>
                    </h5>
                </div>
                <div class="card-body">
                    <div class="board">
                        <table class="table table-sm table-bordered table-centered mb-0">
                            <thead>
                                <tr>
                                    <th style="min-width:50px">

                                    </th>
                                    <th>
                                        Y - 0
                                    </th>
                                    <th>
                                        Y - 1
                                    </th>
                                    <th>
                                        Y - 2
                                    </th>
                                    <th>
                                        Y - 3
                                    </th>
                                    <th>
                                        Y - 4
                                    </th>
                                    <th>
                                        Y - 5
                                    </th>
                                    <th>
                                        Y - 6
                                    </th>
                                    <th>
                                        Y - 7
                                    </th>
                                    <th>
                                        Y - 8
                                    </th>
                                    <th>
                                        Y - 9
                                    </th>
                                    <th>
                                        Y - 10
                                    </th>
                                    <th>
                                        Y - 11
                                    </th>
                                    <th>
                                        Y - 12
                                    </th>
                                    <th>
                                        Y - 13
                                    </th>
                                    <th>
                                        Y - 14
                                    </th>
                                    <th>
                                        Y - 15
                                    </th>
                                </tr>
                            </thead>
                            <tbody class="table-group-divider" *ngIf="!showProjectFuelVolumeGrowthEdit">
                                <tr>
                                    <td class="p-0">
                                        <span>
                                            Fuel volume growth (% p.a)
                                        </span>
                                    </td>
                                    <td class="px-1 py-0" *ngFor="let m of fuelVolumeGrowthArray;let i = index;">
                                        <span *ngIf="!(m===0)">{{m}}%</span>
                                        <span *ngIf="m===0">-</span>
                                    </td>
                                </tr>
                                <tr>

                                    <td class="p-0">
                                        <span>
                                            Fuel volume ramp up in % of potential
                                        </span>
                                    </td>
                                    <td class="px-1 py-0"
                                        *ngFor="let m of fuelVolumeGrowthPotentialArray;let i = index;">
                                        <span>
                                            <span *ngIf="!(m===0)">{{m}}%</span>
                                            <span *ngIf="m===0">-</span>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0">
                                        <span>
                                            Months during the period
                                        </span>
                                    </td>
                                    <td class="px-1 py-0" *ngFor="let m of monthsDuringThePeriodArray;let i = index;">
                                        <span>
                                            <span *ngIf="!(m===0)">{{m}}</span>
                                            <span *ngIf="m===0">-</span>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0">
                                        <span>
                                            Fuel margin growth Factor
                                        </span>
                                    </td>
                                    <td class="px-1 py-0" *ngFor="let m of fuelMarginGrowthFactorArray;let i = index;">
                                        <span>
                                            <span *ngIf="!(m===0)">{{m | number : '1.6-6'}}</span>
                                            <span *ngIf="m===0">-</span>
                                        </span>
                                    </td>
                                </tr>
                            </tbody>
                            <tbody class="table-group-divider" *ngIf="showProjectFuelVolumeGrowthEdit">
                                <tr name="fuel-volume-growth-form" [formGroup]="fuelVolumeGrowthForm">
                                    <td class="p-0 col-auto">
                                        <span>
                                            Fuel volume growth (% p.a)
                                        </span>
                                    </td>
                                    <td class="p-0 text-center">
                                        <input class="form-control form-control-sm form-control-plaintext" type="number"
                                            min="0" max="100" id="y0" formControlName="y0">
                                    </td>
                                    <td class="p-0 text-center">
                                        <input class="form-control form-control-sm form-control-plaintext" type="number"
                                            min="0" max="100" id="y1" formControlName="y1">
                                    </td>
                                    <td class="p-0 col-auto">
                                        <input class="form-control form-control-sm form-control-plaintext" type="number"
                                            min="0" max="100" id="y2" formControlName="y2">
                                    </td>
                                    <td class="p-0 col-auto">
                                        <input class="form-control form-control-sm form-control-plaintext" type="number"
                                            min="0" max="100" id="y3" formControlName="y3">
                                    </td>
                                    <td class="p-0 col-auto">
                                        <input class="form-control form-control-sm form-control-plaintext" type="number"
                                            min="0" max="100" id="y4" formControlName="y4">
                                    </td>
                                    <td class="p-0 col-auto">
                                        <input class="form-control form-control-sm form-control-plaintext" type="number"
                                            min="0" max="100" id="y5" formControlName="y5">
                                    </td>
                                    <td class="p-0 col-auto">
                                        <input class="form-control form-control-sm form-control-plaintext" type="number"
                                            min="0" max="100" id="y6" formControlName="y6">
                                    </td>
                                    <td class="p-0 col-auto">
                                        <input class="form-control form-control-sm form-control-plaintext" type="number"
                                            min="0" max="100" id="y7" formControlName="y7">
                                    </td>
                                    <td class="p-0 col-auto">
                                        <input class="form-control form-control-sm form-control-plaintext" type="number"
                                            min="0" max="100" id="y8" formControlName="y8">
                                    </td>
                                    <td class="p-0 col-auto">
                                        <input class="form-control form-control-sm form-control-plaintext" type="number"
                                            min="0" max="100" id="y9" formControlName="y9">
                                    </td>
                                    <td class="p-0 col-auto">
                                        <input class="form-control form-control-sm form-control-plaintext" type="number"
                                            min="0" max="100" id="y10" formControlName="y10">
                                    </td>
                                    <td class="p-0 col-auto">
                                        <input class="form-control form-control-sm form-control-plaintext" type="number"
                                            min="0" max="100" id="y11" formControlName="y11">
                                    </td>
                                    <td class="p-0 col-auto">
                                        <input class="form-control form-control-sm form-control-plaintext" type="number"
                                            min="0" max="100" id="y12" formControlName="y12">
                                    </td>
                                    <td class="p-0 col-auto">
                                        <input class="form-control form-control-sm form-control-plaintext" type="number"
                                            min="0" max="100" id="y13" formControlName="y13">
                                    </td>
                                    <td class="p-0 col-auto">
                                        <input class="form-control form-control-sm form-control-plaintext" type="number"
                                            min="0" max="100" id="y14" formControlName="y14">
                                    </td>
                                    <td class="p-0 col-auto">
                                        <input class="form-control form-control-sm form-control-plaintext" type="number"
                                            min="0" max="100" id="y15" formControlName="y15">
                                    </td>
                                </tr>
                                <tr name="fuel-volume-growth-potential-form"
                                    [formGroup]="fuelVolumeGrowthPotentialForm">
                                    <td class="p-0 col-auto">
                                        <span>
                                            Fuel volume ramp up in % of potential
                                        </span>
                                    </td>
                                    <td class="p-0 text-center">
                                        <input class="form-control form-control-sm form-control-plaintext" type="number"
                                            min="0" max="100" id="y0" formControlName="y0">
                                    </td>
                                    <td class="p-0 text-center">
                                        <input class="form-control form-control-sm form-control-plaintext" type="number"
                                            min="0" max="100" id="y1" formControlName="y1">
                                    </td>
                                    <td class="p-0 col-auto">
                                        <input class="form-control form-control-sm form-control-plaintext" type="number"
                                            min="0" max="100" id="y2" formControlName="y2">
                                    </td>
                                    <td class="p-0 col-auto">
                                        <input class="form-control form-control-sm form-control-plaintext" type="number"
                                            min="0" max="100" id="y3" formControlName="y3">
                                    </td>
                                    <td class="p-0 col-auto">
                                        <input class="form-control form-control-sm form-control-plaintext" type="number"
                                            min="0" max="100" id="y4" formControlName="y4">
                                    </td>
                                    <td class="p-0 col-auto">
                                        <input class="form-control form-control-sm form-control-plaintext" type="number"
                                            min="0" max="100" id="y5" formControlName="y5">
                                    </td>
                                    <td class="p-0 col-auto">
                                        <input class="form-control form-control-sm form-control-plaintext" type="number"
                                            min="0" max="100" id="y6" formControlName="y6">
                                    </td>
                                    <td class="p-0 col-auto">
                                        <input class="form-control form-control-sm form-control-plaintext" type="number"
                                            min="0" max="100" id="y7" formControlName="y7">
                                    </td>
                                    <td class="p-0 col-auto">
                                        <input class="form-control form-control-sm form-control-plaintext" type="number"
                                            min="0" max="100" id="y8" formControlName="y8">
                                    </td>
                                    <td class="p-0 col-auto">
                                        <input class="form-control form-control-sm form-control-plaintext" type="number"
                                            min="0" max="100" id="y9" formControlName="y9">
                                    </td>
                                    <td class="p-0 col-auto">
                                        <input class="form-control form-control-sm form-control-plaintext" type="number"
                                            min="0" max="100" id="y10" formControlName="y10">
                                    </td>
                                    <td class="p-0 col-auto">
                                        <input class="form-control form-control-sm form-control-plaintext" type="number"
                                            min="0" max="100" id="y11" formControlName="y11">
                                    </td>
                                    <td class="p-0 col-auto">
                                        <input class="form-control form-control-sm form-control-plaintext" type="number"
                                            min="0" max="100" id="y12" formControlName="y12">
                                    </td>
                                    <td class="p-0 col-auto">
                                        <input class="form-control form-control-sm form-control-plaintext" type="number"
                                            min="0" max="100" id="y13" formControlName="y13">
                                    </td>
                                    <td class="p-0 col-auto">
                                        <input class="form-control form-control-sm form-control-plaintext" type="number"
                                            min="0" max="100" id="y14" formControlName="y14">
                                    </td>
                                    <td class="p-0 col-auto">
                                        <input class="form-control form-control-sm form-control-plaintext" type="number"
                                            min="0" max="100" id="y15" formControlName="y15">
                                    </td>
                                </tr>
                                <tr name="months-during-the-period-form" [formGroup]="monthsDuringThePeriodForm">
                                    <td class="p-0 col-auto">
                                        <span>
                                            Months during the period
                                        </span>
                                    </td>
                                    <td class="p-0 text-center">
                                        <input class="form-control form-control-sm form-control-plaintext" type="number"
                                            min="0" max="12" id="y0" readonly formControlName="y0">
                                    </td>
                                    <td class="p-0 text-center">
                                        <input class="form-control form-control-sm form-control-plaintext" type="number"
                                            min="1" max="12" id="y1" readonly formControlName="y1">
                                    </td>
                                    <td class="p-0 col-auto">
                                        <input class="form-control form-control-sm form-control-plaintext" type="number"
                                            min="1" max="12" id="y2" readonly formControlName="y2">
                                    </td>
                                    <td class="p-0 col-auto">
                                        <input class="form-control form-control-sm form-control-plaintext" type="number"
                                            min="1" max="12" id="y3" readonly formControlName="y3">
                                    </td>
                                    <td class="p-0 col-auto">
                                        <input class="form-control form-control-sm form-control-plaintext" type="number"
                                            min="1" max="12" id="y4" readonly formControlName="y4">
                                    </td>
                                    <td class="p-0 col-auto">
                                        <input class="form-control form-control-sm form-control-plaintext" type="number"
                                            min="1" max="12" id="y5" readonly formControlName="y5">
                                    </td>
                                    <td class="p-0 col-auto">
                                        <input class="form-control form-control-sm form-control-plaintext" type="number"
                                            min="1" max="12" id="y6" readonly formControlName="y6">
                                    </td>
                                    <td class="p-0 col-auto">
                                        <input class="form-control form-control-sm form-control-plaintext" type="number"
                                            min="1" max="12" id="y7" readonly formControlName="y7">
                                    </td>
                                    <td class="p-0 col-auto">
                                        <input class="form-control form-control-sm form-control-plaintext" type="number"
                                            min="1" max="12" id="y8" readonly formControlName="y8">
                                    </td>
                                    <td class="p-0 col-auto">
                                        <input class="form-control form-control-sm form-control-plaintext" type="number"
                                            min="1" max="12" id="y9" readonly formControlName="y9">
                                    </td>
                                    <td class="p-0 col-auto">
                                        <input class="form-control form-control-sm form-control-plaintext" type="number"
                                            min="1" max="12" id="y10" readonly formControlName="y10">
                                    </td>
                                    <td class="p-0 col-auto">
                                        <input class="form-control form-control-sm form-control-plaintext" type="number"
                                            min="1" max="12" id="y11" readonly formControlName="y11">
                                    </td>
                                    <td class="p-0 col-auto">
                                        <input class="form-control form-control-sm form-control-plaintext" type="number"
                                            min="1" max="12" id="y12" readonly formControlName="y12">
                                    </td>
                                    <td class="p-0 col-auto">
                                        <input class="form-control form-control-sm form-control-plaintext" type="number"
                                            min="1" max="12" id="y13" readonly formControlName="y13">
                                    </td>
                                    <td class="p-0 col-auto">
                                        <input class="form-control form-control-sm form-control-plaintext" type="number"
                                            min="1" max="12" id="y14" readonly formControlName="y14">
                                    </td>
                                    <td class="p-0 col-auto">
                                        <input class="form-control form-control-sm form-control-plaintext" type="number"
                                            min="1" max="12" id="y15" readonly formControlName="y15">
                                    </td>
                                </tr>
                                <tr name="fuel-volume-growth-potential-form" [formGroup]="fuelMarginGrowthForm">
                                    <td class="p-0 col-auto">
                                        <span>
                                            Fuel margin growth (%p.a)
                                        </span>
                                    </td>
                                    <td class="p-0 text-center">
                                        <input class="form-control form-control-sm form-control-plaintext" type="number"
                                            min="0" max="100" id="y0" formControlName="y0">
                                    </td>
                                    <td class="p-0 text-center">
                                        <input class="form-control form-control-sm form-control-plaintext" type="number"
                                            min="0" max="100" id="y1" formControlName="y1">
                                    </td>
                                    <td class="p-0 col-auto">
                                        <input class="form-control form-control-sm form-control-plaintext" type="number"
                                            min="0" max="100" id="y2" formControlName="y2">
                                    </td>
                                    <td class="p-0 col-auto">
                                        <input class="form-control form-control-sm form-control-plaintext" type="number"
                                            min="0" max="100" id="y3" formControlName="y3">
                                    </td>
                                    <td class="p-0 col-auto">
                                        <input class="form-control form-control-sm form-control-plaintext" type="number"
                                            min="0" max="100" id="y4" formControlName="y4">
                                    </td>
                                    <td class="p-0 col-auto">
                                        <input class="form-control form-control-sm form-control-plaintext" type="number"
                                            min="0" max="100" id="y5" formControlName="y5">
                                    </td>
                                    <td class="p-0 col-auto">
                                        <input class="form-control form-control-sm form-control-plaintext" type="number"
                                            min="0" max="100" id="y6" formControlName="y6">
                                    </td>
                                    <td class="p-0 col-auto">
                                        <input class="form-control form-control-sm form-control-plaintext" type="number"
                                            min="0" max="100" id="y7" formControlName="y7">
                                    </td>
                                    <td class="p-0 col-auto">
                                        <input class="form-control form-control-sm form-control-plaintext" type="number"
                                            min="0" max="100" id="y8" formControlName="y8">
                                    </td>
                                    <td class="p-0 col-auto">
                                        <input class="form-control form-control-sm form-control-plaintext" type="number"
                                            min="0" max="100" id="y9" formControlName="y9">
                                    </td>
                                    <td class="p-0 col-auto">
                                        <input class="form-control form-control-sm form-control-plaintext" type="number"
                                            min="0" max="100" id="y10" formControlName="y10">
                                    </td>
                                    <td class="p-0 col-auto">
                                        <input class="form-control form-control-sm form-control-plaintext" type="number"
                                            min="0" max="100" id="y11" formControlName="y11">
                                    </td>
                                    <td class="p-0 col-auto">
                                        <input class="form-control form-control-sm form-control-plaintext" type="number"
                                            min="0" max="100" id="y12" formControlName="y12">
                                    </td>
                                    <td class="p-0 col-auto">
                                        <input class="form-control form-control-sm form-control-plaintext" type="number"
                                            min="0" max="100" id="y13" formControlName="y13">
                                    </td>
                                    <td class="p-0 col-auto">
                                        <input class="form-control form-control-sm form-control-plaintext" type="number"
                                            min="0" max="100" id="y14" formControlName="y14">
                                    </td>
                                    <td class="p-0 col-auto">
                                        <input class="form-control form-control-sm form-control-plaintext" type="number"
                                            min="0" max="100" id="y15" formControlName="y15">
                                    </td>
                                </tr>
                            </tbody>
                            <tbody class="table-group-divider">
                                <tr>
                                    <td class="p-0">
                                        <span>
                                            Months before operations
                                        </span>
                                    </td>
                                    <td class="px-1 py-0" *ngFor="let m of monthsBeforeOperationArray;let i = index;">
                                        <span>
                                            <span *ngIf="!(m===0)">{{m}}</span>
                                            <span *ngIf="m===0">-</span>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0">
                                        <span>
                                            Fuel margin growth Factor 2
                                        </span>
                                    </td>
                                    <td class="px-1 py-0"
                                        *ngFor="let m of fuelMarginGrowthFactorTwoArray;let i = index;">
                                        <span>
                                            <span *ngIf="!(m===0)">{{m | number : '1.6-6'}}</span>
                                            <span *ngIf="m===0">-</span>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0">
                                        <span>
                                            Fuel volume - Without Lubricants (m3)
                                        </span>
                                    </td>
                                    <td class="px-1 py-0"
                                        *ngFor="let m of fuelVolumeWithoutLubricantArray;let i = index;">
                                        <span>
                                            <span *ngIf="!(m===0)">{{m | number: '1.2-2'}}</span>
                                            <span *ngIf="m===0">-</span>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0">
                                        <span>
                                            Fuel volume (m3)
                                        </span>
                                    </td>
                                    <td class="px-1 py-0" *ngFor="let m of fuelVolumeArray;let i = index;">
                                        <span>
                                            <span *ngIf="!(m===0)">{{m | number: '1.2-2'}}</span>
                                            <span *ngIf="m===0">-</span>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0">
                                        <span>
                                            Fuel margin (<span *ngIf="localCurrencyISOCode">{{localCurrencyISOCode}}</span>/m3)
                                        </span>
                                    </td>
                                    <td class="px-1 py-0" *ngFor="let m of fuelMarginArray;let i = index;">
                                        <span>
                                            <span *ngIf="!(m===0)">{{m | number: '1.6-6'}}</span>
                                            <span *ngIf="m===0">-</span>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0">
                                        <span>
                                            Gas Margin
                                        </span>
                                    </td>
                                    <td class="px-1 py-0" *ngFor="let m of gasMarginArray;let i = index;">
                                        <span>
                                            <span *ngIf="!(m===0)">{{m | number: '1.2-2'}}</span>
                                            <span *ngIf="m===0">-</span>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0">
                                        <span>
                                            Fuel & Lubricant Margin x Volume
                                        </span>
                                    </td>
                                    <td class="px-1 py-0"
                                        *ngFor="let m of fuelAndLubricantMarginByVolume;let i = index;">
                                        <span>
                                            <span *ngIf="!(m===0)">{{m | number: '1.2-2'}}</span>
                                            <span *ngIf="m===0">-</span>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0">
                                        <span>
                                            Gas Margin x Volume
                                        </span>
                                    </td>
                                    <td class="px-1 py-0" *ngFor="let m of gasMarginByVolumeArray;let i = index;">
                                        <span>
                                            <span *ngIf="!(m===0)">{{m | number: '1.2-2'}}</span>
                                            <span *ngIf="m===0">-</span>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0">
                                        <span>
                                            Fuel Margin (<span *ngIf="localCurrencyISOCode">{{localCurrencyISOCode}}</span>)
                                        </span>
                                    </td>
                                    <td class="px-1 py-0" *ngFor="let m of fuelMarginTotalArray;let i = index;">
                                        <span>
                                            <span *ngIf="!(m===0)">{{m | number: '1.2-2'}}</span>
                                            <span *ngIf="m===0">-</span>
                                        </span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <div class="card-footer" *ngIf="showProjectFuelVolumeGrowthEdit">
            <div class="row">
                <div class="col-sm-12">
                    <div class="d-grid">
                        <button class="btn btn-sm btn-outline-primary"
                            (click)="updateProjectFuelVolumeGrowth(fuelVolumeGrowthForm.value,fuelVolumeGrowthPotentialForm.value,monthsDuringThePeriodForm.value,fuelMarginGrowthForm.value)"
                            type="button" *ngIf="!updateInProgress"><i class=""></i>Update</button>
                        <button class="btn btn-sm btn-outline-primary" type="button" disabled *ngIf="updateInProgress">
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="card-footer" *ngIf="!showProjectFuelVolumeGrowthSection">
            <div class="row">
                <div class="col-sm-6"></div>
                <div class="col-sm-6">
                    <div class="d-grid">
                        <button class="btn btn-sm btn-outline-secondary"
                            (click)="showProjectFuelVolumeGrowthSectionButton(true)" type="button"><i
                                class=""></i>View</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="card-footer" *ngIf="showProjectFuelVolumeGrowthSection">
            <div class="row" *ngIf="showProjectFuelVolumeGrowthEdit">
                <div class="col-sm-6">
                    <div class="d-grid">
                        <button class="btn btn-sm btn-outline-secondary"
                            (click)="showProjectFuelVolumeGrowthEditButton(false)" type="button"><i class=""></i>Simple
                            View</button>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="d-grid">
                        <button class="btn btn-sm btn-outline-success" (click)="submitProjectFuelVolumeGrowth()"
                            type="button" *ngIf="!submitInProgress && !updateInProgress"><i class=""></i>Submit</button>
                        <button class="btn btn-sm btn-outline-primary" type="button" disabled
                            *ngIf="submitInProgress || updateInProgress">
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            
                        </button>
                    </div>
                </div>
            </div>
            <div class="row" *ngIf="!showProjectFuelVolumeGrowthEdit">
                <div class="col-sm-6">
                    <div class="d-grid">
                        <button class="btn btn-sm btn-outline-secondary"
                            (click)="showProjectFuelVolumeGrowthEditButton(true)" type="button"><i class=""></i>Edit
                            View</button>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="d-grid">
                        <button class="btn btn-sm btn-outline-success" (click)="submitProjectFuelVolumeGrowth()"
                            type="button" *ngIf="!submitInProgress && !updateInProgress"><i class=""></i>Submit</button>
                        <button class="btn btn-sm btn-outline-primary" type="button" disabled
                            *ngIf="submitInProgress || updateInProgress">
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Step 4. Investment -->
    <div id="stepFour" class="border border-secondary card stepFour">
        <div class="card-header">
            <div class="table-responsive-sm" *ngIf="projectArray">
                <table class="table table-sm table-centered table-borderless mb-0" *ngIf="projectArray[0]">
                    <tbody>
                        <tr>
                            <td class="p-0" style="width:60%">
                                Step 4. Investment - Galana
                            </td>
                            <td class="p-0" style="width:30%">
                                <p class="mb-0">
                                    Completion
                                    <ngb-progressbar [showValue]="true" type="info" [striped]="true" [animated]="true"
                                        [value]="projectInvestmentProgressPercentage"></ngb-progressbar>
                                </p>
                            </td>
                            <td class="p-0 text-center text-primary" style="width:10%">
                                <i class="mdi mdi-help-circle-outline mdi-24px"></i>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div> <!-- end table-responsive-->
        </div>
        <div class="card-body" *ngIf="showProjectInvestmentSection">
            <div class="card" *ngIf="showProjectInvestmentEdit">
                <div class="card">
                    <div class="card-header">
                        <h5>
                            <span>i. Branding</span>
                        </h5>
                    </div>
                    <div class="card-body">

                        <table class="table table-sm table-borderless table-centered mb-2">
                            <thead>
                                <tr>
                                    <th class="p-0"></th>
                                    <th class="p-0">
                                        <span class="text-muted">Item</span>
                                    </th>
                                    <th class="p-0">
                                        <span class="text-muted">Cost</span>
                                    </th>
                                    <th class="p-0"></th>
                                </tr>
                            </thead>
                            <tbody class="table-group-divider" *ngFor="let m of brandingItemArray; let i = index;">
                                <tr>
                                    <td class="p-0">
                                        <span>
                                            {{i+1}}.
                                        </span>
                                    </td>
                                    <td class="p-0">
                                        <span *ngIf="m.name">
                                            {{m.name}}
                                        </span>
                                    </td>
                                    <td class="p-0">
                                        <span *ngIf="m.total">
                                            {{m.total | number : '1.2-2'}}
                                        </span>
                                        <span *ngIf="m.total === 0">
                                            {{0 | number : '1.2-2'}}
                                        </span>
                                    </td>
                                    <td class="p-0">
                                        <button class="btn btn-sm btn-outline-danger"
                                            *ngIf="!submitInProgress && !updateInProgress"
                                            (click)="removeBrandingItem(i)" type="button">X</button>
                                        <button class="btn btn-sm btn-outline-danger" type="button" disabled
                                            *ngIf="submitInProgress || updateInProgress">
                                            <span class="spinner-border spinner-border-sm" role="status"
                                                aria-hidden="true"></span>
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <form name="signup-form" [formGroup]="brandingItemForm">
                            <div class="input-group input-group-sm">
                                <!-- <span class="input-group-text" id="inputGroup-sizing-sm"
                                    *ngIf="brandingItemArray">{{brandingItemArray.length+1}}</span>
                                <span class="input-group-text" id="inputGroup-sizing-sm"
                                    *ngIf="!brandingItemArray">1</span> -->
                                <span class="input-group-text" id="inputGroup-sizing-sm"></span>
                                <input type="text" class="form-control" placeholder="Name" id="name"
                                    aria-label="Sizing example input" formControlName="name"
                                    aria-describedby="inputGroup-sizing-sm">
                                <input type="number" min="1" class="form-control" id="total"
                                    aria-label="Sizing example input" formControlName="total"
                                    aria-describedby="inputGroup-sizing-sm">
                                <button class="btn btn-outline-success"
                                    (click)="addBrandingItem(brandingItemForm.value)"
                                    *ngIf="!submitInProgress && !updateInProgress" type="button">Add Item</button>
                                <button class="btn btn-sm btn-outline-danger" type="button" disabled
                                    *ngIf="submitInProgress || updateInProgress">
                                    <span class="spinner-border spinner-border-sm" role="status"
                                        aria-hidden="true"></span>
                                </button>
                            </div>
                        </form>
                    </div>
                    <div class="card-footer">
                        <div class="row ms-1">
                            <div class="col-sm-12 text-end">
                                <span class="px-3">
                                    Total
                                </span>
                                <span *ngIf="companyInvestmentBrandingTotal">
                                    {{companyInvestmentBrandingTotal | number:'1.2-2'}}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card">
                    <div class="card-header">
                        <h5>
                            <span>ii. Construction</span>
                        </h5>
                    </div>
                    <div class="card-body">

                        <table class="table table-sm table-borderless table-centered mb-2">
                            <thead>
                                <tr>
                                    <th class="p-0"></th>
                                    <th class="p-0">
                                        <span class="text-muted">Item</span>
                                    </th>
                                    <th class="p-0">
                                        <span class="text-muted">Cost</span>
                                    </th>
                                    <th class="p-0"></th>
                                </tr>
                            </thead>
                            <tbody class="table-group-divider" *ngFor="let m of constructionItemArray; let i = index;">
                                <tr>
                                    <td class="p-0">
                                        <span>
                                            {{i+1}}.
                                        </span>
                                    </td>
                                    <td class="p-0">
                                        <span *ngIf="m.name">
                                            {{m.name}}
                                        </span>
                                    </td>
                                    <td class="p-0">
                                        <span *ngIf="m.total">
                                            {{m.total | number : '1.2-2'}}
                                        </span>
                                        <span *ngIf="m.total === 0">
                                            {{0 | number : '1.2-2'}}
                                        </span>
                                    </td>
                                    <td class="p-0">
                                        <button class="btn btn-sm btn-outline-danger"
                                            *ngIf="!submitInProgress && !updateInProgress"
                                            (click)="removeConstructionItem(i)" type="button">X</button>
                                        <button class="btn btn-sm btn-outline-danger" type="button" disabled
                                            *ngIf="submitInProgress || updateInProgress">
                                            <span class="spinner-border spinner-border-sm" role="status"
                                                aria-hidden="true"></span>
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <form name="signup-form" [formGroup]="constructionItemForm">
                            <div class="input-group input-group-sm">
                                <!-- <span class="input-group-text" id="inputGroup-sizing-sm"
                                    *ngIf="constructionItemArray">{{constructionItemArray.length+1}}</span>
                                <span class="input-group-text" id="inputGroup-sizing-sm"
                                    *ngIf="!constructionItemArray">1</span> -->
                                <span class="input-group-text" id="inputGroup-sizing-sm"></span>
                                <input type="text" class="form-control" placeholder="Name" id="name"
                                    aria-label="Sizing example input" formControlName="name"
                                    aria-describedby="inputGroup-sizing-sm">
                                <input type="number" min="1" class="form-control" id="total"
                                    aria-label="Sizing example input" formControlName="total"
                                    aria-describedby="inputGroup-sizing-sm">
                                <button class="btn btn-outline-success"
                                    (click)="addConstructionItem(constructionItemForm.value)"
                                    *ngIf="!submitInProgress && !updateInProgress" type="button">Add Item</button>
                                <button class="btn btn-sm btn-outline-danger" type="button" disabled
                                    *ngIf="submitInProgress || updateInProgress">
                                    <span class="spinner-border spinner-border-sm" role="status"
                                        aria-hidden="true"></span>
                                </button>
                            </div>
                        </form>
                    </div>
                    <div class="card-footer">
                        <div class="row ms-1">
                            <div class="col-sm-12 text-end">
                                <span class="px-3">
                                    Total
                                </span>
                                <span *ngIf="companyInvestmentConstructionTotal">
                                    {{companyInvestmentConstructionTotal | number:'1.2-2'}}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card">
                    <div class="card-header">
                        <h5>
                            <span>iii. Equipment</span>
                        </h5>
                    </div>
                    <div class="card-body">

                        <table class="table table-sm table-borderless table-centered mb-2">
                            <thead>
                                <tr>
                                    <th class="p-0"></th>
                                    <th class="p-0">
                                        <span class="text-muted">Item</span>
                                    </th>
                                    <th class="p-0">
                                        <span class="text-muted">Cost</span>
                                    </th>
                                    <th class="p-0"></th>
                                </tr>
                            </thead>
                            <tbody class="table-group-divider" *ngFor="let m of equipmentItemArray; let i = index;">
                                <tr>
                                    <td class="p-0">
                                        <span>
                                            {{i+1}}.
                                        </span>
                                    </td>
                                    <td class="p-0">
                                        <span *ngIf="m.name">
                                            {{m.name}}
                                        </span>
                                    </td>
                                    <td class="p-0">
                                        <span *ngIf="m.total">
                                            {{m.total | number : '1.2-2'}}
                                        </span>
                                        <span *ngIf="m.total === 0">
                                            {{0 | number : '1.2-2'}}
                                        </span>
                                    </td>
                                    <td class="p-0">
                                        <button class="btn btn-sm btn-outline-danger"
                                            *ngIf="!submitInProgress && !updateInProgress"
                                            (click)="removeEquipmentItem(i)" type="button">X</button>
                                        <button class="btn btn-sm btn-outline-danger" type="button" disabled
                                            *ngIf="submitInProgress || updateInProgress">
                                            <span class="spinner-border spinner-border-sm" role="status"
                                                aria-hidden="true"></span>
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <form name="signup-form" [formGroup]="equipmentItemForm">
                            <div class="input-group input-group-sm">
                                <!-- <span class="input-group-text" id="inputGroup-sizing-sm"
                                    *ngIf="equipmentItemArray">{{constructionItemArray.length+1}}</span>
                                <span class="input-group-text" id="inputGroup-sizing-sm"
                                    *ngIf="!equipmentItemArray">1</span> -->
                                <span class="input-group-text" id="inputGroup-sizing-sm"></span>
                                <input type="text" class="form-control" placeholder="Name" id="name"
                                    aria-label="Sizing example input" formControlName="name"
                                    aria-describedby="inputGroup-sizing-sm">
                                <input type="number" min="1" class="form-control" id="total"
                                    aria-label="Sizing example input" formControlName="total"
                                    aria-describedby="inputGroup-sizing-sm">
                                <button class="btn btn-outline-success"
                                    (click)="addEquipmentItem(equipmentItemForm.value)"
                                    *ngIf="!submitInProgress && !updateInProgress" type="button">Add Item</button>
                                <button class="btn btn-sm btn-outline-danger" type="button" disabled
                                    *ngIf="submitInProgress || updateInProgress">
                                    <span class="spinner-border spinner-border-sm" role="status"
                                        aria-hidden="true"></span>
                                </button>
                            </div>
                        </form>
                    </div>
                    <div class="card-footer">
                        <div class="row ms-1">
                            <div class="col-sm-12 text-end">
                                <span class="px-3">
                                    Total
                                </span>
                                <span *ngIf="companyInvestmentEquipmentTotal">
                                    {{companyInvestmentEquipmentTotal | number:'1.2-2'}}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card">
                    <div class="card-body">
                        <div class="row text-center">
                            <div class="col-6 text-end">
                                <span>Investment Total :</span>
                            </div>
                            <div class="col-6 text-start">
                                <span *ngIf="companyInvestmentTotal">{{companyInvestmentTotal | number:'1.2-2'}}</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="card">
                    <div class="card-header mb-0">
                        <span>
                            Investment Amortization
                        </span>
                    </div>
                    <div class="card-body" name="working-capital-form" [formGroup]="investmentAmortizationForm">
                        <table class="table table-sm table-borderless table-centered mb-0">
                            <thead>
                                <tr>
                                    <th class="p-0 w-25">
                                    </th>
                                    <th class="p-0 w-25">
                                    </th>
                                    <th class="p-0 w-50">
                                    </th>
                                </tr>
                            </thead>
                            <tbody class="table-group-divider">
                                <tr>
                                    <td class="p-0">
                                        <span>Branding</span>
                                    </td>
                                    <td class="p-0 ps-1">
                                        <div class="input-group input-group-sm input-group-merge">
                                            <input class="form-control form-control-sm " type="number"
                                                id="brandingAmortizationPeriod"
                                                formControlName="brandingAmortizationPeriod"
                                                [ngClass]="{'is-invalid': (formSubmitted && investmentAmortizationFormValues['brandingAmortizationPeriod'].invalid)||(investmentAmortizationFormValues['brandingAmortizationPeriod'].touched && investmentAmortizationFormValues['brandingAmortizationPeriod'].invalid)}">
                                            <div class="input-group-text">
                                                <span>Years</span>
                                            </div>
                                        </div>
                                    </td>
                                    <td class="p-0 text-end">
                                        <span *ngIf="companyInvestmentBrandingTotal">
                                            {{companyInvestmentBrandingTotal | number : '1.2-2'}}
                                        </span>
                                        <span *ngIf="companyInvestmentBrandingTotal === 0">
                                            {{0 | number : '1.2-2'}}
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0">
                                        <span>Construction</span>
                                    </td>
                                    <td class="p-0 ps-1">
                                        <div class="input-group input-group-sm input-group-merge">
                                            <input class="form-control form-control-sm " type="number"
                                                id="constructionAmortizationPeriod" min="1" placeholder="23"
                                                formControlName="constructionAmortizationPeriod"
                                                [ngClass]="{'is-invalid': (formSubmitted && investmentAmortizationFormValues['constructionAmortizationPeriod'].invalid)||(investmentAmortizationFormValues['constructionAmortizationPeriod'].touched && investmentAmortizationFormValues['constructionAmortizationPeriod'].invalid)}">
                                            <div class="input-group-text">
                                                <span>Years</span>
                                            </div>
                                        </div>
                                    </td>
                                    <td class="p-0 text-end">
                                        <span *ngIf="companyInvestmentConstructionTotal">
                                            {{companyInvestmentConstructionTotal | number : '1.2-2'}}
                                        </span>
                                        <span *ngIf="companyInvestmentConstructionTotal === 0">
                                            {{0 | number : '1.2-2'}}
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0">
                                        <span>Equipment</span>
                                    </td>
                                    <td class="p-0 ps-1">
                                        <div class="input-group input-group-sm input-group-merge">
                                            <input class="form-control form-control-sm " type="number"
                                                id="equipmentAmortizationPeriod" min="1" placeholder="23"
                                                formControlName="equipmentAmortizationPeriod"
                                                [ngClass]="{'is-invalid': (formSubmitted && investmentAmortizationFormValues['equipmentAmortizationPeriod'].invalid)||(investmentAmortizationFormValues['equipmentAmortizationPeriod'].touched && investmentAmortizationFormValues['equipmentAmortizationPeriod'].invalid)}">
                                            <div class="input-group-text">
                                                <span>Years</span>
                                            </div>
                                        </div>
                                    </td>
                                    <td class="p-0 text-end">
                                        <span *ngIf="companyInvestmentEquipmentTotal">
                                            {{companyInvestmentEquipmentTotal | number : '1.2-2'}}
                                        </span>
                                        <span *ngIf="companyInvestmentEquipmentTotal === 0">
                                            {{0 | number : '1.2-2'}}
                                        </span>
                                    </td>
                                </tr>

                            </tbody>

                        </table>
                    </div>
                    <div class="card-footer" *ngIf="showProjectInvestmentSection">
                        <div class="row" *ngIf="showProjectInvestmentEdit">
                            <div class="col-sm-12">
                                <div class="d-grid">
                                    <button class="btn btn-sm btn-outline-primary"
                                        (click)="updateProjectInvestment(investmentAmortizationForm.value)"
                                        type="button" *ngIf="!updateInProgress"><i class=""></i>Update</button>
                                    <button class="btn btn-sm btn-outline-primary" type="button" disabled
                                        *ngIf="updateInProgress">
                                        <span class="spinner-border spinner-border-sm" role="status"
                                            aria-hidden="true"></span>
                                        
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="card">
                    <div class="card-header">
                        <span>
                            <p class="py-0 my-0"><strong>Amortization</strong></p>
                        </span>
                    </div>
                    <div class=card-body>
                        <div class="board">
                            <table class="table table-sm table-bordered table-centered mb-0">
                                <thead>
                                    <tr>
                                        <th style="min-width:50px">

                                        </th>
                                        <th>
                                            Y - 0
                                        </th>
                                        <th>
                                            Y - 1
                                        </th>
                                        <th>
                                            Y - 2
                                        </th>
                                        <th>
                                            Y - 3
                                        </th>
                                        <th>
                                            Y - 4
                                        </th>
                                        <th>
                                            Y - 5
                                        </th>
                                        <th>
                                            Y - 6
                                        </th>
                                        <th>
                                            Y - 7
                                        </th>
                                        <th>
                                            Y - 8
                                        </th>
                                        <th>
                                            Y - 9
                                        </th>
                                        <th>
                                            Y - 10
                                        </th>
                                        <th>
                                            Y - 11
                                        </th>
                                        <th>
                                            Y - 12
                                        </th>
                                        <th>
                                            Y - 13
                                        </th>
                                        <th>
                                            Y - 14
                                        </th>
                                        <th>
                                            Y - 15
                                        </th>
                                    </tr>
                                </thead>
                                <tbody class="table-group-divider">
                                    <tr>
                                        <td class="px-1 py-0 col-auto">
                                            <span>
                                                Branding Amortization
                                            </span>
                                        </td>
                                        <td class="px-1 py-0"
                                            *ngFor="let m of brandingAmortizationArray;let i = index;">
                                            <span *ngIf="!(m===0)">{{m | number: '1.2-2'}}</span>
                                            <span *ngIf="m===0">-</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="px-1 py-0 col-auto">
                                            <span>
                                                Construction Amortization
                                            </span>
                                        </td>
                                        <td class="px-1 py-0"
                                            *ngFor="let m of constructionAmortizationArray;let i = index;">
                                            <span *ngIf="!(m===0)">{{m | number: '1.4-4'}}</span>
                                            <span *ngIf="m===0">-</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="px-1 py-0 col-auto">
                                            <span>
                                                Equipment Amortization
                                            </span>
                                        </td>
                                        <td class="px-1 py-0"
                                            *ngFor="let m of equipmentAmortizationArray;let i = index;">
                                            <span *ngIf="!(m===0)">{{m | number: '1.2-2'}}</span>
                                            <span *ngIf="m===0">-</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="px-1 py-0 col-auto">
                                            <span>
                                                Total
                                            </span>
                                        </td>
                                        <td class="px-1 py-0" *ngFor="let m of totalAmortizationArray;let i = index;">
                                            <span *ngIf="!(m===0)">{{m | number: '1.2-2'}}</span>
                                            <span *ngIf="m===0">-</span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

            </div>
            <div class="card" *ngIf="!showProjectInvestmentEdit">
                <div class="card-header">
                    <span>
                        <p class="py-0 my-0"><strong>Investments</strong></p>
                    </span>
                </div>
                <div class="card-body">
                    <div class="board">
                        <table class="table table-sm table-borderless table-centered mb-0">
                            <thead>
                                <tr>
                                    <th class="p-0 ps-1">Item</th>
                                    <th class="p-0 text-end">Amount</th>
                                    <th class="p-0 text-end">Total</th>
                                </tr>
                            </thead>
                            <tbody class="table-group-divider">
                                <tr>
                                    <td class="p-0">
                                        <span>i. Branding</span>
                                    </td>
                                    <td class="p-0 ps-1">

                                    </td>
                                    <td class="p-0 text-end">
                                        <span *ngIf="companyInvestmentBrandingTotal">
                                            {{companyInvestmentBrandingTotal | number : '1.2-2'}}
                                        </span>
                                    </td>
                                </tr>

                            </tbody>
                            <tbody class="table-group-divider" *ngIf="brandingItemArray">
                                <tr *ngFor="let m of brandingItemArray;let i = index;">
                                    <td class="p-0 ps-3">
                                        <span *ngIf="m.name">{{m.name}}</span>
                                    </td>
                                    <td class="p-0 text-end">
                                        <span *ngIf="m.total">{{m.total | number : '1.2-2'}}</span>
                                    </td>
                                    <td class="p-0">

                                    </td>
                                </tr>
                            </tbody>
                            <tbody class="table-group-divider">
                                <tr>
                                    <td class="p-0">
                                        <span>ii. Construction</span>
                                    </td>
                                    <td class="p-0 ps-1">

                                    </td>
                                    <td class="p-0 text-end">
                                        <span *ngIf="companyInvestmentConstructionTotal">
                                            {{companyInvestmentConstructionTotal | number : '1.2-2'}}
                                        </span>
                                    </td>
                                </tr>
                            </tbody>
                            <tbody class="table-group-divider" *ngIf="constructionItemArray">
                                <tr *ngFor="let m of constructionItemArray;let i = index;">
                                    <td class="p-0 ps-3">
                                        <span *ngIf="m.name">{{m.name}}</span>
                                    </td>
                                    <td class="p-0 text-end">
                                        <span *ngIf="m.total">{{m.total | number : '1.2-2'}}</span>
                                    </td>
                                    <td class="p-0">

                                    </td>
                                </tr>
                            </tbody>
                            <tbody class="table-group-divider">
                                <tr>
                                    <td class="p-0">
                                        <span>iii. Equipment</span>
                                    </td>
                                    <td class="p-0 ps-1">

                                    </td>
                                    <td class="p-0 text-end">
                                        <span *ngIf="companyInvestmentEquipmentTotal">
                                            {{companyInvestmentEquipmentTotal | number : '1.2-2'}}
                                        </span>
                                    </td>
                                </tr>
                            </tbody>
                            <tbody class="table-group-divider" *ngIf="equipmentItemArray">
                                <tr *ngFor="let m of equipmentItemArray;let i = index;">
                                    <td class="p-0 ps-3">
                                        <span *ngIf="m.name">{{m.name}}</span>
                                    </td>
                                    <td class="p-0 text-end">
                                        <span *ngIf="m.total">{{m.total | number : '1.2-2'}}</span>
                                    </td>
                                    <td class="p-0">

                                    </td>
                                </tr>
                            </tbody>
                            <tbody class="table-group-divider">
                                <tr>
                                    <td class="p-0 text-end">
                                        <span>Total</span>
                                    </td>
                                    <td class="p-0 ps-1">

                                    </td>
                                    <td class="p-0 text-end">
                                        <span *ngIf="companyInvestmentTotal">
                                            {{companyInvestmentTotal | number : '1.2-2'}}
                                        </span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="card">
                        <div class="card-header">
                            <span>
                                <p class="py-0 my-0"><strong>Yearly Escalation</strong></p>
                            </span>
                        </div>
                        <div class=card-body>
                            <div class="board">
                                <table class="table table-sm table-bordered table-centered mb-0">
                                    <thead>
                                        <tr>
                                            <th style="min-width:50px">

                                            </th>
                                            <th>
                                                Y - 0
                                            </th>
                                            <th>
                                                Y - 1
                                            </th>
                                            <th>
                                                Y - 2
                                            </th>
                                            <th>
                                                Y - 3
                                            </th>
                                            <th>
                                                Y - 4
                                            </th>
                                            <th>
                                                Y - 5
                                            </th>
                                            <th>
                                                Y - 6
                                            </th>
                                            <th>
                                                Y - 7
                                            </th>
                                            <th>
                                                Y - 8
                                            </th>
                                            <th>
                                                Y - 9
                                            </th>
                                            <th>
                                                Y - 10
                                            </th>
                                            <th>
                                                Y - 11
                                            </th>
                                            <th>
                                                Y - 12
                                            </th>
                                            <th>
                                                Y - 13
                                            </th>
                                            <th>
                                                Y - 14
                                            </th>
                                            <th>
                                                Y - 15
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody class="table-group-divider">
                                        <tr>
                                            <td class="px-1 py-0 col-auto">
                                                <span>Branding Amortization</span>
                                            </td>
                                            <td class="px-1 py-0"
                                                *ngFor="let m of brandingAmortizationArray;let i = index;">
                                                <span *ngIf="!(m===0)">{{m | number: '1.2-2'}}</span>
                                                <span *ngIf="m===0">-</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="px-1 py-0 col-auto">
                                                <span>Construction Amortization</span>
                                            </td>
                                            <td class="px-1 py-0"
                                                *ngFor="let m of constructionAmortizationArray;let i = index;">
                                                <span *ngIf="!(m===0)">{{m | number: '1.4-4'}}</span>
                                                <span *ngIf="m===0">-</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="px-1 py-0 col-auto">
                                                <span>Equipment Amortization</span>
                                            </td>
                                            <td class="px-1 py-0"
                                                *ngFor="let m of equipmentAmortizationArray;let i = index;">
                                                <span *ngIf="!(m===0)">{{m | number: '1.2-2'}}</span>
                                                <span *ngIf="m===0">-</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="px-1 py-0 col-auto">
                                                <span>Amortization Total</span>
                                            </td>
                                            <td class="px-1 py-0"
                                                *ngFor="let m of totalAmortizationArray;let i = index;">
                                                <span *ngIf="!(m===0)">{{m | number: '1.2-2'}}</span>
                                                <span *ngIf="m===0">-</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="p-0">
                                                <span>Investments</span>
                                            </td>
                                            <td class="py-0" *ngFor="let m of companyInvestmentArray;let i = index;">
                                                <span *ngIf="!(m===0)">({{m| number:'1.2-2'}})</span>
                                                <span *ngIf="m===0">-</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="p-0">
                                                <span>
                                                    <p class="py-0 my-0"><strong>Investments Total</strong></p>
                                                </span>
                                            </td>
                                            <td class="py-0" *ngFor="let m of companyInvestmentTotalArray;let i = index;">
                                                <span *ngIf="!(m===0)">({{m| number:'1.2-2'}})</span>
                                                <span *ngIf="m===0">-</span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

            </div>
        </div>
        <div class="card-footer" *ngIf="!showProjectInvestmentSection">
            <div class="row">
                <div class="col-sm-6"></div>
                <div class="col-sm-6">
                    <div class="d-grid">
                        <button class="btn btn-sm btn-outline-secondary"
                            (click)="showProjectInvestmentSectionButton(true)" type="button"><i
                                class=""></i>View</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="card-footer" *ngIf="showProjectInvestmentSection">
            <div class="row" *ngIf="showProjectInvestmentEdit">
                <div class="col-sm-6">
                    <div class="d-grid">
                        <button class="btn btn-sm btn-outline-secondary"
                            (click)="showProjectInvestmentEditButton(false)" type="button"><i class=""></i>Simple
                            View</button>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="d-grid">
                        <button class="btn btn-sm btn-outline-success" (click)="submitProjectInvestment()" type="button"
                            *ngIf="!submitInProgress && !updateInProgress"><i class=""></i>Submit</button>
                        <button class="btn btn-sm btn-outline-primary" type="button" disabled
                            *ngIf="submitInProgress || updateInProgress">
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            
                        </button>
                    </div>
                </div>
            </div>
            <div class="row" *ngIf="!showProjectInvestmentEdit">
                <div class="col-sm-6">
                    <div class="d-grid">
                        <button class="btn btn-sm btn-outline-secondary" (click)="showProjectInvestmentEditButton(true)"
                            type="button"><i class=""></i>Edit View</button>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="d-grid">
                        <button class="btn btn-sm btn-outline-success" (click)="submitProjectInvestment()" type="button"
                            *ngIf="!submitInProgress && !updateInProgress"><i class=""></i>Submit</button>
                        <button class="btn btn-sm btn-outline-primary" type="button" disabled
                            *ngIf="submitInProgress || updateInProgress">
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Step 5. Working Capital -->
    <div id="stepFive" class="border border-secondary card stepFive">
        <div class="card-header">
            <div class="table-responsive-sm" *ngIf="projectArray">
                <table class="table table-sm table-centered table-borderless mb-0" *ngIf="projectArray[0]">
                    <tbody>
                        <tr>
                            <td class="p-0" style="width:60%">
                                Step 5. Working Capital - Galana
                            </td>
                            <td class="p-0" style="width:30%">
                                <p class="mb-0">
                                    Completion
                                    <ngb-progressbar [showValue]="true" type="info" [striped]="true" [animated]="true"
                                        [value]="projectWorkingCapitalProgressPercentage"></ngb-progressbar>
                                </p>
                            </td>
                            <td class="p-0 text-center text-primary" style="width:10%">
                                <i class="mdi mdi-help-circle-outline mdi-24px"></i>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div> <!-- end table-responsive-->
        </div>
        <div class="card-body" *ngIf="showProjectWorkingCapitalSection">
            <div class="card">
                <div class="card-body" name="working-capital-form" [formGroup]="workingCapitalForm"
                    *ngIf="showProjectWorkingCapitalEdit">
                    <div class="card">
                        <div class="card-header mb-0">
                            <span>
                                White Products
                            </span>
                        </div>
                        <div class="card-body">
                            <table class="table table-sm table-borderless table-centered mb-0">
                                <thead>
                                    <tr>
                                        <th class="p-0 w-25">
                                        </th>
                                        <th class="p-0 w-25">
                                        </th>
                                        <th class="p-0 w-50">
                                        </th>
                                    </tr>
                                </thead>
                                <tbody class="table-group-divider">
                                    <tr>
                                        <td class="p-0">
                                            <span>Supplier Credit</span>
                                        </td>
                                        <td class="p-0 ps-1">
                                            <div class="input-group input-group-sm input-group-merge">
                                                <input class="form-control form-control-sm " type="number"
                                                    id="whiteProductSupplierCreditDays"
                                                    formControlName="whiteProductSupplierCreditDays"
                                                    [ngClass]="{'is-invalid': (formSubmitted && workingCapitalFormValues['whiteProductSupplierCreditDays'].invalid)||(workingCapitalFormValues['whiteProductSupplierCreditDays'].touched && workingCapitalFormValues['whiteProductSupplierCreditDays'].invalid)}">
                                                <div class="input-group-text">
                                                    <span>Days</span>
                                                </div>
                                            </div>
                                        </td>
                                        <td class="p-0 text-end">
                                            <span *ngIf="whiteProductSupplierCreditTotal">
                                                ({{whiteProductSupplierCreditTotal | number : '1.2-2'}})
                                            </span>
                                            <span *ngIf="whiteProductSupplierCreditTotal === 0">
                                                ({{0 | number : '1.2-2'}})
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="p-0">
                                            <span>Stock Logistics</span>
                                        </td>
                                        <td class="p-0 ps-1">
                                            <div class="input-group input-group-sm input-group-merge">
                                                <input class="form-control form-control-sm " type="number"
                                                    id="whiteProductStockLogisticsDays" min="1" placeholder="23"
                                                    formControlName="whiteProductStockLogisticsDays"
                                                    [ngClass]="{'is-invalid': (formSubmitted && workingCapitalFormValues['whiteProductStockLogisticsDays'].invalid)||(workingCapitalFormValues['whiteProductStockLogisticsDays'].touched && workingCapitalFormValues['whiteProductStockLogisticsDays'].invalid)}">
                                                <div class="input-group-text">
                                                    <span>Days</span>
                                                </div>
                                            </div>
                                        </td>
                                        <td class="p-0 text-end">
                                            <span *ngIf="whiteProductStockLogisticsTotal">
                                                {{whiteProductStockLogisticsTotal | number : '1.2-2'}}
                                            </span>
                                            <span *ngIf="whiteProductStockLogisticsTotal === 0">
                                                {{0 | number : '1.2-2'}}
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="p-0">
                                            <span>Total</span>
                                        </td>
                                        <td class="p-0 ps-1">

                                        </td>
                                        <td class="p-0 text-end">
                                            <span *ngIf="whiteProductWorkingCapital">
                                                {{whiteProductWorkingCapital | number : '1.2-2'}}
                                            </span>
                                            <span *ngIf="whiteProductWorkingCapital === 0">
                                                {{0 | number : '1.2-2'}}
                                            </span>
                                        </td>
                                    </tr>
                                </tbody>

                            </table>
                        </div>
                        <div class="card-footer" *ngIf="showProjectWorkingCapitalEdit">
                            <div class="row">
                                <div class="col-sm-12">
                                    <div class="d-grid">
                                        <button class="btn btn-sm btn-outline-success"
                                            (click)="updateProjectWorkingCapital(workingCapitalForm.value)"
                                            type="button" *ngIf="!submitInProgress && !updateInProgress"><i
                                                class=""></i>Update</button>
                                        <button class="btn btn-sm btn-outline-primary" type="button" disabled
                                            *ngIf="submitInProgress || updateInProgress">
                                            <span class="spinner-border spinner-border-sm" role="status"
                                                aria-hidden="true"></span>
                                            
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header mb-0">
                            <span>
                                Lubricants
                            </span>
                        </div>
                        <div class="card-body">
                            <table class="table table-sm table-borderless table-centered mb-0">
                                <thead>
                                    <tr>
                                        <th class="p-0 w-25">
                                        </th>
                                        <th class="p-0 w-25">
                                        </th>
                                        <th class="p-0 w-50">
                                        </th>
                                    </tr>
                                </thead>
                                <tbody class="table-group-divider">
                                    <tr>
                                        <td class="p-0">
                                            <span>Supplier Credit</span>
                                        </td>
                                        <td class="p-0 ps-1">
                                            <div class="input-group input-group-sm input-group-merge">
                                                <input class="form-control form-control-sm " type="number"
                                                    id="lubricantSupplierCreditDays" placeholder="30"
                                                    formControlName="lubricantSupplierCreditDays"
                                                    [ngClass]="{'is-invalid': (formSubmitted && workingCapitalFormValues['lubricantSupplierCreditDays'].invalid)||(workingCapitalFormValues['lubricantSupplierCreditDays'].touched && workingCapitalFormValues['lubricantSupplierCreditDays'].invalid)}">
                                                <div class="input-group-text">
                                                    <span>Days</span>
                                                </div>
                                            </div>
                                        </td>
                                        <td class="p-0 text-end">
                                            <span *ngIf="lubricantSupplierCreditTotal">
                                                ({{lubricantSupplierCreditTotal | number : '1.2-2'}})
                                            </span>
                                            <span *ngIf="lubricantSupplierCreditTotal === 0">
                                                ({{0 | number : '1.2-2'}})
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="p-0">
                                            <span>Stock Logistics</span>
                                        </td>
                                        <td class="p-0 ps-1">
                                            <div class="input-group input-group-sm input-group-merge">
                                                <input class="form-control form-control-sm " type="number"
                                                    id="lubricantStockLogisticsDays" min="1" placeholder="30"
                                                    formControlName="lubricantStockLogisticsDays"
                                                    [ngClass]="{'is-invalid': (formSubmitted && workingCapitalFormValues['lubricantStockLogisticsDays'].invalid)||(workingCapitalFormValues['lubricantStockLogisticsDays'].touched && workingCapitalFormValues['lubricantStockLogisticsDays'].invalid)}">
                                                <div class="input-group-text">
                                                    <span>Days</span>
                                                </div>
                                            </div>
                                        </td>
                                        <td class="p-0 text-end">
                                            <span *ngIf="lubricantStockLogisticsTotal">
                                                {{lubricantStockLogisticsTotal | number : '1.2-2'}}
                                            </span>
                                            <span *ngIf="lubricantStockLogisticsTotal === 0">
                                                {{0 | number : '1.2-2'}}
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="p-0">
                                            <span>Total</span>
                                        </td>
                                        <td class="p-0 ps-1">

                                        </td>
                                        <td class="p-0 text-end">
                                            <span *ngIf="lubricantWorkingCapital">
                                                {{lubricantWorkingCapital | number : '1.2-2'}}
                                            </span>
                                            <span *ngIf="lubricantWorkingCapital===0">
                                                {{0 | number : '1.2-2'}}
                                            </span>
                                        </td>
                                    </tr>
                                </tbody>

                            </table>
                        </div>
                        <div class="card-footer" *ngIf="showProjectWorkingCapitalEdit">
                            <div class="row">
                                <div class="col-sm-12">
                                    <div class="d-grid">
                                        <button class="btn btn-sm btn-outline-success"
                                            (click)="updateProjectWorkingCapital(workingCapitalForm.value)"
                                            type="button" *ngIf="!submitInProgress && !updateInProgress"><i
                                                class=""></i>Update</button>
                                        <button class="btn btn-sm btn-outline-primary" type="button" disabled
                                            *ngIf="submitInProgress || updateInProgress">
                                            <span class="spinner-border spinner-border-sm" role="status"
                                                aria-hidden="true"></span>
                                            
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-6 text-end">
                                    <span>
                                        White Products & Lubricants Total
                                    </span>
                                </div>
                                <div class="col-6 text-start">
                                    <span *ngIf="whiteProductLubricantWorkingCapital">
                                        {{whiteProductLubricantWorkingCapital|number:"1.2-2"}} <span *ngIf="localCurrencyISOCode">{{localCurrencyISOCode}}</span>
                                    </span>
                                    <span *ngIf="whiteProductLubricantWorkingCapital === 0">
                                        {{0|number:"1.2-2"}} <span *ngIf="localCurrencyISOCode">{{localCurrencyISOCode}}</span>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header">
                            <span>Working Capital Overview</span>
                        </div>
                        <div class="card-body">

                            <div class="row mb-3" *ngIf="fuelProductArray">
                                <div class="table-responsive-xxl">
                                    <table class="table table-sm table-borderless table-centered mb-0">
                                        <thead>
                                            <tr>
                                                <th class="p-0">
                                                </th>
                                                <th class="p-0">
                                                </th>
                                                <th class="p-0">
                                                </th>
                                                <th class="p-0">
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody class="table-group-divider">
                                            <tr>
                                                <td class="p-0">
                                                    <span>
                                                        Average Working Capital
                                                    </span>
                                                </td>
                                                <td class="p-0">
                                                    <span *ngIf="whiteProductLubricantAverageWorkingCapital">
                                                        {{whiteProductLubricantAverageWorkingCapital | number :
                                                        '1.2-2'}}
                                                    </span>
                                                </td>
                                                <td class="p-0">
                                                    <span>
                                                        m3/month
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="p-0">
                                                    <span>
                                                        Fuel - Yearly Volume
                                                    </span>
                                                </td>
                                                <td class="p-0">
                                                    <span *ngIf="fuelYearlyVolume">
                                                        {{fuelYearlyVolume | number : '1.2-2'}}
                                                    </span>
                                                </td>
                                                <td class="p-0">
                                                    <span>
                                                        m3/month
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="p-0">
                                                    <span>
                                                        First Year Working Capital
                                                    </span>
                                                </td>
                                                <td class="p-0">
                                                    <span *ngIf="firstYearWorkingCapital">
                                                        ({{firstYearWorkingCapital | number : '1.2-2'}})
                                                    </span>
                                                </td>
                                                <td class="p-0">
                                                    <span>
                                                        m3/month
                                                    </span>
                                                </td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header">
                            <span>
                                <p class="py-0 my-0"><strong>Yearly Escalation</strong></p>
                            </span>
                        </div>
                        <div class=card-body>
                            <div class="board">
                                <table class="table table-sm table-bordered table-centered mb-0">
                                    <thead>
                                        <tr>
                                            <th style="min-width:50px">

                                            </th>
                                            <th>
                                                Y - 0
                                            </th>
                                            <th>
                                                Y - 1
                                            </th>
                                            <th>
                                                Y - 2
                                            </th>
                                            <th>
                                                Y - 3
                                            </th>
                                            <th>
                                                Y - 4
                                            </th>
                                            <th>
                                                Y - 5
                                            </th>
                                            <th>
                                                Y - 6
                                            </th>
                                            <th>
                                                Y - 7
                                            </th>
                                            <th>
                                                Y - 8
                                            </th>
                                            <th>
                                                Y - 9
                                            </th>
                                            <th>
                                                Y - 10
                                            </th>
                                            <th>
                                                Y - 11
                                            </th>
                                            <th>
                                                Y - 12
                                            </th>
                                            <th>
                                                Y - 13
                                            </th>
                                            <th>
                                                Y - 14
                                            </th>
                                            <th>
                                                Y - 15
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody class="table-group-divider">
                                        <tr>
                                            <td class="px-1 py-0 col-auto">
                                                <span>
                                                    Average Working Capital
                                                </span>
                                            </td>
                                            <td class="px-1 py-0"
                                                *ngFor="let m of averageWorkingCapitalArray;let i = index;">
                                                <span *ngIf="!(m===0)">{{m | number: '1.2-2'}}</span>
                                                <span *ngIf="m===0">-</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="px-1 py-0 col-auto">
                                                <span>
                                                    Inflation Rate
                                                </span>
                                            </td>
                                            <td class="px-1 py-0" *ngFor="let m of inflationRateArray;let i = index;">
                                                <span *ngIf="!(m===0)">{{m | number: '1.4-4'}}</span>
                                                <span *ngIf="m===0">-</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="px-1 py-0 col-auto">
                                                <span>
                                                    Inflation Factor
                                                </span>
                                            </td>
                                            <td class="px-1 py-0" *ngFor="let m of inflationFactorArray;let i = index;">
                                                <span *ngIf="!(m===0)">{{m | number: '1.2-2'}}</span>
                                                <span *ngIf="m===0">-</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="px-1 py-0 col-auto">
                                                <span>
                                                    Average Working Capital x Inflation Factor
                                                </span>
                                            </td>
                                            <td class="px-1 py-0"
                                                *ngFor="let m of averageWorkingCapitalByInflationFactorArray;let i = index;">
                                                <span *ngIf="!(m===0)">{{m | number: '1.2-2'}}</span>
                                                <span *ngIf="m===0">-</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="px-1 py-0 col-auto">
                                                <span>
                                                    Fuel And Lubricant Volume (m3)
                                                </span>
                                            </td>
                                            <td class="px-1 py-0"
                                                *ngFor="let m of fuelsAndLubricantVolumeArray;let i = index;">
                                                <span *ngIf="!(m===0)">{{m | number: '1.2-2'}}</span>
                                                <span *ngIf="m===0">-</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="px-1 py-0 col-auto">
                                                <span>
                                                    Working Capital - Nominal
                                                </span>
                                            </td>
                                            <td class="px-1 py-0"
                                                *ngFor="let m of workingCapitalMainArray;let i = index;">
                                                <span *ngIf="!(m===0)">{{m | number: '1.2-2'}}</span>
                                                <span *ngIf="m===0">-</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="px-1 py-0 col-auto">
                                                <span>
                                                    <p><strong>Change in Working Capital - Nominal</strong></p>
                                                </span>
                                            </td>
                                            <td class="px-1 py-0"
                                                *ngFor="let m of changeInWorkingCapitalArray;let i = index;">
                                                <span *ngIf="!(m===0)">{{m | number: '1.2-2'}}</span>
                                                <span *ngIf="m===0">-</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="px-1 py-0 col-auto">
                                                <span>
                                                    Working Capital - Real
                                                </span>
                                            </td>
                                            <td class="px-1 py-0"
                                                *ngFor="let m of workingCapitalMainRealArray;let i = index;">
                                                <span *ngIf="!(m===0)">{{m | number: '1.2-2'}}</span>
                                                <span *ngIf="m===0">-</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="px-1 py-0 col-auto">
                                                <span>
                                                    <p><strong>Change in Working Capital - Real</strong></p>
                                                </span>
                                            </td>
                                            <td class="px-1 py-0"
                                                *ngFor="let m of changeInWorkingCapitalRealArray;let i = index;">
                                                <span *ngIf="!(m===0)">{{m | number: '1.2-2'}}</span>
                                                <span *ngIf="m===0">-</span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-body" *ngIf="!showProjectWorkingCapitalEdit">
                    <div class="card">
                        <div class="card-header mb-0">
                            <span>
                                White Products
                            </span>
                        </div>
                        <div class="card-body">
                            <table class="table table-sm table-borderless table-centered mb-0">
                                <thead>
                                    <tr>
                                        <th class="p-0 w-25">
                                        </th>
                                        <th class="p-0 w-25">
                                        </th>
                                        <th class="p-0 w-50">
                                        </th>
                                    </tr>
                                </thead>
                                <tbody class="table-group-divider">
                                    <tr>
                                        <td class="p-0">
                                            <span>Supplier Credit</span>
                                        </td>
                                        <td class="p-0 text-end">
                                            <span *ngIf="whiteProductSupplierCreditDays">
                                                {{whiteProductSupplierCreditDays | number : '1.0-0'}} Days
                                            </span>
                                            <span *ngIf="whiteProductSupplierCreditDays === 0">
                                                {{0 | number : '1.0-0'}} Days
                                            </span>
                                        </td>
                                        <td class="p-0 text-end">
                                            <span *ngIf="whiteProductSupplierCreditTotal">
                                                ({{whiteProductSupplierCreditTotal | number : '1.2-2'}})
                                            </span>
                                            <span *ngIf="whiteProductSupplierCreditTotal === 0">
                                                ({{0 | number : '1.2-2'}})
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="p-0">
                                            <span>Stock Logistics</span>
                                        </td>
                                        <td class="p-0 text-end">
                                            <span *ngIf="whiteProductStockLogisticsDays">
                                                {{whiteProductStockLogisticsDays | number : '1.0-0'}} Days
                                            </span>
                                            <span *ngIf="whiteProductStockLogisticsDays === 0">
                                                {{0 | number : '1.0-0'}} Days
                                            </span>
                                        </td>
                                        <td class="p-0 text-end">
                                            <span *ngIf="whiteProductStockLogisticsTotal">
                                                {{whiteProductStockLogisticsTotal | number : '1.2-2'}}
                                            </span>
                                            <span *ngIf="whiteProductStockLogisticsTotal === 0">
                                                {{0 | number : '1.2-2'}}
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="p-0">
                                            <span>Total</span>
                                        </td>
                                        <td class="p-0 ps-1">

                                        </td>
                                        <td class="p-0 text-end">
                                            <span *ngIf="whiteProductWorkingCapital">
                                                {{whiteProductWorkingCapital | number : '1.2-2'}}
                                            </span>
                                            <span *ngIf="whiteProductWorkingCapital === 0">
                                                {{0 | number : '1.2-2'}}
                                            </span>
                                        </td>
                                    </tr>
                                </tbody>

                            </table>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header mb-0">
                            <span>
                                Lubricants
                            </span>
                        </div>
                        <div class="card-body">
                            <table class="table table-sm table-borderless table-centered mb-0">
                                <thead>
                                    <tr>
                                        <th class="p-0 w-25">
                                        </th>
                                        <th class="p-0 w-25">
                                        </th>
                                        <th class="p-0 w-50">
                                        </th>
                                    </tr>
                                </thead>
                                <tbody class="table-group-divider">
                                    <tr>
                                        <td class="p-0">
                                            <span>Supplier Credit</span>
                                        </td>
                                        <td class="p-0 text-end">
                                            <span *ngIf="lubricantSupplierCreditDays">
                                                {{lubricantSupplierCreditDays | number : '1.0-0'}} Days
                                            </span>
                                            <span *ngIf="lubricantSupplierCreditDays === 0">
                                                {{0 | number : '1.0-0'}} Days
                                            </span>
                                        </td>
                                        <td class="p-0 text-end">
                                            <span *ngIf="lubricantSupplierCreditTotal">
                                                ({{lubricantSupplierCreditTotal | number : '1.2-2'}})
                                            </span>
                                            <span *ngIf="lubricantSupplierCreditTotal === 0">
                                                ({{0 | number : '1.2-2'}})
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="p-0">
                                            <span>Stock Logistics</span>
                                        </td>
                                        <td class="p-0 text-end">
                                            <span *ngIf="lubricantStockLogisticsDays">
                                                {{lubricantStockLogisticsDays | number : '1.0-0'}} Days
                                            </span>
                                            <span *ngIf="lubricantStockLogisticsDays === 0">
                                                {{0 | number : '1.0-0'}} Days
                                            </span>
                                        </td>
                                        <td class="p-0 text-end">
                                            <span *ngIf="lubricantStockLogisticsTotal">
                                                {{lubricantStockLogisticsTotal | number : '1.2-2'}}
                                            </span>
                                            <span *ngIf="lubricantStockLogisticsTotal === 0">
                                                {{0 | number : '1.2-2'}}
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="p-0">
                                            <span>Total</span>
                                        </td>
                                        <td class="p-0 ps-1">

                                        </td>
                                        <td class="p-0 text-end">
                                            <span *ngIf="lubricantWorkingCapital">
                                                {{lubricantWorkingCapital | number : '1.2-2'}}
                                            </span>
                                            <span *ngIf="lubricantWorkingCapital===0">
                                                {{0 | number : '1.2-2'}}
                                            </span>
                                        </td>
                                    </tr>
                                </tbody>

                            </table>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-6 text-end">
                                    <span>
                                        White Products & Lubricants Total
                                    </span>
                                </div>
                                <div class="col-6 text-start">
                                    <span *ngIf="whiteProductLubricantWorkingCapital">
                                        {{whiteProductLubricantWorkingCapital|number:"1.2-2"}} <span *ngIf="localCurrencyISOCode">{{localCurrencyISOCode}}</span>
                                    </span>
                                    <span *ngIf="whiteProductLubricantWorkingCapital === 0">
                                        {{0|number:"1.2-2"}} <span *ngIf="localCurrencyISOCode">{{localCurrencyISOCode}}</span>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header">
                            <span>Working Capital Overview</span>
                        </div>
                        <div class="card-body">

                            <div class="row mb-3" *ngIf="fuelProductArray">
                                <div class="table-responsive-xxl">
                                    <table class="table table-sm table-borderless table-centered mb-0">
                                        <thead>
                                            <tr>
                                                <th class="p-0">
                                                </th>
                                                <th class="p-0">
                                                </th>
                                                <th class="p-0">
                                                </th>
                                                <th class="p-0">
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody class="table-group-divider">
                                            <tr>
                                                <td class="p-0">
                                                    <span>
                                                        Average Working Capital
                                                    </span>
                                                </td>
                                                <td class="p-0">
                                                    <span *ngIf="whiteProductLubricantAverageWorkingCapital">
                                                        {{whiteProductLubricantAverageWorkingCapital | number :
                                                        '1.2-2'}}
                                                    </span>
                                                </td>
                                                <td class="p-0">
                                                    <span>
                                                        m3/month
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="p-0">
                                                    <span>
                                                        Fuel - Yearly Volume
                                                    </span>
                                                </td>
                                                <td class="p-0">
                                                    <span *ngIf="fuelYearlyVolume">
                                                        {{fuelYearlyVolume | number : '1.2-2'}}
                                                    </span>
                                                </td>
                                                <td class="p-0">
                                                    <span>
                                                        m3/month
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="p-0">
                                                    <span>
                                                        First Year Working Capital
                                                    </span>
                                                </td>
                                                <td class="p-0">
                                                    <span *ngIf="firstYearWorkingCapital">
                                                        ({{firstYearWorkingCapital | number : '1.2-2'}})
                                                    </span>
                                                </td>
                                                <td class="p-0">
                                                    <span>
                                                        m3/month
                                                    </span>
                                                </td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header mb-0">
                            <h5>
                                <span>
                                    Yearly Escalation
                                </span>
                            </h5>
                        </div>
                        <div class=card-body>
                            <div class="board">
                                <table class="table table-sm table-bordered table-centered mb-0">
                                    <thead>
                                        <tr>
                                            <th style="min-width:50px">

                                            </th>
                                            <th>
                                                Y - 0
                                            </th>
                                            <th>
                                                Y - 1
                                            </th>
                                            <th>
                                                Y - 2
                                            </th>
                                            <th>
                                                Y - 3
                                            </th>
                                            <th>
                                                Y - 4
                                            </th>
                                            <th>
                                                Y - 5
                                            </th>
                                            <th>
                                                Y - 6
                                            </th>
                                            <th>
                                                Y - 7
                                            </th>
                                            <th>
                                                Y - 8
                                            </th>
                                            <th>
                                                Y - 9
                                            </th>
                                            <th>
                                                Y - 10
                                            </th>
                                            <th>
                                                Y - 11
                                            </th>
                                            <th>
                                                Y - 12
                                            </th>
                                            <th>
                                                Y - 13
                                            </th>
                                            <th>
                                                Y - 14
                                            </th>
                                            <th>
                                                Y - 15
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody class="table-group-divider">
                                        <tr>
                                            <td class="px-1 py-0 col-auto">
                                                <span>
                                                    Average Working Capital
                                                </span>
                                            </td>
                                            <td class="px-1 py-0"
                                                *ngFor="let m of averageWorkingCapitalArray;let i = index;">
                                                <span *ngIf="!(m===0)">{{m | number: '1.2-2'}}</span>
                                                <span *ngIf="m===0">-</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="px-1 py-0 col-auto">
                                                <span>
                                                    Inflation Rate
                                                </span>
                                            </td>
                                            <td class="px-1 py-0" *ngFor="let m of inflationRateArray;let i = index;">
                                                <span *ngIf="!(m===0)">{{m | number: '1.4-4'}}</span>
                                                <span *ngIf="m===0">-</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="px-1 py-0 col-auto">
                                                <span>
                                                    Inflation Factor
                                                </span>
                                            </td>
                                            <td class="px-1 py-0" *ngFor="let m of inflationFactorArray;let i = index;">
                                                <span *ngIf="!(m===0)">{{m | number: '1.2-2'}}</span>
                                                <span *ngIf="m===0">-</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="px-1 py-0 col-auto">
                                                <span>
                                                    Average Working Capital x Inflation Factor
                                                </span>
                                            </td>
                                            <td class="px-1 py-0"
                                                *ngFor="let m of averageWorkingCapitalByInflationFactorArray;let i = index;">
                                                <span *ngIf="!(m===0)">{{m | number: '1.2-2'}}</span>
                                                <span *ngIf="m===0">-</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="px-1 py-0 col-auto">
                                                <span>
                                                    Fuel And Lubricant Volume (m3)
                                                </span>
                                            </td>
                                            <td class="px-1 py-0"
                                                *ngFor="let m of fuelsAndLubricantVolumeArray;let i = index;">
                                                <span *ngIf="!(m===0)">{{m | number: '1.2-2'}}</span>
                                                <span *ngIf="m===0">-</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="px-1 py-0 col-auto">
                                                <span>
                                                    Working Capital - Nominal
                                                </span>
                                            </td>
                                            <td class="px-1 py-0"
                                                *ngFor="let m of workingCapitalMainArray;let i = index;">
                                                <span *ngIf="!(m===0)">{{m | number: '1.2-2'}}</span>
                                                <span *ngIf="m===0">-</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="px-1 py-0 col-auto">
                                                <span>
                                                    <p><strong>Change in Working Capital - Nominal</strong></p>
                                                </span>
                                            </td>
                                            <td class="px-1 py-0"
                                                *ngFor="let m of changeInWorkingCapitalArray;let i = index;">
                                                <span *ngIf="!(m===0)">{{m | number: '1.2-2'}}</span>
                                                <span *ngIf="m===0">-</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="px-1 py-0 col-auto">
                                                <span>
                                                    Working Capital - Real
                                                </span>
                                            </td>
                                            <td class="px-1 py-0"
                                                *ngFor="let m of workingCapitalMainRealArray;let i = index;">
                                                <span *ngIf="!(m===0)">{{m | number: '1.2-2'}}</span>
                                                <span *ngIf="m===0">-</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="px-1 py-0 col-auto">
                                                <span>
                                                    <p><strong>Change in Working Capital - Real</strong></p>
                                                </span>
                                            </td>
                                            <td class="px-1 py-0"
                                                *ngFor="let m of changeInWorkingCapitalRealArray;let i = index;">
                                                <span *ngIf="!(m===0)">{{m | number: '1.2-2'}}</span>
                                                <span *ngIf="m===0">-</span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card-footer" *ngIf="!showProjectWorkingCapitalSection">
            <div class="row">
                <div class="col-sm-6"></div>
                <div class="col-sm-6">
                    <div class="d-grid">
                        <button class="btn btn-sm btn-outline-secondary"
                            (click)="showProjectWorkingCapitalSectionButton(true)" type="button"><i
                                class=""></i>View</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="card-footer" *ngIf="showProjectWorkingCapitalSection">
            <div class="row" *ngIf="showProjectWorkingCapitalEdit">
                <div class="col-sm-6">
                    <div class="d-grid">
                        <button class="btn btn-sm btn-outline-secondary"
                            (click)="showProjectWorkingCapitalEditButton(false)" type="button"><i class=""></i>Simple
                            View</button>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="d-grid">
                        <button class="btn btn-sm btn-outline-success" (click)="submitProjectWorkingCapital()"
                            type="button" *ngIf="!submitInProgress && !updateInProgress"><i class=""></i>Submit</button>
                        <button class="btn btn-sm btn-outline-primary" type="button" disabled
                            *ngIf="submitInProgress || updateInProgress">
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            
                        </button>
                    </div>
                </div>
            </div>
            <div class="row" *ngIf="!showProjectWorkingCapitalEdit">
                <div class="col-sm-6">
                    <div class="d-grid">
                        <button class="btn btn-sm btn-outline-secondary"
                            (click)="showProjectWorkingCapitalEditButton(true)" type="button"><i class=""></i>Edit
                            View</button>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="d-grid">
                        <button class="btn btn-sm btn-outline-success" (click)="submitProjectWorkingCapital()"
                            type="button" *ngIf="!submitInProgress && !updateInProgress"><i class=""></i>Submit</button>
                        <button class="btn btn-sm btn-outline-primary" type="button" disabled
                            *ngIf="submitInProgress || updateInProgress">
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Step 6. Other Revenue -->
    <div id="stepSix" class="border border-secondary card stepSix">
        <div class="card-header">
            <div class="table-responsive-sm" *ngIf="projectArray">
                <table class="table table-sm table-centered table-borderless mb-0" *ngIf="projectArray[0]">
                    <tbody>
                        <tr>
                            <td class="p-0" style="width:60%">
                                Step 6. Other Revenue - Galana
                            </td>
                            <td class="p-0" style="width:30%">
                                <p class="mb-0">
                                    Completion
                                    <ngb-progressbar [showValue]="true" type="info" [striped]="true" [animated]="true"
                                        [value]="projectOtherRevenueProgressPercentage"></ngb-progressbar>
                                </p>
                            </td>
                            <td class="p-0 text-center text-primary" style="width:10%">
                                <i class="mdi mdi-help-circle-outline mdi-24px"></i>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div> <!-- end table-responsive-->
        </div>
        <div class="card-body" *ngIf="showProjectOtherRevenueSection">
            <div class="card" *ngIf="showProjectOtherRevenueEdit">
                <div class="card-header mb-0">
                    <h5>
                        <span>
                            Fuel Fees
                        </span>
                    </h5>
                </div>
                <div class="card-body">
                    <table class="table table-sm table-borderless table-centered mb-0" name="company-fees-received-form"
                        [formGroup]="companyFeesReceivedForm">
                        <thead>
                            <tr>
                                <th class="p-0 w-50">
                                </th>
                                <th class="p-0 w-50">
                                </th>
                            </tr>
                        </thead>
                        <tbody class="table-group-divider">
                            <tr>
                                <td class="p-0">
                                    <span>Fixed Fees - Fuel - Dodo Discount Given (m3)</span>
                                </td>
                                <td class="p-0 ps-1">
                                    <div class="input-group input-group-sm input-group-merge">
                                        <input class="form-control form-control-sm " type="number"
                                            id="fixedFeesFuelDodoDiscountGivenM3"
                                            formControlName="fixedFeesFuelDodoDiscountGivenM3"
                                            [ngClass]="{'is-invalid': (formSubmitted && companyFeesReceivedFormValues['fixedFeesFuelDodoDiscountGivenM3'].invalid)||(companyFeesReceivedFormValues['fixedFeesFuelDodoDiscountGivenM3'].touched && companyFeesReceivedFormValues['fixedFeesFuelDodoDiscountGivenM3'].invalid)}">
                                        <div class="input-group-text">
                                            <span><span *ngIf="localCurrencyISOCode">{{localCurrencyISOCode}}</span></span>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td class="p-0">
                                    <span>Variable Fees - Fuel Rate</span>
                                </td>
                                <td class="p-0 ps-1">
                                    <div class="input-group input-group-sm input-group-merge">
                                        <input class="form-control form-control-sm " type="number"
                                            id="variableFeesFuelRate" formControlName="variableFeesFuelRate"
                                            [ngClass]="{'is-invalid': (formSubmitted && companyFeesReceivedFormValues['variableFeesFuelRate'].invalid)||(companyFeesReceivedFormValues['variableFeesFuelRate'].touched && companyFeesReceivedFormValues['variableFeesFuelRate'].invalid)}">
                                        <div class="input-group-text">
                                            <span>%</span>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td class="p-0">
                                    <span>Fuel Cost (m3)</span>
                                </td>
                                <td class="p-0 ps-1">
                                    <div class="input-group input-group-sm input-group-merge">
                                        <input class="form-control form-control-sm " type="number" id="fuelCostM3"
                                            formControlName="fuelCostM3"
                                            [ngClass]="{'is-invalid': (formSubmitted && companyFeesReceivedFormValues['fuelCostM3'].invalid)||(companyFeesReceivedFormValues['fuelCostM3'].touched && companyFeesReceivedFormValues['fuelCostM3'].invalid)}">
                                        <div class="input-group-text">
                                            <span><span *ngIf="localCurrencyISOCode">{{localCurrencyISOCode}}</span></span>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td class="p-0">
                                    <span>Fixed Fees - Fuel Rate</span>
                                </td>
                                <td class="p-0 ps-1">
                                    <div class="input-group input-group-sm input-group-merge">
                                        <input class="form-control form-control-sm " type="number"
                                            id="fixedFeesFuelRate" formControlName="fixedFeesFuelRate"
                                            [ngClass]="{'is-invalid': (formSubmitted && companyFeesReceivedFormValues['fixedFeesFuelRate'].invalid)||(companyFeesReceivedFormValues['fixedFeesFuelRate'].touched && companyFeesReceivedFormValues['fixedFeesFuelRate'].invalid)}">
                                        <div class="input-group-text">
                                            <span>%</span>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td class="p-0">
                                    <span>Variable Fees - Card Commission Rate</span>
                                </td>
                                <td class="p-0 ps-1">
                                    <div class="input-group input-group-sm input-group-merge">
                                        <input class="form-control form-control-sm " type="number"
                                            id="variableFeesCardCommissionRate"
                                            formControlName="variableFeesCardCommissionRate"
                                            [ngClass]="{'is-invalid': (formSubmitted && companyFeesReceivedFormValues['variableFeesCardCommissionRate'].invalid)||(companyFeesReceivedFormValues['variableFeesCardCommissionRate'].touched && companyFeesReceivedFormValues['variableFeesCardCommissionRate'].invalid)}">
                                        <div class="input-group-text">
                                            <span>%</span>
                                        </div>
                                    </div>
                                </td>
                            </tr>

                        </tbody>

                    </table>
                </div>
                <div class="card-footer">
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="d-grid">
                                <button class="btn btn-sm btn-outline-success"
                                    (click)="updateProjectOtherRevenue(companyFeesReceivedForm.value)" type="button"
                                    *ngIf="!submitInProgress && !updateInProgress"><i class=""></i>Update</button>
                                <button class="btn btn-sm btn-outline-primary" type="button" disabled
                                    *ngIf="submitInProgress || updateInProgress">
                                    <span class="spinner-border spinner-border-sm" role="status"
                                        aria-hidden="true"></span>
                                    
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card">
                <div class="card-header mb-0">
                    <h5>
                        <span>
                            Fees Received And Other Revenues
                        </span>
                    </h5>
                </div>
                <div class="card-body">
                    <div class="board">
                        <div class="table-responsive-xxl">
                            <table class="table table-sm table-borderless table-centered mb-0">
                                <thead>
                                    <tr>
                                        <th class="p-0">
                                        </th>
                                        <th class="p-0">
                                        </th>
                                        <th class="p-0">
                                        </th>
                                        <th class="p-0">
                                        </th>
                                        <th class="p-0">
                                        </th>
                                        <th class="p-0">
                                        </th>
                                        <th class="p-0">
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td class="p-0">
                                            <span>
                                                <p class="py-0 my-0"><strong>Fees Received And Other Revenues</strong>
                                                </p>
                                            </span>
                                        </td>
                                        <td class="p-0"></td>
                                        <td class="p-0"></td>
                                        <td class="p-0"></td>
                                        <td class="p-0"></td>
                                        <td class="p-0"></td>
                                        <td class="p-0 text-end"></td>
                                    </tr>
                                    <tr>
                                        <td class="p-0"></td>
                                        <td class="p-0"><span class="text-muted">DODO Discount</span></td>
                                        <td class="p-0"><span class="text-muted">Yearly</span></td>
                                        <td class="p-0"><span class="text-muted">Rate</span></td>
                                        <td class="p-0"></td>
                                        <td class="p-0"></td>
                                        <td class="p-0 text-end"><span class="text-muted">Total</span></td>
                                    </tr>
                                    <tr>
                                        <td class="p-0"></td>
                                        <td class="p-0"><span class="text-muted"><span *ngIf="localCurrencyISOCode">{{localCurrencyISOCode}}</span></span></td>
                                        <td class="p-0"><span class="text-muted"><span *ngIf="localCurrencyISOCode">{{localCurrencyISOCode}}</span></span></td>
                                        <td class="p-0"><span class="text-muted"></span></td>
                                        <td class="p-0"></td>
                                        <td class="p-0 text-end"><span class="text-muted"><span *ngIf="localCurrencyISOCode">{{localCurrencyISOCode}}</span></span></td>
                                        <td class="p-0 text-end"><span class="text-muted"><span *ngIf="localCurrencyISOCode">{{localCurrencyISOCode}}</span></span></td>
                                    </tr>
                                    <tr>
                                        <td class="p-0">
                                            <span>
                                                <p class="py-0 my-0"><strong>Fixed Fees</strong></p>
                                            </span>
                                        </td>
                                        <td class="p-0"></td>
                                        <td class="p-0"></td>
                                        <td class="p-0"></td>
                                        <td class="p-0"></td>
                                        <td class="p-0"></td>
                                        <td class="p-0 text-end">
                                            <span *ngIf="companyFeesFixedFeesTotal">
                                                {{companyFeesFixedFeesTotal | number : '1.2-2'}}
                                            </span>
                                            <span *ngIf="companyFeesFixedFeesTotal === 0">
                                                {{0 | number : '1.2-2'}}
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="p-0">
                                            <span class="ms-1">
                                                Fuels - Fixed Fees
                                            </span>
                                        </td>
                                        <td class="p-0">
                                            <span *ngIf="companyFeesFuelFixedFeesDodoDiscountGivenM3">
                                                {{companyFeesFuelFixedFeesDodoDiscountGivenM3 | number : '1.2-2'}}
                                            </span>
                                            <span *ngIf="companyFeesFuelFixedFeesDodoDiscountGivenM3 === 0">
                                                {{0 | number : '1.2-2'}}
                                            </span>
                                        </td>
                                        <td class="p-0">
                                            <span *ngIf="companyFeesFuelFixedFeesProductVolume">
                                                {{companyFeesFuelFixedFeesProductVolume | number : '1.2-2'}}
                                            </span>
                                            <span *ngIf="companyFeesFuelFixedFeesProductVolume === 0">
                                                {{0 | number : '1.2-2'}}
                                            </span>
                                        </td>
                                        <td class="p-0">
                                            <span *ngIf="companyFeesFuelFixedFeesRate">
                                                {{companyFeesFuelFixedFeesRate | number : '1.2-2'}} %
                                            </span>
                                            <span *ngIf="companyFeesFuelFixedFeesRate === 0">
                                                {{0 | number : '1.2-2'}} %
                                            </span>
                                        </td>
                                        <td class="p-0"></td>
                                        <td class="p-0 text-end">
                                            <span *ngIf="companyFeesFuelFixedFeesTotal">
                                                {{companyFeesFuelFixedFeesTotal | number : '1.2-2'}}
                                            </span>
                                            <span *ngIf="companyFeesFuelFixedFeesTotal === 0">
                                                {{0 | number : '1.2-2'}}
                                            </span>
                                        </td>
                                        <td class="p-0"></td>
                                    </tr>
                                    <tr>
                                        <td class="p-0">
                                            <span class="ms-1">
                                                Diversifications - Fixed Fees
                                            </span>
                                        </td>
                                        <td class="p-0"></td>
                                        <td class="p-0"></td>
                                        <td class="p-0"></td>
                                        <td class="p-0"></td>
                                        <td class="p-0 text-end">
                                            <span *ngIf="companyFeesDiversificationFixedFeesTotal">
                                                {{companyFeesDiversificationFixedFeesTotal | number : '1.2-2'}}
                                            </span>
                                            <span *ngIf="companyFeesDiversificationFixedFeesTotal === 0">
                                                {{0 | number : '1.2-2'}}
                                            </span>
                                        </td>
                                        <td class="p-0"></td>
                                    </tr>
                                    <tr *ngFor="let m of companyFeesDiversificationFixedFeesItemArray; let i = index;">
                                        <td class="p-0">
                                            <span class="ms-2" *ngIf="m.name">
                                                {{m.name}}
                                            </span>
                                        </td>
                                        <td class="p-0"></td>
                                        <td class="p-0"></td>
                                        <td class="p-0"></td>
                                        <td class="p-0">
                                            <span *ngIf="m.total">
                                                {{m.total | number : '1.2-2'}}
                                            </span>
                                            <span *ngIf="m.total === 0">
                                                {{0 | number : '1.2-2'}}
                                            </span>
                                        </td>
                                        <td class="p-0 text-end"></td>
                                        <td class="p-0"></td>
                                    </tr>
                                    <tr>
                                        <td class="p-0">
                                            <span>
                                                <p class="py-0 my-0"><strong>Variable Fees</strong></p>
                                            </span>
                                        </td>
                                        <td class="p-0"></td>
                                        <td class="p-0"></td>
                                        <td class="p-0"></td>
                                        <td class="p-0"></td>
                                        <td class="p-0"></td>
                                        <td class="p-0 text-end">
                                            <span *ngIf="companyFeesVariableFeesTotal">
                                                {{companyFeesVariableFeesTotal | number : '1.2-2'}}
                                            </span>
                                            <span *ngIf="companyFeesVariableFeesTotal === 0">
                                                {{0 | number : '1.2-2'}}
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="p-0">
                                            <span class="ms-1">
                                                Fuels - Variable Fees
                                            </span>
                                        </td>
                                        <td class="p-0"></td>
                                        <td class="p-0">
                                            <span *ngIf="companyFeesFuelVariableFeesYearly">
                                                {{companyFeesFuelVariableFeesYearly | number : '1.2-2'}}
                                            </span>
                                            <span *ngIf="companyFeesFuelVariableFeesYearly === 0">
                                                {{0 | number : '1.2-2'}}
                                            </span>
                                        </td>
                                        <td class="p-0">
                                            <span *ngIf="companyFeesFuelVariableFeesRate">
                                                {{companyFeesFuelVariableFeesRate | number : '1.2-2'}}
                                            </span>
                                            <span *ngIf="companyFeesFuelVariableFeesRate === 0">
                                                {{0 | number : '1.2-2'}}
                                            </span>
                                        </td>
                                        <td class="p-0"></td>
                                        <td class="p-0 text-end">
                                            <span *ngIf="companyFeesFuelVariableFeesTotal">
                                                {{companyFeesFuelVariableFeesTotal | number : '1.2-2'}}
                                            </span>
                                            <span *ngIf="companyFeesFuelVariableFeesTotal === 0">
                                                {{0 | number : '1.2-2'}}
                                            </span>
                                        </td>
                                        <td class="p-0"></td>
                                    </tr>
                                    <tr>
                                        <td class="p-0">
                                            <span class="ms-1">
                                                Diversifications - Variable Fees
                                            </span>
                                        </td>
                                        <td class="p-0"></td>
                                        <td class="p-0">
                                            <span *ngIf="companyFeesDiversificationVariableFeesYearly">
                                                {{companyFeesDiversificationVariableFeesYearly | number : '1.2-2'}}
                                            </span>
                                            <span *ngIf="companyFeesDiversificationVariableFeesYearly === 0">
                                                {{0 | number : '1.2-2'}}
                                            </span>
                                        </td>
                                        <td class="p-0">
                                            <span *ngIf="companyFeesDiversificationVariableFeesRate">
                                                {{companyFeesDiversificationVariableFeesRate | number : '1.2-2'}} %
                                            </span>
                                            <span *ngIf="companyFeesDiversificationVariableFeesRate === 0">
                                                {{0 | number : '1.2-2'}} %
                                            </span>
                                        </td>
                                        <td class="p-0"></td>
                                        <td class="p-0 text-end">
                                            <span *ngIf="companyFeesDiversificationVariableFeesTotal">
                                                {{companyFeesDiversificationVariableFeesTotal | number : '1.2-2'}}
                                            </span>
                                            <span *ngIf="companyFeesDiversificationVariableFeesTotal === 0">
                                                {{0 | number : '1.2-2'}}
                                            </span>
                                        </td>
                                        <td class="p-0"></td>
                                    </tr>
                                    <tr>
                                        <td class="p-0">
                                            <span class="ms-1">
                                                Card Commission - Fees
                                            </span>
                                        </td>
                                        <td class="p-0"></td>
                                        <td class="p-0">
                                            <span *ngIf="companyFeesCardCommissionYearly">
                                                {{companyFeesCardCommissionYearly | number : '1.2-2'}}
                                            </span>
                                            <span *ngIf="companyFeesCardCommissionYearly === 0">
                                                {{0 | number : '1.2-2'}}
                                            </span>
                                        </td>
                                        <td class="p-0">
                                            <span *ngIf="companyFeesCardCommissionRate">
                                                {{companyFeesCardCommissionRate | number : '1.2-2'}} %
                                            </span>
                                            <span *ngIf="companyFeesCardCommissionRate === 0">
                                                {{0 | number : '1.2-2'}} %
                                            </span>
                                        </td>
                                        <td class="p-0"></td>
                                        <td class="p-0 text-end">
                                            <span *ngIf="companyFeesCardCommissionTotal">
                                                {{companyFeesCardCommissionTotal | number : '1.2-2'}}
                                            </span>
                                            <span *ngIf="companyFeesCardCommissionTotal === 0">
                                                {{0 | number : '1.2-2'}}
                                            </span>
                                        </td>
                                        <td class="p-0"></td>
                                    </tr>
                                    <tr>
                                        <td class="p-0">
                                            <span>
                                                <p class="py-0 my-0"><strong>Total</strong></p>
                                            </span>
                                        </td>
                                        <td class="p-0"></td>
                                        <td class="p-0"></td>
                                        <td class="p-0"></td>
                                        <td class="p-0"></td>
                                        <td class="p-0"></td>
                                        <td class="p-0 text-end">
                                            <span *ngIf="companyFeesTotal">
                                                {{companyFeesTotal | number : '1.2-2'}}
                                            </span>
                                            <span *ngIf="companyFeesTotal === 0">
                                                {{0 | number : '1.2-2'}}
                                            </span>
                                        </td>
                                    </tr>
                                </tbody>

                            </table>
                        </div>

                    </div>
                </div>
            </div>
            <div class="card">
                <div class="card-header mb-0">
                    <h5>
                        <span>
                            Yearly Escalation
                        </span>
                    </h5>
                </div>
                <div class="card-body">
                    <div class="card-body">
                        <div class="board">
                            <table class="table table-sm table-bordered table-centered mb-0">
                                <thead>
                                    <tr>
                                        <th style="min-width:50px">

                                        </th>
                                        <th>
                                            Y - 0
                                        </th>
                                        <th>
                                            Y - 1
                                        </th>
                                        <th>
                                            Y - 2
                                        </th>
                                        <th>
                                            Y - 3
                                        </th>
                                        <th>
                                            Y - 4
                                        </th>
                                        <th>
                                            Y - 5
                                        </th>
                                        <th>
                                            Y - 6
                                        </th>
                                        <th>
                                            Y - 7
                                        </th>
                                        <th>
                                            Y - 8
                                        </th>
                                        <th>
                                            Y - 9
                                        </th>
                                        <th>
                                            Y - 10
                                        </th>
                                        <th>
                                            Y - 11
                                        </th>
                                        <th>
                                            Y - 12
                                        </th>
                                        <th>
                                            Y - 13
                                        </th>
                                        <th>
                                            Y - 14
                                        </th>
                                        <th>
                                            Y - 15
                                        </th>
                                    </tr>
                                </thead>
                                <tbody class="table-group-divider">
                                    <tr>
                                        <td class="p-0">
                                            <span>
                                                Variable Fees - Fuel
                                            </span>
                                        </td>
                                        <td class="py-0"
                                            *ngFor="let m of companyFeesReceivedVariableFeesFuelArray;let i = index;">
                                            <span *ngIf="!(m===0)">{{m| number:'1.2-2'}}</span>
                                            <span *ngIf="m===0">-</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="p-0">
                                            <span>
                                                Variable Fees - Diversification
                                            </span>
                                        </td>
                                        <td class="py-0"
                                            *ngFor="let m of companyFeesReceivedVariableFeesDiversificationArray;let i = index;">
                                            <span *ngIf="!(m===0)">{{m| number:'1.2-2'}}</span>
                                            <span *ngIf="m===0">-</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="p-0">
                                            <span>
                                                Fixed Fees - Fuel
                                            </span>
                                        </td>
                                        <td class="py-0"
                                            *ngFor="let m of companyFeesReceivedFixedFeesFuelArray;let i = index;">
                                            <span *ngIf="!(m===0)">{{m| number:'1.2-2'}}</span>
                                            <span *ngIf="m===0">-</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="p-0">
                                            <span>
                                                Fixed Fees - Diversification
                                            </span>
                                        </td>
                                        <td class="py-0"
                                            *ngFor="let m of companyFeesReceivedFixedFeesDiversificationArray;let i = index;">
                                            <span *ngIf="!(m===0)">{{m| number:'1.2-2'}}</span>
                                            <span *ngIf="m===0">-</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="p-0">
                                            <span>
                                                Variable Fees - Card Commission
                                            </span>
                                        </td>
                                        <td class="py-0"
                                            *ngFor="let m of companyFeesReceivedVariableFeesCardCommissionArray;let i = index;">
                                            <span *ngIf="!(m===0)">{{m| number:'1.2-2'}}</span>
                                            <span *ngIf="m===0">-</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="p-0">
                                            <span>
                                                <p class="py-0 my-0"><strong>Fees Received</strong></p>
                                            </span>
                                        </td>
                                        <td class="py-0" *ngFor="let m of companyFeesReceivedTotalArray;let i = index;">
                                            <span *ngIf="!(m===0)">{{m| number:'1.2-2'}}</span>
                                            <span *ngIf="m===0">-</span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card-footer" *ngIf="!showProjectOtherRevenueSection">
            <div class="row">
                <div class="col-sm-6"></div>
                <div class="col-sm-6">
                    <div class="d-grid">
                        <button class="btn btn-sm btn-outline-secondary"
                            (click)="showProjectOtherRevenueSectionButton(true)" type="button"><i
                                class=""></i>View</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="card-footer" *ngIf="showProjectOtherRevenueSection">
            <div class="row" *ngIf="showProjectOtherRevenueEdit">
                <div class="col-sm-6">
                    <div class="d-grid">
                        <button class="btn btn-sm btn-outline-secondary"
                            (click)="showProjectOtherRevenueEditButton(false)" type="button"><i class=""></i>Simple
                            View</button>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="d-grid">
                        <button class="btn btn-sm btn-outline-success" (click)="submitProjectOtherRevenue()"
                            type="button" *ngIf="!submitInProgress && !updateInProgress"><i class=""></i>Submit</button>
                        <button class="btn btn-sm btn-outline-primary" type="button" disabled
                            *ngIf="submitInProgress || updateInProgress">
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            
                        </button>
                    </div>
                </div>
            </div>
            <div class="row" *ngIf="!showProjectOtherRevenueEdit">
                <div class="col-sm-6">
                    <div class="d-grid">
                        <button class="btn btn-sm btn-outline-secondary"
                            (click)="showProjectOtherRevenueEditButton(true)" type="button"><i class=""></i>Edit
                            View</button>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="d-grid">
                        <button class="btn btn-sm btn-outline-success" (click)="submitProjectOtherRevenue()"
                            type="button" *ngIf="!submitInProgress && !updateInProgress"><i class=""></i>Submit</button>
                        <button class="btn btn-sm btn-outline-primary" type="button" disabled
                            *ngIf="submitInProgress || updateInProgress">
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Step 7. Operational Expenses -->
    <div id="stepSeven" class="border border-secondary card stepSeven">
        <div class="card-header">
            <div class="table-responsive-sm" *ngIf="projectArray">
                <table class="table table-sm table-centered table-borderless mb-0" *ngIf="projectArray[0]">
                    <tbody>
                        <tr>
                            <td class="p-0" style="width:60%">
                                Step 7. Operational Expenses - Galana
                            </td>
                            <td class="p-0" style="width:30%">
                                <p class="mb-0">
                                    Completion
                                    <ngb-progressbar [showValue]="true" type="info" [striped]="true" [animated]="true"
                                        [value]="projectCompanyOperatingExpensesProgressPercentage"></ngb-progressbar>
                                </p>
                            </td>
                            <td class="p-0 text-center text-primary" style="width:10%">
                                <i class="mdi mdi-help-circle-outline mdi-24px"></i>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div> <!-- end table-responsive-->
        </div>
        <div class="card-body" *ngIf="showProjectCompanyOperatingExpensesSection">
            <div class="card" *ngIf="showProjectCompanyOperatingExpensesEdit">
                <div class="card">
                    <div class="card-header mb-0">
                        <h5>
                            <span>
                                Operating Expenses
                            </span>
                        </h5>
                    </div>
                    <div class="card-body">

                        <form class="form-horizontal" [formGroup]="companyOperatingExpensesForm">
                            <div class="row">

                                <div class="col-md-12">
                                    <div class="row mb-1">
                                        <span>
                                            <p class="py-0 my-0"><strong>Operating Expenses from Investments</strong>
                                            </p>
                                        </span>
                                    </div>
                                    <div class="row mb-3">
                                        <label for="operatingExpensesFromInvestments"
                                            class="col-6 col-form-label col-form-label-sm">
                                            <span class="ps-1">Operating Expenses (from investments)</span>
                                        </label>
                                        <div class="col-6">
                                            <div class="input-group input-group-sm input-group-merge">
                                                <input class="form-control form-control-sm" type="number" min="0"
                                                    id="operatingExpensesFromInvestments"
                                                    formControlName="operatingExpensesFromInvestments"
                                                    placeholder="1,000,000"
                                                    [ngClass]="{'is-invalid': (formSubmitted && companyOperatingExpensesFormValues['operatingExpensesFromInvestments'].invalid)||(companyOperatingExpensesFormValues['operatingExpensesFromInvestments'].touched && companyOperatingExpensesFormValues['operatingExpensesFromInvestments'].invalid)}">
                                                <div class="input-group-text">
                                                    <span><span *ngIf="localCurrencyISOCode">{{localCurrencyISOCode}}</span></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mb-1">
                                        <span>
                                            <p class="py-0 my-0"><strong>Overhead Costs</strong></p>
                                        </span>
                                    </div>
                                    <div class="row mb-1">
                                        <label for="directFixedCosts" class="col-6 col-form-label col-form-label-sm">
                                            <span class="ps-1">Direct Fixed Costs</span>
                                        </label>
                                        <div class="col-6">
                                            <div class="input-group input-group-sm input-group-merge">
                                                <input class="form-control form-control-sm" type="number" min="0"
                                                    id="directFixedCosts" formControlName="directFixedCosts"
                                                    placeholder="1,000,000"
                                                    [ngClass]="{'is-invalid': (formSubmitted && companyOperatingExpensesFormValues['directFixedCosts'].invalid)||(companyOperatingExpensesFormValues['directFixedCosts'].touched && companyOperatingExpensesFormValues['directFixedCosts'].invalid)}">
                                                <div class="input-group-text">
                                                    <span><span *ngIf="localCurrencyISOCode">{{localCurrencyISOCode}}</span></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mb-1">
                                        <label for="networkChannelFixedCostsAllStations"
                                            class="col-6 col-form-label col-form-label-sm">
                                            <span class="ps-1">Network Channel Fixed Costs All Stations</span>
                                        </label>
                                        <div class="col-6">
                                            <div class="input-group input-group-sm input-group-merge">
                                                <input class="form-control form-control-sm" type="number" min="0"
                                                    id="networkChannelFixedCostsAllStations"
                                                    formControlName="networkChannelFixedCostsAllStations"
                                                    placeholder="1,000,000"
                                                    [ngClass]="{'is-invalid': (formSubmitted && companyOperatingExpensesFormValues['networkChannelFixedCostsAllStations'].invalid)||(companyOperatingExpensesFormValues['networkChannelFixedCostsAllStations'].touched && companyOperatingExpensesFormValues['networkChannelFixedCostsAllStations'].invalid)}">
                                                <div class="input-group-text">
                                                    <span><span *ngIf="localCurrencyISOCode">{{localCurrencyISOCode}}</span></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mb-1">
                                        <label for="networkChannelNumberOfStations"
                                            class="col-6 col-form-label col-form-label-sm">
                                            <span class="ps-1">Number of Stations in the Channel</span>
                                        </label>
                                        <div class="col-6">
                                            <div class="input-group input-group-sm input-group-merge">
                                                <input class="form-control form-control-sm" type="number" min="0"
                                                    id="networkChannelNumberOfStations"
                                                    formControlName="networkChannelNumberOfStations"
                                                    placeholder="1,000,000"
                                                    [ngClass]="{'is-invalid': (formSubmitted && companyOperatingExpensesFormValues['networkChannelNumberOfStations'].invalid)||(companyOperatingExpensesFormValues['networkChannelNumberOfStations'].touched && companyOperatingExpensesFormValues['networkChannelNumberOfStations'].invalid)}">
                                                <div class="input-group-text">
                                                    <span><span *ngIf="localCurrencyISOCode">{{localCurrencyISOCode}}</span></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mb-3">
                                        <label for="networkChannelFixedCostsPerStation"
                                            class="col-6 col-form-label col-form-label-sm">
                                            <span class="ps-1">Network Channel Fixed Costs - Per Station</span>
                                        </label>
                                        <div class="col-6">
                                            <div class="input-group input-group-sm input-group-merge">
                                                <input class="form-control form-control-sm" type="number" min="0"
                                                    id="networkChannelFixedCostsPerStation"
                                                    formControlName="networkChannelFixedCostsPerStation"
                                                    placeholder="1,000,000"
                                                    [ngClass]="{'is-invalid': (formSubmitted && companyOperatingExpensesFormValues['networkChannelFixedCostsPerStation'].invalid)||(companyOperatingExpensesFormValues['networkChannelFixedCostsPerStation'].touched && companyOperatingExpensesFormValues['networkChannelFixedCostsPerStation'].invalid)}">
                                                <div class="input-group-text">
                                                    <span><span *ngIf="localCurrencyISOCode">{{localCurrencyISOCode}}</span></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-6 text-end">
                                            <span>
                                                <p class="py-0"><strong>Total</strong></p>
                                            </span>
                                        </div>
                                        <div class="col-6">
                                            <span *ngIf="companyOperatingExpensesOverheadCosts">
                                                {{companyOperatingExpensesOverheadCosts | number : '1.2-2'}}
                                            </span>
                                            <span *ngIf="companyOperatingExpensesOverheadCosts === 0">
                                                {{0 | number : '1.2-2'}} <span *ngIf="localCurrencyISOCode">{{localCurrencyISOCode}}</span>
                                            </span>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="card">
                    <div class="card-header mb-0">
                        <h5>
                            <span>
                                Yearly Escalation
                            </span>
                        </h5>
                    </div>
                    <div class="card-body">
                        <div class="card-body">
                            <div class="board">
                                <table class="table table-sm table-bordered table-centered mb-0">
                                    <thead>
                                        <tr>
                                            <th style="min-width:50px">

                                            </th>
                                            <th>
                                                Y - 0
                                            </th>
                                            <th>
                                                Y - 1
                                            </th>
                                            <th>
                                                Y - 2
                                            </th>
                                            <th>
                                                Y - 3
                                            </th>
                                            <th>
                                                Y - 4
                                            </th>
                                            <th>
                                                Y - 5
                                            </th>
                                            <th>
                                                Y - 6
                                            </th>
                                            <th>
                                                Y - 7
                                            </th>
                                            <th>
                                                Y - 8
                                            </th>
                                            <th>
                                                Y - 9
                                            </th>
                                            <th>
                                                Y - 10
                                            </th>
                                            <th>
                                                Y - 11
                                            </th>
                                            <th>
                                                Y - 12
                                            </th>
                                            <th>
                                                Y - 13
                                            </th>
                                            <th>
                                                Y - 14
                                            </th>
                                            <th>
                                                Y - 15
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody class="table-group-divider">
                                        <tr>
                                            <td class="p-0 ">
                                                <span>
                                                    Inflation Rate
                                                </span>
                                            </td>
                                            <td class="py-0" *ngFor="let m of inflationRateArray;let i = index;">
                                                <span *ngIf="!(m===0)">{{m| number:'1.2-2'}}%</span>
                                                <span *ngIf="m===0">-</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="p-0 ">
                                                <span>
                                                    Inflation Factor
                                                </span>
                                            </td>
                                            <td class="my-0 py-0" *ngFor="let m of inflationFactorArray;let i = index;">
                                                <span *ngIf="!(m===0)">{{m| number:'1.2-2'}}</span>
                                                <span *ngIf="m===0">-</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="p-0">
                                                <span>
                                                    Operating Expenses from Investments
                                                </span>
                                            </td>
                                            <td class="py-0"
                                                *ngFor="let m of companyOperatingExpensesOperatingExpensesFromInvestmentsArray;let i = index;">
                                                <span *ngIf="!(m===0)">({{m| number:'1.2-2'}})</span>
                                                <span *ngIf="m===0">-</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="p-0">
                                                <span>
                                                    Overhead Costs
                                                </span>
                                            </td>
                                            <td class="py-0"
                                                *ngFor="let m of companyOperatingExpensesOverheadCostsArray;let i = index;">
                                                <span *ngIf="!(m===0)">({{m| number:'1.2-2'}})</span>
                                                <span *ngIf="m===0">-</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="p-0">
                                                <span>
                                                    <p class="py-0 my-0"><strong>Operational Expenses</strong></p>
                                                </span>
                                            </td>
                                            <td class="py-0"
                                                *ngFor="let m of companyOperatingExpensesTotalArray;let i = index;">
                                                <span *ngIf="!(m===0)">({{m| number:'1.2-2'}})</span>
                                                <span *ngIf="m===0">-</span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card" *ngIf="!showProjectCompanyOperatingExpensesEdit">
                <div class="card">
                    <div class="card-header mb-0">
                        <h5>
                            <span>
                                Operating Expenses
                            </span>
                        </h5>
                    </div>
                    <div class="card-body">

                        <form class="form-horizontal" [formGroup]="companyOperatingExpensesForm">
                            <div class="row">

                                <div class="col-md-12">
                                    <div class="row mb-1">
                                        <span>
                                            <p class="py-0 my-0"><strong>Operating Expenses from Investments</strong>
                                            </p>
                                        </span>
                                    </div>
                                    <div class="row mb-3">
                                        <label for="companyOperatingExpensesOperatingExpensesFromInvestments"
                                            class="col-6 col-form-label col-form-label-sm">
                                            <span class="ps-1">Operating Expenses (from investments)</span>
                                        </label>
                                        <div class="col-6">
                                            <span *ngIf="companyOperatingExpensesOperatingExpensesFromInvestments">
                                                {{companyOperatingExpensesOperatingExpensesFromInvestments | number :
                                                '1.2-2'}}
                                            </span>
                                            <span
                                                *ngIf="companyOperatingExpensesOperatingExpensesFromInvestments === 0">
                                                {{0 | number : '1.2-2'}}
                                            </span>
                                        </div>
                                    </div>
                                    <div class="row mb-1">
                                        <span>
                                            <p class="py-0 my-0"><strong>Overhead Costs</strong></p>
                                        </span>
                                    </div>
                                    <div class="row mb-1">
                                        <label for="companyOperatingExpensesDirectFixedCosts"
                                            class="col-6 col-form-label col-form-label-sm">
                                            <span class="ps-1">Direct Fixed Costs</span>
                                        </label>
                                        <div class="col-6">
                                            <span *ngIf="companyOperatingExpensesDirectFixedCosts">
                                                {{companyOperatingExpensesDirectFixedCosts | number : '1.2-2'}}
                                            </span>
                                            <span *ngIf="companyOperatingExpensesDirectFixedCosts === 0">
                                                {{0 | number : '1.2-2'}}
                                            </span>
                                        </div>
                                    </div>
                                    <div class="row mb-1">
                                        <label for="companyOperatingExpensesNetworkChannelFixedCostsAllStations"
                                            class="col-6 col-form-label col-form-label-sm">
                                            <span class="ps-1">Network Channel Fixed Costs All Stations</span>
                                        </label>
                                        <div class="col-6">
                                            <span *ngIf="companyOperatingExpensesNetworkChannelFixedCostsAllStations">
                                                {{companyOperatingExpensesNetworkChannelFixedCostsAllStations | number :
                                                '1.2-2'}}
                                            </span>
                                            <span
                                                *ngIf="companyOperatingExpensesNetworkChannelFixedCostsAllStations === 0">
                                                {{0 | number : '1.2-2'}}
                                            </span>
                                        </div>
                                    </div>
                                    <div class="row mb-1">
                                        <label for="companyOperatingExpensesNetworkChannelNumberOfStations"
                                            class="col-6 col-form-label col-form-label-sm">
                                            <span class="ps-1">Number of Stations in the Channel</span>
                                        </label>
                                        <div class="col-6">
                                            <span *ngIf="companyOperatingExpensesNetworkChannelNumberOfStations">
                                                {{companyOperatingExpensesNetworkChannelNumberOfStations | number :
                                                '1.0-0'}}
                                            </span>
                                            <span *ngIf="companyOperatingExpensesNetworkChannelNumberOfStations === 0">
                                                {{0 | number : '1.0-0'}}
                                            </span>
                                        </div>
                                    </div>
                                    <div class="row mb-3">
                                        <label for="companyOperatingExpensesNetworkChannelFixedCostsPerStation"
                                            class="col-6 col-form-label col-form-label-sm">
                                            <span class="ps-1">Network Channel Fixed Costs - Per Station</span>
                                        </label>
                                        <div class="col-6">
                                            <span *ngIf="companyOperatingExpensesNetworkChannelFixedCostsPerStation">
                                                {{companyOperatingExpensesNetworkChannelFixedCostsPerStation | number :
                                                '1.2-2'}}
                                            </span>
                                            <span
                                                *ngIf="companyOperatingExpensesNetworkChannelFixedCostsPerStation === 0">
                                                {{0 | number : '1.2-2'}}
                                            </span>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-6 text-end">
                                            <span>
                                                <p class="py-0"><strong>Total</strong></p>
                                            </span>
                                        </div>
                                        <div class="col-6">
                                            <span *ngIf="companyOperatingExpensesOverheadCosts">
                                                {{companyOperatingExpensesOverheadCosts | number : '1.2-2'}}
                                            </span>
                                            <span *ngIf="companyOperatingExpensesOverheadCosts === 0">
                                                {{0 | number : '1.2-2'}} <span *ngIf="localCurrencyISOCode">{{localCurrencyISOCode}}</span>
                                            </span>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="card-footer" *ngIf="showProjectCompanyOperatingExpensesEdit">
                        <div class="row">
                            <div class="col-sm-12">
                                <div class="d-grid">
                                    <button class="btn btn-sm btn-outline-success"
                                        (click)="updateProjectCompanyOperatingExpenses(companyOperatingExpensesForm.value)"
                                        type="button" *ngIf="!submitInProgress && !updateInProgress"><i
                                            class=""></i>Update</button>
                                    <button class="btn btn-sm btn-outline-primary" type="button" disabled
                                        *ngIf="submitInProgress || updateInProgress">
                                        <span class="spinner-border spinner-border-sm" role="status"
                                            aria-hidden="true"></span>
                                        
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card">
                    <div class="card-header mb-0">
                        <h5>
                            <span>
                                Yearly Escalation
                            </span>
                        </h5>
                    </div>
                    <div class="card-body">
                        <div class="card-body">
                            <div class="board">
                                <table class="table table-sm table-bordered table-centered mb-0">
                                    <thead>
                                        <tr>
                                            <th style="min-width:50px">

                                            </th>
                                            <th>
                                                Y - 0
                                            </th>
                                            <th>
                                                Y - 1
                                            </th>
                                            <th>
                                                Y - 2
                                            </th>
                                            <th>
                                                Y - 3
                                            </th>
                                            <th>
                                                Y - 4
                                            </th>
                                            <th>
                                                Y - 5
                                            </th>
                                            <th>
                                                Y - 6
                                            </th>
                                            <th>
                                                Y - 7
                                            </th>
                                            <th>
                                                Y - 8
                                            </th>
                                            <th>
                                                Y - 9
                                            </th>
                                            <th>
                                                Y - 10
                                            </th>
                                            <th>
                                                Y - 11
                                            </th>
                                            <th>
                                                Y - 12
                                            </th>
                                            <th>
                                                Y - 13
                                            </th>
                                            <th>
                                                Y - 14
                                            </th>
                                            <th>
                                                Y - 15
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody class="table-group-divider">
                                        <tr>
                                            <td class="p-0 ">
                                                <span>
                                                    Inflation Rate
                                                </span>
                                            </td>
                                            <td class="py-0" *ngFor="let m of inflationRateArray;let i = index;">
                                                <span *ngIf="!(m===0)">{{m*100| number:'1.2-2'}}%</span>
                                                <span *ngIf="m===0">-</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="p-0 ">
                                                <span>
                                                    Inflation Factor
                                                </span>
                                            </td>
                                            <td class="my-0 py-0" *ngFor="let m of inflationFactorArray;let i = index;">
                                                <span *ngIf="!(m===0)">{{m| number:'1.2-2'}}</span>
                                                <span *ngIf="m===0">-</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="p-0">
                                                <span>
                                                    Operating Expenses from Investments
                                                </span>
                                            </td>
                                            <td class="py-0"
                                                *ngFor="let m of companyOperatingExpensesOperatingExpensesFromInvestmentsArray;let i = index;">
                                                <span *ngIf="!(m===0)">({{m| number:'1.2-2'}})</span>
                                                <span *ngIf="m===0">-</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="p-0">
                                                <span>
                                                    Overhead Costs
                                                </span>
                                            </td>
                                            <td class="py-0"
                                                *ngFor="let m of companyOperatingExpensesOverheadCostsArray;let i = index;">
                                                <span *ngIf="!(m===0)">({{m| number:'1.2-2'}})</span>
                                                <span *ngIf="m===0">-</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="p-0">
                                                <span>
                                                    <p class="py-0 my-0"><strong>Operational Expenses</strong></p>
                                                </span>
                                            </td>
                                            <td class="py-0"
                                                *ngFor="let m of companyOperatingExpensesTotalArray;let i = index;">
                                                <span *ngIf="!(m===0)">({{m| number:'1.2-2'}})</span>
                                                <span *ngIf="m===0">-</span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card-footer" *ngIf="!showProjectCompanyOperatingExpensesSection">
            <div class="row">
                <div class="col-sm-6"></div>
                <div class="col-sm-6">
                    <div class="d-grid">
                        <button class="btn btn-sm btn-outline-secondary"
                            (click)="showProjectCompanyOperatingExpensesSectionButton(true)" type="button"><i
                                class=""></i>View</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="card-footer" *ngIf="showProjectCompanyOperatingExpensesSection">
            <div class="row" *ngIf="showProjectCompanyOperatingExpensesEdit">
                <div class="col-sm-6">
                    <div class="d-grid">
                        <button class="btn btn-sm btn-outline-secondary"
                            (click)="showProjectCompanyOperatingExpensesEditButton(false)" type="button"><i
                                class=""></i>Simple
                            View</button>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="d-grid">
                        <button class="btn btn-sm btn-outline-success" (click)="submitProjectCompanyOperatingExpenses()"
                            type="button" *ngIf="!submitInProgress && !updateInProgress"><i class=""></i>Submit</button>
                        <button class="btn btn-sm btn-outline-primary" type="button" disabled
                            *ngIf="submitInProgress || updateInProgress">
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            
                        </button>
                    </div>
                </div>
            </div>
            <div class="row" *ngIf="!showProjectCompanyOperatingExpensesEdit">
                <div class="col-sm-6">
                    <div class="d-grid">
                        <button class="btn btn-sm btn-outline-secondary"
                            (click)="showProjectCompanyOperatingExpensesEditButton(true)" type="button"><i
                                class=""></i>Edit
                            View</button>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="d-grid">
                        <button class="btn btn-sm btn-outline-success" (click)="submitProjectCompanyOperatingExpenses()"
                            type="button" *ngIf="!submitInProgress && !updateInProgress"><i class=""></i>Submit</button>
                        <button class="btn btn-sm btn-outline-primary" type="button" disabled
                            *ngIf="submitInProgress || updateInProgress">
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Step 8. Taxes -->
    <div id="stepEight" class="border border-secondary card stepEight">
        <div class="card-header">
            <div class="table-responsive-sm" *ngIf="projectArray">
                <table class="table table-sm table-centered table-borderless mb-0" *ngIf="projectArray[0]">
                    <tbody>
                        <tr>
                            <td class="p-0" style="width:60%">
                                Step 8. Taxes - Galana
                            </td>
                            <td class="p-0" style="width:30%">
                                <p class="mb-0">
                                    Completion
                                    <ngb-progressbar [showValue]="true" type="info" [striped]="true" [animated]="true"
                                        [value]="projectTaxesProgressPercentage"></ngb-progressbar>
                                </p>
                            </td>
                            <td class="p-0 text-center text-primary" style="width:10%">
                                <i class="mdi mdi-help-circle-outline mdi-24px"></i>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div> <!-- end table-responsive-->
        </div>
        <div class="card-body" *ngIf="showProjectTaxesSection">
            <div class="card">
                <div class="card">
                    <div class="card-header mb-0">
                        <h5>
                            <span>
                                Taxes
                            </span>
                        </h5>
                    </div>
                    <div class="card-body" name="company-operating-expenses-form"
                        [formGroup]="companyOperatingExpensesForm">
                        <table class="table table-sm table-borderless table-centered mb-0">
                            <thead>
                                <tr>
                                    <th class="p-0">
                                    </th>
                                    <th class="p-0">
                                    </th>
                                    <th class="p-0">
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr class="p-3">
                                    <td class="p-0 text-start">
                                        <span>
                                            Corporate Tax Rate (On Profit)
                                        </span>
                                    </td>
                                    <td class="p-0 text-start">
                                        <span>
                                            :
                                        </span>
                                    </td>
                                    <td class="p-0 text-start">
                                        <span *ngIf="companyTaxesRate">
                                            {{companyTaxesRate | number : '1.2-2'}} %
                                        </span>
                                        <span *ngIf="companyTaxesRate === 0">
                                            {{0 | number : '1.2-2'}} %
                                        </span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="card">
                    <div class="card-header mb-0">
                        <h5>
                            <span>
                                Yearly Escalation
                            </span>
                        </h5>
                    </div>
                    <div class="card-body">
                        <div class="card-body">
                            <div class="board">
                                <table class="table table-sm table-bordered table-centered mb-0">
                                    <thead>
                                        <tr>
                                            <th style="min-width:50px">

                                            </th>
                                            <th>
                                                Y - 0
                                            </th>
                                            <th>
                                                Y - 1
                                            </th>
                                            <th>
                                                Y - 2
                                            </th>
                                            <th>
                                                Y - 3
                                            </th>
                                            <th>
                                                Y - 4
                                            </th>
                                            <th>
                                                Y - 5
                                            </th>
                                            <th>
                                                Y - 6
                                            </th>
                                            <th>
                                                Y - 7
                                            </th>
                                            <th>
                                                Y - 8
                                            </th>
                                            <th>
                                                Y - 9
                                            </th>
                                            <th>
                                                Y - 10
                                            </th>
                                            <th>
                                                Y - 11
                                            </th>
                                            <th>
                                                Y - 12
                                            </th>
                                            <th>
                                                Y - 13
                                            </th>
                                            <th>
                                                Y - 14
                                            </th>
                                            <th>
                                                Y - 15
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody class="table-group-divider">
                                        <tr>
                                            <td class="p-0 px-1">
                                                <span>
                                                    Tax Rate
                                                </span>
                                            </td>
                                            <td class="px-1 py-0"
                                                *ngFor="let m of companyTaxesYearlyEscalationRateArray;let i = index;">
                                                <span *ngIf="!(m===0)">{{m| number:'1.2-2'}}%</span>
                                                <span *ngIf="m===0">-</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="p-0 px-1">
                                                <span>
                                                    EBITDA
                                                </span>
                                            </td>
                                            <td class="px-1 my-0 py-0"
                                                *ngFor="let m of companySummaryEBITDAArray;let i = index;">
                                                <span *ngIf="!(m===0)">{{m| number:'1.2-2'}}</span>
                                                <span *ngIf="m===0">-</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="p-0 px-1">
                                                <span>
                                                    Amortization Excluding Goodwill
                                                </span>
                                            </td>
                                            <td class="px-1 py-0"
                                                *ngFor="let m of totalAmortizationArray;let i = index;">
                                                <span *ngIf="!(m===0)">({{m| number:'1.2-2'}})</span>
                                                <span *ngIf="m===0">-</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="p-0 px-1">
                                                <span>
                                                    Amortization Goodwill
                                                </span>
                                            </td>
                                            <td class="px-1 py-0"
                                                *ngFor="let m of companyTaxesYearlyEscalationAmortizationGoodwill;let i = index;">
                                                <span *ngIf="!(m===0)">({{m| number:'1.2-2'}})</span>
                                                <span *ngIf="m===0">-</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="p-0 px-1">
                                                <span>
                                                    Overhead Costs
                                                </span>
                                            </td>
                                            <td class="px-1 py-0"
                                                *ngFor="let m of companyTaxesYearlyEscalationExpensesOverheadCosts;let i = index;">
                                                <span *ngIf="!(m===0)">({{m| number:'1.2-2'}})</span>
                                                <span *ngIf="m===0">-</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="p-0 px-1">
                                                <span>
                                                    Non Refunded Deposits
                                                </span>
                                            </td>
                                            <td class="px-1 py-0"
                                                *ngFor="let m of companyTaxesYearlyEscalationNonRefundedDeposits;let i = index;">
                                                <span *ngIf="!(m===0)">({{m| number:'1.2-2'}})</span>
                                                <span *ngIf="m===0">-</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="p-0 px-1">
                                                <span>
                                                    Financial Interest Paid
                                                </span>
                                            </td>
                                            <td class="px-1 py-0"
                                                *ngFor="let m of companyTaxesYearlyEscalationFinancingInterestPaidArray;let i = index;">
                                                <span *ngIf="!(m===0)">({{m| number:'1.2-2'}})</span>
                                                <span *ngIf="m===0">-</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="p-0 px-1">
                                                <span>
                                                    Taxable Amount
                                                </span>
                                            </td>
                                            <td class="px-1 py-0"
                                                *ngFor="let m of companyTaxesYearlyEscalationTaxableAmount;let i = index;">
                                                <span *ngIf="!(m===0)">({{m| number:'1.2-2'}})</span>
                                                <span *ngIf="m===0">-</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="p-0 px-1">
                                                <span>
                                                    <p class="py-0"><strong>Tax Amount</strong></p>
                                                </span>
                                            </td>
                                            <td class="px-1 py-0"
                                                *ngFor="let m of companySummaryTaxesArray;let i = index;">
                                                <span *ngIf="!(m===0)">({{m| number:'1.2-2'}})</span>
                                                <span *ngIf="m===0">-</span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <div class="card-footer" *ngIf="!showProjectTaxesSection">
            <div class="row">
                <div class="col-sm-6"></div>
                <div class="col-sm-6">
                    <div class="d-grid">
                        <button class="btn btn-sm btn-outline-secondary" (click)="showProjectTaxesSectionButton(true)"
                            type="button"><i class=""></i>View</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="card-footer" *ngIf="showProjectTaxesSection">
            <div class="row">
                <div class="col-sm-6">
                </div>
                <div class="col-sm-6">
                    <div class="d-grid">
                        <button class="btn btn-sm btn-outline-success" (click)="submitProjectTaxes()" type="button"
                            *ngIf="!submitInProgress && !updateInProgress"><i class=""></i>Submit</button>
                        <button class="btn btn-sm btn-outline-primary" type="button" disabled
                            *ngIf="submitInProgress || updateInProgress">
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Step 9. Financing -->
    <div id="stepNine" class="border border-secondary card stepNine">
        <div class="card-header">
            <div class="table-responsive-sm" *ngIf="projectArray">
                <table class="table table-sm table-centered table-borderless mb-0" *ngIf="projectArray[0]">
                    <tbody>
                        <tr>
                            <td class="p-0" style="width:60%">
                                Step 9. Financing - Galana
                            </td>
                            <td class="p-0" style="width:30%">
                                <p class="mb-0">
                                    Completion
                                    <ngb-progressbar [showValue]="true" type="info" [striped]="true" [animated]="true"
                                        [value]="projectFinancingProgressPercentage"></ngb-progressbar>
                                </p>
                            </td>
                            <td class="p-0 text-center text-primary" style="width:10%">
                                <i class="mdi mdi-help-circle-outline mdi-24px"></i>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="card-body" *ngIf="showProjectFinancingSection">
            <div class="card">
                <div class="card" *ngIf="!showProjectFinancingEdit">
                    <div class="card-header">
                        <span>Loan to Dealer</span>
                    </div>
                    <div class="card-body">
                        <form class="form-horizontal">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="row mb-1">
                                        <div class="col-6">
                                            <span>Loan Amount</span>
                                        </div>
                                        <div class="col-6">
                                            <span *ngIf="financingLoanAmount">
                                                :{{financingLoanAmount | number : '1.0-0'}} <span *ngIf="localCurrencyISOCode">{{localCurrencyISOCode}}</span>
                                            </span>
                                            <span *ngIf="financingLoanAmount === 0">
                                                :{{0 | number : '1.0-0'}} <span *ngIf="localCurrencyISOCode">{{localCurrencyISOCode}}</span>
                                            </span>
                                        </div>
                                    </div>
                                    <div class="row mb-3">
                                        <div class="col-6">
                                            <span>Interest Rate</span>
                                        </div>
                                        <div class="col-6">
                                            <span *ngIf="financingLoanInterest">
                                                :{{financingLoanInterest | number : '1.0-0'}} %
                                            </span>
                                            <span *ngIf="financingLoanInterest === 0">
                                                :{{0 | number : '1.0-0'}} %
                                            </span>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-6">
                                    <div class="row mb-3">
                                        <div class="col-6">
                                            <span>Loan Period (Years)</span>
                                        </div>
                                        <div class="col-6">
                                            <span *ngIf="financingLoanYears">
                                                :{{financingLoanYears | number : '1.0-0'}} Years
                                            </span>
                                            <span *ngIf="financingLoanYears === 0">
                                                :{{0 | number : '1.0-0'}} Years
                                            </span>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </form>
                    </div>

                </div>
                <div class="card" *ngIf="!showProjectFinancingEdit">
                    <div class="card-header">
                        <span>Yearly Escalation</span>
                    </div>
                    <div class="card-body">
                        <div class="board">
                            <table class="table table-sm table-bordered table-centered mb-0">
                                <thead>
                                    <tr>
                                        <th style="min-width:50px">

                                        </th>
                                        <th>
                                            Y - 0
                                        </th>
                                        <th>
                                            Y - 1
                                        </th>
                                        <th>
                                            Y - 2
                                        </th>
                                        <th>
                                            Y - 3
                                        </th>
                                        <th>
                                            Y - 4
                                        </th>
                                        <th>
                                            Y - 5
                                        </th>
                                        <th>
                                            Y - 6
                                        </th>
                                        <th>
                                            Y - 7
                                        </th>
                                        <th>
                                            Y - 8
                                        </th>
                                        <th>
                                            Y - 9
                                        </th>
                                        <th>
                                            Y - 10
                                        </th>
                                        <th>
                                            Y - 11
                                        </th>
                                        <th>
                                            Y - 12
                                        </th>
                                        <th>
                                            Y - 13
                                        </th>
                                        <th>
                                            Y - 14
                                        </th>
                                        <th>
                                            Y - 15
                                        </th>
                                    </tr>
                                </thead>
                                <tbody class="table-group-divider">
                                    <tr>
                                        <td class="p-0 px-1">
                                            <span>
                                                Interest Amount
                                            </span>
                                        </td>
                                        <td class="px-1 py-0 text-end"
                                            *ngFor="let m of financingLoanInterestAmountArray;let i = index;">
                                            <span *ngIf="!(m===0)">{{m|number:"1.2-2"}}</span>
                                            <span *ngIf="m===0">-</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="p-0 px-1">
                                            <span>
                                                Loan Balance
                                            </span>
                                        </td>
                                        <td class="px-1 py-0 text-end"
                                            *ngFor="let m of financingLoanBalanceArray;let i = index;">
                                            <span>
                                                <span *ngIf="!(m===0)">{{m|number:"1.2-2"}}</span>
                                                <span *ngIf="m===0">-</span>
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="p-0 px-1">
                                            <span>
                                                Loan Drawdown
                                            </span>
                                        </td>
                                        <td class="px-1 py-0 text-end"
                                            *ngFor="let m of financingLoanDrawdownArray;let i = index;">
                                            <span>
                                                <span *ngIf="!(m===0)">{{m|number:"1.2-2"}}</span>
                                                <span *ngIf="m===0">-</span>
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="p-0 px-1">
                                            <span>
                                                Yearly Payment Amount
                                            </span>
                                        </td>
                                        <td class="px-1 py-0 text-end"
                                            *ngFor="let m of financingLoanYearlyPaymentAmountArray;let i = index;">
                                            <span>
                                                <span *ngIf="!(m===0)">({{m|number:"1.2-2"}})</span>
                                                <span *ngIf="m===0">-</span>
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="p-0 px-1">
                                            <span>
                                                <p class="py-0 my-0"><strong>Loan to Dealer</strong></p>
                                            </span>
                                        </td>
                                        <td class="px-1 py-0 text-end"
                                            *ngFor="let m of financingLoanToDealerArray;let i = index;">
                                            <span>
                                                <span *ngIf="!(m===0)">{{m|number:"1.2-2"}}</span>
                                                <span *ngIf="m===0">-</span>
                                            </span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                    </div>
                </div>
                <div class="card" *ngIf="showProjectFinancingEdit">
                    <div class="card-header">
                        <span>Loan to Dealer</span>
                    </div>
                    <div class="card-body">
                        <form class="form-horizontal" name="financing-form" [formGroup]="financingForm">
                            <div class="row">

                                <div class="col-md-6">
                                    <div class="row mb-1">
                                        <label for="loanAmount" class="col-6 col-form-label col-form-label-sm">Loan
                                            Amount</label>
                                        <div class="col-6">
                                            <div class="input-group input-group-sm input-group-merge">
                                                <input class="form-control form-control-sm" type="number" min="0"
                                                    id="loanAmount" formControlName="loanAmount"
                                                    placeholder="15,000,000"
                                                    [ngClass]="{'is-invalid': (formSubmitted && financingFormValues['loanAmount'].invalid)||(financingFormValues['loanAmount'].touched && financingFormValues['loanAmount'].invalid)}">
                                                <div class="input-group-text">
                                                    <span><span *ngIf="localCurrencyISOCode">{{localCurrencyISOCode}}</span></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mb-3">
                                        <label for="interestRate"
                                            class="col-6 col-form-label col-form-label-sm">Interest
                                            Rate</label>
                                        <div class="col-6">
                                            <div class="input-group input-group-sm input-group-merge">
                                                <input class="form-control form-control-sm" type="number" min="0"
                                                    id="interestRate" formControlName="interestRate" placeholder="13"
                                                    [ngClass]="{'is-invalid': (formSubmitted && financingFormValues['interestRate'].invalid)||(financingFormValues['interestRate'].touched && financingFormValues['interestRate'].invalid)}">
                                                <div class="input-group-text">
                                                    <span>%</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-6">
                                    <div class="row mb-3">
                                        <label for="loanYears" class="col-6 col-form-label col-form-label-sm">Loan Over
                                            Years</label>
                                        <div class="col-6">
                                            <div class="input-group input-group-sm input-group-merge">
                                                <input class="form-control form-control-sm" type="number" min="0"
                                                    id="loanYears" formControlName="loanYears" placeholder="60"
                                                    [ngClass]="{'is-invalid': (formSubmitted && financingFormValues['loanYears'].invalid)||(financingFormValues['loanYears'].touched && financingFormValues['loanYears'].invalid)}">
                                                <div class="input-group-text">
                                                    <span>Years</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </form>
                    </div>
                    <div class="card-footer" *ngIf="showProjectFinancingEdit">
                        <div class="row">
                            <div class="col-sm-12">
                                <div class="d-grid">
                                    <button class="btn btn-sm btn-outline-success" (click)="updateProjectFinancing()"
                                        type="button" *ngIf="!submitInProgress && !updateInProgress"><i
                                            class=""></i>Update</button>
                                    <button class="btn btn-sm btn-outline-primary" type="button" disabled
                                        *ngIf="submitInProgress || updateInProgress">
                                        <span class="spinner-border spinner-border-sm" role="status"
                                            aria-hidden="true"></span>
                                        
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="card" *ngIf="showProjectFinancingEdit">
                    <div class="card-header">
                        <span>Yearly Escalation</span>
                    </div>
                    <div class="card-body">
                        <div class="board">
                            <table class="table table-sm table-bordered table-centered mb-0">
                                <thead>
                                    <tr>
                                        <th style="min-width:50px">

                                        </th>
                                        <th>
                                            Y - 0
                                        </th>
                                        <th>
                                            Y - 1
                                        </th>
                                        <th>
                                            Y - 2
                                        </th>
                                        <th>
                                            Y - 3
                                        </th>
                                        <th>
                                            Y - 4
                                        </th>
                                        <th>
                                            Y - 5
                                        </th>
                                        <th>
                                            Y - 6
                                        </th>
                                        <th>
                                            Y - 7
                                        </th>
                                        <th>
                                            Y - 8
                                        </th>
                                        <th>
                                            Y - 9
                                        </th>
                                        <th>
                                            Y - 10
                                        </th>
                                        <th>
                                            Y - 11
                                        </th>
                                        <th>
                                            Y - 12
                                        </th>
                                        <th>
                                            Y - 13
                                        </th>
                                        <th>
                                            Y - 14
                                        </th>
                                        <th>
                                            Y - 15
                                        </th>
                                    </tr>
                                </thead>
                                <tbody class="table-group-divider">
                                    <tr>
                                        <td class="p-0 px-1">
                                            <span>
                                                Interest Amount
                                            </span>
                                        </td>
                                        <td class="px-1 py-0 text-end"
                                            *ngFor="let m of financingLoanInterestAmountArray;let i = index;">
                                            <span *ngIf="!(m===0)">{{m|number:"1.2-2"}}</span>
                                            <span *ngIf="m===0">-</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="p-0 px-1">
                                            <span>
                                                Loan Balance
                                            </span>
                                        </td>
                                        <td class="px-1 py-0 text-end"
                                            *ngFor="let m of financingLoanBalanceArray;let i = index;">
                                            <span>
                                                <span *ngIf="!(m===0)">{{m|number:"1.2-2"}}</span>
                                                <span *ngIf="m===0">-</span>
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="p-0 px-1">
                                            <span>
                                                Loan Drawdown
                                            </span>
                                        </td>
                                        <td class="px-1 py-0 text-end"
                                            *ngFor="let m of financingLoanDrawdownArray;let i = index;">
                                            <span>
                                                <span *ngIf="!(m===0)">{{m|number:"1.2-2"}}</span>
                                                <span *ngIf="m===0">-</span>
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="p-0 px-1">
                                            <span>
                                                Yearly Payment Amount
                                            </span>
                                        </td>
                                        <td class="px-1 py-0 text-end"
                                            *ngFor="let m of financingLoanYearlyPaymentAmountArray;let i = index;">
                                            <span>
                                                <span *ngIf="!(m===0)">({{m|number:"1.2-2"}})</span>
                                                <span *ngIf="m===0">-</span>
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="p-0 px-1">
                                            <span>
                                                <p class="py-0 my-0"><strong>Loan to Dealer</strong></p>
                                            </span>
                                        </td>
                                        <td class="px-1 py-0 text-end"
                                            *ngFor="let m of financingLoanToDealerArray;let i = index;">
                                            <span>
                                                <span *ngIf="!(m===0)">{{m|number:"1.2-2"}}</span>
                                                <span *ngIf="m===0">-</span>
                                            </span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                    </div>
                </div>
                <div class="card" *ngIf="!showProjectFinancingEdit">
                    <div class="card-header">
                        <span>Company Borrowings</span>
                    </div>
                    <div class="card-body">

                        <div class="row">
                            <div class="col-sm-12">
                                <table class="table table-sm table-centered table-borderless mb-0">
                                    <tbody>
                                        <tr>
                                            <td class="p-0">
                                                Investment Financed by Loan - Percentage
                                            </td>
                                            <td class="p-0">
                                                <span *ngIf="financingInvestmentPercentageFinancedByLoan">
                                                    :{{financingInvestmentPercentageFinancedByLoan|number:"1.2-2"}} %
                                                </span>
                                                <span *ngIf="financingInvestmentPercentageFinancedByLoan === 0">
                                                    :{{0|number:"1.2-2"}} %
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="p-0">
                                                Investment Financed by Loan - Amount
                                            </td>
                                            <td class="p-0">
                                                <span *ngIf="financingInvestmentAmountFinancedByLoan">
                                                    :{{financingInvestmentAmountFinancedByLoan|number:"1.2-2"}} <span *ngIf="localCurrencyISOCode">{{localCurrencyISOCode}}</span>
                                                </span>
                                                <span *ngIf="financingInvestmentAmountFinancedByLoan === 0">
                                                    :{{0|number:"1.2-2"}} <span *ngIf="localCurrencyISOCode">{{localCurrencyISOCode}}</span>
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="p-0">
                                                Investment Interest Rate
                                            </td>
                                            <td class="p-0">
                                                <span *ngIf="financingInvestmentInterestRate">
                                                    :{{financingInvestmentInterestRate|number:"1.2-2"}} %
                                                </span>
                                                <span *ngIf="financingInvestmentInterestRate === 0">
                                                    :{{0|number:"1.2-2"}} %
                                                </span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                    </div>
                </div>
                <div class="card" *ngIf="showProjectFinancingEdit">
                    <div class="card-header">
                        <span>Company Borrowings</span>
                    </div>
                    <div class="card-body">

                        <div class="row">
                            <div class="col-sm-12">
                                <table class="table table-sm table-centered table-borderless mb-0" name="financing-form"
                                    [formGroup]="financingForm">
                                    <tbody>
                                        <tr>
                                            <td class="p-0">
                                                Investment Financed by Loan - Percentage
                                            </td>
                                            <td class="p-0">
                                                <div class="input-group input-group-sm input-group-merge">
                                                    <input class="form-control form-control-sm" type="number" min="0"
                                                        id="investmentPercentageFinancedByLoan"
                                                        formControlName="investmentPercentageFinancedByLoan"
                                                        placeholder="50%"
                                                        [ngClass]="{'is-invalid': (formSubmitted && financingFormValues['investmentPercentageFinancedByLoan'].invalid)||(financingFormValues['investmentPercentageFinancedByLoan'].touched && financingFormValues['investmentPercentageFinancedByLoan'].invalid)}">
                                                    <div class="input-group-text">
                                                        <span>%</span>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="p-0 mb-3 mt-3">
                                                Investment Financed by Loan - Amount
                                            </td>
                                            <td class="p-0">
                                                <span>
                                                    {{financingInvestmentAmountFinancedByLoan|number:'1.2-2'}}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="p-0">
                                                Investment Interest Rate
                                            </td>
                                            <td class="p-0">
                                                <div class="input-group input-group-sm input-group-merge">
                                                    <input class="form-control form-control-sm" type="number" min="0"
                                                        id="investmentInterestRate"
                                                        formControlName="investmentInterestRate" placeholder="2.7"
                                                        [ngClass]="{'is-invalid': (formSubmitted && financingFormValues['investmentInterestRate'].invalid)||(financingFormValues['investmentInterestRate'].touched && financingFormValues['investmentInterestRate'].invalid)}">
                                                    <div class="input-group-text">
                                                        <span>%</span>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                    </div>
                    <div class="card-footer" *ngIf="showProjectFinancingEdit">
                        <div class="row">
                            <div class="col-sm-12">
                                <div class="d-grid">
                                    <button class="btn btn-sm btn-outline-success" (click)="updateProjectFinancing()"
                                        type="button" *ngIf="!submitInProgress && !updateInProgress"><i
                                            class=""></i>Update</button>
                                    <button class="btn btn-sm btn-outline-primary" type="button" disabled
                                        *ngIf="submitInProgress || updateInProgress">
                                        <span class="spinner-border spinner-border-sm" role="status"
                                            aria-hidden="true"></span>
                                        
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card">
                    <div class="card-header mb-0">
                        <h5>
                            <span>
                                Yearly Escalation
                            </span>
                        </h5>
                    </div>
                    <div class="card-body">
                        <div class="card-body">
                            <div class="board">
                                <table class="table table-sm table-bordered table-centered mb-0">
                                    <thead>
                                        <tr>
                                            <th style="min-width:50px">

                                            </th>
                                            <th>
                                                Y - 0
                                            </th>
                                            <th>
                                                Y - 1
                                            </th>
                                            <th>
                                                Y - 2
                                            </th>
                                            <th>
                                                Y - 3
                                            </th>
                                            <th>
                                                Y - 4
                                            </th>
                                            <th>
                                                Y - 5
                                            </th>
                                            <th>
                                                Y - 6
                                            </th>
                                            <th>
                                                Y - 7
                                            </th>
                                            <th>
                                                Y - 8
                                            </th>
                                            <th>
                                                Y - 9
                                            </th>
                                            <th>
                                                Y - 10
                                            </th>
                                            <th>
                                                Y - 11
                                            </th>
                                            <th>
                                                Y - 12
                                            </th>
                                            <th>
                                                Y - 13
                                            </th>
                                            <th>
                                                Y - 14
                                            </th>
                                            <th>
                                                Y - 15
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody class="table-group-divider">
                                        <tr>
                                            <td class="p-0 px-1">
                                                <span>
                                                    Interest Rate For Financing (%)
                                                </span>
                                            </td>
                                            <td class="px-1 py-0"
                                                *ngFor="let m of financingInterestRateForFinancingArray;let i = index;">
                                                <span *ngIf="!(m===0)">{{m| number:'1.2-2'}}%</span>
                                                <span *ngIf="m===0">-</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="p-0 px-1">
                                                <span>
                                                    Loan to Company Payment
                                                </span>
                                            </td>
                                            <td class="px-1 py-0"
                                                *ngFor="let m of financingLoanToCompanyPaymentArray;let i = index;">
                                                <span *ngIf="!(m===0)">{{m| number:'1.2-2'}}</span>
                                                <span *ngIf="m===0">-</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="p-0 px-1">
                                                <span>
                                                    Loan To Company Balance
                                                </span>
                                            </td>
                                            <td class="px-1 py-0"
                                                *ngFor="let m of financingLoanToCompanyBalanceArray;let i = index;">
                                                <span *ngIf="!(m===0)">{{m| number:'1.2-2'}}</span>
                                                <span *ngIf="m===0">-</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="p-0 px-1">
                                                <span>
                                                    Cash Flow - Nominal
                                                </span>
                                            </td>
                                            <td class="px-1 py-0"
                                                *ngFor="let m of companySummaryNetCashAfterTaxArray;let i = index;">
                                                <span *ngIf="!(i===0)">
                                                    <span *ngIf="!(m===0)">{{m| number:'1.2-2'}}</span>
                                                    <span *ngIf="m===0">-</span>
                                                </span>
                                                <span *ngIf="(i===0)">
                                                    <span *ngIf="!(m===0)">({{-m| number:'1.2-2'}})</span>
                                                    <span *ngIf="m===0">-</span>
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="p-0 px-1">
                                                <span>
                                                    Cash Flow - Real
                                                </span>
                                            </td>
                                            <td class="px-1 py-0"
                                                *ngFor="let m of companyCashFlowRealArray;let i = index;">
                                                <span *ngIf="!(m===0)">{{m| number:'1.2-2'}}</span>
                                                <span *ngIf="m===0">-</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="p-0 px-1">
                                                <span>
                                                    Company Payback
                                                </span>
                                            </td>
                                            <td class="px-1 py-0"
                                                *ngFor="let m of companyPaybackFromInitialCashNeededArray;let i = index;">
                                                <span *ngIf="!(m===0)">{{m| number:'1.2-2'}}</span>
                                                <span *ngIf="m===0">-</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="p-0 px-1">
                                                <span>
                                                    Company Payback - Balance
                                                </span>
                                            </td>
                                            <td class="px-1 py-0"
                                                *ngFor="let m of companyPaybackBalanceFromInitialCashNeededArray;let i = index;">
                                                <span *ngIf="!(m===0)">{{m| number:'1.2-2'}}</span>
                                                <span *ngIf="m===0">-</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="p-0 px-1">
                                                <span>
                                                    Financial Interest Paid
                                                </span>
                                            </td>
                                            <td class="px-1 py-0"
                                                *ngFor="let m of financingInterestPaidArray;let i = index;">
                                                <span *ngIf="!(m===0)">{{m| number:'1.2-2'}}</span>
                                                <span *ngIf="m===0">-</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="p-0 px-1">
                                                <span>
                                                    Financial Interest Received
                                                </span>
                                            </td>
                                            <td class="px-1 py-0"
                                                *ngFor="let m of financingLoanInterestAmountArray;let i = index;">
                                                <span *ngIf="!(m===0)">{{m| number:'1.2-2'}}</span>
                                                <span *ngIf="m===0">-</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="p-0 px-1">
                                                <span>
                                                    <p class="py-0 my-0"><strong>Financial Total</strong></p>
                                                </span>
                                            </td>
                                            <td class="px-1 py-0" *ngFor="let m of financingTotalArray;let i = index;">
                                                <span *ngIf="!(m===0)">{{m| number:'1.2-2'}}</span>
                                                <span *ngIf="m===0">-</span>
                                            </td>
                                        </tr>


                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card-footer" *ngIf="!showProjectFinancingSection">
            <div class="row">
                <div class="col-sm-6"></div>
                <div class="col-sm-6">
                    <div class="d-grid">
                        <button class="btn btn-sm btn-outline-secondary"
                            (click)="showProjectFinancingSectionButton(true)" type="button"><i
                                class=""></i>View</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="card-footer" *ngIf="showProjectFinancingSection">
            <div class="row" *ngIf="showProjectFinancingEdit">
                <div class="col-sm-6">
                    <div class="d-grid">
                        <button class="btn btn-sm btn-outline-secondary" (click)="showProjectFinancingEditButton(false)"
                            type="button"><i class=""></i>Simple
                            View</button>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="d-grid">
                        <button class="btn btn-sm btn-outline-success" (click)="submitProjectFinancing()" type="button"
                            *ngIf="!submitInProgress && !updateInProgress"><i class=""></i>Submit</button>
                        <button class="btn btn-sm btn-outline-primary" type="button" disabled
                            *ngIf="submitInProgress || updateInProgress">
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            
                        </button>
                    </div>
                </div>
            </div>
            <div class="row" *ngIf="!showProjectFinancingEdit">
                <div class="col-sm-6">
                    <div class="d-grid">
                        <button class="btn btn-sm btn-outline-secondary" (click)="showProjectFinancingEditButton(true)"
                            type="button"><i class=""></i>Edit
                            View</button>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="d-grid">
                        <button class="btn btn-sm btn-outline-success" (click)="submitProjectFinancing()" type="button"
                            *ngIf="!submitInProgress && !updateInProgress"><i class=""></i>Submit</button>
                        <button class="btn btn-sm btn-outline-primary" type="button" disabled
                            *ngIf="submitInProgress || updateInProgress">
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Step 10. Summary - Galana -->
    <div id="stepTen" class="border border-secondary card stepTen">
        <div class="card-header">
            <div class="table-responsive-sm" *ngIf="projectArray">
                <table class="table table-sm table-centered table-borderless mb-0" *ngIf="projectArray[0]">
                    <tbody>
                        <tr>
                            <td class="p-0" style="width:60%">
                                Step 10. P&L Summary - Galana
                            </td>
                            <td class="p-0" style="width:30%">
                                <p class="mb-0">
                                    Completion
                                    <ngb-progressbar [showValue]="true" type="info" [striped]="true" [animated]="true"
                                        [value]="projectCompanySummaryProgressPercentage"></ngb-progressbar>
                                </p>
                            </td>
                            <td class="p-0 text-center text-primary" style="width:10%">
                                <i class="mdi mdi-help-circle-outline mdi-24px"></i>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div> <!-- end table-responsive-->
        </div>
        <div class="card-body" *ngIf="showProjectCompanySummarySection">
            <div class="card">
                <div class="card-body">
                    <div class="card-header mb-0">
                        <h5>
                            <span>
                                P&L Summary
                            </span>
                        </h5>
                    </div>
                    <div class="card-body">
                        <div class="board">
                            <table class="table table-sm table-bordeless table-centered mb-0">
                                <thead>
                                    <tr>
                                        <th style="min-width:50px">

                                        </th>
                                        <th>
                                            Y - 0
                                        </th>
                                        <th>
                                            Y - 1
                                        </th>
                                        <th>
                                            Y - 2
                                        </th>
                                        <th>
                                            Y - 3
                                        </th>
                                        <th>
                                            Y - 4
                                        </th>
                                        <th>
                                            Y - 5
                                        </th>
                                        <th>
                                            Y - 6
                                        </th>
                                        <th>
                                            Y - 7
                                        </th>
                                        <th>
                                            Y - 8
                                        </th>
                                        <th>
                                            Y - 9
                                        </th>
                                        <th>
                                            Y - 10
                                        </th>
                                        <th>
                                            Y - 11
                                        </th>
                                        <th>
                                            Y - 12
                                        </th>
                                        <th>
                                            Y - 13
                                        </th>
                                        <th>
                                            Y - 14
                                        </th>
                                        <th>
                                            Y - 15
                                        </th>
                                    </tr>
                                </thead>
                                <tbody class="table-group-divider">
                                    <tr>
                                        <td class="p-0 px-1">
                                            <span class="ps-1">
                                                Net Fuel Margin
                                            </span>
                                        </td>
                                        <td class="py-0"
                                            *ngFor="let m of companySummaryNetFuelMarginArray;let i = index;">
                                            <span *ngIf="!(m===0)">{{m| number:'1.2-2'}}</span>
                                            <span *ngIf="m===0">-</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="p-0 px-1">
                                            <span class="ps-1">
                                                Diversification Margin
                                            </span>
                                        </td>
                                        <td class="py-0"
                                            *ngFor="let m of companySummaryDiversificationMarginArray;let i = index;">
                                            <span *ngIf="!(m===0)">{{m| number:'1.2-2'}}</span>
                                            <span *ngIf="m===0">-</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="p-0 px-1">
                                            <span class="ps-1">
                                                Other Revenues
                                            </span>
                                        </td>
                                        <td class="py-0"
                                            *ngFor="let m of companySummaryOtherRevenueArray;let i = index;">
                                            <span *ngIf="!(m===0)">{{m| number:'1.2-2'}}</span>
                                            <span *ngIf="m===0">-</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="p-0 px-1">
                                            <span class="ps-1">
                                                Operating Expenses
                                            </span>
                                        </td>
                                        <td class="py-0"
                                            *ngFor="let m of companySummaryOperatingExpensesArray;let i = index;">
                                            <span *ngIf="!(m===0)">({{m| number:'1.2-2'}})</span>
                                            <span *ngIf="m===0">-</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="p-0 px-1">
                                            <span class="ps-1">
                                                Rentals & Fees Due
                                            </span>
                                        </td>
                                        <td class="py-0"
                                            *ngFor="let m of companySummaryRentalsAndFeesDueArray;let i = index;">
                                            <span *ngIf="!(m===0)">{{m| number:'1.2-2'}}</span>
                                            <span *ngIf="m===0">-</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="p-0 px-1">
                                            <span class="ps-2 text-muted">
                                                <em>Financial Interest Received</em>
                                            </span>
                                        </td>
                                        <td class="py-0 text-muted"
                                            *ngFor="let m of companyInterestReceivedArray;let i = index;">
                                            <span *ngIf="!(m===0)">{{m| number:'1.2-2'}}</span>
                                            <span *ngIf="m===0">-</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="p-0 px-1">
                                            <span>
                                                <p class="py-o my-0"><strong>EBITDA</strong></p>
                                            </span>
                                        </td>
                                        <td class="py-0" *ngFor="let m of companySummaryEBITDAArray;let i = index;">
                                            <span *ngIf="!(m===0)">{{m| number:'1.2-2'}}</span>
                                            <span *ngIf="m===0">-</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="p-0 px-1">
                                            <span class="ps-1">
                                                Investments
                                            </span>
                                        </td>
                                        <td class="py-0" *ngFor="let m of companySummaryInvestmentArray;let i = index;">
                                            <span *ngIf="!(m===0)">({{m| number:'1.2-2'}})</span>
                                            <span *ngIf="m===0">-</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="p-0 px-1">
                                            <span class="ps-1">
                                                Change in Working Capital
                                            </span>
                                        </td>
                                        <td class="py-0"
                                            *ngFor="let m of companySummaryChangeInWorkingCapitalArray;let i = index;">
                                            <span *ngIf="!(m===0)">({{(-m)| number:'1.2-2'}})</span>
                                            <span *ngIf="m===0">-</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="p-0 px-1">
                                            <span class="ps-1">
                                                Taxes
                                            </span>
                                        </td>
                                        <td class="py-0" *ngFor="let m of companySummaryTaxesArray;let i = index;">
                                            <span *ngIf="!(m===0)">({{m| number:'1.2-2'}})</span>
                                            <span *ngIf="m===0">-</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="p-0 px-1">
                                            <span class="ps-1">
                                                Other Cash Items
                                            </span>
                                        </td>
                                        <td class="py-0"
                                            *ngFor="let m of companySummaryOtherCashItemsArray;let i = index;">
                                            <span *ngIf="!(i===0)">
                                                <span *ngIf="!(m===0)">{{m| number:'1.2-2'}}</span>
                                                <span *ngIf="m===0">-</span>
                                            </span>
                                            <span *ngIf="(i===0)">
                                                <span *ngIf="!(m===0)">({{m| number:'1.2-2'}})</span>
                                                <span *ngIf="m===0">-</span>
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="p-0 px-1">
                                            <span>
                                                <p class="py-o my-0"><strong>Net Cash After Tax</strong></p>
                                            </span>
                                        </td>
                                        <td class="py-0"
                                            *ngFor="let m of companySummaryNetCashAfterTaxArray;let i = index;">
                                            <span *ngIf="!(m===0)">{{m| number:'1.2-2'}}</span>
                                            <span *ngIf="m===0">-</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="p-0 px-1">
                                            <span class="ps-1">
                                                Financial Result
                                            </span>
                                        </td>
                                        <td class="py-0" *ngFor="let m of financingTotalArray;let i = index;">
                                            <span *ngIf="!(m===0)">{{m| number:'1.2-2'}}</span>
                                            <span *ngIf="m===0">-</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="p-0 px-1">
                                            <span class="ps-1">
                                                Inflation Rate (%)
                                            </span>
                                        </td>
                                        <td class="py-0" *ngFor="let m of inflationRateArray;let i = index;">
                                            <span *ngIf="!(m===0)">{{(m*100)| number:'1.1-1'}}%</span>
                                            <span *ngIf="m===0">-</span>
                                        </td>
                                    </tr>


                                </tbody>
                            </table>
                        </div>
                    </div>

                </div>
            </div>
            <div class="card">
                <div class="card-body">
                    <div class="card-header mb-0">
                        <h5>
                            <span>
                                Cash Flow - Nominal
                            </span>
                        </h5>
                    </div>
                    <div class="card-body">
                        <div class="card-body">
                            <div class="board">
                                <table class="table table-sm table-bordeless table-centered mb-0">
                                    <thead>
                                        <tr>
                                            <th style="min-width:50px">

                                            </th>
                                            <th>
                                                Y - 0
                                            </th>
                                            <th>
                                                Y - 1
                                            </th>
                                            <th>
                                                Y - 2
                                            </th>
                                            <th>
                                                Y - 3
                                            </th>
                                            <th>
                                                Y - 4
                                            </th>
                                            <th>
                                                Y - 5
                                            </th>
                                            <th>
                                                Y - 6
                                            </th>
                                            <th>
                                                Y - 7
                                            </th>
                                            <th>
                                                Y - 8
                                            </th>
                                            <th>
                                                Y - 9
                                            </th>
                                            <th>
                                                Y - 10
                                            </th>
                                            <th>
                                                Y - 11
                                            </th>
                                            <th>
                                                Y - 12
                                            </th>
                                            <th>
                                                Y - 13
                                            </th>
                                            <th>
                                                Y - 14
                                            </th>
                                            <th>
                                                Y - 15
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody class="table-group-divider">
                                        <tr>
                                            <td class="p-0 px-1">
                                                <span>
                                                    <p class="py-o my-0"><strong>EBITDA</strong></p>
                                                </span>
                                            </td>
                                            <td class="py-0" *ngFor="let m of companySummaryEBITDAArray;let i = index;">
                                                <span *ngIf="!(m===0)">{{m| number:'1.2-2'}}</span>
                                                <span *ngIf="m===0">-</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="p-0 px-1">
                                                <span class="ps-1">
                                                    Other Cash Items
                                                </span>
                                            </td>
                                            <td class="py-0"
                                                *ngFor="let m of companySummaryOtherCashItemsArray;let i = index;">
                                                <span *ngIf="!(i===0)">
                                                    <span *ngIf="!(m===0)">{{m| number:'1.2-2'}}</span>
                                                    <span *ngIf="m===0">-</span>
                                                </span>
                                                <span *ngIf="(i===0)">
                                                    <span *ngIf="!(m===0)">({{m| number:'1.2-2'}})</span>
                                                    <span *ngIf="m===0">-</span>
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="p-0 px-1">
                                                <span class="ps-1">
                                                    Investments
                                                </span>
                                            </td>
                                            <td class="py-0"
                                                *ngFor="let m of companySummaryInvestmentArray;let i = index;">
                                                <span *ngIf="!(m===0)">({{m| number:'1.2-2'}})</span>
                                                <span *ngIf="m===0">-</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="p-0 px-1">
                                                <span class="ps-1">
                                                    Taxes
                                                </span>
                                            </td>
                                            <td class="py-0" *ngFor="let m of companySummaryTaxesArray;let i = index;">
                                                <span *ngIf="!(m===0)">({{m| number:'1.2-2'}})</span>
                                                <span *ngIf="m===0">-</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="p-0 px-1">
                                                <span class="ps-1">
                                                    Change in Working Capital
                                                </span>
                                            </td>
                                            <td class="py-0"
                                                *ngFor="let m of companySummaryChangeInWorkingCapitalArray;let i = index;">
                                                <span *ngIf="!(m===0)">({{(-m)| number:'1.2-2'}})</span>
                                                <span *ngIf="m===0">-</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="p-0 px-1">
                                                <span>
                                                    <p class="py-o my-0"><strong>Net Cash After Tax</strong></p>
                                                </span>
                                            </td>
                                            <td class="py-0"
                                                *ngFor="let m of companySummaryNetCashAfterTaxArray;let i = index;">
                                                <span *ngIf="!(i===0)">
                                                    <span *ngIf="!(m===0)">{{m| number:'1.2-2'}}</span>
                                                    <span *ngIf="m===0">-</span>
                                                </span>
                                                <span *ngIf="(i===0)">
                                                    <span *ngIf="!(m===0)">({{-m| number:'1.2-2'}})</span>
                                                    <span *ngIf="m===0">-</span>
                                                </span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div class="card">
                <div class="card-body">
                    <div class="card-header mb-0">
                        <h5>
                            <span>
                                Cash Flow - Real
                            </span>
                        </h5>
                    </div>
                    <div class="card-body">
                        <div class="card-body">
                            <div class="board">
                                <table class="table table-sm table-bordeless table-centered mb-0">
                                    <thead>
                                        <tr>
                                            <th style="min-width:50px">

                                            </th>
                                            <th>
                                                Y - 0
                                            </th>
                                            <th>
                                                Y - 1
                                            </th>
                                            <th>
                                                Y - 2
                                            </th>
                                            <th>
                                                Y - 3
                                            </th>
                                            <th>
                                                Y - 4
                                            </th>
                                            <th>
                                                Y - 5
                                            </th>
                                            <th>
                                                Y - 6
                                            </th>
                                            <th>
                                                Y - 7
                                            </th>
                                            <th>
                                                Y - 8
                                            </th>
                                            <th>
                                                Y - 9
                                            </th>
                                            <th>
                                                Y - 10
                                            </th>
                                            <th>
                                                Y - 11
                                            </th>
                                            <th>
                                                Y - 12
                                            </th>
                                            <th>
                                                Y - 13
                                            </th>
                                            <th>
                                                Y - 14
                                            </th>
                                            <th>
                                                Y - 15
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody class="table-group-divider">
                                        <tr>
                                            <td class="p-0 px-1">
                                                <span>
                                                    <p class="py-o my-0"><strong>EBITDA</strong></p>
                                                </span>
                                            </td>
                                            <td class="py-0"
                                                *ngFor="let m of companySummaryEBITDARealArray;let i = index;">
                                                <span *ngIf="!(m===0)">{{m| number:'1.2-2'}}</span>
                                                <span *ngIf="m===0">-</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="p-0 px-1">
                                                <span class="ps-1">
                                                    Other Cash Items
                                                </span>
                                            </td>
                                            <td class="py-0"
                                                *ngFor="let m of companySummaryOtherCashItemsRealArray;let i = index;">
                                                <span *ngIf="!(i===0)">
                                                    <span *ngIf="!(m===0)">{{m| number:'1.2-2'}}</span>
                                                    <span *ngIf="m===0">-</span>
                                                </span>
                                                <span *ngIf="(i===0)">
                                                    <span *ngIf="!(m===0)">({{m| number:'1.2-2'}})</span>
                                                    <span *ngIf="m===0">-</span>
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="p-0 px-1">
                                                <span class="ps-1">
                                                    Investments
                                                </span>
                                            </td>
                                            <td class="py-0"
                                                *ngFor="let m of companySummaryInvestmentRealArray;let i = index;">
                                                <span *ngIf="!(m===0)">({{m| number:'1.2-2'}})</span>
                                                <span *ngIf="m===0">-</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="p-0 px-1">
                                                <span class="ps-1">
                                                    Taxes
                                                </span>
                                            </td>
                                            <td class="py-0"
                                                *ngFor="let m of companySummaryTaxesRealArray;let i = index;">
                                                <span *ngIf="!(m===0)">({{m| number:'1.2-2'}})</span>
                                                <span *ngIf="m===0">-</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="p-0 px-1">
                                                <span class="ps-1">
                                                    Change in Working Capital
                                                </span>
                                            </td>
                                            <td class="py-0"
                                                *ngFor="let m of companySummaryChangeInWorkingCapitalRealArray;let i = index;">
                                                <span *ngIf="!(m===0)">({{(-m)| number:'1.2-2'}})</span>
                                                <span *ngIf="m===0">-</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="p-0 px-1">
                                                <span>
                                                    <p class="py-o my-0"><strong>Net Cash After Tax</strong></p>
                                                </span>
                                            </td>
                                            <td class="py-0"
                                                *ngFor="let m of companySummaryNetCashAfterTaxRealArray;let i = index;">
                                                <span *ngIf="!(m===0)">{{m| number:'1.2-2'}}</span>
                                                <span *ngIf="m===0">-</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="p-0 px-1">
                                                <span>
                                                    <p class="py-o my-0"><strong>Cash Balance</strong></p>
                                                </span>
                                            </td>
                                            <td class="py-0"
                                                *ngFor="let m of companySummaryRealCompanyCashBalanceArray;let i = index;">
                                                <span *ngIf="!(m===0)">{{m| number:'1.2-2'}}</span>
                                                <span *ngIf="m===0">-</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="p-0 px-1">
                                                <span>
                                                    <p class="py-o my-0"><strong>Net Cash After Tax (Real &
                                                            Discounted)</strong></p>
                                                </span>
                                            </td>
                                            <td class="py-0"
                                                *ngFor="let m of companySummaryDiscountedNetCashAfterTaxRealArray;let i = index;">
                                                <span *ngIf="!(m===0)">{{m| number:'1.2-2'}}</span>
                                                <span *ngIf="m===0">-</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="p-0 px-1">
                                                <span>
                                                    <p class="py-o my-0"><strong>Net Cash After Tax
                                                            (Discounted)</strong></p>
                                                </span>
                                            </td>
                                            <td class="py-0"
                                                *ngFor="let m of companySummaryNetCashAfterTaxDiscountedArray;let i = index;">
                                                <span *ngIf="!(m===0)">{{m| number:'1.2-2'}}</span>
                                                <span *ngIf="m===0">-</span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <div class="card-footer" *ngIf="!showProjectCompanySummarySection">
            <div class="row">
                <div class="col-sm-6"></div>
                <div class="col-sm-6">
                    <div class="d-grid">
                        <button class="btn btn-sm btn-outline-secondary"
                            (click)="showProjectCompanySummarySectionButton(true)" type="button"><i
                                class=""></i>View</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="card-footer" *ngIf="showProjectCompanySummarySection">
            <div class="row">
                <div class="col-sm-6">
                </div>
                <div class="col-sm-6">
                    <div class="d-grid">
                        <button class="btn btn-sm btn-outline-success" (click)="submitProjectCompanySummary()"
                            type="button" *ngIf="!submitInProgress && !updateInProgress"><i class=""></i>Submit</button>
                        <button class="btn btn-sm btn-outline-primary" type="button" disabled
                            *ngIf="submitInProgress || updateInProgress">
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Section Break -->
    <div class="border border-secondary card">
        <div class="card-body text-center text-primary">
            <span>Section B - Dealer</span>
        </div>
    </div>

    <!-- Step 11. Operating Expenses - Dealer -->
    <div id="stepEleven" class="border border-secondary card stepEleven">
        <div class="card-header">
            <div class="table-responsive-sm" *ngIf="projectArray">
                <table class="table table-sm table-centered table-borderless mb-0" *ngIf="projectArray[0]">
                    <tbody>
                        <tr>
                            <td class="p-0" style="width:60%">
                                Step 11. Operating Expenses - Dealer
                            </td>
                            <td class="p-0" style="width:30%">
                                <p class="mb-0">
                                    Completion
                                    <ngb-progressbar [showValue]="true" type="info" [striped]="true" [animated]="true"
                                        [value]="projectOperatingExpenseProgressPercentage"></ngb-progressbar>
                                </p>
                            </td>
                            <td class="p-0 text-center text-primary" style="width:10%">
                                <i class="mdi mdi-help-circle-outline mdi-24px"></i>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div> <!-- end table-responsive-->
        </div>
        <div class="card-body" *ngIf="showProjectDealerOperatingExpenseSection">
            <div class="card">
                <div class="card-body" *ngIf="showProjectDealerOperatingExpenseEdit">
                    <div class="card">
                        <div class="card-header">
                            <h5>
                                <span>i. Salaries And Bonus</span>
                            </h5>
                        </div>
                        <div class="card-body">
                            <table class="table table-sm table-borderless table-centered mb-2">
                                <thead>
                                    <tr>
                                        <th class="p-0"></th>
                                        <th class="p-0">
                                            <span class="text-muted">Item</span>
                                        </th>
                                        <th class="p-0">
                                            <span class="text-muted">Cost</span>
                                        </th>
                                        <th class="p-0" *ngIf="showProjectDealerOperatingExpenseEdit"></th>
                                    </tr>
                                </thead>
                                <tbody class="table-group-divider"
                                    *ngFor="let m of salariesAndBonusItemArray; let i = index;">
                                    <tr>
                                        <td class="p-0">
                                            <span>
                                                {{i+1}}.
                                            </span>
                                        </td>
                                        <td class="p-0">
                                            <span *ngIf="m.name">
                                                {{m.name}}
                                            </span>
                                        </td>
                                        <td class="p-0">
                                            <span *ngIf="m.total">
                                                {{m.total | number : '1.2-2'}}
                                            </span>
                                            <span *ngIf="m.total === 0">
                                                {{0 | number : '1.2-2'}}
                                            </span>
                                        </td>
                                        <td class="p-0" *ngIf="showProjectDealerOperatingExpenseEdit">
                                            <button class="btn btn-sm btn-outline-danger"
                                                *ngIf="!submitInProgress && !updateInProgress"
                                                (click)="removeDealerOperatingExpenseItem(i,'salariesAndBonus')"
                                                type="button">X</button>
                                            <button class="btn btn-sm btn-outline-danger" type="button" disabled
                                                *ngIf="submitInProgress || updateInProgress">
                                                <span class="spinner-border spinner-border-sm" role="status"
                                                    aria-hidden="true"></span>
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                            <form name="signup-form" [formGroup]="salariesAndBonusItemForm"
                                *ngIf="showProjectDealerOperatingExpenseEdit">
                                <div>
                                    <div class="input-group input-group-sm">
                                        <span class="input-group-text"
                                            id="inputGroup-sizing-sm">{{salariesAndBonusItemArray.length + 1}}</span>
                                        <input type="text" class="form-control" placeholder="Name" id="name"
                                            aria-label="Sizing example input" formControlName="name"
                                            aria-describedby="inputGroup-sizing-sm">
                                        <input type="number" min="1" class="form-control" placeholder="Cost" id="total"
                                            aria-label="Sizing example input" formControlName="total"
                                            aria-describedby="inputGroup-sizing-sm">
                                        <button class="btn btn-outline-success"
                                            *ngIf="!submitInProgress && !updateInProgress"
                                            (click)="addDealerOperatingExpenseItem(salariesAndBonusItemForm.value,'salariesAndBonus')"
                                            type="button">Add Item</button>
                                        <button class="btn btn-sm btn-outline-danger" type="button" disabled
                                            *ngIf="submitInProgress || updateInProgress">
                                            <span class="spinner-border spinner-border-sm" role="status"
                                                aria-hidden="true"></span>
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div class="card-footer">
                            <div class="row ms-1">
                                <div class="col-sm-12 text-end">
                                    <span class="px-3">
                                        Total
                                    </span>
                                    <span *ngIf="dealerOperatingExpenseSalariesAndBonusTotal">
                                        {{dealerOperatingExpenseSalariesAndBonusTotal | number:'1.2-2'}}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header">
                            <h5>
                                <span>ii. Employee Contribution</span>
                            </h5>
                        </div>
                        <div class="card-body">
                            <table class="table table-sm table-borderless table-centered mb-2">
                                <thead>
                                    <tr>
                                        <th class="p-0"></th>
                                        <th class="p-0">
                                            <span class="text-muted">Item</span>
                                        </th>
                                        <th class="p-0">
                                            <span class="text-muted">Cost</span>
                                        </th>
                                        <th class="p-0" *ngIf="showProjectDealerOperatingExpenseEdit"></th>
                                    </tr>
                                </thead>
                                <tbody class="table-group-divider"
                                    *ngFor="let m of employeeContributionItemArray; let i = index;">
                                    <tr>
                                        <td class="p-0">
                                            <span>
                                                {{i+1}}.
                                            </span>
                                        </td>
                                        <td class="p-0">
                                            <span *ngIf="m.name">
                                                {{m.name}}
                                            </span>
                                        </td>
                                        <td class="p-0">
                                            <span *ngIf="m.total">
                                                {{m.total | number : '1.2-2'}}
                                            </span>
                                            <span *ngIf="m.total === 0">
                                                {{0 | number : '1.2-2'}}
                                            </span>
                                        </td>
                                        <td class="p-0" *ngIf="showProjectDealerOperatingExpenseEdit">
                                            <button class="btn btn-sm btn-outline-danger"
                                                *ngIf="!submitInProgress && !updateInProgress"
                                                (click)="removeDealerOperatingExpenseItem(i,'employeeContribution')"
                                                type="button">X</button>
                                            <button class="btn btn-sm btn-outline-danger" type="button" disabled
                                                *ngIf="submitInProgress || updateInProgress">
                                                <span class="spinner-border spinner-border-sm" role="status"
                                                    aria-hidden="true"></span>
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                            <form name="signup-form" [formGroup]="employeeContributionItemForm"
                                *ngIf="showProjectDealerOperatingExpenseEdit">
                                <div>
                                    <div class="input-group input-group-sm">
                                        <span class="input-group-text"
                                            id="inputGroup-sizing-sm">{{employeeContributionItemArray.length +
                                            1}}</span>
                                        <input type="text" class="form-control" placeholder="Name" id="name"
                                            aria-label="Sizing example input" formControlName="name"
                                            aria-describedby="inputGroup-sizing-sm">
                                        <input type="number" min="1" class="form-control" placeholder="Cost" id="total"
                                            aria-label="Sizing example input" formControlName="total"
                                            aria-describedby="inputGroup-sizing-sm">
                                        <button class="btn btn-outline-primary"
                                            *ngIf="!submitInProgress && !updateInProgress"
                                            (click)="addDealerOperatingExpenseItem(employeeContributionItemForm.value,'employeeContribution')"
                                            type="button">Add
                                            Item</button>
                                        <button class="btn btn-sm btn-outline-danger" type="button" disabled
                                            *ngIf="submitInProgress || updateInProgress">
                                            <span class="spinner-border spinner-border-sm" role="status"
                                                aria-hidden="true"></span>
                                            
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div class="card-footer">
                            <div class="row ms-1">
                                <div class="col-sm-12 text-end">
                                    <span class="px-3">
                                        Total
                                    </span>
                                    <span *ngIf="dealerOperatingExpenseEmployeeContributionTotal">
                                        {{dealerOperatingExpenseEmployeeContributionTotal | number:'1.2-2'}}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header">
                            <h5>
                                <span>iii. Electricity</span>
                            </h5>
                        </div>
                        <div class="card-body">
                            <table class="table table-sm table-borderless table-centered mb-2">
                                <thead>
                                    <tr>
                                        <th class="p-0"></th>
                                        <th class="p-0">
                                            <span class="text-muted">Item</span>
                                        </th>
                                        <th class="p-0">
                                            <span class="text-muted">Cost</span>
                                        </th>
                                        <th class="p-0" *ngIf="showProjectDealerOperatingExpenseEdit"></th>
                                    </tr>
                                </thead>
                                <tbody class="table-group-divider"
                                    *ngFor="let m of electricityItemArray; let i = index;">
                                    <tr>
                                        <td class="p-0">
                                            <span>
                                                {{i+1}}.
                                            </span>
                                        </td>
                                        <td class="p-0">
                                            <span *ngIf="m.name">
                                                {{m.name}}
                                            </span>
                                        </td>
                                        <td class="p-0">
                                            <span *ngIf="m.total">
                                                {{m.total | number : '1.2-2'}}
                                            </span>
                                            <span *ngIf="m.total === 0">
                                                {{0 | number : '1.2-2'}}
                                            </span>
                                        </td>
                                        <td class="p-0" *ngIf="showProjectDealerOperatingExpenseEdit">
                                            <button class="btn btn-sm btn-outline-danger"
                                                *ngIf="!submitInProgress && !updateInProgress"
                                                (click)="removeDealerOperatingExpenseItem(i,'Electricity')"
                                                type="button">X</button>
                                            <button class="btn btn-sm btn-outline-danger" type="button" disabled
                                                *ngIf="submitInProgress || updateInProgress">
                                                <span class="spinner-border spinner-border-sm" role="status"
                                                    aria-hidden="true"></span>
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                            <form name="signup-form" [formGroup]="electricityItemForm"
                                *ngIf="showProjectDealerOperatingExpenseEdit">
                                <div>
                                    <div class="input-group input-group-sm">
                                        <span class="input-group-text"
                                            id="inputGroup-sizing-sm">{{electricityItemArray.length + 1}}</span>
                                        <input type="text" class="form-control" placeholder="Name" id="name"
                                            aria-label="Sizing example input" formControlName="name"
                                            aria-describedby="inputGroup-sizing-sm">
                                        <input type="number" min="1" class="form-control" placeholder="Cost" id="total"
                                            aria-label="Sizing example input" formControlName="total"
                                            aria-describedby="inputGroup-sizing-sm">
                                        <button class="btn btn-outline-primary"
                                            *ngIf="!submitInProgress && !updateInProgress"
                                            (click)="addDealerOperatingExpenseItem(electricityItemForm.value,'Electricity')"
                                            type="button">Add
                                            Item</button>
                                        <button class="btn btn-sm btn-outline-danger" type="button" disabled
                                            *ngIf="submitInProgress || updateInProgress">
                                            <span class="spinner-border spinner-border-sm" role="status"
                                                aria-hidden="true"></span>
                                            
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div class="card-footer">
                            <div class="row ms-1">
                                <div class="col-sm-12 text-end">
                                    <span class="px-3">
                                        Total
                                    </span>
                                    <span *ngIf="dealerOperatingExpenseElectricityTotal">
                                        {{dealerOperatingExpenseElectricityTotal | number:'1.2-2'}}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header">
                            <h5>
                                <span>iv. Miscellaneous</span>
                            </h5>
                        </div>
                        <div class="card-body">
                            <table class="table table-sm table-borderless table-centered mb-2">
                                <thead>
                                    <tr>
                                        <th class="p-0"></th>
                                        <th class="p-0">
                                            <span class="text-muted">Item</span>
                                        </th>
                                        <th class="p-0">
                                            <span class="text-muted">Cost</span>
                                        </th>
                                        <th class="p-0" *ngIf="showProjectDealerOperatingExpenseEdit"></th>
                                    </tr>
                                </thead>
                                <tbody class="table-group-divider"
                                    *ngFor="let m of miscellaneousItemArray; let i = index;">
                                    <tr>
                                        <td class="p-0">
                                            <span>
                                                {{i+1}}.
                                            </span>
                                        </td>
                                        <td class="p-0">
                                            <span *ngIf="m.name">
                                                {{m.name}}
                                            </span>
                                        </td>
                                        <td class="p-0">
                                            <span *ngIf="m.total">
                                                {{m.total | number : '1.2-2'}}
                                            </span>
                                            <span *ngIf="m.total === 0">
                                                {{0 | number : '1.2-2'}}
                                            </span>
                                        </td>
                                        <td class="p-0" *ngIf="showProjectDealerOperatingExpenseEdit">
                                            <button class="btn btn-sm btn-outline-danger"
                                                *ngIf="!submitInProgress && !updateInProgress"
                                                (click)="removeDealerOperatingExpenseItem(i,'miscellaneous')"
                                                type="button">X</button>
                                            <button class="btn btn-sm btn-outline-danger" type="button" disabled
                                                *ngIf="submitInProgress || updateInProgress">
                                                <span class="spinner-border spinner-border-sm" role="status"
                                                    aria-hidden="true"></span>
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                            <form name="signup-form" [formGroup]="miscellaneousItemForm"
                                *ngIf="showProjectDealerOperatingExpenseEdit">
                                <div>
                                    <div class="input-group input-group-sm">
                                        <span class="input-group-text"
                                            id="inputGroup-sizing-sm">{{miscellaneousItemArray.length + 1}}</span>
                                        <input type="text" class="form-control" placeholder="Name" id="name"
                                            aria-label="Sizing example input" formControlName="name"
                                            aria-describedby="inputGroup-sizing-sm">
                                        <input type="number" min="1" class="form-control" placeholder="Cost" id="total"
                                            aria-label="Sizing example input" formControlName="total"
                                            aria-describedby="inputGroup-sizing-sm">
                                        <button class="btn btn-outline-primary"
                                            *ngIf="!submitInProgress && !updateInProgress"
                                            (click)="addDealerOperatingExpenseItem(miscellaneousItemForm.value,'miscellaneous')"
                                            type="button">Add
                                            Item</button>
                                        <button class="btn btn-sm btn-outline-danger" type="button" disabled
                                            *ngIf="submitInProgress || updateInProgress">
                                            <span class="spinner-border spinner-border-sm" role="status"
                                                aria-hidden="true"></span>
                                            
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div class="card-footer">
                            <div class="row ms-1">
                                <div class="col-sm-12 text-end">
                                    <span class="px-3">
                                        Total
                                    </span>
                                    <span *ngIf="dealerOperatingExpenseMiscellaneousTotal">
                                        {{dealerOperatingExpenseMiscellaneousTotal | number:'1.2-2'}}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header">
                            <h5>
                                <span>v. Product Loss</span>
                            </h5>
                        </div>
                        <div class="card-body">
                            <table class="table table-sm table-borderless table-centered mb-2">
                                <thead>
                                    <tr>
                                        <th class="p-0"></th>
                                        <th class="p-0">
                                            <span class="text-muted">Item</span>
                                        </th>
                                        <th class="p-0">
                                            <span class="text-muted">Cost</span>
                                        </th>
                                        <th class="p-0" *ngIf="showProjectDealerOperatingExpenseEdit"></th>
                                    </tr>
                                </thead>
                                <tbody class="table-group-divider"
                                    *ngFor="let m of productLossItemArray; let i = index;">
                                    <tr>
                                        <td class="p-0">
                                            <span>
                                                {{i+1}}.
                                            </span>
                                        </td>
                                        <td class="p-0">
                                            <span *ngIf="m.name">
                                                {{m.name}}
                                            </span>
                                        </td>
                                        <td class="p-0">
                                            <span *ngIf="m.total">
                                                {{m.total | number : '1.2-2'}}
                                            </span>
                                            <span *ngIf="m.total === 0">
                                                {{0 | number : '1.2-2'}}
                                            </span>
                                        </td>
                                        <td class="p-0" *ngIf="showProjectDealerOperatingExpenseEdit">
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="card-footer">
                            <div class="row ms-1">
                                <div class="col-sm-12 text-end">
                                    <span class="px-3">
                                        Total
                                    </span>
                                    <span *ngIf="dealerOperatingExpenseProductLossTotal">
                                        {{dealerOperatingExpenseProductLossTotal | number:'1.2-2'}}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-body" *ngIf="!showProjectDealerOperatingExpenseEdit">
                    <div class="board">
                        <table class="table table-sm table-borderless table-centered mb-0">
                            <thead>
                                <tr>
                                    <th class="p-0">
                                    </th>
                                    <th class="p-0">
                                    </th>
                                    <th class="p-0">
                                    </th>
                                </tr>
                            </thead>
                            <tbody class="table-group-divider">
                                <tr>
                                    <td class="p-0">
                                        <span>i. Salaries & Bonuses</span>
                                    </td>
                                    <td class="p-0 ps-1">

                                    </td>
                                    <td class="p-0 text-end">
                                        <span *ngIf="dealerOperatingExpenseSalariesAndBonusTotal">
                                            {{dealerOperatingExpenseSalariesAndBonusTotal | number : '1.2-2'}}
                                        </span>
                                    </td>
                                </tr>
                            </tbody>
                            <tbody class="table-group-divider">
                                <tr *ngFor="let m of salariesAndBonusItemArray;let i = index;">
                                    <td class="p-0 ps-3">
                                        <span *ngIf="m.name">{{m.name}}</span>
                                    </td>
                                    <td class="p-0 text-end">
                                        <span *ngIf="m.total">{{m.total | number : '1.2-2'}}</span>
                                    </td>
                                    <td class="p-0">

                                    </td>
                                </tr>
                            </tbody>
                            <tbody class="table-group-divider">
                                <tr>
                                    <td class="p-0">
                                        <span>ii. Employee Contribution</span>
                                    </td>
                                    <td class="p-0 ps-1">

                                    </td>
                                    <td class="p-0 text-end">
                                        <span *ngIf="dealerOperatingExpenseEmployeeContributionTotal">
                                            {{dealerOperatingExpenseEmployeeContributionTotal | number : '1.2-2'}}
                                        </span>
                                    </td>
                                </tr>
                            </tbody>
                            <tbody class="table-group-divider">
                                <tr *ngFor="let m of employeeContributionItemArray;let i = index;">
                                    <td class="p-0 ps-3">
                                        <span *ngIf="m.name">{{m.name}}</span>
                                    </td>
                                    <td class="p-0 text-end">
                                        <span *ngIf="m.total">{{m.total | number : '1.2-2'}}</span>
                                    </td>
                                    <td class="p-0">

                                    </td>
                                </tr>
                            </tbody>
                            <tbody class="table-group-divider">
                                <tr>
                                    <td class="p-0">
                                        <span>iii. Electricity</span>
                                    </td>
                                    <td class="p-0 ps-1">

                                    </td>
                                    <td class="p-0 text-end">
                                        <span *ngIf="dealerOperatingExpenseElectricityTotal">
                                            {{dealerOperatingExpenseElectricityTotal | number : '1.2-2'}}
                                        </span>
                                    </td>
                                </tr>
                            </tbody>
                            <tbody class="table-group-divider">
                                <tr *ngFor="let m of electricityItemArray;let i = index;">
                                    <td class="p-0 ps-3">
                                        <span *ngIf="m.name">{{m.name}}</span>
                                    </td>
                                    <td class="p-0 text-end">
                                        <span *ngIf="m.total">{{m.total | number : '1.2-2'}}</span>
                                    </td>
                                    <td class="p-0">

                                    </td>
                                </tr>
                            </tbody>
                            <tbody class="table-group-divider">
                                <tr>
                                    <td class="p-0">
                                        <span>iv. Miscellaneous</span>
                                    </td>
                                    <td class="p-0 ps-1">

                                    </td>
                                    <td class="p-0 text-end">
                                        <span *ngIf="dealerOperatingExpenseMiscellaneousTotal">
                                            {{dealerOperatingExpenseMiscellaneousTotal | number : '1.2-2'}}
                                        </span>
                                    </td>
                                </tr>
                            </tbody>
                            <tbody class="table-group-divider">
                                <tr *ngFor="let m of miscellaneousItemArray;let i = index;">
                                    <td class="p-0 ps-3">
                                        <span *ngIf="m.name">{{m.name}}</span>
                                    </td>
                                    <td class="p-0 text-end">
                                        <span *ngIf="m.total">{{m.total | number : '1.2-2'}}</span>
                                    </td>
                                    <td class="p-0">

                                    </td>
                                </tr>
                            </tbody>
                            <tbody class="table-group-divider">
                                <tr>
                                    <td class="p-0">
                                        <span>v. Product Loss</span>
                                    </td>
                                    <td class="p-0 ps-1">

                                    </td>
                                    <td class="p-0 text-end">
                                        <span *ngIf="dealerOperatingExpenseProductLossTotal">
                                            {{dealerOperatingExpenseProductLossTotal | number : '1.2-2'}}
                                        </span>
                                    </td>
                                </tr>
                            </tbody>
                            <tbody dy class="table-group-divider">
                                <tr *ngFor="let m of productLossItemArray;let i = index;">
                                    <td class="p-0 ps-3">
                                        <span *ngIf="m.name">{{m.name}}</span>
                                    </td>
                                    <td class="p-0 text-end">
                                        <span *ngIf="m.total">{{m.total | number : '1.2-2'}}</span>
                                    </td>
                                    <td class="p-0">

                                    </td>
                                </tr>
                            </tbody>
                            <tbody class="table-group-divider">
                                <tr>
                                    <td class="p-0 text-end">
                                        <span>Total</span>
                                    </td>
                                    <td class="p-0 ps-1">

                                    </td>
                                    <td class="p-0 text-end">
                                        <span *ngIf="dealerOperatingExpenseTotal">
                                            {{dealerOperatingExpenseTotal | number : '1.2-2'}}
                                        </span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <div class="card-footer text-center" *ngIf="showProjectDealerOperatingExpenseEdit">
            <h4 class="dispaly-5">
                Operating Expenses Total : <span *ngIf="dealerOperatingExpenseTotal">{{dealerOperatingExpenseTotal |
                    number:'1.2-2'}}</span>
            </h4>
        </div>
        <div class="card-footer" *ngIf="!showProjectDealerOperatingExpenseSection">
            <div class="row">
                <div class="col-sm-6"></div>
                <div class="col-sm-6">
                    <div class="d-grid">
                        <button class="btn btn-sm btn-outline-secondary"
                            (click)="showProjectDealerOperatingExpenseSectionButton(true)" type="button"><i
                                class=""></i>View</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="card-footer" *ngIf="showProjectDealerOperatingExpenseSection">
            <div class="row" *ngIf="showProjectDealerOperatingExpenseEdit">
                <div class="col-sm-6">
                    <div class="d-grid">
                        <button class="btn btn-sm btn-outline-secondary"
                            (click)="showProjectDealerOperatingExpenseEditButton(false)" type="button"><i
                                class=""></i>Simple View</button>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="d-grid">
                        <button class="btn btn-sm btn-outline-success" (click)="submitProjectDealerOperatingExpense()"
                            type="button" *ngIf="!submitInProgress && !updateInProgress"><i class=""></i>Submit</button>
                        <button class="btn btn-sm btn-outline-primary" type="button" disabled
                            *ngIf="submitInProgress || updateInProgress">
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            
                        </button>
                    </div>
                </div>
            </div>
            <div class="row" *ngIf="!showProjectDealerOperatingExpenseEdit">
                <div class="col-sm-6">
                    <div class="d-grid">
                        <button class="btn btn-sm btn-outline-secondary"
                            (click)="showProjectDealerOperatingExpenseEditButton(true)" type="button"><i
                                class=""></i>Edit
                            View</button>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="d-grid">
                        <button class="btn btn-sm btn-outline-success" (click)="submitProjectDealerOperatingExpense()"
                            type="button" *ngIf="!submitInProgress && !updateInProgress"><i class=""></i>Submit</button>
                        <button class="btn btn-sm btn-outline-primary" type="button" disabled
                            *ngIf="submitInProgress || updateInProgress">
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Step 12. Revenue - Dealer -->
    <div id="stepTwelve" class="border border-secondary card stepTwelve">
        <div class="card-header">
            <div class="table-responsive-sm" *ngIf="projectArray">
                <table class="table table-sm table-centered table-borderless mb-0" *ngIf="projectArray[0]">
                    <tbody>
                        <tr>
                            <td class="p-0" style="width:60%">
                                Step 12. Revenue - Dealer
                            </td>
                            <td class="p-0" style="width:30%">
                                <p class="mb-0">
                                    Completion
                                    <ngb-progressbar [showValue]="true" type="info" [striped]="true" [animated]="true"
                                        [value]="projectDealerRevenueProgressPercentage"></ngb-progressbar>
                                </p>
                            </td>
                            <td class="p-0 text-center text-primary" style="width:10%">
                                <i class="mdi mdi-help-circle-outline mdi-24px"></i>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div> <!-- end table-responsive-->
        </div>
        <div class="card-body" *ngIf="showProjectDealerRevenueSection">
            <div class="card">
                <div class="card-body">
                    <div class="card">
                        <div class="card-header mb-0">
                            <h5>
                                <span>
                                    Fuel Revenue
                                </span>
                            </h5>
                        </div>
                        <div class="card-body">
                            <table class="table table-sm table-borderless table-centered mb-0">
                                <thead>
                                    <tr>
                                        <th class="p-0"></th>
                                        <th class="p-0">
                                            <span class="text-muted">Product</span>
                                        </th>
                                        <th class="p-0">
                                            <span class="text-muted">Yearly Volume (M3)</span>
                                        </th>
                                        <th class="p-0">
                                            <span class="text-muted">Dealer Margin (KSH/M3)</span>
                                        </th>
                                        <th class="p-0">
                                            <span class="text-muted">Total</span>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody class="table-group-divider"
                                    *ngFor="let m of dealerRevenueFuelProductArray; let i = index;">
                                    <tr>
                                        <td class="p-0">{{i+1}}.</td>
                                        <td class="p-0 ps-1">
                                            <span *ngIf="m.name">{{m.name}}
                                                <span *ngIf="m.industryAbbreviation">
                                                    ({{m.industryAbbreviation}})</span>
                                            </span>
                                        </td>
                                        <td class="p-0">
                                            <span *ngIf="m.yearlyVolume">
                                                {{m.yearlyVolume | number : '1.0-2'}}
                                            </span>
                                            <span *ngIf="m.yearlyVolume === 0">
                                                {{0 | number : '1.0-2'}}
                                            </span>
                                        </td>
                                        <td class="p-0">
                                            <span *ngIf="m.marginM3">
                                                {{m.marginM3 | number : '1.2-2'}}
                                            </span>
                                            <span *ngIf="m.marginM3 === 0">
                                                {{0 | number : '1.2-2'}}
                                            </span>
                                        </td>
                                        <td class="p-0">
                                            <span *ngIf="m.total">
                                                {{m.total | number : '1.2-2'}}
                                            </span>
                                            <span *ngIf="m.total === 0">
                                                {{0 | number : '1.2-2'}}
                                            </span>
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody class="table-group-divider">
                                    <tr>
                                        <td class="p-0"></td>
                                        <td class="p-0"><span>
                                                <p><strong>Fuel Total</strong></p>
                                            </span></td>
                                        <td class="p-0"></td>
                                        <td class="p-0"></td>
                                        <td class="p-0">
                                            <p><strong>
                                                    <span *ngIf="dealerRevenueFuelProductTotalRevenue">
                                                        {{dealerRevenueFuelProductTotalRevenue | number : '1.2-2'}}
                                                    </span>
                                                    <span *ngIf="dealerRevenueFuelProductTotalRevenue === 0">
                                                        {{0 | number : '1.2-2'}}
                                                    </span>
                                                </strong></p>
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody class="table-group-divider"
                                    *ngFor="let m of dealerRevenueLubricantProductArray; let i = index;">
                                    <tr>
                                        <td class="p-0">{{i+1}}.</td>
                                        <td class="p-0 ps-1">
                                            <span *ngIf="m.name">{{m.name}}
                                                <span *ngIf="m.industryAbbreviation">
                                                    ({{m.industryAbbreviation}})</span>
                                            </span>
                                        </td>
                                        <td class="p-0">
                                            <span *ngIf="m.yearlyVolume">
                                                {{m.yearlyVolume | number : '1.0-2'}}
                                            </span>
                                            <span *ngIf="m.yearlyVolume === 0">
                                                {{0 | number : '1.0-2'}}
                                            </span>
                                        </td>
                                        <td class="p-0">
                                            <span *ngIf="m.marginM3">
                                                {{m.marginM3 | number : '1.2-2'}}
                                            </span>
                                            <span *ngIf="m.marginM3 === 0">
                                                {{0 | number : '1.2-2'}}
                                            </span>
                                        </td>
                                        <td class="p-0">
                                            <span *ngIf="m.total">
                                                {{m.total | number : '1.2-2'}}
                                            </span>
                                            <span *ngIf="m.total === 0">
                                                {{0 | number : '1.2-2'}}
                                            </span>
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody class="table-group-divider">
                                    <tr>
                                        <td class="p-0"></td>
                                        <td class="p-0">
                                            <span>
                                                <p><strong>Lubricant Total</strong></p>
                                            </span>
                                        </td>
                                        <td class="p-0"></td>
                                        <td class="p-0"></td>
                                        <td class="p-0">
                                            <p><strong>
                                                    <span *ngIf="dealerRevenueLubricantProductTotalRevenue">
                                                        {{dealerRevenueLubricantProductTotalRevenue | number : '1.2-2'}}
                                                    </span>
                                                    <span *ngIf="dealerRevenueLubricantProductTotalRevenue === 0">
                                                        {{0 | number : '1.2-2'}}
                                                    </span>
                                                </strong></p>
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody class="table-group-divider"
                                    *ngFor="let m of dealerRevenueGasProductArray; let i = index;">
                                    <tr>
                                        <td class="p-0">{{i+1}}.</td>
                                        <td class="p-0 ps-1">
                                            <span *ngIf="m.name">{{m.name}}
                                                <span *ngIf="m.industryAbbreviation">
                                                    ({{m.industryAbbreviation}})</span>
                                            </span>
                                        </td>
                                        <td class="p-0">
                                            <span *ngIf="m.yearlyVolume">
                                                {{m.yearlyVolume | number : '1.0-2'}}
                                            </span>
                                            <span *ngIf="m.yearlyVolume === 0">
                                                {{0 | number : '1.0-2'}}
                                            </span>
                                        </td>
                                        <td class="p-0">
                                            <span *ngIf="m.marginM3">
                                                {{m.marginM3 | number : '1.2-2'}}
                                            </span>
                                            <span *ngIf="m.marginM3 === 0">
                                                {{0 | number : '1.2-2'}}
                                            </span>
                                        </td>
                                        <td class="p-0">
                                            <span *ngIf="m.total">
                                                {{m.total | number : '1.2-2'}}
                                            </span>
                                            <span *ngIf="m.total === 0">
                                                {{0 | number : '1.2-2'}}
                                            </span>
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody class="table-group-divider"
                                    *ngFor="let m of dealerRevenueGasProductArray; let i = index;">
                                    <tr>
                                        <td class="p-0"></td>
                                        <td class="p-0">
                                            <span>
                                                <p><strong>Gas Total</strong></p>
                                            </span>
                                        </td>
                                        <td class="p-0"></td>
                                        <td class="p-0"></td>
                                        <td class="p-0">
                                            <p><strong>
                                                    <span *ngIf="dealerRevenueGasProductTotalRevenue">
                                                        {{dealerRevenueGasProductTotalRevenue | number : '1.2-2'}}
                                                    </span>
                                                    <span *ngIf="dealerRevenueGasProductTotalRevenue === 0">
                                                        {{0 | number : '1.2-2'}}
                                                    </span>
                                                </strong></p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="card-footer">
                            <div class="row ms-1">
                                <div class="col-sm-12 text-end">
                                    <span class="px-3">
                                        Total
                                    </span>
                                    <span *ngIf="dealerRevenueFuelLubricantGasTotal">
                                        {{dealerRevenueFuelLubricantGasTotal | number : '1.2-2'}}
                                    </span>
                                    <span *ngIf="dealerRevenueFuelLubricantGasTotal === 0">
                                        {{0 | number : '1.2-2'}}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-body" *ngIf="!showProjectDealerRevenueEdit">
                    <div class="card">
                        <div class="card-header mb-0">
                            <h5>
                                <span>Non-Fuel Revenue</span>
                            </h5>
                        </div>
                        <div class="card-body">

                            <table class="table table-sm table-borderless table-centered mb-0">
                                <thead>
                                    <tr>
                                        <th class="p-0"></th>
                                        <th class="p-0">
                                            <span class="text-muted">Product</span>
                                        </th>
                                        <th class="p-0">
                                            <span class="text-muted">Yearly Volume (M3)</span>
                                        </th>
                                        <th class="p-0">
                                            <span class="text-muted">Dealer Margin (KSH/M3)</span>
                                        </th>
                                        <th class="p-0">
                                            <span class="text-muted">Total</span>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody class="table-group-divider"
                                    *ngFor="let m of dealerRevenueNonFuelProductArray; let i = index;">
                                    <tr>
                                        <td class="p-0">
                                            <span>
                                                {{i+1}}.
                                            </span>
                                        </td>
                                        <td class="p-0">
                                            <span *ngIf="m.name">{{m.name}}
                                                <span *ngIf="m.industryAbbreviation">
                                                    ({{m.industryAbbreviation}})</span>
                                            </span>
                                        </td>
                                        <td class="p-0">
                                            <span *ngIf="m.yearly">
                                                {{m.yearly | number : '1.0-2'}}
                                            </span>
                                            <span *ngIf="m.yearly === 0">
                                                {{0 | number : '1.0-2'}}
                                            </span>
                                        </td>
                                        <td class="p-0">
                                            <span *ngIf="m.rate">
                                                {{m.rate | number : '1.2-2'}}
                                            </span>
                                            <span *ngIf="m.rate === 0">
                                                {{0 | number : '1.2-2'}}
                                            </span>
                                        </td>
                                        <td class="p-0">
                                            <span *ngIf="m.total">
                                                {{m.total | number : '1.2-2'}}
                                            </span>
                                            <span *ngIf="m.total === 0">
                                                {{0 | number : '1.2-2'}}
                                            </span>
                                        </td>
                                    </tr>
                                </tbody>

                            </table>
                        </div>
                        <div class="card-footer">
                            <div class="row ms-1">
                                <div class="col-sm-12 text-end">
                                    <span class="px-3">
                                        Total
                                    </span>
                                    <span *ngIf="dealerRevenueNonFuelTotal">
                                        {{dealerRevenueNonFuelTotal | number:'1.2-2'}}
                                    </span>
                                    <span *ngIf="dealerRevenueNonFuelTotal === 0">
                                        {{0 | number : '1.2-2'}}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-body" *ngIf="showProjectDealerRevenueEdit">
                    <div class="card">
                        <div class="card-header mb-0">
                            <h5>
                                <span>Non-Fuel Revenue</span>
                            </h5>
                        </div>
                        <div class="card-body">
                            <table class="table table-sm table-borderless table-centered mb-2">
                                <thead>
                                    <tr>
                                        <th class="p-0"></th>
                                        <th class="p-0">
                                            <span class="text-muted">Product</span>
                                        </th>
                                        <th class="p-0">
                                            <span class="text-muted">Yearly</span>
                                        </th>
                                        <th class="p-0">
                                            <span class="text-muted">Rate</span>
                                        </th>
                                        <th class="p-0">
                                            <span class="text-muted">Total</span>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody class="table-group-divider"
                                    *ngFor="let m of dealerRevenueNonFuelProductArray; let i = index;">
                                    <tr>
                                        <td class="p-0">
                                            <span>
                                                {{i+1}}.
                                            </span>
                                        </td>
                                        <td class="p-0">
                                            <span *ngIf="m.name">{{m.name}}
                                                <span *ngIf="m.industryAbbreviation">
                                                    ({{m.industryAbbreviation}})</span>
                                            </span>
                                        </td>
                                        <td class="p-0">
                                            <span *ngIf="m.yearly">
                                                {{m.yearly | number : '1.0-2'}}
                                            </span>
                                            <span *ngIf="m.yearly === 0">
                                                {{0 | number : '1.0-2'}}
                                            </span>
                                        </td>
                                        <td class="p-0">
                                            <span *ngIf="m.rate">
                                                {{m.rate | number : '1.2-2'}} %
                                            </span>
                                            <span *ngIf="m.rate === 0">
                                                {{0 | number : '1.2-2'}} %
                                            </span>
                                        </td>
                                        <td class="p-0">
                                            <span *ngIf="m.total">
                                                {{m.total | number : '1.2-2'}}
                                            </span>
                                            <span *ngIf="m.total === 0">
                                                {{0 | number : '1.2-2'}}
                                            </span>
                                        </td>
                                        <td class="p-0">
                                            <button class="btn btn-sm btn-outline-danger"
                                                *ngIf="!submitInProgress && !updateInProgress"
                                                (click)="removeDealerRevenueNonFuelItem(i)" type="button">X</button>
                                            <button class="btn btn-sm btn-outline-danger" type="button" disabled
                                                *ngIf="submitInProgress || updateInProgress">
                                                <span class="spinner-border spinner-border-sm" role="status"
                                                    aria-hidden="true"></span>
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                            <form name="signup-form" [formGroup]="dealerRevenueNonFuelForm">
                                <div>
                                    <div class="input-group input-group-sm">
                                        <span class="input-group-text"
                                            id="inputGroup-sizing-sm">{{dealerRevenueNonFuelProductArray.length +
                                            1}}</span>
                                        <input type="text" class="form-control" placeholder="Name" id="name"
                                            aria-label="Sizing example input" formControlName="name"
                                            aria-describedby="inputGroup-sizing-sm">
                                        <input type="number" min="1" class="form-control" placeholder="Yearly"
                                            id="yearly" aria-label="Sizing example input" formControlName="yearly"
                                            aria-describedby="inputGroup-sizing-sm">
                                        <input type="number" min="1" class="form-control" placeholder="Rate" id="rate"
                                            aria-label="Sizing example input" formControlName="rate"
                                            aria-describedby="inputGroup-sizing-sm">
                                        <input type="number" min="1" class="form-control" placeholder="Total" id="total"
                                            aria-label="Sizing example input" formControlName="total"
                                            aria-describedby="inputGroup-sizing-sm" readonly>
                                        <button class="btn btn-outline-success"
                                            *ngIf="!submitInProgress && !updateInProgress"
                                            (click)="addDealerRevenueNonFuelItem(dealerRevenueNonFuelForm.value)"
                                            type="button">Add Item</button>
                                        <button class="btn btn-sm btn-outline-danger" type="button" disabled
                                            *ngIf="submitInProgress || updateInProgress">
                                            <span class="spinner-border spinner-border-sm" role="status"
                                                aria-hidden="true"></span>
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div class="card-footer">
                            <div class="row ms-1">
                                <div class="col-sm-12 text-end">
                                    <span class="px-3">
                                        Total
                                    </span>
                                    <span *ngIf="dealerRevenueNonFuelTotal">
                                        {{dealerRevenueNonFuelTotal | number:'1.2-2'}}
                                    </span>
                                    <span *ngIf="dealerRevenueNonFuelTotal === 0">
                                        {{0 | number : '1.2-2'}}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <div class="card">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-6 text-end">
                                    <span>
                                        Dealer Revenue Total :
                                    </span>
                                </div>
                                <div class="col-6 text-start">
                                    <span *ngIf="dealerRevenueTotal">
                                        {{dealerRevenueTotal | number : '1.2-2'}}
                                    </span>
                                    <span *ngIf="dealerRevenueTotal === 0">
                                        {{0 | number : '1.2-2'}}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card-footer" *ngIf="!showProjectDealerRevenueSection">
            <div class="row">
                <div class="col-sm-6"></div>
                <div class="col-sm-6">
                    <div class="d-grid">
                        <button class="btn btn-sm btn-outline-secondary"
                            (click)="showProjectDealerRevenueSectionButton(true)" type="button">
                            <i class=""></i>View</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="card-footer" *ngIf="showProjectDealerRevenueSection">
            <div class="row" *ngIf="showProjectDealerRevenueEdit">
                <div class="col-sm-6">
                    <div class="d-grid">
                        <button class="btn btn-sm btn-outline-secondary"
                            (click)="showProjectDealerRevenueEditButton(false)" type="button"><i class=""></i>Simple
                            View</button>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="d-grid">
                        <button class="btn btn-sm btn-outline-success" (click)="submitProjectDealerRevenue()"
                            type="button" *ngIf="!submitInProgress && !updateInProgress"><i class=""></i>Submit</button>
                        <button class="btn btn-sm btn-outline-primary" type="button" disabled
                            *ngIf="submitInProgress || updateInProgress">
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            
                        </button>
                    </div>
                </div>
            </div>
            <div class="row" *ngIf="!showProjectDealerRevenueEdit">
                <div class="col-sm-6">
                    <div class="d-grid">
                        <button class="btn btn-sm btn-outline-secondary"
                            (click)="showProjectDealerRevenueEditButton(true)" type="button"><i class=""></i>Edit
                            View</button>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="d-grid">
                        <button class="btn btn-sm btn-outline-success" (click)="submitProjectDealerRevenue()"
                            type="button" *ngIf="!submitInProgress && !updateInProgress"><i class=""></i>Submit</button>
                        <button class="btn btn-sm btn-outline-primary" type="button" disabled
                            *ngIf="submitInProgress || updateInProgress">
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Step 13. Fees - Dealer -->
    <div id="stepThirteen" class="border border-secondary card stepThirteen">
        <div class="card-header">
            <div class="table-responsive-sm" *ngIf="projectArray">
                <table class="table table-sm table-centered table-borderless mb-0" *ngIf="projectArray[0]">
                    <tbody>
                        <tr>
                            <td class="p-0" style="width:60%">
                                Step 13. Fees - Dealer
                            </td>
                            <td class="p-0" style="width:30%">
                                <p class="mb-0">
                                    Completion
                                    <ngb-progressbar [showValue]="true" type="info" [striped]="true" [animated]="true"
                                        [value]="projectDealerFeesProgressPercentage"></ngb-progressbar>
                                </p>
                            </td>
                            <td class="p-0 text-center text-primary" style="width:10%">
                                <i class="mdi mdi-help-circle-outline mdi-24px"></i>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div> <!-- end table-responsive-->
        </div>
        <div class="card-body" *ngIf="showProjectDealerFeesSection">
            <div class="card">
                <div class="card-body">
                    <div class="card">
                        <div class="card-header mb-0">
                            <h5>
                                <span>
                                    Fuel Fees
                                </span>
                            </h5>
                        </div>
                        <div class="card-body">
                            <div class="board">
                                <div class="table-responsive-sm">
                                    <table class="table table-sm table-borderless table-centered mb-0">
                                        <thead>
                                            <tr>
                                                <th class="p-0">
                                                </th>
                                                <th class="p-0">
                                                </th>
                                                <th class="p-0">
                                                </th>
                                                <th class="p-0">
                                                </th>
                                                <th class="p-0">
                                                </th>
                                                <th class="p-0">
                                                </th>
                                                <th class="p-0">
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td class="p-0">
                                                    <span>
                                                        <p class="py-0 my-0"><strong>Fees Received And Other
                                                                Revenues</strong></p>
                                                    </span>
                                                </td>
                                                <td class="p-0 text-end"></td>
                                                <td class="p-0 text-end"></td>
                                                <td class="p-0 text-end"></td>
                                                <td class="p-0 text-end"></td>
                                                <td class="p-0 text-end"></td>
                                                <td class="p-0 text-end"></td>
                                            </tr>
                                            <tr>
                                                <td class="p-0"></td>
                                                <td class="p-0 text-end"><span class="text-muted">DODO Discount</span>
                                                </td>
                                                <td class="p-0 text-end"><span class="text-muted">Yearly</span></td>
                                                <td class="p-0 text-end"><span class="text-muted">Rate</span></td>
                                                <td class="p-0"></td>
                                                <td class="p-0"></td>
                                                <td class="p-0 text-end"><span class="text-muted">Total</span></td>
                                            </tr>
                                            <tr>
                                                <td class="p-0"></td>
                                                <td class="p-0 text-end"><span class="text-muted"><span *ngIf="localCurrencyISOCode">{{localCurrencyISOCode}}</span></span></td>
                                                <td class="p-0 text-end"><span class="text-muted"><span *ngIf="localCurrencyISOCode">{{localCurrencyISOCode}}</span></span></td>
                                                <td class="p-0"><span class="text-muted"></span></td>
                                                <td class="p-0"></td>
                                                <td class="p-0 text-end"><span class="text-muted"><span *ngIf="localCurrencyISOCode">{{localCurrencyISOCode}}</span></span></td>
                                                <td class="p-0 text-end"><span class="text-muted"><span *ngIf="localCurrencyISOCode">{{localCurrencyISOCode}}</span></span></td>
                                            </tr>
                                            <tr>
                                                <td class="p-0">
                                                    <span>
                                                        <p class="py-0 my-0"><strong>Fixed Fees</strong></p>
                                                    </span>
                                                </td>
                                                <td class="p-0"></td>
                                                <td class="p-0"></td>
                                                <td class="p-0"></td>
                                                <td class="p-0"></td>
                                                <td class="p-0"></td>
                                                <td class="p-0 text-end">
                                                    <span *ngIf="dealerFeesFixedFeesTotal">
                                                        {{dealerFeesFixedFeesTotal | number : '1.2-2'}}
                                                    </span>
                                                    <span *ngIf="dealerFeesFixedFeesTotal === 0">
                                                        {{0 | number : '1.2-2'}}
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="p-0">
                                                    <span class="ms-1">
                                                        Fuels - Fixed Fees
                                                    </span>
                                                </td>
                                                <td class="p-0 text-end">
                                                    <span *ngIf="dealerFeesFuelFixedFeesDodoDiscountGiven">
                                                        {{dealerFeesFuelFixedFeesDodoDiscountGiven | number : '1.2-2'}}
                                                    </span>
                                                    <span *ngIf="dealerFeesFuelFixedFeesDodoDiscountGiven === 0">
                                                        {{0 | number : '1.2-2'}}
                                                    </span>
                                                </td>
                                                <td class="p-0 text-end">
                                                    <span *ngIf="dealerFeesFuelFixedFeesProductVolume">
                                                        {{dealerFeesFuelFixedFeesProductVolume | number : '1.2-2'}}
                                                    </span>
                                                    <span *ngIf="dealerFeesFuelFixedFeesProductVolume === 0">
                                                        {{0 | number : '1.2-2'}}
                                                    </span>
                                                </td>
                                                <td class="p-0 text-end">
                                                    <span *ngIf="dealerFeesFuelFixedFeesRate">
                                                        {{dealerFeesFuelFixedFeesRate | number : '1.2-2'}} %
                                                    </span>
                                                    <span *ngIf="dealerFeesFuelFixedFeesRate === 0">
                                                        {{0 | number : '1.2-2'}} %
                                                    </span>
                                                </td>
                                                <td class="p-0 text-end"></td>
                                                <td class="p-0 text-end">
                                                    <span *ngIf="dealerFeesFuelFixedFeesTotal">
                                                        {{dealerFeesFuelFixedFeesTotal | number : '1.2-2'}}
                                                    </span>
                                                    <span *ngIf="dealerFeesFuelFixedFeesTotal === 0">
                                                        {{0 | number : '1.2-2'}}
                                                    </span>
                                                </td>
                                                <td class="p-0 text-end"></td>
                                            </tr>
                                            <tr>
                                                <td class="p-0">
                                                    <span class="ms-1">
                                                        Diversifications - Fixed Fees
                                                    </span>
                                                </td>
                                                <td class="p-0"></td>
                                                <td class="p-0"></td>
                                                <td class="p-0"></td>
                                                <td class="p-0"></td>
                                                <td class="p-0 text-end">
                                                    <span *ngIf="dealerFeesDiversificationFixedFeesTotal">
                                                        {{dealerFeesDiversificationFixedFeesTotal | number : '1.2-2'}}
                                                    </span>
                                                    <span *ngIf="dealerFeesDiversificationFixedFeesTotal === 0">
                                                        {{0 | number : '1.2-2'}}
                                                    </span>
                                                </td>
                                                <td class="p-0"></td>
                                            </tr>
                                            <tr
                                                *ngFor="let m of dealerFeesDiversificationFixedFeesItemArray; let i = index;">
                                                <td class="p-0">
                                                    <span class="ms-2" *ngIf="m.name">
                                                        {{m.name}}
                                                    </span>
                                                </td>
                                                <td class="p-0"></td>
                                                <td class="p-0"></td>
                                                <td class="p-0"></td>
                                                <td class="p-0 text-end">
                                                    <span *ngIf="m.total">
                                                        {{m.total | number : '1.2-2'}}
                                                    </span>
                                                    <span *ngIf="m.total === 0">
                                                        {{0 | number : '1.2-2'}}
                                                    </span>
                                                </td>
                                                <td class="p-0 text-end"></td>
                                                <td class="p-0"></td>
                                            </tr>
                                            <tr>
                                                <td class="p-0">
                                                    <span>
                                                        <p class="py-0 my-0"><strong>Variable Fees</strong></p>
                                                    </span>
                                                </td>
                                                <td class="p-0"></td>
                                                <td class="p-0"></td>
                                                <td class="p-0"></td>
                                                <td class="p-0"></td>
                                                <td class="p-0"></td>
                                                <td class="p-0 text-end">
                                                    <span *ngIf="dealerFeesVariableFeesTotal">
                                                        {{dealerFeesVariableFeesTotal | number : '1.2-2'}}
                                                    </span>
                                                    <span *ngIf="dealerFeesVariableFeesTotal === 0">
                                                        {{0 | number : '1.2-2'}}
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="p-0">
                                                    <span class="ms-1">
                                                        Fuels - Variable Fees
                                                    </span>
                                                </td>
                                                <td class="p-0"></td>
                                                <td class="p-0 text-end">
                                                    <span *ngIf="dealerFeesFuelVariableFeesYearly">
                                                        {{dealerFeesFuelVariableFeesYearly | number : '1.2-2'}}
                                                    </span>
                                                    <span *ngIf="dealerFeesFuelVariableFeesYearly === 0">
                                                        {{0 | number : '1.2-2'}}
                                                    </span>
                                                </td>
                                                <td class="p-0 text-end">
                                                    <span *ngIf="dealerFeesFuelVariableFeesRate">
                                                        {{dealerFeesFuelVariableFeesRate | number : '1.2-2'}}
                                                    </span>
                                                    <span *ngIf="dealerFeesFuelVariableFeesRate === 0">
                                                        {{0 | number : '1.2-2'}}
                                                    </span>
                                                </td>
                                                <td class="p-0"></td>
                                                <td class="p-0 text-end">
                                                    <span *ngIf="dealerFeesFuelVariableFeesTotal">
                                                        {{dealerFeesFuelVariableFeesTotal | number : '1.2-2'}}
                                                    </span>
                                                    <span *ngIf="dealerFeesFuelVariableFeesTotal === 0">
                                                        {{0 | number : '1.2-2'}}
                                                    </span>
                                                </td>
                                                <td class="p-0"></td>
                                            </tr>
                                            <tr>
                                                <td class="p-0">
                                                    <span class="ms-1">
                                                        Diversifications - Variable Fees
                                                    </span>
                                                </td>
                                                <td class="p-0"></td>
                                                <td class="p-0 text-end">
                                                    <span *ngIf="dealerFeesDiversificationVariableFeesYearly">
                                                        {{dealerFeesDiversificationVariableFeesYearly | number :
                                                        '1.2-2'}}
                                                    </span>
                                                    <span *ngIf="dealerFeesDiversificationVariableFeesYearly === 0">
                                                        {{0 | number : '1.2-2'}}
                                                    </span>
                                                </td>
                                                <td class="p-0 text-end">
                                                    <span *ngIf="dealerFeesDiversificationVariableFeesRate">
                                                        {{dealerFeesDiversificationVariableFeesRate | number : '1.2-2'}}
                                                        %
                                                    </span>
                                                    <span *ngIf="dealerFeesDiversificationVariableFeesRate === 0">
                                                        {{0 | number : '1.2-2'}} %
                                                    </span>
                                                </td>
                                                <td class="p-0 text-end"></td>
                                                <td class="p-0 text-end">
                                                    <span *ngIf="dealerFeesDiversificationVariableFeesTotal">
                                                        {{dealerFeesDiversificationVariableFeesTotal | number :
                                                        '1.2-2'}}
                                                    </span>
                                                    <span *ngIf="dealerFeesDiversificationVariableFeesTotal === 0">
                                                        {{0 | number : '1.2-2'}}
                                                    </span>
                                                </td>
                                                <td class="p-0"></td>
                                            </tr>
                                            <tr>
                                                <td class="p-0">
                                                    <span class="ms-1">
                                                        Card Commission - Fees
                                                    </span>
                                                </td>
                                                <td class="p-0"></td>
                                                <td class="p-0 text-end">
                                                    <span *ngIf="dealerFeesCardCommissionYearly">
                                                        {{dealerFeesCardCommissionYearly | number : '1.2-2'}}
                                                    </span>
                                                    <span *ngIf="dealerFeesCardCommissionYearly === 0">
                                                        {{0 | number : '1.2-2'}}
                                                    </span>
                                                </td>
                                                <td class="p-0 text-end">
                                                    <span *ngIf="dealerFeesCardCommissionRate">
                                                        {{dealerFeesCardCommissionRate | number : '1.2-2'}} %
                                                    </span>
                                                    <span *ngIf="dealerFeesCardCommissionRate === 0">
                                                        {{0 | number : '1.2-2'}} %
                                                    </span>
                                                </td>
                                                <td class="p-0"></td>
                                                <td class="p-0 text-end">
                                                    <span *ngIf="dealerFeesCardCommissionTotal">
                                                        {{dealerFeesCardCommissionTotal | number : '1.2-2'}}
                                                    </span>
                                                    <span *ngIf="dealerFeesCardCommissionTotal === 0">
                                                        {{0 | number : '1.2-2'}}
                                                    </span>
                                                </td>
                                                <td class="p-0"></td>
                                            </tr>
                                            <tr>
                                                <td class="p-0">
                                                    <span>
                                                        <p class="py-0 my-0"><strong>Total</strong></p>
                                                    </span>
                                                </td>
                                                <td class="p-0"></td>
                                                <td class="p-0"></td>
                                                <td class="p-0"></td>
                                                <td class="p-0"></td>
                                                <td class="p-0"></td>
                                                <td class="p-0 text-end">
                                                    <span *ngIf="dealerFeesTotal">
                                                        {{dealerFeesTotal | number : '1.2-2'}}
                                                    </span>
                                                    <span *ngIf="dealerFeesTotal === 0">
                                                        {{0 | number : '1.2-2'}}
                                                    </span>
                                                </td>
                                            </tr>
                                        </tbody>

                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card" *ngIf="showProjectDealerFeesEdit">
                        <div class="card-header mb-0">
                            <h5>
                                <span>Fixed Fees - Diversification - Items</span>
                            </h5>
                        </div>

                        <div class="card-body">
                            <table class="table table-sm table-borderless table-centered mb-2">
                                <thead>
                                    <tr>
                                        <th class="p-0"></th>
                                        <th class="p-0">
                                            <span class="text-muted">Item</span>
                                        </th>
                                        <th class="p-0">
                                            <span class="text-muted">Cost</span>
                                        </th>
                                        <th class="p-0" *ngIf="showProjectDealerFeesEdit"></th>
                                    </tr>
                                </thead>
                                <tbody class="table-group-divider"
                                    *ngFor="let m of dealerFeesDiversificationFixedFeesItemArray; let i = index;">
                                    <tr>
                                        <td class="p-0">
                                            <span>
                                                {{i+1}}.
                                            </span>
                                        </td>
                                        <td class="p-0">
                                            <span *ngIf="m.name">
                                                {{m.name}}
                                            </span>
                                        </td>
                                        <td class="p-0">
                                            <span *ngIf="m.total">
                                                {{m.total | number : '1.2-2'}}
                                            </span>
                                            <span *ngIf="m.total === 0">
                                                {{0 | number : '1.2-2'}}
                                            </span>
                                        </td>
                                        <td class="p-0" *ngIf="showProjectDealerFeesEdit">
                                            <button class="btn btn-sm btn-outline-danger"
                                                *ngIf="!submitInProgress && !updateInProgress"
                                                (click)="removeDiversificationFixedFeesItem(i)" type="button">X</button>
                                            <button class="btn btn-sm btn-outline-danger" type="button" disabled
                                                *ngIf="submitInProgress || updateInProgress">
                                                <span class="spinner-border spinner-border-sm" role="status"
                                                    aria-hidden="true"></span>
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                            <form name="signup-form" [formGroup]="diversificationFixedFeesForm">
                                <div class="input-group input-group-sm">
                                    <span class="input-group-text" id="inputGroup-sizing-sm"
                                        *ngIf="(dealerFeesDiversificationFixedFeesItemArray.length)>0">{{dealerFeesDiversificationFixedFeesItemArray.length+1}}</span>
                                    <span class="input-group-text" id="inputGroup-sizing-sm"
                                        *ngIf="dealerFeesDiversificationFixedFeesItemArray.length === 0">1</span>
                                    <input type="text" class="form-control" placeholder="Name" id="name"
                                        aria-label="Sizing example input" formControlName="name"
                                        aria-describedby="inputGroup-sizing-sm">
                                    <input type="number" min="1" class="form-control" id="total"
                                        aria-label="Sizing example input" formControlName="total"
                                        aria-describedby="inputGroup-sizing-sm">
                                    <button class="btn btn-outline-success"
                                        (click)="addDiversificationFixedFeesItem(diversificationFixedFeesForm.value)"
                                        type="button">Add Item</button>
                                    <button class="btn btn-sm btn-outline-danger" type="button" disabled
                                        *ngIf="submitInProgress || updateInProgress">
                                        <span class="spinner-border spinner-border-sm" role="status"
                                            aria-hidden="true"></span>
                                    </button>
                                </div>
                            </form>
                        </div>
                        <div class="card-footer">
                            <div class="row ms-1">
                                <div class="col-sm-12 text-end">
                                    <span class="px-3">
                                        Total
                                    </span>
                                    <span *ngIf="dealerFeesDiversificationFixedFeesTotal">
                                        {{dealerFeesDiversificationFixedFeesTotal | number:'1.2-2'}}
                                    </span>
                                    <span *ngIf="dealerFeesDiversificationFixedFeesTotal === 0">
                                        {{0 | number : '1.2-2'}}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card" *ngIf="showProjectDealerFeesEdit">
                        <div class="card-header mb-0">
                            <h5>
                                <span>Variable Fees - Diversification - Items</span>
                            </h5>
                        </div>
                        <div class="card-body">

                            <table class="table table-sm table-borderless table-centered mb-2">
                                <thead>
                                    <tr>
                                        <th class="p-0"></th>
                                        <th class="p-0">
                                            <span class="text-muted">Item</span>
                                        </th>
                                        <th class="p-0">
                                            <span class="text-muted">Cost</span>
                                        </th>
                                        <th class="p-0" *ngIf="showProjectDealerFeesEdit"></th>
                                    </tr>
                                </thead>
                                <tbody class="table-group-divider"
                                    *ngFor="let m of dealerFeesDiversificationVariableFeesItemArray; let i = index;">
                                    <tr>
                                        <td class="p-0">
                                            <span>
                                                {{i+1}}.
                                            </span>
                                        </td>
                                        <td class="p-0">
                                            <span *ngIf="m.name">
                                                {{m.name}}
                                            </span>
                                        </td>
                                        <td class="p-0">
                                            <span *ngIf="m.total">
                                                {{m.total | number : '1.2-2'}}
                                            </span>
                                            <span *ngIf="m.total === 0">
                                                {{0 | number : '1.2-2'}}
                                            </span>
                                        </td>
                                        <td class="p-0" *ngIf="showProjectDealerFeesEdit">
                                            <button class="btn btn-sm btn-outline-danger"
                                                *ngIf="!submitInProgress && !updateInProgress"
                                                (click)="removeDiversificationVariableFeesItem(i)"
                                                type="button">X</button>
                                            <button class="btn btn-sm btn-outline-danger" type="button" disabled
                                                *ngIf="submitInProgress || updateInProgress">
                                                <span class="spinner-border spinner-border-sm" role="status"
                                                    aria-hidden="true"></span>
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                            <form name="signup-form" [formGroup]="diversificationVariableFeesForm">
                                <div class="input-group input-group-sm">
                                    <span class="input-group-text" id="inputGroup-sizing-sm"
                                        *ngIf="(dealerFeesDiversificationVariableFeesItemArray.length)>0">{{dealerFeesDiversificationVariableFeesItemArray.length+1}}</span>
                                    <span class="input-group-text" id="inputGroup-sizing-sm"
                                        *ngIf="dealerFeesDiversificationVariableFeesItemArray.length === 0">1</span>
                                    <input type="text" class="form-control" placeholder="Name" id="name"
                                        aria-label="Sizing example input" formControlName="name"
                                        aria-describedby="inputGroup-sizing-sm">
                                    <input type="number" min="1" class="form-control" id="total"
                                        aria-label="Sizing example input" formControlName="total"
                                        aria-describedby="inputGroup-sizing-sm">
                                    <button class="btn btn-outline-success"
                                        (click)="addDiversificationVariableFeesItem(diversificationVariableFeesForm.value)"
                                        type="button">Add Item</button>
                                    <button class="btn btn-sm btn-outline-danger" type="button" disabled
                                        *ngIf="submitInProgress || updateInProgress">
                                        <span class="spinner-border spinner-border-sm" role="status"
                                            aria-hidden="true"></span>
                                    </button>
                                </div>
                            </form>
                        </div>
                        <div class="card-footer">
                            <div class="row ms-1">
                                <div class="col-sm-12 text-end">
                                    <span class="px-3">
                                        Total
                                    </span>
                                    <span *ngIf="dealerFeesDiversificationVariableFeesTotal">
                                        {{dealerFeesDiversificationVariableFeesTotal | number:'1.2-2'}}
                                    </span>
                                    <span *ngIf="dealerFeesDiversificationVariableFeesTotal === 0">
                                        {{0 | number : '1.2-2'}}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-6 text-end">
                                    <span>
                                        Dealer Fees Total :
                                    </span>
                                </div>
                                <div class="col-6 text-start">
                                    <span *ngIf="dealerFeesTotal">
                                        {{dealerFeesTotal | number : '1.2-2'}}
                                    </span>
                                    <span *ngIf="dealerFeesTotal === 0">
                                        {{0 | number : '1.2-2'}}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card-footer" *ngIf="!showProjectDealerFeesSection">
            <div class="row">
                <div class="col-sm-6"></div>
                <div class="col-sm-6">
                    <div class="d-grid">
                        <button class="btn btn-sm btn-outline-secondary"
                            (click)="showProjectDealerFeesSectionButton(true)" type="button"><i
                                class=""></i>View</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="card-footer" *ngIf="showProjectDealerFeesSection">
            <div class="row" *ngIf="showProjectDealerFeesEdit">
                <div class="col-sm-6">
                    <div class="d-grid">
                        <button class="btn btn-sm btn-outline-secondary"
                            (click)="showProjectDealerFeesEditButton(false)" type="button"><i class=""></i>Simple
                            View</button>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="d-grid">
                        <button class="btn btn-sm btn-outline-success" (click)="submitProjectDealerFees()" type="button"
                            *ngIf="!submitInProgress && !updateInProgress"><i class=""></i>Submit</button>
                        <button class="btn btn-sm btn-outline-primary" type="button" disabled
                            *ngIf="submitInProgress || updateInProgress">
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            
                        </button>
                    </div>
                </div>
            </div>
            <div class="row" *ngIf="!showProjectDealerFeesEdit">
                <div class="col-sm-6">
                    <div class="d-grid">
                        <button class="btn btn-sm btn-outline-secondary" (click)="showProjectDealerFeesEditButton(true)"
                            type="button"><i class=""></i>Edit
                            View</button>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="d-grid">
                        <button class="btn btn-sm btn-outline-success" (click)="submitProjectDealerFees()" type="button"
                            *ngIf="!submitInProgress && !updateInProgress"><i class=""></i>Submit</button>
                        <button class="btn btn-sm btn-outline-primary" type="button" disabled
                            *ngIf="submitInProgress || updateInProgress">
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Step 14. Summary - Dealer -->
    <div id="stepFourteen" class="border border-secondary card stepFourteen">
        <div class="card-header">
            <div class="table-responsive-sm" *ngIf="projectArray">
                <table class="table table-sm table-centered table-borderless mb-0" *ngIf="projectArray[0]">
                    <tbody>
                        <tr>
                            <td class="p-0" style="width:60%">
                                Step 14. Summary - Dealer
                            </td>
                            <td class="p-0" style="width:30%">
                                <p class="mb-0">
                                    Completion
                                    <ngb-progressbar [showValue]="true" type="info" [striped]="true" [animated]="true"
                                        [value]="projectDealerSummaryProgressPercentage"></ngb-progressbar>
                                </p>
                            </td>
                            <td class="p-0 text-center text-primary" style="width:10%">
                                <i class="mdi mdi-help-circle-outline mdi-24px"></i>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div> <!-- end table-responsive-->
        </div>
        <div class="card-body" *ngIf="showProjectDealerSummarySection">
            <div class="card">
                <div class="card-body">
                    <div class="board">
                        <div class="table-responsive-xxl">
                            <table class="table table-sm table-borderless table-centered mb-0">
                                <thead>
                                    <tr>
                                        <th class="p-0">
                                        </th>
                                        <th class="p-0">
                                        </th>
                                        <th class="p-0">
                                        </th>
                                        <th class="p-0">
                                        </th>
                                        <th class="p-0 text-end">
                                        </th>
                                    </tr>
                                </thead>
                                <tbody class="table-group-divider">
                                    <tr>
                                        <td class="p-0"></td>
                                        <td class="p-0">
                                            <span>
                                                Revenues
                                            </span>
                                        </td>
                                        <td class="p-0"></td>
                                        <td class="p-0"></td>
                                        <td class="p-0 text-end">
                                            <span *ngIf="dealerSummaryRevenue">
                                                {{dealerSummaryRevenue | number : '1.2-2'}} <span *ngIf="localCurrencyISOCode">{{localCurrencyISOCode}}</span>
                                            </span>
                                            <span *ngIf="dealerSummaryRevenue === 0">
                                                {{0 | number : '1.2-2'}} <span *ngIf="localCurrencyISOCode">{{localCurrencyISOCode}}</span>
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="p-0"></td>
                                        <td class="p-0">
                                            <span>
                                                Expenses
                                            </span>
                                        </td>
                                        <td class="p-0"></td>
                                        <td class="p-0"></td>
                                        <td class="p-0 text-end">
                                            <span *ngIf="dealerSummaryExpense">
                                                ({{dealerSummaryExpense | number : '1.2-2'}}) <span *ngIf="localCurrencyISOCode">{{localCurrencyISOCode}}</span>
                                            </span>
                                            <span *ngIf="dealerSummaryExpense === 0">
                                                ({{0 | number : '1.2-2'}}) <span *ngIf="localCurrencyISOCode">{{localCurrencyISOCode}}</span>
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="p-0">1.</td>
                                        <td class="p-0">
                                            <span>
                                                <p class="py-0 my-0"><strong>EBITDA (Before Fees)</strong></p>
                                            </span>
                                        </td>
                                        <td class="p-0"></td>
                                        <td class="p-0"></td>
                                        <td class="p-0  fw-bold text-end">
                                            <span *ngIf="dealerSummaryEbitdaBeforeFees">
                                                {{dealerSummaryEbitdaBeforeFees | number : '1.2-2'}} <span *ngIf="localCurrencyISOCode">{{localCurrencyISOCode}}</span>
                                            </span>
                                            <span *ngIf="dealerSummaryEbitdaBeforeFees === 0">
                                                {{0 | number : '1.2-2'}} <span *ngIf="localCurrencyISOCode">{{localCurrencyISOCode}}</span>
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="p-0"></td>
                                        <td class="p-0">
                                            <span>
                                                Fees
                                            </span>
                                        </td>
                                        <td class="p-0"></td>
                                        <td class="p-0"></td>
                                        <td class="p-0 text-end">
                                            <span *ngIf="dealerSummaryFees">
                                                ({{dealerSummaryFees | number : '1.2-2'}}) <span *ngIf="localCurrencyISOCode">{{localCurrencyISOCode}}</span>
                                            </span>
                                            <span *ngIf="dealerSummaryFees === 0">
                                                ({{0 | number : '1.2-2'}}) <span *ngIf="localCurrencyISOCode">{{localCurrencyISOCode}}</span>
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="p-0">2.</td>
                                        <td class="p-0">
                                            <span>
                                                <p class="py-0 my-0"><strong>EBITDA</strong></p>
                                            </span>
                                        </td>
                                        <td class="p-0"></td>
                                        <td class="p-0"></td>
                                        <td class="p-0  fw-bold text-end">
                                            <span *ngIf="dealerSummaryEbitda">
                                                {{dealerSummaryEbitda | number : '1.2-2'}} <span *ngIf="localCurrencyISOCode">{{localCurrencyISOCode}}</span>
                                            </span>
                                            <span *ngIf="dealerSummaryEbitda === 0">
                                                {{0 | number : '1.2-2'}} <span *ngIf="localCurrencyISOCode">{{localCurrencyISOCode}}</span>
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="p-0"></td>
                                        <td class="p-0"></td>
                                        <td class="p-0"><span class="text-muted">Taxable Profit</span></td>
                                        <td class="p-0"><span class="text-muted">Rate</span></td>
                                        <td class="p-0 text-end"><span></span></td>
                                    </tr>
                                    <tr>
                                        <td class="p-0"></td>
                                        <td class="p-0">
                                            <p class="py-0 my-0"><strong>Corporate Income Tax</strong></p>
                                        </td>
                                        <td class="p-0">
                                            <span *ngIf="dealerSummaryTaxableProfit">
                                                {{dealerSummaryTaxableProfit | number : '1.2-2'}}
                                                <span *ngIf="localCurrencyISOCode">{{localCurrencyISOCode}}</span>
                                            </span>
                                            <span *ngIf="dealerSummaryTaxableProfit === 0">
                                                {{0 | number : '1.2-2'}} <span *ngIf="localCurrencyISOCode">{{localCurrencyISOCode}}</span>
                                            </span>
                                        </td>
                                        <td class="p-0">
                                            <span *ngIf="dealerSummaryTaxRate">
                                                {{dealerSummaryTaxRate | number : '1.2-2'}} %
                                            </span>
                                            <span *ngIf="dealerSummaryTaxRate === 0">
                                                {{0 | number : '1.2-2'}} %
                                            </span>
                                        </td>
                                        <td class="p-0 text-end">
                                            <span *ngIf="dealerSummaryTax">
                                                ({{dealerSummaryTax | number : '1.2-2'}}) <span *ngIf="localCurrencyISOCode">{{localCurrencyISOCode}}</span>
                                            </span>
                                            <span *ngIf="dealerSummaryTax === 0">
                                                ({{0 | number : '1.2-2'}}) <span *ngIf="localCurrencyISOCode">{{localCurrencyISOCode}}</span>
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="p-0">3.</td>
                                        <td class="p-0">
                                            <span>
                                                <p class="py-0 my-0"><strong>Net Cash After Tax Before
                                                        Deposit</strong>
                                                </p>
                                            </span>
                                        </td>
                                        <td class="p-0"></td>
                                        <td class="p-0"></td>
                                        <td class="p-0  fw-bold text-end">
                                            <span *ngIf="dealerSummaryNetCashAfterTaxBeforeDeposit">
                                                {{dealerSummaryNetCashAfterTaxBeforeDeposit | number : '1.2-2'}} <span *ngIf="localCurrencyISOCode">{{localCurrencyISOCode}}</span>
                                            </span>
                                            <span *ngIf="dealerSummaryNetCashAfterTaxBeforeDeposit === 0">
                                                {{0 | number : '1.2-2'}} <span *ngIf="localCurrencyISOCode">{{localCurrencyISOCode}}</span>
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="p-0"></td>
                                        <td class="p-0">
                                            <p class="py-0 my-0"><strong>Deposits</strong></p>
                                        </td>
                                        <td class="p-0"></td>
                                        <td class="p-0"></td>
                                        <td class="p-0 text-end"><span>{{0 | number : '1.2-2'}} <span *ngIf="localCurrencyISOCode">{{localCurrencyISOCode}}</span></span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="p-0"></td>
                                        <td class="p-0"></td>
                                        <td class="p-0"><span class="text-muted">White Products Volume</span></td>
                                        <td class="p-0"><span class="text-muted">Rate</span></td>
                                        <td class="p-0 text-end"><span></span></td>
                                    </tr>
                                    <tr>
                                        <td class="p-0"></td>
                                        <td class="p-0 ps-1">
                                            <span>
                                                to build WCR or loan repay.
                                            </span>
                                        </td>
                                        <td class="p-0">
                                            <span
                                                *ngIf="dealerSummaryDepositToBuildWCROrLoanRepayVolume">{{dealerSummaryDepositToBuildWCROrLoanRepayVolume
                                                | number : '1.2-2'}} m3
                                            </span>
                                            <span *ngIf="dealerSummaryDepositToBuildWCROrLoanRepayVolume === 0">
                                                {{0 | number : '1.2-2'}} m3
                                            </span>
                                        </td>
                                        <td class="p-0">
                                            <span
                                                *ngIf="dealerSummaryDepositToBuildWCROrLoanRepayRate">{{dealerSummaryDepositToBuildWCROrLoanRepayRate
                                                | number : '1.2-2'}} %</span>
                                            <span *ngIf="dealerSummaryDepositToBuildWCROrLoanRepayRate === 0">{{0 |
                                                number : '1.2-2'}} %</span>
                                        </td>
                                        <td class="p-0 text-end">
                                            <span
                                                *ngIf="dealerSummaryDepositToBuildWCROrLoanRepay">{{dealerSummaryDepositToBuildWCROrLoanRepay
                                                | number : '1.2-2'}} <span *ngIf="localCurrencyISOCode">{{localCurrencyISOCode}}</span></span>
                                            <span *ngIf="dealerSummaryDepositToBuildWCROrLoanRepay === 0">{{0 |
                                                number :
                                                '1.2-2'}} <span *ngIf="localCurrencyISOCode">{{localCurrencyISOCode}}</span></span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="p-0"></td>
                                        <td class="p-0 ps-1">
                                            <span>
                                                for security deposit.
                                            </span>
                                        </td>
                                        <td class="p-0">
                                            <span
                                                *ngIf="dealerSummaryDepositForSecurityVolume">{{dealerSummaryDepositForSecurityVolume
                                                | number : '1.2-2'}} m3</span>
                                            <span *ngIf="dealerSummaryDepositForSecurityVolume === 0">{{0 | number :
                                                '1.2-2'}} m3</span>
                                        </td>
                                        <td class="p-0">
                                            <span
                                                *ngIf="dealerSummaryDepositForSecurityRate">{{dealerSummaryDepositForSecurityRate
                                                | number : '1.2-2'}} %</span>
                                            <span *ngIf="dealerSummaryDepositForSecurityRate === 0">{{0 | number :
                                                '1.2-2'}} %</span>
                                        </td>
                                        <td class="p-0 text-end">
                                            <span
                                                *ngIf="dealerSummaryDepositForSecurity">{{dealerSummaryDepositForSecurity
                                                | number : '1.2-2'}} <span *ngIf="localCurrencyISOCode">{{localCurrencyISOCode}}</span></span>
                                            <span *ngIf="dealerSummaryDepositForSecurity === 0">{{0 | number :
                                                '1.2-2'}}
                                                <span *ngIf="localCurrencyISOCode">{{localCurrencyISOCode}}</span></span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="p-0">4.</td>
                                        <td class="p-0">
                                            <span>
                                                <p class="py-0 my-0"><strong>Net Cash After Tax & Deposits</strong>
                                                </p>
                                            </span>
                                        </td>
                                        <td class="p-0"></td>
                                        <td class="p-0"></td>
                                        <td class="p-0 fw-bold text-end">
                                            <span
                                                *ngIf="dealerSummaryNetCashAfterTaxAndDeposit">{{dealerSummaryNetCashAfterTaxAndDeposit
                                                | number : '1.2-2'}} <span *ngIf="localCurrencyISOCode">{{localCurrencyISOCode}}</span></span>
                                            <span *ngIf="dealerSummaryNetCashAfterTaxAndDeposit === 0">{{0 | number
                                                :
                                                '1.2-2'}} <span *ngIf="localCurrencyISOCode">{{localCurrencyISOCode}}</span></span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <div class="card-footer" *ngIf="!showProjectDealerSummarySection">
            <div class="row">
                <div class="col-sm-6"></div>
                <div class="col-sm-6">
                    <div class="d-grid">
                        <button class="btn btn-sm btn-outline-secondary"
                            (click)="showProjectDealerSummarySectionButton(true)" type="button"><i
                                class=""></i>View</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="card-footer" *ngIf="showProjectDealerSummarySection">
            <div class="row">
                <div class="col-sm-6">
                </div>
                <div class="col-sm-6">
                    <div class="d-grid">
                        <button class="btn btn-sm btn-outline-success" (click)="submitProjectDealerSummary()"
                            type="button" *ngIf="!submitInProgress && !updateInProgress"><i class=""></i>Submit</button>
                        <button class="btn btn-sm btn-outline-primary" type="button" disabled
                            *ngIf="submitInProgress || updateInProgress">
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-lg-6">
            <div class="card text-white bg-info overflow-hidden" role="button" (click)="navigateTo('report')">
                <div class="card-body">
                    <div class="toll-free-box text-center">
                        <h4> <i class="mdi mdi-file-document-edit-outline"></i> View/Edit Final Report</h4>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-lg-6">
            <div class="card text-white bg-danger overflow-hidden" role="button" (click)="navigateTo('result')">
                <div class="card-body">
                    <div class="toll-free-box text-center">
                        <h4> <i class="mdi mdi-file-chart-outline"></i> View/Edit Appraisal Results</h4>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-lg-6">
            <div class="card bg-success text-white" role="button" (click)="navigateTo('companyPDF')">
                <div class="card-body">
                    <div class="toll-free-box text-center">
                        <h4> <i class="mdi mdi-file-download-outline"></i> Print Company Summary (PDF)</h4>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-lg-6">
            <div class="card bg-success text-white" role="button" (click)="navigateTo('dealerPDF')">
                <div class="card-body">
                    <div class="toll-free-box text-center">
                        <h4> <i class="mdi mdi-file-download-outline"></i> Print Dealer Summary (PDF)</h4>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>