export const environment = {
  firebase: {
    apiKey: "AIzaSyAzjQnrjNrLJUNV8UhQg8OKef81m_qvTV4",
    authDomain: "bold-momentum-410907.firebaseapp.com",
    databaseURL: "https://bold-momentum-410907-default-rtdb.firebaseio.com",
    projectId: "bold-momentum-410907",
    storageBucket: "bold-momentum-410907.appspot.com",
    messagingSenderId: "743960065236",
    appId: "1:743960065236:web:935cfc6602048e9d9fb741",
    measurementId: "G-QEEME69ZW1"
  },
  production: true,
  demo: '', // other possible options are, saas creative and modern
  GOOGLE_MAPS_API_KEY: 'AIzaSyC5pcAkhXSo6-KqV9RtZdUAhdJno7r_8b0'
};
