// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  firebase: {
    apiKey: "AIzaSyAzjQnrjNrLJUNV8UhQg8OKef81m_qvTV4",
    authDomain: "bold-momentum-410907.firebaseapp.com",
    databaseURL: "https://bold-momentum-410907-default-rtdb.firebaseio.com",
    projectId: "bold-momentum-410907",
    storageBucket: "bold-momentum-410907.appspot.com",
    messagingSenderId: "743960065236",
    appId: "1:743960065236:web:935cfc6602048e9d9fb741",
    measurementId: "G-QEEME69ZW1"
  },
  production: true,
  demo: '', // other possible options are, saas creative and modern
  GOOGLE_MAPS_API_KEY: 'AIzaSyC5pcAkhXSo6-KqV9RtZdUAhdJno7r_8b0'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
