<div class="card bg-transparent">
    <app-ui-preloader [display]="loading"></app-ui-preloader>
    <!-- Header -->
    <div class="card-header">
        <!-- title-->
        <div class="row flex-grow-1">
            <div class="col">
                <div class="pricing-header px-3 py-3 pt-md-3 pb-md-2 mx-auto text-center">
                    <h1 class="display-6">Project Report</h1>
                    <p class="lead text-muted">Fill in the project report</p>
                </div>
            </div>
        </div>
    </div>

    <!-- Summary -->
    <div class="card border border-primary">
        <div class="card-group">
            <div class="card mb-0">
                <div class="card-header text-center">
                    <span>Project Info</span>
                </div>
                <div class="card-body">
                    <div class="table-responsive-sm" *ngIf="projectArray">
                        <table class="table table-sm table-centered table-borderless mb-0" *ngIf="projectArray[0]">
                            <tbody>
                                <tr>
                                    <td class="p-0">
                                        Project Name
                                    </td>
                                    <td class="p-0">
                                        :
                                    </td>
                                    <td class="p-0">
                                        <span *ngIf="projectArray[0].name">{{projectArray[0].name}}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0">
                                        Country
                                    </td>
                                    <td class="p-0">
                                        :
                                    </td>
                                    <td class="p-0">
                                        <span *ngIf="projectArray[0].country">
                                            <span *ngIf="projectArray[0].country.name">
                                                {{projectArray[0].country.name}}
                                            </span>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0">
                                        1st Year of Investment
                                    </td>
                                    <td class="p-0">
                                        :
                                    </td>
                                    <td class="p-0">
                                        <span *ngIf="projectArray[0].startDate">{{projectArray[0].startDate}}</span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <div class="card mb-0">
            <div class="card-header text-center">
                <span>Overall Progress</span>
            </div>
            <div class="card-body">
                <p class="mb-0">
                    <ngb-progressbar [showValue]="true" type="info" [striped]="true" [animated]="true"
                        [value]="progressPercentage"></ngb-progressbar>
                </p>
            </div>
        </div>
    </div>

    <!-- Section Break -->
    <div class="border border-secondary card">
        <div class="card-body text-center text-primary">
            <span>Section A - Project Estimates</span>
        </div>
    </div>

    <!-- Step 1. Project Summary. -->
    <div id="stepOne" class="border border-secondary card stepOne">
        <div class="card-header">
            <div class="table-responsive-sm" *ngIf="projectArray">
                <table class="table table-sm table-centered table-borderless mb-0" *ngIf="projectArray[0]">
                    <tbody>
                        <tr>
                            <td class="p-0" style="width:60%">
                                Step 1. Project Summary.
                            </td>
                            <td class="p-0" style="width:30%">
                                <p class="mb-0">
                                    Completion
                                    <ngb-progressbar [showValue]="true" type="info" [striped]="true" [animated]="true"
                                        [value]="projectInvestmentProgressPercentage"></ngb-progressbar>
                                </p>
                            </td>
                            <td class="p-0 text-center text-primary" style="width:10%">
                                <i class="mdi mdi-help-circle-outline mdi-24px"></i>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div> <!-- end table-responsive-->
        </div>
        <div class="card-body">
            <div class="card">
                <div class="card-body">
                    <div class="border p-3 mt-lg-0 rounded">
                        <h4 class="header-title mb-3">Project Summary</h4>

                        <div class="table-responsive">
                            <table class="table mb-0">
                                <thead>
                                    <tr>
                                        <th class="p-0">
                                        </th>
                                        <th class="p-0">
                                        </th>
                                        <th class="p-0 w-25">
                                        </th>
                                        <th class="p-0 w-25">
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <th>Project Name</th>
                                        <td>:</td>
                                        <td *ngIf="projectName">{{projectName}}</td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th>Location</th>
                                        <td>:</td>
                                        <td *ngIf="projectLocationDescription">{{projectLocationDescription}}</td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th>Project Scope</th>
                                        <td>:</td>
                                        <td *ngIf="projectDescription">{{projectDescription}}</td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th>Project Dates</th>
                                        <td>:</td>
                                        <td *ngIf="projectStartDate"><span
                                                class="text-muted font-8">Start<br></span>{{projectStartDate |
                                            date:'medium'}}</td>
                                        <td *ngIf="projectEndDate"><span
                                                class="text-muted font-8">End<br></span>{{projectEndDate |
                                            date:'medium'}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <!-- end table-responsive -->
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Step 2. Project Estimate/ Actual Data. -->
    <div id="stepTwo" class="border border-secondary card stepTwo">
        <div class="card-header">
            <div class="table-responsive-sm">
                <table class="table table-sm table-centered table-borderless mb-0">
                    <tbody>
                        <tr>
                            <td class="p-0" style="width:60%">
                                Step 2. Project Estimate/Actual Data.
                            </td>
                            <td class="p-0" style="width:30%">
                                <p class="mb-0">
                                    Completion
                                    <ngb-progressbar [showValue]="true" type="info" [striped]="true" [animated]="true"
                                        [value]="projectInvestmentProgressPercentage"></ngb-progressbar>
                                </p>
                            </td>
                            <td class="p-0 text-center text-primary" style="width:10%">
                                <i class="mdi mdi-help-circle-outline mdi-24px"></i>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div> <!-- end table-responsive-->
        </div>
        <div class="card-body">
            <div class="card">
                <div class="card-header">
                    <div class="table-responsive-sm">
                        <table class="table table-sm table-centered table-borderless mb-0">
                            <tbody>
                                <tr>
                                    <td class="p-0" style="width:60%">
                                        A. Investments
                                    </td>
                                    <td class="p-0" style="width:30%">
                                    </td>
                                    <td class="p-0 text-center text-primary" style="width:10%">
                                        <i class="mdi mdi-help-circle-outline mdi-24px"></i>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="card-body">
                    <div class="board">
                        <table class="table table-sm table-borderless table-centered mb-0">
                            <thead>
                                <tr>
                                    <th class="p-0">
                                    </th>
                                    <th class="p-0">
                                    </th>
                                    <th class="p-0">
                                    </th>
                                </tr>
                            </thead>
                            <tbody class="table-group-divider">
                                <tr>
                                    <td class="p-0">
                                        <span>i. Branding</span>
                                    </td>
                                    <td class="p-0 ps-1">

                                    </td>
                                    <td class="p-0 text-end">
                                        <span *ngIf="companyInvestmentBrandingTotal">
                                            {{companyInvestmentBrandingTotal | number : '1.2-2'}}
                                        </span>
                                    </td>
                                </tr>

                            </tbody>
                            <tbody class="table-group-divider" *ngIf="brandingItemArray">
                                <tr *ngFor="let m of brandingItemArray;let i = index;">
                                    <td class="p-0 ps-3">
                                        <span *ngIf="m.name">{{m.name}}</span>
                                    </td>
                                    <td class="p-0 text-end">
                                        <span *ngIf="m.total">{{m.total | number : '1.2-2'}}</span>
                                    </td>
                                    <td class="p-0">

                                    </td>
                                </tr>
                            </tbody>
                            <tbody class="table-group-divider">
                                <tr>
                                    <td class="p-0">
                                        <span>ii. Construction</span>
                                    </td>
                                    <td class="p-0 ps-1">

                                    </td>
                                    <td class="p-0 text-end">
                                        <span *ngIf="companyInvestmentConstructionTotal">
                                            {{companyInvestmentConstructionTotal | number : '1.2-2'}}
                                        </span>
                                    </td>
                                </tr>
                            </tbody>
                            <tbody class="table-group-divider" *ngIf="constructionItemArray">
                                <tr *ngFor="let m of constructionItemArray;let i = index;">
                                    <td class="p-0 ps-3">
                                        <span *ngIf="m.name">{{m.name}}</span>
                                    </td>
                                    <td class="p-0 text-end">
                                        <span *ngIf="m.total">{{m.total | number : '1.2-2'}}</span>
                                    </td>
                                    <td class="p-0">

                                    </td>
                                </tr>
                            </tbody>
                            <tbody class="table-group-divider">
                                <tr>
                                    <td class="p-0">
                                        <span>iii. Equipment</span>
                                    </td>
                                    <td class="p-0 ps-1">

                                    </td>
                                    <td class="p-0 text-end">
                                        <span *ngIf="companyInvestmentEquipmentTotal">
                                            {{companyInvestmentEquipmentTotal | number : '1.2-2'}}
                                        </span>
                                    </td>
                                </tr>
                            </tbody>
                            <tbody class="table-group-divider" *ngIf="equipmentItemArray">
                                <tr *ngFor="let m of equipmentItemArray;let i = index;">
                                    <td class="p-0 ps-3">
                                        <span *ngIf="m.name">{{m.name}}</span>
                                    </td>
                                    <td class="p-0 text-end">
                                        <span *ngIf="m.total">{{m.total | number : '1.2-2'}}</span>
                                    </td>
                                    <td class="p-0">

                                    </td>
                                </tr>
                            </tbody>
                            <tbody class="table-group-divider">
                                <tr>
                                    <td class="p-0 text-end">
                                        <span>Total</span>
                                    </td>
                                    <td class="p-0 ps-1">

                                    </td>
                                    <td class="p-0 text-end">
                                        <span *ngIf="companyInvestmentTotal">
                                            {{companyInvestmentTotal | number : '1.2-2'}}
                                        </span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="card">
                <div class="card-header">
                    <div class="table-responsive-sm">
                        <table class="table table-sm table-centered table-borderless mb-0">
                            <tbody>
                                <tr>
                                    <td class="p-0" style="width:60%">
                                        B. Sales Profit & Cashflow
                                    </td>
                                    <td class="p-0" style="width:30%">
                                    </td>
                                    <td class="p-0 text-center text-primary" style="width:10%">
                                        <i class="mdi mdi-help-circle-outline mdi-24px"></i>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="card-body">
                    <div class="board">
                        <table class="table table-sm table-bordeless table-centered mb-0">
                            <thead>
                                <tr>
                                    <th style="min-width:50px">

                                    </th>
                                    <th>
                                        Y - 0
                                    </th>
                                    <th>
                                        Y - 1
                                    </th>
                                    <th>
                                        Y - 2
                                    </th>
                                    <th>
                                        Y - 3
                                    </th>
                                    <th>
                                        Y - 4
                                    </th>
                                    <th>
                                        Y - 5
                                    </th>
                                    <th>
                                        Y - 6
                                    </th>
                                    <th>
                                        Y - 7
                                    </th>
                                    <th>
                                        Y - 8
                                    </th>
                                    <th>
                                        Y - 9
                                    </th>
                                    <th>
                                        Y - 10
                                    </th>
                                    <th>
                                        Y - 11
                                    </th>
                                    <th>
                                        Y - 12
                                    </th>
                                    <th>
                                        Y - 13
                                    </th>
                                    <th>
                                        Y - 14
                                    </th>
                                    <th>
                                        Y - 15
                                    </th>
                                </tr>
                            </thead>
                            <tbody class="table-group-divider">
                                <tr>
                                    <td class="p-0 px-1">
                                        <span class="ps-1">
                                            Net Fuel Margin
                                        </span>
                                    </td>
                                    <td class="py-0" *ngFor="let m of companySummaryNetFuelMarginArray;let i = index;">
                                        <span *ngIf="!(m===0)">{{m| number:'1.2-2'}}</span>
                                        <span *ngIf="m===0">-</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0 px-1">
                                        <span class="ps-1">
                                            Diversification Margin
                                        </span>
                                    </td>
                                    <td class="py-0"
                                        *ngFor="let m of companySummaryDiversificationMarginArray;let i = index;">
                                        <span *ngIf="!(m===0)">{{m| number:'1.2-2'}}</span>
                                        <span *ngIf="m===0">-</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0 px-1">
                                        <span class="ps-1">
                                            Other Revenues
                                        </span>
                                    </td>
                                    <td class="py-0" *ngFor="let m of companySummaryOtherRevenueArray;let i = index;">
                                        <span *ngIf="!(m===0)">{{m| number:'1.2-2'}}</span>
                                        <span *ngIf="m===0">-</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0 px-1">
                                        <span class="ps-1">
                                            Operating Expenses
                                        </span>
                                    </td>
                                    <td class="py-0"
                                        *ngFor="let m of companySummaryOperatingExpensesArray;let i = index;">
                                        <span *ngIf="!(m===0)">({{m| number:'1.2-2'}})</span>
                                        <span *ngIf="m===0">-</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0 px-1">
                                        <span class="ps-1">
                                            Rentals & Fees Due
                                        </span>
                                    </td>
                                    <td class="py-0"
                                        *ngFor="let m of companySummaryRentalsAndFeesDueArray;let i = index;">
                                        <span *ngIf="!(m===0)">{{m| number:'1.2-2'}}</span>
                                        <span *ngIf="m===0">-</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0 px-1">
                                        <span class="ps-2 text-muted">
                                            <em>Financial Interest Received</em>
                                        </span>
                                    </td>
                                    <td class="py-0 text-muted"
                                        *ngFor="let m of companyInterestReceivedArray;let i = index;">
                                        <span *ngIf="!(m===0)">{{m| number:'1.2-2'}}</span>
                                        <span *ngIf="m===0">-</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0 px-1">
                                        <span>
                                            <p class="py-o my-0"><strong>EBITDA</strong></p>
                                        </span>
                                    </td>
                                    <td class="py-0" *ngFor="let m of companySummaryEBITDAArray;let i = index;">
                                        <span *ngIf="!(m===0)">{{m| number:'1.2-2'}}</span>
                                        <span *ngIf="m===0">-</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0 px-1">
                                        <span class="ps-1">
                                            Investments
                                        </span>
                                    </td>
                                    <td class="py-0" *ngFor="let m of companySummaryInvestmentArray;let i = index;">
                                        <span *ngIf="!(m===0)">({{m| number:'1.2-2'}})</span>
                                        <span *ngIf="m===0">-</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0 px-1">
                                        <span class="ps-1">
                                            Change in Working Capital
                                        </span>
                                    </td>
                                    <td class="py-0"
                                        *ngFor="let m of companySummaryChangeInWorkingCapitalArray;let i = index;">
                                        <span *ngIf="!(m===0)">({{(-m)| number:'1.2-2'}})</span>
                                        <span *ngIf="m===0">-</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0 px-1">
                                        <span class="ps-1">
                                            Taxes
                                        </span>
                                    </td>
                                    <td class="py-0" *ngFor="let m of companySummaryTaxesArray;let i = index;">
                                        <span *ngIf="!(m===0)">({{m| number:'1.2-2'}})</span>
                                        <span *ngIf="m===0">-</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0 px-1">
                                        <span class="ps-1">
                                            Other Cash Items
                                        </span>
                                    </td>
                                    <td class="py-0" *ngFor="let m of companySummaryOtherCashItemsArray;let i = index;">
                                        <span *ngIf="!(i===0)">
                                            <span *ngIf="!(m===0)">{{m| number:'1.2-2'}}</span>
                                            <span *ngIf="m===0">-</span>
                                        </span>
                                        <span *ngIf="(i===0)">
                                            <span *ngIf="!(m===0)">({{m| number:'1.2-2'}})</span>
                                            <span *ngIf="m===0">-</span>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0 px-1">
                                        <span>
                                            <p class="py-o my-0"><strong>Net Cash After Tax</strong></p>
                                        </span>
                                    </td>
                                    <td class="py-0"
                                        *ngFor="let m of companySummaryNetCashAfterTaxArray;let i = index;">
                                        <span *ngIf="!(m===0)">{{m| number:'1.2-2'}}</span>
                                        <span *ngIf="m===0">-</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0 px-1">
                                        <span class="ps-1">
                                            Financial Result
                                        </span>
                                    </td>
                                    <td class="py-0" *ngFor="let m of financingTotalArray;let i = index;">
                                        <span *ngIf="!(m===0)">{{m| number:'1.2-2'}}</span>
                                        <span *ngIf="m===0">-</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="p-0 px-1">
                                        <span class="ps-1">
                                            Inflation Rate (%)
                                        </span>
                                    </td>
                                    <td class="py-0" *ngFor="let m of inflationRateArray;let i = index;">
                                        <span *ngIf="!(m===0)">{{(m*100)| number:'1.1-1'}}%</span>
                                        <span *ngIf="m===0">-</span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Step 3. Evaluation. -->
    <div id="stepThree" class="border border-secondary card stepThree">
        <div class="card-header">
            <div class="table-responsive-sm" *ngIf="projectArray">
                <table class="table table-sm table-centered table-borderless mb-0" *ngIf="projectArray[0]">
                    <tbody>
                        <tr>
                            <td class="p-0" style="width:60%">
                                Step 3. Evaluation.
                            </td>
                            <td class="p-0" style="width:30%">
                                <p class="mb-0">
                                    Completion
                                    <ngb-progressbar [showValue]="true" type="info" [striped]="true" [animated]="true"
                                        [value]="projectSalesProfitAndCashFlowProgressPercentage"></ngb-progressbar>
                                </p>
                            </td>
                            <td class="p-0 text-center text-primary" style="width:10%">
                                <i class="mdi mdi-help-circle-outline mdi-24px"></i>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div> <!-- end table-responsive-->
        </div>
        <div class="card-body">
            <div class="card">
                <div class="card-header">
                    <div class="table-responsive-sm" *ngIf="projectArray">
                        <table class="table table-sm table-centered table-borderless mb-0" *ngIf="projectArray[0]">
                            <tbody>
                                <tr>
                                    <td class="p-0" style="width:60%">
                                        i. Net Present Value (NPV).
                                    </td>
                                    <td class="p-0" style="width:30%">
                                    </td>
                                    <td class="p-0 text-center text-primary" style="width:10%">
                                        <i class="mdi mdi-help-circle-outline mdi-24px"></i>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="card-body">

                    <div class="row mb-3">
                        <div class="col-4">
                            <span class="mt-0 mb-0 ms-2">Definition :</span>
                        </div>
                        <div class="col-8 border border-light px-0">
                            <span *ngIf="npvIntro">
                                {{npvIntro}}
                            </span>
                        </div>
                    </div>

                    <div class="row mb-3">
                        <div class="col-4">
                            <span class="mt-0 mb-0 ms-2">Recommended :</span>
                        </div>
                        <div class="col-8 border border-light px-0 text-center">
                            <span *ngIf="npvRecommended">
                                {{npvRecommended}}
                            </span>
                        </div>
                    </div>

                    <div class="row mb-3">
                        <div class="col-4">
                            <span class="mt-0 mb-0 ms-2">Project :</span>
                        </div>
                        <div class="col-8 border border-primary px-0 text-center">
                            <span *ngIf="projectAppraisalNpv">
                                {{projectAppraisalNpv|number:'1.2-2'}}
                            </span>
                        </div>
                    </div>

                    <!-- Remark By Other -->
                    <div class="row mb-3">
                        <div class="card">
                            <div class="card-header">
                                <span>Remarks<br></span>
                            </div>
                            <div class="card-body">
                                <ul class="conversation-list px-3">
                                    <li class="clearfix odd" *ngFor="let r of netPresentValueRemarkArray">
                                        <div class="chat-avatar" style="width: 60px;" *ngIf="!(r.from.profilePicture)">
                                            <img src="../../../assets/images/Male-Avatar.jpg" class="rounded"
                                                [alt]="r.from.firstName" />
                                            <i>{{r.date|date:'short'}}</i>
                                        </div>
                                        <div class="chat-avatar" style="width: 60px;" *ngIf="r.from.profilePicture">
                                            <img [src]="r.from.profilePicture" class="rounded"
                                                [alt]="r.from.firstName" />
                                            <i>{{r.date|date:'short'}}</i>
                                        </div>
                                        <div class="conversation-text">
                                            <div class="ctext-wrap">
                                                <i>{{r.from.firstName}} {{r.from.surname}}<br></i>
                                                <i><span class="text-muted">{{r.from.role}}</span></i>
                                                <p *ngIf="r.remark">
                                                    {{r.remark}}
                                                </p>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div class="card-body">
                                <div class="row mb-3">
                                    <div class="col-3 text-center">
                                        <p class="mt-0 mb-0 pb-0 ms-2">
                                            <img [src]="profilePictureUrl" class="me-2 rounded-circle" height="32"
                                                [alt]="loggedInUserFirstName + loggedInUserSurname" />
                                        </p>
                                        <h5 class="mt-0 mb-0 ms-2">{{loggedInUserFirstName+' '+loggedInUserSurname}}
                                        </h5>
                                        <h5 class="mt-0 mb-0 ms-2 text-muted">{{loggedInUserRole}}</h5>
                                        <small class="text-muted ms-2">{{loggedInUserDate|date:'short'}}</small>
                                    </div>
                                    <div class="col-9 px-0" name="gas-product-form"
                                        [formGroup]="netPresentValueRemarkForm">
                                        <textarea class="form-control form-control-light mb-2"
                                            placeholder="Write a remark." id="example-textarea" rows="6"
                                            formControlName="remark"></textarea>
                                        <div class="text-end">
                                            <div class="btn-group mb-2 ms-2">
                                                <button type="button" class="btn btn-primary btn-sm"
                                                    (click)="submitRemark(netPresentValueRemarkForm.value,'netPresentValueRemark')">Submit
                                                    Remark</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-footer"></div>
            </div>
            <div class="card">
                <div class="card-header">
                    <div class="table-responsive-sm" *ngIf="projectArray">
                        <table class="table table-sm table-centered table-borderless mb-0" *ngIf="projectArray[0]">
                            <tbody>
                                <tr>
                                    <td class="p-0" style="width:60%">
                                        ii. Payback Period.
                                    </td>
                                    <td class="p-0" style="width:30%">
                                    </td>
                                    <td class="p-0 text-center text-primary" style="width:10%">
                                        <i class="mdi mdi-help-circle-outline mdi-24px"></i>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="card-body">

                    <div class="row mb-3">
                        <div class="col-4">
                            <span class="mt-0 mb-0 ms-2">Definition :</span>
                        </div>
                        <div class="col-8 border border-light px-0">
                            <span *ngIf="paybackPeriodIntro">
                                {{paybackPeriodIntro}}
                            </span>
                        </div>
                    </div>

                    <div class="row mb-3">
                        <div class="col-4">
                            <span class="mt-0 mb-0 ms-2">Recommended :</span>
                        </div>
                        <div class="col-8 border border-light px-0 text-center">
                            <span *ngIf="paybackPeriodRecommended">
                                {{paybackPeriodRecommended}}
                            </span>
                        </div>
                    </div>

                    <div class="row mb-3">
                        <div class="col-4">
                            <span class="mt-0 mb-0 ms-2">Project :</span>
                        </div>
                        <div class="col-8 border border-primary px-0 text-center">
                            <span *ngIf="projectAppraisalPaybackPeriod">
                                {{projectAppraisalPaybackPeriod|number:'1.2-2'}}
                            </span>
                        </div>
                    </div>

                    <!-- Remark By Other -->
                    <div class="row mb-3">
                        <div class="card">
                            <div class="card-header">
                                <span>Remarks<br></span>
                            </div>
                            <div class="card-body">
                                <ul class="conversation-list px-3">
                                    <li class="clearfix odd" *ngFor="let r of paybackPeriodRemarkArray">
                                        <div class="chat-avatar" style="width: 60px;" *ngIf="!(r.from.profilePicture)">
                                            <img src="../../../assets/images/Male-Avatar.jpg" class="rounded"
                                                [alt]="r.from.firstName" />
                                            <i>{{r.date|date:'short'}}</i>
                                        </div>
                                        <div class="chat-avatar" style="width: 60px;" *ngIf="r.from.profilePicture">
                                            <img [src]="r.from.profilePicture" class="rounded"
                                                [alt]="r.from.firstName" />
                                            <i>{{r.date|date:'short'}}</i>
                                        </div>
                                        <div class="conversation-text">
                                            <div class="ctext-wrap">
                                                <i>{{r.from.firstName}} {{r.from.surname}}<br></i>
                                                <i><span class="text-muted">{{r.from.role}}</span></i>
                                                <p *ngIf="r.remark">
                                                    {{r.remark}}
                                                </p>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div class="card-body">
                                <div class="row mb-3">
                                    <div class="col-3 text-center">
                                        <p class="mt-0 mb-0 pb-0 ms-2">
                                            <img [src]="profilePictureUrl" class="me-2 rounded-circle" height="32"
                                                [alt]="loggedInUserFirstName + loggedInUserSurname" />
                                        </p>
                                        <h5 class="mt-0 mb-0 ms-2">{{loggedInUserFirstName+' '+loggedInUserSurname}}
                                        </h5>
                                        <h5 class="mt-0 mb-0 ms-2 text-muted">{{loggedInUserRole}}</h5>
                                        <small class="text-muted ms-2">{{loggedInUserDate|date:'short'}}</small>
                                    </div>
                                    <div class="col-9 px-0" name="gas-product-form"
                                        [formGroup]="paybackPeriodRemarkForm">
                                        <textarea class="form-control form-control-light mb-2"
                                            placeholder="Write a remark." id="example-textarea" rows="6"
                                            formControlName="remark"></textarea>
                                        <div class="text-end">
                                            <div class="btn-group mb-2 ms-2">
                                                <button type="button" class="btn btn-primary btn-sm"
                                                    (click)="submitRemark(paybackPeriodRemarkForm.value,'paybackPeriodRemark')">Submit
                                                    Remark</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-footer"></div>
            </div>
            <div class="card">
                <div class="card-header">
                    <div class="table-responsive-sm" *ngIf="projectArray">
                        <table class="table table-sm table-centered table-borderless mb-0" *ngIf="projectArray[0]">
                            <tbody>
                                <tr>
                                    <td class="p-0" style="width:60%">
                                        iii. Discounted - Payback Period.
                                    </td>
                                    <td class="p-0" style="width:30%">
                                    </td>
                                    <td class="p-0 text-center text-primary" style="width:10%">
                                        <i class="mdi mdi-help-circle-outline mdi-24px"></i>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div> <!-- end table-responsive-->
                </div>
                <div class="card-body">

                    <div class="row mb-3">
                        <div class="col-4">
                            <span class="mt-0 mb-0 ms-2">Definition :</span>
                        </div>
                        <div class="col-8 border border-light px-0">
                            <span *ngIf="discountedPaybackPeriodIntro">
                                {{discountedPaybackPeriodIntro}}
                            </span>
                        </div>
                    </div>

                    <div class="row mb-3">
                        <div class="col-4">
                            <span class="mt-0 mb-0 ms-2">Recommended :</span>
                        </div>
                        <div class="col-8 border border-light px-0 text-center">
                            <span *ngIf="discountedPaybackPeriodRecommended">
                                {{discountedPaybackPeriodRecommended}}
                            </span>
                        </div>
                    </div>

                    <div class="row mb-3">
                        <div class="col-4">
                            <span class="mt-0 mb-0 ms-2">Project :</span>
                        </div>
                        <div class="col-8 border border-primary px-0 text-center">
                            <span *ngIf="projectAppraisalDiscountedPaybackPeriod">
                                {{projectAppraisalDiscountedPaybackPeriod|number:'1.2-2'}}
                            </span>
                        </div>
                    </div>

                    <!-- Remark By Other -->
                    <div class="row mb-3">
                        <div class="card">
                            <div class="card-header">
                                <span>Remarks<br></span>
                            </div>
                            <div class="card-body">
                                <ul class="conversation-list px-3">
                                    <li class="clearfix odd" *ngFor="let r of discountedPaybackPeriodRemarkArray">
                                        <div class="chat-avatar" style="width: 60px;" *ngIf="!(r.from.profilePicture)">
                                            <img src="../../../assets/images/Male-Avatar.jpg" class="rounded"
                                                [alt]="r.from.firstName" />
                                            <i>{{r.date|date:'short'}}</i>
                                        </div>
                                        <div class="chat-avatar" style="width: 60px;" *ngIf="r.from.profilePicture">
                                            <img [src]="r.from.profilePicture" class="rounded"
                                                [alt]="r.from.firstName" />
                                            <i>{{r.date|date:'short'}}</i>
                                        </div>
                                        <div class="conversation-text">
                                            <div class="ctext-wrap">
                                                <i>{{r.from.firstName}} {{r.from.surname}}<br></i>
                                                <i><span class="text-muted">{{r.from.role}}</span></i>
                                                <p *ngIf="r.remark">
                                                    {{r.remark}}
                                                </p>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div class="card-body">
                                <div class="row mb-3">
                                    <div class="col-3 text-center">
                                        <p class="mt-0 mb-0 pb-0 ms-2">
                                            <img [src]="profilePictureUrl" class="me-2 rounded-circle" height="32"
                                                [alt]="loggedInUserFirstName + loggedInUserSurname" />
                                        </p>
                                        <h5 class="mt-0 mb-0 ms-2">{{loggedInUserFirstName+' '+loggedInUserSurname}}
                                        </h5>
                                        <h5 class="mt-0 mb-0 ms-2 text-muted">{{loggedInUserRole}}</h5>
                                        <small class="text-muted ms-2">{{loggedInUserDate|date:'short'}}</small>
                                    </div>
                                    <div class="col-9 px-0" name="gas-product-form"
                                        [formGroup]="discountedPaybackPeriodRemarkForm">
                                        <textarea class="form-control form-control-light mb-2"
                                            placeholder="Write a remark." id="example-textarea" rows="6"
                                            formControlName="remark"></textarea>
                                        <div class="text-end">
                                            <div class="btn-group mb-2 ms-2">
                                                <button type="button" class="btn btn-primary btn-sm"
                                                    (click)="submitRemark(discountedPaybackPeriodRemarkForm.value,'discountedPaybackPeriodRemark')">Submit
                                                    Remark</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="card-footer"></div>
            </div>
            <div class="card">
                <div class="card-header">
                    <div class="table-responsive-sm" *ngIf="projectArray">
                        <table class="table table-sm table-centered table-borderless mb-0" *ngIf="projectArray[0]">
                            <tbody>
                                <tr>
                                    <td class="p-0" style="width:60%">
                                        iv. Accounting Rate Of Return (ARR).
                                    </td>
                                    <td class="p-0" style="width:30%">
                                    </td>
                                    <td class="p-0 text-center text-primary" style="width:10%">
                                        <i class="mdi mdi-help-circle-outline mdi-24px"></i>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div> <!-- end table-responsive-->
                </div>
                <div class="card-body">

                    <div class="row mb-3">
                        <div class="col-4">
                            <span class="mt-0 mb-0 ms-2">Definition :</span>
                        </div>
                        <div class="col-8 border border-light px-0">
                            <span *ngIf="accountingRateOfReturnIntro">
                                {{accountingRateOfReturnIntro}}
                            </span>
                        </div>
                    </div>

                    <div class="row mb-3">
                        <div class="col-4">
                            <span class="mt-0 mb-0 ms-2">Recommended :</span>
                        </div>
                        <div class="col-8 border border-light px-0 text-center">
                            <span *ngIf="accountingRateOfReturnRecommended">
                                {{accountingRateOfReturnRecommended}}
                            </span>
                        </div>
                    </div>

                    <div class="row mb-3">
                        <div class="col-4">
                            <span class="mt-0 mb-0 ms-2">Project :</span>
                        </div>
                        <div class="col-8 border border-primary px-0 text-center">
                            <span *ngIf="projectAppraisalAccountingRateOfReturn">
                                {{projectAppraisalAccountingRateOfReturn|number:'1.2-2'}}
                            </span>
                        </div>
                    </div>

                    <div class="row mb-3">
                        <div class="card">
                            <div class="card-header mb-0">
                                <span>
                                    Internal Rate Of Return Summary
                                </span>
                            </div>
                            <div class="card-body">
                                
                            </div>
                            <div class="card-body">
                                <div id="line-chart-datalabel">
                                    <apx-chart class="apex-charts" #irrChartObj [series]="lineChartIRR.series!"
                                        [chart]="lineChartIRR.chart!" [xaxis]="lineChartIRR.xaxis!"
                                        [dataLabels]="lineChartIRR.dataLabels!" [grid]="lineChartIRR.grid!"
                                        [stroke]="lineChartIRR.stroke!" [labels]="lineChartIRR.labels!"
                                        [annotations]="lineChartIRR.annotations!"
                                        [colors]="lineChartIRR.colors!"></apx-chart>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Remark By Other -->
                    <div class="row mb-3">
                        <div class="card">
                            <div class="card-header">
                                <span>Remarks<br></span>
                            </div>
                            <div class="card-body">
                                <ul class="conversation-list px-3">
                                    <li class="clearfix odd" *ngFor="let r of accountingRateOfReturnRemarkArray">
                                        <div class="chat-avatar" style="width: 60px;" *ngIf="!(r.from.profilePicture)">
                                            <img src="../../../assets/images/Male-Avatar.jpg" class="rounded"
                                                [alt]="r.from.firstName" />
                                            <i>{{r.date|date:'short'}}</i>
                                        </div>
                                        <div class="chat-avatar" style="width: 60px;" *ngIf="r.from.profilePicture">
                                            <img [src]="r.from.profilePicture" class="rounded"
                                                [alt]="r.from.firstName" />
                                            <i>{{r.date|date:'short'}}</i>
                                        </div>
                                        <div class="conversation-text">
                                            <div class="ctext-wrap">
                                                <i>{{r.from.firstName}} {{r.from.surname}}<br></i>
                                                <i><span class="text-muted">{{r.from.role}}</span></i>
                                                <p *ngIf="r.remark">
                                                    {{r.remark}}
                                                </p>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div class="card-body">
                                <div class="row mb-3">
                                    <div class="col-3 text-center">
                                        <p class="mt-0 mb-0 pb-0 ms-2">
                                            <img [src]="profilePictureUrl" class="me-2 rounded-circle" height="32"
                                                [alt]="loggedInUserFirstName + loggedInUserSurname" />
                                        </p>
                                        <h5 class="mt-0 mb-0 ms-2">{{loggedInUserFirstName+' '+loggedInUserSurname}}
                                        </h5>
                                        <h5 class="mt-0 mb-0 ms-2 text-muted">{{loggedInUserRole}}</h5>
                                        <small class="text-muted ms-2">{{loggedInUserDate|date:'short'}}</small>
                                    </div>
                                    <div class="col-9 px-0" name="gas-product-form"
                                        [formGroup]="accountingRateOfReturnRemarkForm">
                                        <textarea class="form-control form-control-light mb-2"
                                            placeholder="Write a remark." id="example-textarea" rows="6"
                                            formControlName="remark"></textarea>
                                        <div class="text-end">
                                            <div class="btn-group mb-2 ms-2">
                                                <button type="button" class="btn btn-primary btn-sm"
                                                    (click)="submitRemark(accountingRateOfReturnRemarkForm.value,'accountingRateOfReturnRemark')">Submit
                                                    Remark</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-footer"></div>
            </div>
            <div class="card">
                <div class="card-header">
                    <div class="table-responsive-sm" *ngIf="projectArray">
                        <table class="table table-sm table-centered table-borderless mb-0" *ngIf="projectArray[0]">
                            <tbody>
                                <tr>
                                    <td class="p-0" style="width:60%">
                                        v. Profitability.
                                    </td>
                                    <td class="p-0" style="width:30%">
                                    </td>
                                    <td class="p-0 text-center text-primary" style="width:10%">
                                        <i class="mdi mdi-help-circle-outline mdi-24px"></i>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div> <!-- end table-responsive-->
                </div>
                <div class="card-body">
                    <div class="card">
                        <div class="card-header">
                            <span>
                                Net Cash After Tax / Profit
                            </span>
                        </div>
                        <div class="card-body">
                            <div class="board">
                                <table class="table table-sm table-bordeless table-centered mb-0">
                                    <thead>
                                        <tr>
                                            <th style="min-width:50px">

                                            </th>
                                            <th>
                                                Y - 0
                                            </th>
                                            <th>
                                                Y - 1
                                            </th>
                                            <th>
                                                Y - 2
                                            </th>
                                            <th>
                                                Y - 3
                                            </th>
                                            <th>
                                                Y - 4
                                            </th>
                                            <th>
                                                Y - 5
                                            </th>
                                            <th>
                                                Y - 6
                                            </th>
                                            <th>
                                                Y - 7
                                            </th>
                                            <th>
                                                Y - 8
                                            </th>
                                            <th>
                                                Y - 9
                                            </th>
                                            <th>
                                                Y - 10
                                            </th>
                                            <th>
                                                Y - 11
                                            </th>
                                            <th>
                                                Y - 12
                                            </th>
                                            <th>
                                                Y - 13
                                            </th>
                                            <th>
                                                Y - 14
                                            </th>
                                            <th>
                                                Y - 15
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody class="table-group-divider">
                                        <tr>
                                            <td class="p-0 px-1">
                                                <span>
                                                    <p class="py-o my-0"><strong>Net Cash After Tax - Nominal
                                                            Terms</strong>
                                                    </p>
                                                </span>
                                            </td>
                                            <td class="py-0"
                                                *ngFor="let m of companySummaryNetCashAfterTaxArray;let i = index;">
                                                <span *ngIf="!(i===0)">
                                                    <span *ngIf="!(m===0)">{{m| number:'1.2-2'}}</span>
                                                    <span *ngIf="m===0">-</span>
                                                </span>
                                                <span *ngIf="(i===0)">
                                                    <span *ngIf="!(m===0)">({{(m*(-1))| number:'1.2-2'}})</span>
                                                    <span *ngIf="m===0">-</span>
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="p-0 px-1">
                                                <span>
                                                    <p class="py-o my-0"><strong>Net Cash After Tax - Real
                                                            Terms</strong>
                                                    </p>
                                                </span>
                                            </td>
                                            <td class="py-0"
                                                *ngFor="let m of companySummaryNetCashAfterTaxRealArray;let i = index;">
                                                <span *ngIf="!(i===0)">
                                                    <span *ngIf="!(m===0)">{{m| number:'1.2-2'}}</span>
                                                    <span *ngIf="m===0">-</span>
                                                </span>
                                                <span *ngIf="(i===0)">
                                                    <span *ngIf="!(m===0)">({{(m*(-1))| number:'1.2-2'}})</span>
                                                    <span *ngIf="m===0">-</span>
                                                </span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header mb-0">
                            <span>Cumulated Cash Flow (Constant Money)</span>
                        </div>
                        <div class="card-body">
                            <div id="line-chart-datalabel">
                                <apx-chart class="apex-charts" #cashFlowChart [series]="lineChartCumulatedCashFlow.series!"
                                    [chart]="lineChartCumulatedCashFlow.chart!" [xaxis]="lineChartCumulatedCashFlow.xaxis!"
                                    [dataLabels]="lineChartCumulatedCashFlow.dataLabels!" [grid]="lineChartCumulatedCashFlow.grid!"
                                    [stroke]="lineChartCumulatedCashFlow.stroke!" [labels]="lineChartCumulatedCashFlow.labels!"
                                    [annotations]="lineChartCumulatedCashFlow.annotations!"
                                    [colors]="lineChartCumulatedCashFlow.colors!"></apx-chart>
                            </div>
                        </div>
                        <!-- end card body-->
                    </div>
                    <div class="card">
                        <div class="card-header">
                            <span>Remarks<br></span>
                        </div>
                        <div class="card-body">
                            <ul class="conversation-list px-3">
                                <li class="clearfix odd" *ngFor="let r of profitabilityRemarkArray">
                                    <div class="chat-avatar" style="width: 60px;" *ngIf="!(r.from.profilePicture)">
                                        <img src="../../../assets/images/Male-Avatar.jpg" class="rounded"
                                            [alt]="r.from.firstName" />
                                        <i>{{r.date|date:'short'}}</i>
                                    </div>
                                    <div class="chat-avatar" style="width: 60px;" *ngIf="r.from.profilePicture">
                                        <img [src]="r.from.profilePicture" class="rounded" [alt]="r.from.firstName" />
                                        <i>{{r.date|date:'short'}}</i>
                                    </div>
                                    <div class="conversation-text">
                                        <div class="ctext-wrap">
                                            <i>{{r.from.firstName}} {{r.from.surname}}<br></i>
                                            <i><span class="text-muted">{{r.from.role}}</span></i>
                                            <p *ngIf="r.remark">
                                                {{r.remark}}
                                            </p>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div class="card-body">
                            <div class="row mb-3">
                                <div class="col-3 text-center">
                                    <p class="mt-0 mb-0 pb-0 ms-2">
                                        <img [src]="profilePictureUrl" class="me-2 rounded-circle" height="32"
                                            [alt]="loggedInUserFirstName + loggedInUserSurname" />
                                    </p>
                                    <h5 class="mt-0 mb-0 ms-2">{{loggedInUserFirstName+' '+loggedInUserSurname}}</h5>
                                    <h5 class="mt-0 mb-0 ms-2 text-muted">{{loggedInUserRole}}</h5>
                                    <small class="text-muted ms-2">{{loggedInUserDate|date:'short'}}</small>
                                </div>
                                <div class="col-9 px-0" name="gas-product-form" [formGroup]="profitabilityRemarkForm">
                                    <textarea class="form-control form-control-light mb-2" placeholder="Write a remark."
                                        id="example-textarea" rows="6" formControlName="remark"></textarea>
                                    <div class="text-end">
                                        <div class="btn-group mb-2 ms-2">
                                            <button type="button" class="btn btn-primary btn-sm"
                                                (click)="submitRemark(profitabilityRemarkForm.value,'profitabilityRemark')">Submit
                                                Remark</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-footer"></div>
            </div>
            <div class="card">
                <div class="card-header">
                    <div class="table-responsive-sm" *ngIf="projectArray">
                        <table class="table table-sm table-centered table-borderless mb-0" *ngIf="projectArray[0]">
                            <tbody>
                                <tr>
                                    <td class="p-0" style="width:60%">
                                        vi. Sensitivity Analysis.
                                    </td>
                                    <td class="p-0" style="width:30%">
                                    </td>
                                    <td class="p-0 text-center text-primary" style="width:10%">
                                        <i class="mdi mdi-help-circle-outline mdi-24px"></i>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="card-body">

                    <div class="card">
                        <div class="card-header mb-0">
                            <span>Sensitivity Summary</span>
                        </div>
                        <div class="card-body">
                            <div class="row mb-3">
                                <div class="table-responsive-xxl">
                                    <table class="table table-sm table-borderless table-centered mb-0">
                                        <thead>
                                            <tr>
                                                <th class="py-0 px-1">
                                                </th>
                                                <th class="py-0 px-1">
                                                </th>
                                                <th class="py-0 text-center">
                                                    <span>
                                                        (+)
                                                    </span>
                                                </th>
                                                <th class="py-0 text-center">
                                                    <span>
                                                        (-)
                                                    </span>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody class="table-group-divider">
                                            <tr>
                                                <td class="py-0 px-1">
                                                    <span>
                                                        Volume
                                                    </span>
                                                </td>
                                                <td class="py-0 px-1">
                                                    <span>
                                                        +15% / -15%
                                                    </span>
                                                </td>
                                                <td class="py-0 text-center">
                                                    <span *ngIf="(volumePlus15PercentIrr === 0)">-</span>
                                                    <span *ngIf="!(volumePlus15PercentIrr === 0)">
                                                        {{volumePlus15PercentIrr | number : '1.2-2'}}
                                                    </span>
                                                </td>
                                                <td class="py-0 text-center">
                                                    <span *ngIf="(volumeMinus15PercentIrr === 0)">-</span>
                                                    <span *ngIf="!(volumeMinus15PercentIrr === 0)">
                                                        {{volumeMinus15PercentIrr | number : '1.2-2'}}
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="py-0 px-1">
                                                    <span>
                                                        Gross Margin
                                                    </span>
                                                </td>
                                                <td class="py-0 px-1">
                                                    <span>
                                                        +20% / -20%
                                                    </span>
                                                </td>
                                                <td class="py-0 text-center">
                                                    <span *ngIf="(marginPlus20PercentIrr === 0)">-</span>
                                                    <span *ngIf="!(marginPlus20PercentIrr === 0)">
                                                        {{marginPlus20PercentIrr | number : '1.2-2'}}
                                                    </span>
                                                </td>
                                                <td class="py-0 text-center">
                                                    <span *ngIf="(marginMinus20PercentIrr === 0)">-</span>
                                                    <span *ngIf="!(marginMinus20PercentIrr === 0)">
                                                        {{marginMinus20PercentIrr | number : '1.2-2'}}
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="py-0 px-1">
                                                    <span>
                                                        Investment
                                                    </span>
                                                </td>
                                                <td class="py-0 px-1">
                                                    <span>
                                                        +10% / -10%
                                                    </span>
                                                </td>
                                                <td class="py-0 text-center">
                                                    <span *ngIf="(investmentPlus10PercentIrr === 0)">-</span>
                                                    <span *ngIf="!(investmentPlus10PercentIrr === 0)">
                                                        {{investmentPlus10PercentIrr | number : '1.2-2'}}
                                                    </span>
                                                </td>
                                                <td class="py-0 text-center">
                                                    <span *ngIf="(investmentMinus10PercentIrr === 0)">-</span>
                                                    <span *ngIf="!(investmentMinus10PercentIrr === 0)">
                                                        {{investmentMinus10PercentIrr | number : '1.2-2'}}
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="py-0 px-1">
                                                    <span>
                                                        Working Capital
                                                    </span>
                                                </td>
                                                <td class="py-0 px-1">
                                                    <span>
                                                        +10% / -10%
                                                    </span>
                                                </td>
                                                <td class="py-0 text-center">
                                                    <span *ngIf="(workingCapitalPlus10PercentIrr === 0)">-</span>
                                                    <span *ngIf="!(workingCapitalPlus10PercentIrr === 0)">
                                                        {{workingCapitalPlus10PercentIrr | number : '1.2-2'}}
                                                    </span>
                                                </td>
                                                <td class="py-0 text-center">
                                                    <span *ngIf="(workingCapitalMinus10PercentIrr === 0)">-</span>
                                                    <span *ngIf="!(workingCapitalMinus10PercentIrr === 0)">
                                                        {{workingCapitalMinus10PercentIrr | number : '1.2-2'}}
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="py-0 px-1">
                                                    <span>
                                                        Operating Cost
                                                    </span>
                                                </td>
                                                <td class="py-0 px-1">
                                                    <span>
                                                        +10% / -10%
                                                    </span>
                                                </td>
                                                <td class="py-0 text-center">
                                                    <span *ngIf="(operatingCostPlus10PercentIrr === 0)">-</span>
                                                    <span *ngIf="!(operatingCostPlus10PercentIrr === 0)">
                                                        {{operatingCostPlus10PercentIrr | number : '1.2-2'}}
                                                    </span>
                                                </td>
                                                <td class="py-0 text-center">
                                                    <span *ngIf="(operatingCostMinus10PercentIrr === 0)">-</span>
                                                    <span *ngIf="!(operatingCostMinus10PercentIrr === 0)">
                                                        {{operatingCostMinus10PercentIrr | number : '1.2-2'}}
                                                    </span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="card">
                        <div class="card-header mb-0">
                            <span>IRR & Sensitivity</span>
                        </div>
                        <div class="card-body">
                            <div id="line-chart-datalabel">
                                <apx-chart class="apex-charts" #chartObj [series]="lineChartSensitivity.series!"
                                    [chart]="lineChartSensitivity.chart!" [xaxis]="lineChartSensitivity.xaxis!"
                                    [dataLabels]="lineChartSensitivity.dataLabels!" [grid]="lineChartSensitivity.grid!"
                                    [stroke]="lineChartSensitivity.stroke!" [labels]="lineChartSensitivity.labels!"
                                    [annotations]="lineChartSensitivity.annotations!"
                                    [colors]="lineChartSensitivity.colors!"></apx-chart>
                            </div>
                        </div>
                        <!-- end card body-->
                    </div>

                    <div class="card">
                        <div class="card-header">
                            <span>Remarks<br></span>
                        </div>
                        <div class="card-body">
                            <ul class="conversation-list px-3">
                                <li class="clearfix odd" *ngFor="let r of sensitivityRemarkArray">
                                    <div class="chat-avatar" style="width: 60px;" *ngIf="!(r.from.profilePicture)">
                                        <img src="../../../assets/images/Male-Avatar.jpg" class="rounded"
                                            [alt]="r.from.firstName" />
                                        <i>{{r.date|date:'short'}}</i>
                                    </div>
                                    <div class="chat-avatar" style="width: 60px;" *ngIf="r.from.profilePicture">
                                        <img [src]="r.from.profilePicture" class="rounded" [alt]="r.from.firstName" />
                                        <i>{{r.date|date:'short'}}</i>
                                    </div>
                                    <div class="conversation-text">
                                        <div class="ctext-wrap">
                                            <i>{{r.from.firstName}} {{r.from.surname}}<br></i>
                                            <i><span class="text-muted">{{r.from.role}}</span></i>
                                            <p *ngIf="r.remark">
                                                {{r.remark}}
                                            </p>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div class="card-body">
                            <div class="row mb-3">
                                <div class="col-3 text-center">
                                    <p class="mt-0 mb-0 pb-0 ms-2">
                                        <img [src]="profilePictureUrl" class="me-2 rounded-circle" height="32"
                                            [alt]="loggedInUserFirstName + loggedInUserSurname" />
                                    </p>
                                    <h5 class="mt-0 mb-0 ms-2">{{loggedInUserFirstName+' '+loggedInUserSurname}}</h5>
                                    <h5 class="mt-0 mb-0 ms-2 text-muted">{{loggedInUserRole}}</h5>
                                    <small class="text-muted ms-2">{{loggedInUserDate|date:'short'}}</small>
                                </div>
                                <div class="col-9 px-0" name="gas-product-form" [formGroup]="sensitivityRemarkForm">
                                    <textarea class="form-control form-control-light mb-2" placeholder="Write a remark."
                                        id="example-textarea" rows="6" formControlName="remark"></textarea>
                                    <div class="text-end">
                                        <div class="btn-group mb-2 ms-2">
                                            <button type="button" class="btn btn-primary btn-sm"
                                                (click)="submitRemark(sensitivityRemarkForm.value,'sensitivityRemark')">Submit
                                                Remark</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-footer"></div>
            </div>
        </div>
    </div>

    <!-- Step 4. Physical Environment. -->
    <div id="stepFour" class="border border-secondary card stepFour">
        <div class="card-header">
            <div class="table-responsive-sm" *ngIf="projectArray">
                <table class="table table-sm table-centered table-borderless mb-0" *ngIf="projectArray[0]">
                    <tbody>
                        <tr>
                            <td class="p-0" style="width:60%">
                                4. Physical Environment.
                            </td>
                            <td class="p-0" style="width:30%">
                                <p class="mb-0">
                                    Completion
                                    <ngb-progressbar [showValue]="true" type="info" [striped]="true" [animated]="true"
                                        [value]="projectMapProgressPercentage"></ngb-progressbar>
                                </p>
                            </td>
                            <td class="p-0 text-center text-primary" style="width:10%">
                                <i class="mdi mdi-help-circle-outline mdi-24px"></i>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div> <!-- end table-responsive-->
        </div>
        <div class="card-body">

            <!-- Google Map -->
            <div class="card">
                <div class="card-header">
                    <div class="row">
                        <div class="col-lg-12">
                            <div>
                                <span>A. Station Map Location</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <div class="col-md-12">
                        <div class="card">
                            <div class="card-body">
                                <google-map #myGoogleMap height="300px" width="100%" (mapClick)="moveMap($event)"
                                    (mapMousemove)="move($event)" [zoom]="zoom" [center]="center" [options]="options">
                                    <map-marker *ngIf="markerPosition" [position]="markerPosition"
                                        [options]="markerOptions"></map-marker>
                                    <map-info-window>
                                        <span>Nairobi Chapel</span>
                                    </map-info-window>
                                    <map-rectangle [bounds]="bounds"></map-rectangle>
                                </google-map>
                            </div>


                            <div class="card-body">
                                <div class="row" name="map-edit-form" [formGroup]="mapEditForm">
                                    <div class="row">
                                        <div class="col-12 mb-1">
                                            Map GPS Coordinates:
                                        </div>
                                    </div>
                                    <div class="row mb-2">
                                        <div class="col-md-6">
                                            <div class="input-group input-group-sm input-group-merge">
                                                <div class="input-group-text">
                                                    <span>
                                                        latitude:
                                                    </span>
                                                </div>
                                                <input class="form-control form-control-sm " type="text" id="lat"
                                                    formControlName="lat"
                                                    [ngClass]="{'is-invalid': (formSubmitted && mapEditFormValues['lat'].invalid)||(mapEditFormValues['lat'].touched && mapEditFormValues['lat'].invalid)}">
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="input-group input-group-sm input-group-merge">
                                                <div class="input-group-text">
                                                    <span>
                                                        longitude:
                                                    </span>
                                                </div>
                                                <input class="form-control form-control-sm " type="text" id="lng"
                                                    formControlName="lng"
                                                    [ngClass]="{'is-invalid': (formSubmitted && mapEditFormValues['lng'].invalid)||(mapEditFormValues['lng'].touched && mapEditFormValues['lng'].invalid)}">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-12">
                                            <div class="table-responsive-sm">
                                                <table
                                                    class="table table-sm table-centered table-borderless mb-0 caption-top">
                                                    <tbody>
                                                        <tr>
                                                            <td class="p-0">
                                                                Street Name:
                                                            </td>
                                                            <td class="p-0">
                                                                <div
                                                                    class="input-group input-group-sm input-group-merge mb-1 mt-1">
                                                                    <input class="form-control form-control-sm "
                                                                        type="text" id="streetName"
                                                                        formControlName="streetName"
                                                                        [ngClass]="{'is-invalid': (formSubmitted && mapEditFormValues['streetName'].invalid)||(mapEditFormValues['streetName'].touched && mapEditFormValues['streetName'].invalid)}">
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td class="p-0">
                                                                Town:
                                                            </td>
                                                            <td class="p-0">
                                                                <div
                                                                    class="input-group input-group-sm input-group-merge mb-1 mt-1">
                                                                    <input class="form-control form-control-sm "
                                                                        type="text" id="town" formControlName="town"
                                                                        [ngClass]="{'is-invalid': (formSubmitted && mapEditFormValues['town'].invalid)||(mapEditFormValues['town'].touched && mapEditFormValues['town'].invalid)}">
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td class="p-0">
                                                                Country:
                                                            </td>
                                                            <td class="p-0">
                                                                <div
                                                                    class="input-group input-group-sm input-group-merge mb-1 mt-1">
                                                                    <input class="form-control form-control-sm "
                                                                        type="text" id="country"
                                                                        formControlName="country" readonly
                                                                        [ngClass]="{'is-invalid': (formSubmitted && mapEditFormValues['country'].invalid)||(mapEditFormValues['country'].touched && mapEditFormValues['country'].invalid)}">
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td class="p-0">
                                                                Description:
                                                            </td>
                                                            <td class="p-0">
                                                                <div
                                                                    class="input-group input-group-sm input-group-merge mb-1 mt-1">
                                                                    <textarea class="form-control form-control-sm "
                                                                        type="text" id="description"
                                                                        formControlName="description" rows="6"
                                                                        placeholder="Write the location description."
                                                                        [ngClass]="{'is-invalid': (formSubmitted && mapEditFormValues['description'].invalid)||(mapEditFormValues['description'].touched && mapEditFormValues['description'].invalid)}"></textarea>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>

                                    </div>

                                </div>
                            </div>



                            <div class="card-footer">
                                <div class="row">
                                    <div class="col-sm-12">
                                        <div class="d-grid">
                                            <button class="btn btn-sm btn-outline-primary"
                                                (click)="updateStationMapLocation(mapEditForm.value)" type="button"
                                                *ngIf="!updateInProgress">Update</button>
                                            <button class="btn btn-sm btn-outline-primary" type="button" disabled
                                                *ngIf="updateInProgress">
                                                <span class="spinner-border spinner-border-sm me-1" role="status"
                                                    aria-hidden="true"></span>
                                                Loading...
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>



                        </div>
                    </div>
                </div>
                <div class="card-footer">
                    <div class="row">
                        <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                            <div class="d-grid">
                                <a href="https://www.google.com/maps/place/Nairobi+Chapel/@-1.305269,36.7520181,17z/data=!3m1!4b1!4m6!3m5!1s0x182f1a5b6b967303:0x4775199e1b585ee6!8m2!3d-1.305269!4d36.754593!16s%2Fg%2F1tkp216n?hl=en-GB&entry=ttu"
                                    target="_blank" role="button" class="btn btn-outline-primary">
                                    <i class="mdi mdi-earth-outline mdi-18px"></i> View on Google Maps
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card">
                <div class="card-header">
                    <div class="row">
                        <div class="col-lg-12">
                            <div>
                                <span>B. Station Street View</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <div class="card">
                        <div class="card-header">
                            <span>
                                Street View - Image 1.
                            </span>
                        </div>
                        <div class="card-body">
                            <div class="row">
                                <div class="card" role="button">
                                    <img src="../../../assets/images/image_place_holder.svg" height="300px"
                                        style="border-style: dashed;object-fit: scale-down;" class="card-img-top"
                                        alt="..." *ngIf="!oneViewPicture">
                                    <img [src]="oneViewPicture" class="card-img-top" height="300px"
                                        style="border-style: dashed;object-fit: scale-down;" alt="..."
                                        *ngIf="oneViewPicture" (click)="enlargeImage(sizeableModal,'One')">
                                    <ul class="list-group text-center list-group-flush">
                                        <li class="list-group-item" *ngIf="!oneViewPicture">
                                            <div class="form-group">
                                                <small class="text-body-secondary">Upload the front view.</small>
                                                <input type="file" class="form-control" accept=".png,.jpg,.jpeg"
                                                    [multiple]="false" (change)="loadFiles($event,'One')">
                                            </div>
                                        </li>
                                        <li class="list-group-item" *ngIf="oneViewPicture">
                                            <button type="button" (click)="deleteImage('station','One')"
                                                class="btn btn-sm btn-outline-danger">
                                                Delete
                                            </button>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="row" [formGroup]="streetViewOneForm">
                                <label for="description" class="form-label">Description:</label>
                                <div class="input-group input-group-sm input-group-merge mb-3">
                                    <textarea class="form-control form-control-sm " type="text" id="description"
                                        formControlName="description" rows="2"
                                        placeholder="Write the image description."></textarea>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-12">
                                    <div class="d-grid">
                                        <button class="btn btn-sm btn-outline-primary"
                                            (click)="updateStreetViewImage(streetViewOneForm.value,'One')" type="button"
                                            *ngIf="!updateInProgress">Save
                                            Description</button>
                                        <button class="btn btn-sm btn-outline-primary" type="button" disabled
                                            *ngIf="updateInProgress">
                                            <span class="spinner-border spinner-border-sm me-1" role="status"
                                                aria-hidden="true"></span>
                                            Loading...
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header">
                            <span>
                                Street View - Image 2.
                            </span>
                        </div>
                        <div class="card-body">
                            <div class="row">
                                <div class="card" role="button">
                                    <img src="../../../assets/images/image_place_holder.svg" height="300px"
                                        style="border-style: dashed;object-fit: scale-down;" class="card-img-top"
                                        alt="..." *ngIf="!twoViewPicture">
                                    <img [src]="twoViewPicture" class="card-img-top" height="300px"
                                        style="border-style: dashed;object-fit: scale-down;" alt="..."
                                        *ngIf="twoViewPicture" (click)="enlargeImage(sizeableModal,'Two')">
                                    <ul class="list-group text-center list-group-flush">
                                        <li class="list-group-item" *ngIf="!twoViewPicture">
                                            <div class="form-group">
                                                <small class="text-body-secondary">Upload the left view.</small>
                                                <input type="file" class="form-control" accept=".png,.jpg,.jpeg"
                                                    [multiple]="false" (change)="loadFiles($event,'Two')">
                                            </div>
                                        </li>
                                        <li class="list-group-item" *ngIf="twoViewPicture">
                                            <button type="button" (click)="deleteImage('station','Two')"
                                                class="btn btn-sm btn-outline-danger">
                                                Delete
                                            </button>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="row" [formGroup]="streetViewTwoForm">
                                <label for="description" class="form-label">Description:</label>
                                <div class="input-group input-group-sm input-group-merge mb-3">
                                    <textarea class="form-control form-control-sm " type="text" id="description"
                                        formControlName="description" rows="2"
                                        placeholder="Write the image description."></textarea>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-12">
                                    <div class="d-grid">
                                        <button class="btn btn-sm btn-outline-primary"
                                            (click)="updateStreetViewImage(streetViewTwoForm.value,'Two')" type="button"
                                            *ngIf="!updateInProgress">Save
                                            Description</button>
                                        <button class="btn btn-sm btn-outline-primary" type="button" disabled
                                            *ngIf="updateInProgress">
                                            <span class="spinner-border spinner-border-sm me-1" role="status"
                                                aria-hidden="true"></span>
                                            Loading...
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header">
                            <span>
                                Street View - Image 3.
                            </span>
                        </div>
                        <div class="card-body">
                            <div class="row">
                                <div class="card" role="button">
                                    <img src="../../../assets/images/image_place_holder.svg" height="300px"
                                        style="border-style: dashed;object-fit: scale-down;" class="card-img-top"
                                        alt="..." *ngIf="!threeViewPicture">
                                    <img [src]="threeViewPicture" class="card-img-top" height="300px"
                                        style="border-style: dashed;object-fit: scale-down;" alt="..."
                                        *ngIf="threeViewPicture" (click)="enlargeImage(sizeableModal,'Three')">
                                    <ul class="list-group text-center list-group-flush">
                                        <li class="list-group-item" *ngIf="!threeViewPicture">
                                            <div class="form-group">
                                                <small class="text-body-secondary">Upload the right view.</small>
                                                <input type="file" class="form-control" accept=".png,.jpg,.jpeg"
                                                    [multiple]="false" (change)="loadFiles($event,'Three')">
                                            </div>
                                        </li>
                                        <li class="list-group-item" *ngIf="threeViewPicture">
                                            <button type="button" (click)="deleteImage('station','Three')"
                                                class="btn btn-sm btn-outline-danger">
                                                Delete
                                            </button>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="row" [formGroup]="streetViewThreeForm">
                                <label for="description" class="form-label">Description:</label>
                                <div class="input-group input-group-sm input-group-merge mb-3">
                                    <textarea class="form-control form-control-sm " type="text" id="description"
                                        formControlName="description" rows="2"
                                        placeholder="Write the image description."></textarea>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-12">
                                    <div class="d-grid">
                                        <button class="btn btn-sm btn-outline-primary"
                                            (click)="updateStreetViewImage(streetViewThreeForm.value,'Three')"
                                            type="button" *ngIf="!updateInProgress">Save
                                            Description</button>
                                        <button class="btn btn-sm btn-outline-primary" type="button" disabled
                                            *ngIf="updateInProgress">
                                            <span class="spinner-border spinner-border-sm me-1" role="status"
                                                aria-hidden="true"></span>
                                            Loading...
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header">
                            <span>
                                Street View - Image 4.
                            </span>
                        </div>
                        <div class="card-body">
                            <div class="row">
                                <div class="card" role="button">
                                    <img src="../../../assets/images/image_place_holder.svg" height="300px"
                                        style="border-style: dashed;object-fit: scale-down;" class="card-img-top"
                                        alt="..." *ngIf="!fourViewPicture">
                                    <img [src]="fourViewPicture" class="card-img-top" height="300px"
                                        style="border-style: dashed;object-fit: scale-down;" alt="..."
                                        *ngIf="fourViewPicture" (click)="enlargeImage(sizeableModal,'Four')">
                                    <ul class="list-group text-center list-group-flush">
                                        <li class="list-group-item" *ngIf="!fourViewPicture">
                                            <div class="form-group">
                                                <small class="text-body-secondary">Upload the back view.</small>
                                                <input type="file" class="form-control" accept=".png,.jpg,.jpeg"
                                                    [multiple]="false" (change)="loadFiles($event,'Four')">
                                            </div>
                                        </li>
                                        <li class="list-group-item" *ngIf="fourViewPicture">
                                            <button type="button" (click)="deleteImage('station','Four')"
                                                class="btn btn-sm btn-outline-danger">
                                                Delete
                                            </button>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="row" [formGroup]="streetViewFourForm">
                                <label for="description" class="form-label">Description:</label>
                                <div class="input-group input-group-sm input-group-merge mb-3">
                                    <textarea class="form-control form-control-sm " type="text" id="description"
                                        formControlName="description" rows="2"
                                        placeholder="Write the image description."></textarea>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-12">
                                    <div class="d-grid">
                                        <button class="btn btn-sm btn-outline-primary"
                                            (click)="updateStreetViewImage(streetViewFourForm.value,'Four')"
                                            type="button" *ngIf="!updateInProgress">Save
                                            Description</button>
                                        <button class="btn btn-sm btn-outline-primary" type="button" disabled
                                            *ngIf="updateInProgress">
                                            <span class="spinner-border spinner-border-sm me-1" role="status"
                                                aria-hidden="true"></span>
                                            Loading...
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>




                </div>
                <div class="card-footer">

                </div>
            </div>

            <div class="card">
                <div class="card-header">
                    <div class="row">
                        <div class="col-lg-12">
                            <div>
                                <span>C. Competitors</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-body">

                </div>
                <div class="card-body">
                    <div class="card" *ngFor="let m of stationCompetitorArray;let i =index;">
                        <div class="card-header">
                            <span>{{i+(1)}}. </span>
                            <span *ngIf="m.name">{{m.name}}</span>
                        </div>
                        <div class="card-body">
                            <div class="row">
                                <div class="card" role="button">
                                    <img src="../../../assets/images/image_place_holder.svg" height="300px"
                                        style="border-style: dashed;object-fit: scale-down;" class="card-img-top"
                                        alt="..." *ngIf="!(m.picture.url)">
                                    <img [src]="m.picture.url" class="card-img-top" height="300px"
                                        style="border-style: dashed;object-fit: scale-down;" alt="..."
                                        *ngIf="m.picture.url" (click)="enlargeImage(sizeableModal,'Competitor',i)">
                                </div>
                            </div>
                            <span>Street: </span><span *ngIf="m.streetName">{{m.streetName}}, </span><span
                                *ngIf="m.town">{{m.town}}, </span><span *ngIf="m.country">{{m.country}}.</span><br />
                            <span>Description: </span><span *ngIf="m.description">{{m.description}}</span>
                        </div>
                        <div class="card-footer">
                            <div class="row">
                                <div class="col-sm-12">
                                    <div class="d-grid">
                                        <button class="btn btn-sm btn-outline-danger" (click)="deleteCompetitor(i)"
                                            type="button" *ngIf="!submitInProgress"><i class=""></i>Delete</button>
                                        <button class="btn btn-sm btn-outline-primary" type="button" disabled
                                            *ngIf="submitInProgress">
                                            <span class="spinner-border spinner-border-sm me-1" role="status"
                                                aria-hidden="true"></span>
                                            Loading...
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header">
                            <span>Add Competitor</span>
                        </div>
                        <div class="card-body" *ngIf="showNewCompetitorEdit">
                            <div class="card mb-0" role="button">
                                <img src="../../../assets/images/image_place_holder.svg" height="300px"
                                    style="border-style: dashed;object-fit: scale-down;" class="card-img-top" alt="..."
                                    *ngIf="!newCompetitorPicture">
                                <img [src]="newCompetitorPicture" class="card-img-top" height="300px"
                                    style="border-style: dashed;object-fit: scale-down;" alt="..."
                                    *ngIf="newCompetitorPicture" (click)="enlargeImage(sizeableModal,'newCompetitor')">
                                <ul class="list-group text-center list-group-flush">
                                    <li class="list-group-item" *ngIf="!newCompetitorPicture">
                                        <div class="form-group">
                                            <small class="text-body-secondary">Upload the competitors's
                                                image.</small>
                                            <input type="file" class="form-control" accept=".png,.jpg,.jpeg"
                                                [multiple]="false" (change)="loadFiles($event,'newCompetitor')">
                                        </div>
                                    </li>
                                    <li class="list-group-item" *ngIf="newCompetitorPicture">
                                        <button type="button"
                                            (click)="deleteImage('competitorStation','competitorStation')"
                                            class="btn btn-sm btn-outline-danger">
                                            Delete
                                        </button>
                                    </li>
                                </ul>
                            </div>
                            <form [formGroup]="newCompetitorForm">
                                <div class="row mb-2">
                                    <div class="col-md-12 mb-1">
                                        <div class="input-group input-group-sm">
                                            <span class="input-group-text" id="inputGroup-sizing">
                                                Name<span class="text-danger"> *</span>:
                                            </span>
                                            <input class="form-control form-control " type="text" id="name"
                                                formControlName="name"
                                                [ngClass]="{'is-invalid': (formSubmitted && newCompetitorFormValues['name'].invalid)||(newCompetitorFormValues['name'].touched && newCompetitorFormValues['name'].invalid)}">
                                        </div>
                                    </div>
                                    <div class="col-md-6 mb-1">
                                        <div class="input-group input-group-sm">
                                            <span class="input-group-text" id="inputGroup-sizing">
                                                Longitude<span class="text-danger"> *</span>:
                                            </span>
                                            <input class="form-control form-control " type="text" id="lat"
                                                formControlName="lat"
                                                [ngClass]="{'is-invalid': (formSubmitted && newCompetitorFormValues['lat'].invalid)||(newCompetitorFormValues['lat'].touched && newCompetitorFormValues['lat'].invalid)}">
                                        </div>
                                    </div>
                                    <div class="col-md-6 mb-1">
                                        <div class="input-group input-group-sm">
                                            <span class="input-group-text" id="inputGroup-sizing">
                                                Latitude<span class="text-danger"> *</span>:
                                            </span>
                                            <input class="form-control form-control" type="text" id="lng"
                                                formControlName="lng"
                                                [ngClass]="{'is-invalid': (formSubmitted && newCompetitorFormValues['lng'].invalid)||(newCompetitorFormValues['lng'].touched && newCompetitorFormValues['lng'].invalid)}">
                                        </div>
                                    </div>
                                    <div class="col-md-6 mb-1">
                                        <div class="input-group input-group-sm">
                                            <span class="input-group-text" id="inputGroup-sizing">
                                                Street:
                                            </span>
                                            <input class="form-control form-control " type="text" id="streetName"
                                                formControlName="streetName"
                                                [ngClass]="{'is-invalid': (formSubmitted && newCompetitorFormValues['streetName'].invalid)||(newCompetitorFormValues['streetName'].touched && newCompetitorFormValues['streetName'].invalid)}">
                                        </div>
                                    </div>
                                    <div class="col-md-6 mb-1">
                                        <div class="input-group input-group-sm">
                                            <span class="input-group-text" id="inputGroup-sizing">
                                                Town:
                                            </span>
                                            <input class="form-control form-control " type="text" id="town"
                                                formControlName="town"
                                                [ngClass]="{'is-invalid': (formSubmitted && newCompetitorFormValues['town'].invalid)||(newCompetitorFormValues['town'].touched && newCompetitorFormValues['town'].invalid)}">
                                        </div>
                                    </div>
                                    <div class="col-md-6 mb-1">
                                        <div class="input-group input-group-sm">
                                            <span class="input-group-text" id="inputGroup-sizing">
                                                Country:
                                            </span>
                                            <input class="form-control form-control " type="text" id="country"
                                                formControlName="country" readonly
                                                [ngClass]="{'is-invalid': (formSubmitted && newCompetitorFormValues['country'].invalid)||(newCompetitorFormValues['country'].touched && newCompetitorFormValues['country'].invalid)}">
                                        </div>
                                    </div>
                                    <div class="col-12 mb-1">
                                        <div class="input-group input-group-sm">
                                            <span class="input-group-text" id="inputGroup-sizing">
                                                Description<span class="text-danger"> *</span>:
                                            </span>
                                            <textarea class="form-control form-control " type="text" id="description"
                                                formControlName="description" col="10"
                                                [ngClass]="{'is-invalid': (formSubmitted && newCompetitorFormValues['description'].invalid)||(newCompetitorFormValues['description'].touched && newCompetitorFormValues['description'].invalid)}"></textarea>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div class="card-footer" *ngIf="showNewCompetitorEdit">
                            <div class="row">
                                <div class="col-sm-12">
                                    <div class="d-grid">
                                        <button class="btn btn-sm btn-outline-primary"
                                            (click)="addCompetitor(newCompetitorForm.value)" type="button"
                                            *ngIf="!updateInProgress">
                                            <i class="mdi mdi-plus"></i> Add Competitor</button>
                                        <button class="btn btn-sm btn-outline-primary" type="button" disabled
                                            *ngIf="updateInProgress">
                                            <span class="spinner-border spinner-border-sm me-1" role="status"
                                                aria-hidden="true"></span>
                                            Loading...
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card-footer" *ngIf="!showNewCompetitorEdit">
                            <div class="row">
                                <div class="col-sm-12">
                                    <div class="d-grid">
                                        <button class="btn btn-sm btn-outline-primary" (click)="showAddCompetitorForm()"
                                            type="button" *ngIf="!updateInProgress">
                                            <i class="mdi mdi-plus"></i> Add Competitor</button>
                                        <button class="btn btn-sm btn-outline-primary" type="button" disabled
                                            *ngIf="updateInProgress">
                                            <span class="spinner-border spinner-border-sm me-1" role="status"
                                                aria-hidden="true"></span>
                                            Loading...
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-footer">

                </div>
            </div>

            <div class="card">
                <div class="card-header">
                    <span>Remarks<br></span>
                </div>
                <div class="card-body">
                    <ul class="conversation-list px-3">
                        <li class="clearfix odd" *ngFor="let r of mapLocationRemarkArray">
                            <div class="chat-avatar" style="width: 60px;" *ngIf="!(r.from.profilePicture)">
                                <img src="../../../assets/images/Male-Avatar.jpg" class="rounded"
                                    [alt]="r.from.firstName" />
                                <i>{{r.date|date:'short'}}</i>
                            </div>
                            <div class="chat-avatar" style="width: 60px;" *ngIf="r.from.profilePicture">
                                <img [src]="r.from.profilePicture" class="rounded" [alt]="r.from.firstName" />
                                <i>{{r.date|date:'short'}}</i>
                            </div>
                            <div class="conversation-text">
                                <div class="ctext-wrap">
                                    <i>{{r.from.firstName}} {{r.from.surname}}<br></i>
                                    <i><span class="text-muted">{{r.from.role}}</span></i>
                                    <p *ngIf="r.remark">
                                        {{r.remark}}
                                    </p>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="card-body">
                    <div class="row mb-3">
                        <div class="col-3 text-center">
                            <p class="mt-0 mb-0 pb-0 ms-2">
                                <img [src]="profilePictureUrl" class="me-2 rounded-circle" height="32"
                                    [alt]="loggedInUserFirstName + loggedInUserSurname" />
                            </p>
                            <h5 class="mt-0 mb-0 ms-2">{{loggedInUserFirstName+' '+loggedInUserSurname}}</h5>
                            <h5 class="mt-0 mb-0 ms-2 text-muted">{{loggedInUserRole}}</h5>
                            <small class="text-muted ms-2">{{loggedInUserDate|date:'short'}}</small>
                        </div>
                        <div class="col-9 px-0" name="gas-product-form" [formGroup]="mapLocationRemarkForm">
                            <textarea class="form-control form-control-light mb-2" placeholder="Write a remark."
                                id="example-textarea" rows="6" formControlName="remark"></textarea>
                            <div class="text-end">
                                <div class="btn-group mb-2 ms-2">
                                    <button type="button" class="btn btn-primary btn-sm"
                                        (click)="submitRemark(mapLocationRemarkForm.value,'mapLocationRemark')">Submit
                                        Remark</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>

    <!-- Step 5. Project Committee Recommendation. -->
    <div id="stepFive" class="border border-secondary card stepFive">
        <div class="card-header">
            <div class="table-responsive-sm" *ngIf="projectArray">
                <table class="table table-sm table-centered table-borderless mb-0" *ngIf="projectArray[0]">
                    <tbody>
                        <tr>
                            <td class="p-0" style="width:60%">
                                Step 5. Project Committee Recommendation.
                            </td>
                            <td class="p-0" style="width:30%">
                                <p class="mb-0">
                                    Completion
                                    <ngb-progressbar [showValue]="true" type="info" [striped]="true" [animated]="true"
                                        [value]="projectProjectCommitteeRecommendationProgressPercentage"></ngb-progressbar>
                                </p>
                            </td>
                            <td class="p-0 text-center text-primary" style="width:10%">
                                <i class="mdi mdi-help-circle-outline mdi-24px"></i>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div> <!-- end table-responsive-->
        </div>
        <div class="card-body">
            <div class="card">
                <div class="card-header">
                    <div class="table-responsive-sm">
                        <table class="table table-sm table-centered table-borderless mb-0">
                            <tbody>
                                <tr>
                                    <td class="p-0" style="width:60%">
                                        Recommendations
                                    </td>
                                    <td class="p-0" style="width:30%">

                                    </td>
                                    <td class="p-0 text-center text-primary" style="width:10%">
                                        <i class="mdi mdi-help-circle-outline mdi-24px"></i>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="card-body">

                    <div class="row mb-3">
                        <div class="col-4">
                            <span class="mt-0 mb-0">Project Committee Recommendation :</span>
                        </div>
                        <div class="col-8 border border-light px-0 text-center">
                            <span class="text-secondary" *ngIf="projectCommitteeRecommendation === 'Pending'">Pending </span>
                            <span class="text-primary" *ngIf="projectCommitteeRecommendation === 'Under Review'">Under Review </span>
                            <span class="text-success" *ngIf="projectCommitteeRecommendation === 'Approved'">Approved </span>
                            <span class="text-danger" *ngIf="projectCommitteeRecommendation === 'Rejected'">Rejected </span>
                        </div>
                    </div>

                    <div class="row mb-3">
                        <div class="col-4">
                            <p class="mt-0 mb-0 pb-0">
                                <span>Justification :<br></span>
                                <span class="mt-0 mb-0 pb-0 text-muted">Chief Executive Offices</span>
                            </p>
                        </div>
                        <div class="col-8 px-0">
                            <textarea class="form-control form-control-light mb-2"
                                placeholder="Write the project justification." id="example-textarea"
                                rows="6"></textarea>
                            <div class="text-end">
                                <div class="btn-group mb-2 ms-2">
                                    <button type="button" class="btn btn-primary btn-sm">Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card">
                <div class="card-header">
                    <div class="table-responsive-sm">
                        <table class="table table-sm table-centered table-borderless mb-0">
                            <tbody>
                                <tr>
                                    <td class="p-0" style="width:60%">
                                        Ratification If Required. (Any Two)
                                    </td>
                                    <td class="p-0" style="width:30%">

                                    </td>
                                    <td class="p-0 text-center text-primary" style="width:10%">
                                        <i class="mdi mdi-help-circle-outline mdi-24px"></i>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="card-body">
                    <div class="row row-cols-2 row-cols-sm-3 g-4">
                        <div class="col">
                            <div class="card border border-primary">
                                <div class="card-body text-center" role="button" (click)="ratification('chairman')">
                                    <div class="row">
                                        <img [src]="chairmanQRPicture" alt="Chairman Ratification">
                                    </div>
                                    <div class="row">
                                        <small class="text-muted">click to view...</small>
                                    </div>
                                </div>
                                <div class="card-footer">
                                    <span>Chairman</span>
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="card border border-primary">
                                <div class="card-body text-center" role="button" (click)="ratification('secretary')">
                                    <div class="row">
                                        <img [src]="secretaryQRPicture" alt="Secretary Ratification">
                                    </div>
                                    <div class="row">
                                        <small class="text-muted">click to view...</small>
                                    </div>
                                </div>
                                <div class="card-footer">
                                    <span>Secretary</span>
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="card border border-primary">
                                <div class="card-body text-center" role="button"
                                    (click)="ratification('committeeMember')">
                                    <div class="row">
                                        <img [src]="committeeMemberQRPicture" alt="Committee Member Ratification">
                                    </div>
                                    <div class="row">
                                        <small class="text-muted">click to view...</small>
                                    </div>
                                </div>
                                <div class="card-footer">
                                    <span>Member</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row">

        <div class="col-lg-12">
            <div class="card bg-success text-white" role="button" (click)="navigateTo('reportPDF')">
                <div class="card-body">
                    <div class="toll-free-box text-center">
                        <h4> <i class="mdi mdi-file-download-outline"></i> Print Report (PDF)</h4>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #sizeableModal let-modal>
    <div class="modal-body">
        <div (click)="closeModal()" class="card mb-0 px-0">
            <img [src]="pickedImage" class="img-fluid card-img" alt="image" *ngIf="pickedImage" />
        </div>
    </div>
</ng-template>