import { Route } from "@angular/router";
import { MenuItem } from "../layouts/shared/models/menu.model";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { UserGuideComponent } from "./user-guide/user-guide.component";
import { ResultComponent } from "./result/result.component";
import { CountryAdminComponent } from "./country-admin/country-admin.component";
import { CountryComponent } from "./country/country.component";
import { ProjectListComponent } from "./project-list/project-list.component";
import { NewProjectComponent } from "./new-project/new-project.component";
import { ProjectComponent } from "./project/project.component";
import { PetroleumProductsAdminComponent } from "./petroleum-products-admin/petroleum-products-admin.component";
import { PetroleumProductsCategoryAdminComponent } from "./petroleum-products-category-admin/petroleum-products-category-admin.component";
import { OperatingExpensesComponent } from "./operating-expenses/operating-expenses.component";
import { InvestmentComponent } from "./investment/investment.component";
import { AuthGuard } from "../core/guards/auth.guard";
import { ReportComponent } from "./report/report.component";
import { RatificationComponent } from "./ratification/ratification.component";
import { SupportComponent } from "./support/support.component";
import { PostInvestmentComponent } from "./post-investment/post-investment.component";

export const  PORTAL_MENU: MenuItem[] = [
    { key: 'ds-help', label: 'Portal Navigation', isTitle: true },
    { key: 'ds-dashboard', label: 'Dashboard', isTitle: false, icon: 'uil-home-alt', link: '/portal/dashboard' },
    { key: 'ds-help', label: 'Projects', isTitle: true },
    { key: 'ds-dashboard', label: 'New Project', isTitle: false, icon: 'uil-file-plus', link: '/portal/project/new' },
    { key: 'ds-dashboard', label: 'Projects', isTitle: false, icon: 'uil-chart-line', link: '/portal/project' },
    // { key: 'ds-dashboard', label: 'Company BP', isTitle: false, icon: 'uil-home-alt', link: '/portal/company-bp' },
    // { key: 'ds-dashboard', label: 'Dealer BP', isTitle: false, icon: 'uil-home-alt', link: '/portal/dealer-bp' },
    // { key: 'ds-dashboard', label: 'Maintain Company BP Remod', isTitle: false, icon: 'uil-home-alt', link: '/portal/maintain-company-bp-remod' },
    // { key: 'ds-dashboard', label: 'Maintain Dealer BP Remod', isTitle: false, icon: 'uil-home-alt', link: '/portal/maintain-dealer-bp-remod' },
    // { key: 'ds-dashboard', label: 'Result', isTitle: false, icon: 'uil-home-alt', link: '/portal/result' },
    // { key: 'ds-help', label: 'Project Default Settings', isTitle: true },
    // { key: 'ds-dashboard', label: 'Country', isTitle: false, icon: 'uil-globe', link: '/portal/country-admin' },
    // { key: 'ds-dashboard', label: 'Company Investment', isTitle: false, icon: 'uil-receipt-alt', link: '/portal/investment' },
    // { key: 'ds-dashboard', label: 'Dealer Operating Expenses', isTitle: false, icon: 'uil-bill', link: '/portal/operating-expenses' },
    { key: 'ds-help', label: 'Help', isTitle: true },
    { key: 'ds-dashboard', label: 'User Guide', isTitle: false, icon: 'uil-question-circle', link: '/portal/user-guide' },
    { key: 'ds-support', label: 'Support', isTitle: false, icon: 'uil-globe', link: '/portal/support' },
  ]

export const  PORTAL_ROUTES: Route[] = [
    { path: '', redirectTo: 'dashboard', pathMatch: 'full', },
    {
        path: 'dashboard',
        canActivateChild: [AuthGuard],
        children: [
            { path: '', component: DashboardComponent, },
        ]
    },
    {
        path: 'user-guide',
        canActivateChild: [AuthGuard],
        children: [
            { path: '', component: UserGuideComponent, },
        ]
    },
    {
        path: 'support',
        canActivateChild: [AuthGuard],
        children: [
            { path: '', component: SupportComponent, },
        ]
    },
    
    {
        path: 'result',
        canActivateChild: [AuthGuard],
        children: [
            { path: ':id', component: ResultComponent, },
        ]
    },
    {
        path: 'report',
        canActivateChild: [AuthGuard],
        children: [
            { path: ':id', component: ReportComponent, },
            { path: ':id/ratification/:id2', component: RatificationComponent, },
        ]
    },
    {
        path: 'post-investment',
        canActivateChild: [AuthGuard],
        children: [
            { path: ':id', component: PostInvestmentComponent, },
        ]
    },
    {
        path: 'country-admin',
        canActivateChild: [AuthGuard],
        children: [
            { path: '', component: CountryAdminComponent, },
        ]
    },
    {
        path: 'country',
        canActivateChild: [AuthGuard],
        children: [
            { path: ':id', component: CountryComponent, },
        ]
    },
    {
        path: 'project',
        canActivateChild: [AuthGuard],
        children: [
            { path: '', component: ProjectListComponent, },
            { path: 'new', component: NewProjectComponent, },
            { path: ':id', component: ProjectComponent, },
        ]
    },
    {
        path: 'result/id', component: ResultComponent,
    },
    {
        path: 'product-admin',
        canActivateChild: [AuthGuard],
        children: [
            { path: '', component: PetroleumProductsAdminComponent, },
        ]
    },
    {
        path: 'product-category-admin',
        canActivateChild: [AuthGuard],
        children: [
            { path: '', component: PetroleumProductsCategoryAdminComponent, },
        ]
    },
    {
        path: 'operating-expenses',
        canActivateChild: [AuthGuard],
        children: [
            { path: '', component: OperatingExpensesComponent, },
        ]
    },
    {
        path: 'investment',
        canActivateChild: [AuthGuard],
        children: [
            { path: '', component: InvestmentComponent, },
        ]
    },
];