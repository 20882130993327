import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { Observable, finalize } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProjectService {

  constructor(
    private db: AngularFireDatabase,
    private fns: AngularFireFunctions,
    private storage: AngularFireStorage,
  ) { }

  // Project
  startProject(load: any) {
    const callable = this.fns.httpsCallable('startProject');
    return callable({
      load: load,
    });
  }

  getProjectArray() {
    return this.db.list('/project').valueChanges();
  }

  getProjectObject() {
    return this.db.object('/project').valueChanges();
  }

  getProjectArrayItem(id: string) {
    return this.db.list(`/project/${id}`).valueChanges();
  }

  getCountryArrayItem(id: string) {
    return this.db.list(`/country/${id}`).valueChanges();
  }

  getCountryObjectItem(id: string) {
    return this.db.object(`/country/${id}`).valueChanges();
  }

  getPetroleumProductArray() {
    return this.db.list('/petroleumProduct').valueChanges();
  }

  getProjectObjectItem(id: string) {
    return this.db.object(`/project/${id}`).valueChanges();
  }

  getProjectFuelProductArray(id: string) {
    return this.db.list(`/project/${id}/0/fuelProduct`).valueChanges();
  }

  getProjectFuelProductTotalObject(id: string) {
    return this.db.object(`/project/${id}/0/fuelProductTotal`).valueChanges();
  }

  getProjectLubricantProductArray(id: string) {
    return this.db.list(`/project/${id}/0/lubricantProduct`).valueChanges();
  }

  getProjectLubricantProductTotalObject(id: string) {
    return this.db.object(`/project/${id}/0/lubricantProductTotal`).valueChanges();
  }

  getProjectGasProductArray(id: string) {
    return this.db.list(`/project/${id}/0/gasProduct`).valueChanges();
  }

  getDefaultCompanyInvestmentArray() {
    return this.db.list(`/default/companyInvestment`).valueChanges();
  }

  getDefaultCompanyInvestmentObject() {
    return this.db.object(`/default/companyInvestment`).valueChanges();
  }

  deleteProject(id: string) {
    const callable = this.fns.httpsCallable('deleteProject');
    return callable({
      load: id,
    });
  }

  getProjectId() {
    const callable = this.fns.httpsCallable('projectIdGenerator');
    return callable({ type: '' });
  }

  getInvestmentId() {
    const callable = this.fns.httpsCallable('investmentIdGenerator');
    return callable({ type: '' });
  }

  getOperatingExpenseId() {
    const callable = this.fns.httpsCallable('operatingExpenseIdGenerator');
    return callable({ type: '' });
  }

  updateProjectCountry(projectId: string, countryId: string) {
    const callable = this.fns.httpsCallable('updateProjectCountry');
    return callable({
      id1: projectId,
      id2: countryId,
    });
  }

  updateProjectPeriodOfOperation(projectId: string, period: string) {
    const callable = this.fns.httpsCallable('updateProjectPeriodOfOperation');
    return callable({
      id1: projectId,
      period: period,
    });
  }

  updateProjectItem(projectId: string, itemName: string, itemValue: number) {
    const callable = this.fns.httpsCallable('updateProject');
    return callable({
      id: projectId,
      itemName: itemName,
      itemValue: itemValue,
    });
  }

  updateProjectPetroleumProduct(projectId: string, load: any) {
    const callable = this.fns.httpsCallable('updateProjectPetroleumProduct');
    return callable({
      id: projectId,
      load: load,
    });
  }

  updateCountryPetroleumProduct(countryId: string, load: any) {
    const callable = this.fns.httpsCallable('updateCountryPetroleumProduct');
    return callable({
      id: countryId,
      load: load,
    });
  }

  updateProjectCompanyInvestment(
    projectId: any,
    brandingAmortizationPeriod: any,
    equipmentAmortizationPeriod: any,
    constructionAmortizationPeriod: any,
  ) {
    const callable = this.fns.httpsCallable('updateProjectCompanyInvestment');
    return callable({
      id: projectId,
      a: brandingAmortizationPeriod,
      b: equipmentAmortizationPeriod,
      c: constructionAmortizationPeriod,
    });
  }

  updateDefaultCompanyInvestment(
    brandingForm: any,
    equipmentForm: any,
    constructionForm: any,
    investmentItemForm: any,
    investmentAmortizationForm: any,
    brandingAmortizationArray: any,
    constructionAmortizationArray: any,
    equipmentAmortizationArray: any,
    totalAmortizationArray: any,
    companyInvestmentArray: any,
    companyInvestmentTotalArray: any,
  ) {
    const callable = this.fns.httpsCallable('updateDefaultCompanyInvestment');
    return callable({
      a: brandingForm,
      b: equipmentForm,
      c: constructionForm,
      d: investmentItemForm,
      e: investmentAmortizationForm,
      f: brandingAmortizationArray,
      g: constructionAmortizationArray,
      h: equipmentAmortizationArray,
      i: totalAmortizationArray,
      j: companyInvestmentArray,
      k: companyInvestmentTotalArray,
    });
  }

  getOperatingExpenseArray() {
    return this.db.list(`/default/dealerOperatingExpense`).valueChanges();
  }

  getOperatingExpenseObject() {
    return this.db.object(`/default/dealerOperatingExpense`).valueChanges();
  }

  getProjectDealerOperatingExpenseObject(id: string) {
    return this.db.object(`project/${id}/0/dealerOperatingExpense`).valueChanges();
  }

  getProjectWorkingCapitalObject(id: string) {
    return this.db.object(`project/${id}/0/companyWorkingCapital`).valueChanges();
  }

  getProjectCompanyTaxesObject(id: string) {
    return this.db.object(`project/${id}/0/companyTaxes`).valueChanges();
  }

  getProjectFinancingObject(id: string) {
    return this.db.object(`project/${id}/0/companyFinancing`).valueChanges();
  }

  getProjectDealerRevenueObject(id: string) {
    return this.db.object(`project/${id}/0/dealerRevenue`).valueChanges();
  }

  getProjectDealerFeesObject(id: string) {
    return this.db.object(`project/${id}/0/dealerFees`).valueChanges();
  }

  getProjectCompanyFeesReceivedObject(id: string) {
    return this.db.object(`project/${id}/0/companyOtherFeesReceived`).valueChanges();
  }

  getProjectDealerSummaryObject(id: string) {
    return this.db.object(`project/${id}/0/dealerSummary`).valueChanges();
  }

  getProjectCompanySummaryObject(id: string) {
    return this.db.object(`project/${id}/0/companySummary`).valueChanges();
  }

  getProjectStationMapLocationObject(id: string) {
    return this.db.object(`project/${id}/0/stationMapLocation`).valueChanges();
  }

  getProjectStationMapStreetViewObject(id: string) {
    return this.db.object(`project/${id}/0/map`).valueChanges();
  }

  getProjectStationLocationCompetitorsArray(id: string) {
    return this.db.list(`project/${id}/0/competitor`).valueChanges();
  }

  getProjectSensitivityObject(id: string) {
    return this.db.object(`project/${id}/0/sensitivityAnalysis`).valueChanges();
  }

  getProjectCompanyOperatingExpensesObject(id: string) {
    return this.db.object(`project/${id}/0/companyOperatingExpenses`).valueChanges();
  }

  getProjectOtherRevenueObject(id: string) {
    return this.db.object(`project/${id}/0/companyOtherRevenue`).valueChanges();
  }

  getProjectCompanyInvestmentObject(id: string) {
    return this.db.object(`project/${id}/0/companyInvestment`).valueChanges();
  }

  getProjectCompanyInvestmentArray(id: string) {
    return this.db.list(`project/${id}/0/companyInvestment`).valueChanges();
  }


  updateFuelVolumeGrowth(
    projectId: string,
    fuelVolumeGrowthForm: any,
    fuelVolumeGrowthPotentialForm: any,
    monthsDuringThePeriodForm: any,
    fuelMarginGrowthForm: any,
    fuelVolumeWithoutLubricantForm: any,
    fuelVolumeForm: any,
    fuelMarginForm: any,
    gasMarginForm: any,
    fuelMarginTotalForm: any,
  ) {
    const callable = this.fns.httpsCallable('updateFuelVolumeGrowth');
    return callable({
      id: projectId,
      a: fuelVolumeGrowthForm,
      b: fuelVolumeGrowthPotentialForm,
      c: monthsDuringThePeriodForm,
      d: fuelMarginGrowthForm,
      e: fuelVolumeWithoutLubricantForm,
      f: fuelVolumeForm,
      g: fuelMarginForm,
      h: gasMarginForm,
      i: fuelMarginTotalForm,
    });
  }

  updateCountryFuelVolumeGrowth(
    fuelVolumeGrowthForm: any,
    fuelVolumeGrowthPotentialForm: any,
    monthsDuringThePeriodForm: any,
    fuelMarginGrowthForm: any,
    fuelVolumeForm: any,
    fuelMarginForm: any,
    gasMarginForm: any,
    fuelMarginTotalForm: any,
  ) {
    const callable = this.fns.httpsCallable('updateCountryFuelVolumeGrowth');
    return callable({
      a: fuelVolumeGrowthForm,
      b: fuelVolumeGrowthPotentialForm,
      c: monthsDuringThePeriodForm,
      d: fuelMarginGrowthForm,
      e: fuelVolumeForm,
      f: fuelMarginForm,
      g: gasMarginForm,
      h: fuelMarginTotalForm,
    });
  }

  updateDefaultDealerOperatingExpense(
    salariesAndBonusForm: any,
    employeeContributionForm: any,
    electricityForm: any,
    miscellaneousForm: any,
    productLossForm: any,
    operatingForm: any,
  ) {
    const callable = this.fns.httpsCallable('updateDefaultDealerOperatingExpense');
    return callable({
      a: salariesAndBonusForm,
      b: employeeContributionForm,
      c: electricityForm,
      d: miscellaneousForm,
      e: productLossForm,
      f: operatingForm,
    });
  }

  updateProjectDealerOperatingExpense(
    salariesAndBonusForm: any,
    employeeContributionForm: any,
    electricityForm: any,
    miscellaneousForm: any,
    projectId: any,
  ) {
    const callable = this.fns.httpsCallable('updateProjectDealerOperatingExpense');
    return callable({
      id: projectId,
      a: salariesAndBonusForm,
      b: employeeContributionForm,
      c: electricityForm,
      d: miscellaneousForm,
    });
  }

  updateProjectFuelVolumeGrowth(
    fuelVolumeGrowthForm: any,
    fuelVolumeGrowthPotentialForm: any,
    monthsDuringThePeriodForm: any,
    fuelMarginGrowthForm: any,
    projectId: any,
  ) {
    const callable = this.fns.httpsCallable('updateProjectFuelVolumeGrowth');
    return callable({
      id: projectId,
      a: fuelVolumeGrowthForm,
      b: fuelVolumeGrowthPotentialForm,
      c: monthsDuringThePeriodForm,
      d: fuelMarginGrowthForm,

    });
  }

  updateProjectProgress(a: string, b: boolean, c: string) {
    const callable = this.fns.httpsCallable('updateProjectProgress');
    return callable({
      a: a,
      b: b,
      c: c,
    });
  }

  updateProjectWorkingCapital(
    projectId: any,
    workingCapitalForm: any,
  ) {
    const callable = this.fns.httpsCallable('updateProjectWorkingCapital');
    return callable({
      id: projectId,
      a: workingCapitalForm,
    });
  }

  updateProjectDealerRevenue(
    projectId: any,
    dealerRevenueNonFuelForm: any,
  ) {
    const callable = this.fns.httpsCallable('updateProjectDealerRevenue');
    return callable({
      id: projectId,
      a: dealerRevenueNonFuelForm,
    });
  }

  updateProjectDealerFees(
    projectId: any,
    dealerFeeForm: any,
  ) {
    const callable = this.fns.httpsCallable('updateProjectDealerFees');
    return callable({
      id: projectId,
      a: dealerFeeForm,
    });
  }

  updateProjectSettings(
    projectId: any,
    projectSettingsEditForm: any,
  ) {
    const callable = this.fns.httpsCallable('updateProjectSettings');
    return callable({
      id: projectId,
      a: projectSettingsEditForm,
    });
  }

  updateProjectCompanyOtherRevenue(
    projectId: any,
    companyFeesReceivedForm: any,
  ) {
    const callable = this.fns.httpsCallable('updateProjectCompanyOtherRevenue');
    return callable({
      id: projectId,
      a: companyFeesReceivedForm,
    });
  }

  updateProjectCompanyOperatingExpenses(
    projectId: any,
    companyOperatingExpensesForm: any
  ) {
    const callable = this.fns.httpsCallable('updateProjectCompanyOperatingExpenses');
    return callable({
      id: projectId,
      a: companyOperatingExpensesForm
    });
  }

  sendWebsiteMessage(messageForm: any) {
    const callable = this.fns.httpsCallable('sendWebsiteMessage');
    return callable({
      a: messageForm
    });
  }

  testing(projectId: any) {
    const callable = this.fns.httpsCallable('testingMiddleware');
    return callable({
      id: projectId
    });
  }

  updateProjectReportProgress(projectId: string, name: string, isSubmitted: boolean) {
    const callable = this.fns.httpsCallable('updateProjectReportProgress');
    return callable({
      id: projectId,
      a: name,
      b: isSubmitted,
    });
  }

  updateProjectInvestmentReport(projectId: any, form: any) {
    const callable = this.fns.httpsCallable('updateProjectInvestmentReport');
    return callable({
      id: projectId,
      a: form
    });
  }

  updateProjectSalesProfitAndCashFlowReport(projectId: any, form: any) {
    const callable = this.fns.httpsCallable('updateProjectSalesProfitAndCashFlowReport');
    return callable({
      id: projectId,
      a: form
    });
  }

  updateProjectNPVReport(projectId: any, form: any) {
    const callable = this.fns.httpsCallable('updateProjectNPVReport');
    return callable({
      id: projectId,
      a: form
    });
  }

  updateProjectPaybackPeriodReport(projectId: any, form: any) {
    const callable = this.fns.httpsCallable('updateProjectPaybackPeriodReport');
    return callable({
      id: projectId,
      a: form
    });
  }

  updateProjectDiscountedPaybackPeriodReport(projectId: any, form: any) {
    const callable = this.fns.httpsCallable('updateProjectDiscountedPaybackPeriodReport');
    return callable({
      id: projectId,
      a: form
    });
  }

  updateProjectAccountingRateOfReturnReport(projectId: any, form: any) {
    const callable = this.fns.httpsCallable('updateProjectAccountingRateOfReturnReport');
    return callable({
      id: projectId,
      a: form
    });
  }

  updateProjectProfitabilityReport(projectId: any, form: any) {
    const callable = this.fns.httpsCallable('updateProjectProfitabilityReport');
    return callable({
      id: projectId,
      a: form
    });
  }

  updateProjectSensitivityAnalysisReport(projectId: any, form: any) {
    const callable = this.fns.httpsCallable('updateProjectSensitivityAnalysisReport');
    return callable({
      id: projectId,
      a: form
    });
  }

  updateProjectMapReport(projectId: any, form: any) {
    const callable = this.fns.httpsCallable('updateProjectMapReport');
    return callable({
      id: projectId,
      a: form
    });
  }

  updateProjectImagesReport(projectId: any, form: any) {
    const callable = this.fns.httpsCallable('updateProjectImagesReport');
    return callable({
      id: projectId,
      a: form
    });
  }

  updateProjectProjectCommitteeRecommendationReport(projectId: any, form: any) {
    const callable = this.fns.httpsCallable('updateProjectProjectCommitteeRecommendationReport');
    return callable({
      id: projectId,
      a: form
    });
  }

  uploadImage(event, path: string): Observable<any> {
    let downloadURL = "";
    let uploadPercent = 0;
    const file = event.target["files"][0];
    const filePath = path;
    const fileRef = this.storage.ref(filePath);
    const task = this.storage.upload(filePath, file);
    return Observable.create(observer => {
      task.percentageChanges().subscribe((result) => {
        uploadPercent = result;
        observer.next(
          {
            uploadProgress$: result,
            downloadUrl$: downloadURL,
          }
        );
      })

      // get notified when the download URL is available
      task.snapshotChanges().pipe(
        finalize(() => {
          fileRef.getDownloadURL().subscribe((result) => {
            downloadURL = result;
            observer.next(
              {
                uploadProgress$: uploadPercent,
                downloadUrl$: result,
              }
            );
            observer.complete();
          })
        })
      )
        .subscribe()
    })

  }


  updateDealerRevenueNonFuelItem(
    projectId: any,
    dealerRevenueNonFuelItemForm: any,
  ) {
    const callable = this.fns.httpsCallable('updateDealerRevenueNonFuelItem');
    return callable({
      id: projectId,
      a: dealerRevenueNonFuelItemForm,
    });
  }

  removeDealerRevenueNonFuelItem(
    projectId: any,
    itemIndex: any,
  ) {
    const callable = this.fns.httpsCallable('removeDealerRevenueNonFuelItem');
    return callable({
      id: projectId,
      a: itemIndex,
    });
  }

  addDealerOperatingExpenseItem(
    projectId: any,
    itemName: any,
    itemForm: any,
  ) {
    const callable = this.fns.httpsCallable('addDealerOperatingExpenseItem');
    return callable({
      id: projectId,
      a: itemName,
      b: itemForm
    });
  }

  addDiversificationFixedFeesItem(
    projectId: any,
    itemForm: any,
  ) {
    const callable = this.fns.httpsCallable('addDiversificationFixedFeesItem');
    return callable({
      id: projectId,
      a: itemForm
    });
  }

  removeDiversificationFixedFeesItem(
    projectId: any,
    itemIndex: any,
  ) {
    const callable = this.fns.httpsCallable('removeDiversificationFixedFeesItem');
    return callable({
      id: projectId,
      a: itemIndex
    });
  }

  addDiversificationVariableFeesItem(
    projectId: any,
    itemForm: any,
  ) {
    const callable = this.fns.httpsCallable('addDiversificationVariableFeesItem');
    return callable({
      id: projectId,
      a: itemForm
    });
  }

  removeDiversificationVariableFeesItem(
    projectId: any,
    itemIndex: any,
  ) {
    const callable = this.fns.httpsCallable('removeDiversificationVariableFeesItem');
    return callable({
      id: projectId,
      a: itemIndex
    });
  }

  removeDealerOperatingExpenseItem(
    projectId: any,
    itemName: any,
    itemIndex: any,
  ) {
    const callable = this.fns.httpsCallable('removeDealerOperatingExpenseItem');
    return callable({
      id: projectId,
      a: itemName,
      b: itemIndex
    });
  }

  removeCompanyInvestmentItem(
    projectId: any,
    itemName: any,
    itemIndex: any,
  ) {
    const callable = this.fns.httpsCallable('removeCompanyInvestmentItem');
    return callable({
      id: projectId,
      a: itemName,
      b: itemIndex
    });
  }

  addCompanyInvestmentItem(
    projectId: any,
    itemName: any,
    itemForm: any,
  ) {
    const callable = this.fns.httpsCallable('addCompanyInvestmentItem');
    return callable({
      id: projectId,
      a: itemName,
      b: itemForm
    });
  }

  submitReportRemark(
    projectId: string,
    remarkName: string,
    remark: string,
    uid: string,
  ) {
    const callable = this.fns.httpsCallable('submitReportRemark');
    return callable({
      id: projectId,
      a: remarkName,
      b: remark,
      c: uid,
    });
  }

  submitPostInvestmentRemark(
    projectId: string,
    remarkName: string,
    remark: string,
    uid: string,
  ) {
    const callable = this.fns.httpsCallable('submitPostInvestmentRemark');
    return callable({
      id: projectId,
      a: remarkName,
      b: remark,
      c: uid,
    });
  }

  deleteReportRemark(
    projectId: string,
    remarkName: string,
    uid: string,
  ) {
    const callable = this.fns.httpsCallable('deleteReportRemark');
    return callable({
      id: projectId,
      a: remarkName,
      b: uid,
    });
  }

  removeFuelLubricantGasProductItem(
    projectId: string,
    itemName: string,
    index: number,
  ) {
    const callable = this.fns.httpsCallable('removeFuelLubricantGasProductItem');
    return callable({
      id: projectId,
      a: itemName,
      b: index,
    });
  }

  addFuelLubricantGasProductItem(
    projectId: string,
    itemName: string,
    form: any,
  ) {
    const callable = this.fns.httpsCallable('addFuelLubricantGasProductItem');
    return callable({
      id: projectId,
      a: itemName,
      b: form,
    });
  }

  updateStationMapLocation(
    projectId: string,
    form: any,
    userId: string,
  ) {
    const callable = this.fns.httpsCallable('updateStationMapLocation');
    return callable({
      id: projectId,
      id2: userId,
      a: form,
    });
  }

  getRoleArray() {
    return this.db.list(`/default/role`).valueChanges();
  }

  getRoleObject() {
    return this.db.object(`/default/role`).valueChanges();
  }

  deleteRole(
    index: number,
  ) {
    const callable = this.fns.httpsCallable('deleteRole');
    return callable({
      id: index,
    });
  }

  createRole(
    index: number,
    form: any,
  ) {
    const callable = this.fns.httpsCallable('createRole');
    return callable({
      id: index,
      a: form,
    });
  }

  streetViewDescriptionUpdate(
    projectId: string,
    form: any,
    descriptionId: string,
  ) {
    const callable = this.fns.httpsCallable('streetViewDescriptionUpdate');
    return callable({
      id: projectId,
      id2: descriptionId,
      a: form,
    });
  }

  streetViewImageUpdate(
    projectId: string,
    form: any,
    descriptionId: string,
  ) {
    const callable = this.fns.httpsCallable('streetViewImageUpdate');
    return callable({
      id: projectId,
      id2: descriptionId,
      a: form,
    });
  }

  addCompetitor(
    projectId: string,
    form: any,
    userId: string,
  ) {
    const callable = this.fns.httpsCallable('addCompetitor');
    return callable({
      id: projectId,
      id2: userId,
      a: form,
    });
  }

  deleteCompetitor(
    projectId: string,
    index: number,
    userId: string,
  ) {
    const callable = this.fns.httpsCallable('deleteCompetitor');
    return callable({
      id: projectId,
      id2: index,
      id3: userId,
    });
  }

  getUsersGuideArray() {
    return this.db.list(`/userGuide`).valueChanges();
  }

  getUsersGuideObject() {
    return this.db.object(`/userGuide`).valueChanges();
  }

  updateUserGuide(
    type: string,
    index: string,
    form: string,
    userId: string
  ) {
    const callable = this.fns.httpsCallable('updateUserGuide');
    return callable({
      id1: Number(index),
      id2: userId,
      a: form,
      b: type
    });
  }

}


