import { CommonModule, formatCurrency, formatDate, ViewportScroller } from '@angular/common';
import { AfterViewInit, Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { GoogleMapsModule } from '@angular/google-maps';
import { PRIMARY_OUTLET, Router, RouterLink } from '@angular/router';
import { NgbAlert, NgbAlertModule, NgbModal, NgbProgressbarModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subscription, first } from 'rxjs';
import { ProjectDetails } from 'src/app/core/models-two/ProjectDetails';
import { IpGeoService } from 'src/app/core/service/ip-geo.service';
import { PetroleumProductsService } from 'src/app/core/service/petroleum-products.service';
import { ProjectService } from 'src/app/core/service/project.service';
import { FooterComponent } from 'src/app/shared/footer/footer.component';
import { GiftSecondNavbarComponent } from 'src/app/shared/gift-second-navbar/gift-second-navbar.component';
import { NavbarComponent } from 'src/app/shared/navbar/navbar.component';
import { WidgetModule } from 'src/app/shared/widget/widget.module';
import { NgApexchartsModule } from 'ng-apexcharts';
import { ApexNonAxisChartSeries, ApexChart, ApexPlotOptions, ApexAxisChartSeries, ApexStroke, ApexDataLabels, ApexXAxis, ApexLegend, ApexFill, ApexGrid, ApexYAxis, ApexTooltip, ApexResponsive, ApexTitleSubtitle, ApexAnnotations, ApexMarkers, ApexStates, } from "ng-apexcharts";
import { FileStorageService } from 'src/app/core/service/file-storage.service';
import { AuthenticationService } from 'src/app/core/service/auth.service';
import { AppUser } from 'src/app/core/models-two/AppUser';
import { CompanyInvestment } from 'src/app/core/models-two/CompanyInvestment';
import { CompanyInvestmentItem } from 'src/app/core/models-two/CompanyInvestmentItem';
import { CompanySummary } from 'src/app/core/models-two/CompanySummary';
import { CompanyFinancing } from 'src/app/core/models-two/CompanyFinancing';
import { RemarkItem } from 'src/app/core/models-two/RemarkItem';
import { SensitivityAnalysis } from 'src/app/core/models-two/SensitivityAnalysis';
import { StationMapLocation } from 'src/app/core/models-two/StationMapLocation';
import { StationMapStreetView } from 'src/app/core/models-two/StationMapStreetView';
import { StationCompetitor } from 'src/app/core/models-two/StationCompetitor';
import { Volkhov } from 'src/assets/fonts/Volkhov-normal';

import jsPDF from 'jspdf';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-post-investment',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    WidgetModule,
    NgbAlertModule,
    NavbarComponent,
    FooterComponent,
    RouterLink,
    GiftSecondNavbarComponent,
    NgbProgressbarModule,
    NgbTooltipModule,
    GoogleMapsModule,
    NgApexchartsModule,
  ],
  templateUrl: './post-investment.component.html',
  styleUrls: ['./post-investment.component.scss']
})
export class PostInvestmentComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild('selfClosingAlert', { static: false }) selfClosingAlert!: NgbAlert;
  show_alert: boolean;
  alert_message: string;
  alert_type: string;
  subscription: Subscription = new Subscription();
  yearPickerForm!: FormGroup;
  economicEnvironmentActualForm!: FormGroup;
  profitAndLossActualForm!: FormGroup;
  productSalesActualForm!: FormGroup;
  tree: any;
  g: any;
  s: any;
  projectId: any;
  loading: boolean = false;
  projectArray: ProjectDetails[];
  projectAppraisalNpv: number;
  projectAppraisalPaybackPeriod: number;
  projectAppraisalDiscountedPaybackPeriod: number;
  projectAppraisalAccountingRateOfReturn: number;
  projectName: string;
  projectLocationDescription: string;
  projectDescription: string;
  projectStartDate: number;
  projectEndDate: number;
  inflationRateArray: number[];
  showProfitAndLossEdit: boolean;
  showProductSalesVolumeEdit: boolean;
  showEconomicEnvironmentEdit: boolean;
  formSubmitted: boolean = false;
  yearArray: { name: string; year: number; }[];
  pickedYearInflationRate: number = 0;
  pickedYearExchangeRate: number = 0;
  pickedYearCorporateTaxRate: number = 0;
  pickedYearInterestRate: number = 0;
  pickedYearNetFuelMargin: number = 0;
  pickedYearDiversification: number = 0;
  pickedYearOtherRevenue: number = 0;
  pickedYearOperatingExpenses: number = 0;
  pickedYearRentalsAndFeesDue: number = 0;
  pickedYearEBITDA: number = 0;
  pickedYearInvestments: number = 0;
  pickedYearChangeInWorkingCapital: number = 0;
  pickedYearTaxes: number = 0;
  pickedYearOtherCashItems: number = 0;
  pickedYearNetCashAfterTax: number = 0;
  pickedYearFinancialResult: number = 0;
  pickedYearNetCashAfterTaxDiscounted: number = 0;
  pickedYearNetCashAfterTaxRealAndDiscounted: number = 0;

  // Actual
  pickedYearInflationRateActual: number = 0;
  pickedYearExchangeRateActual: number = 0;
  pickedYearCorporateTaxRateActual: number = 0;
  pickedYearInterestRateActual: number = 0;
  pickedYearNetFuelMarginActual: number = 0;
  pickedYearDiversificationActual: number = 0;
  pickedYearOtherRevenueActual: number = 0;
  pickedYearOperatingExpensesActual: number = 0;
  pickedYearRentalsAndFeesDueActual: number = 0;
  pickedYearEBITDAActual: number = 0;
  pickedYearInvestmentsActual: number = 0;
  pickedYearChangeInWorkingCapitalActual: number = 0;
  pickedYearTaxesActual: number = 0;
  pickedYearOtherCashItemsActual: number = 0;
  pickedYearNetCashAfterTaxActual: number = 0;
  pickedYearFinancialResultActual: number = 0;
  pickedYearNetCashAfterTaxDiscountedActual: number = 0;
  pickedYearNetCashAfterTaxRealAndDiscountedActual: number = 0;
  productOne: { volume: number; name: string; category: string; } = { volume: 0, name: "", category: "" };
  productTwo: { volume: number; name: string; category: string; } = { volume: 0, name: "", category: "" };
  productThree: { volume: number; name: string; category: string; } = { volume: 0, name: "", category: "" };

  productOneActualVolume: number = 0;
  productTwoActualVolume: number = 0;
  productThreeActualVolume: number = 0;
  pickedYear: any;
  updateInProgress: boolean = false;
  submitInProgress: boolean = false;
  pickedYearGasVolume: number = 0;
  pickedYearFuelAndLubricantVolume: number = 0;
  pickedYearGasVolumeActual: number = 0;
  pickedYearFuelAndLubricantVolumeActual: number = 0;
  dateToday: string;
  user: AppUser;
  loggedInUserDate: number;
  authState: boolean;
  loggedInUserLastName: any;
  loggedInUserRole: any;
  loggedInUserSurname: any;
  loggedInUserFirstName: any;
  profilePictureUrl: any;
  userName: string;
  userId: any;

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private scroller: ViewportScroller,
    private modalService: NgbModal,
    private toastr: ToastrService,
    private projectService: ProjectService,
    private authenticationService: AuthenticationService,
    private petroleumProductsService: PetroleumProductsService,
    private sanitizer: DomSanitizer,
  ) {
    this.tree = router.parseUrl(router.url);
    this.g = this.tree.root.children[PRIMARY_OUTLET];
    this.s = this.g.segments;

    this.loadingAnimationFourSeconds();

    this.subscription.add(authenticationService.currentUser.pipe().subscribe((res: AppUser) => {

      if (res) {
        this.user = res;
        this.authState = true;

        if (res[0]) {
          let firstName = "";
          let otherName = "";
          let surname = "";

          this.loggedInUserDate = (new Date()[Symbol.toPrimitive]('number'));
          if (res[0].userId) { this.userId = res[0].userId; };
          if (res[0].firstName) {
            this.loggedInUserFirstName = res[0].firstName;
            firstName = res[0].firstName;
          };
          if (res[0].surname) {
            this.loggedInUserSurname = res[0].surname;
            surname = res[0].surname;
          };
          if (res[0].otherName) {
            this.loggedInUserLastName = res[0].otherName;
            otherName = res[0].otherName;
          };
          if (res[0].role) { this.loggedInUserRole = res[0].role; };
          if (res[0].profilePicture) {
            if (res[0].profilePicture[0]) {
              if (res[0].profilePicture[0].url) {
                this.profilePictureUrl = res[0].profilePicture[0].url;
              } else {
                this.profilePictureUrl = "../../../assets/images/Male-Avatar.jpg";
              }
            } else {
              this.profilePictureUrl = "../../../assets/images/Male-Avatar.jpg";
            }
          } else {
            this.profilePictureUrl = "../../../assets/images/Male-Avatar.jpg";
          }

          this.userName = `${firstName} ${otherName} ${surname}`;
        } else {
          this.profilePictureUrl = "../../../assets/images/Male-Avatar.jpg";
        }

      } else {
        this.authState = false;
      }

    }));

    if (this.s[2].path) {
      if ((this.s[2].path.toString()).length === 22) {
        this.projectId = this.s[2].path.toString();

        this.subscription.add(
          this.projectService.getProjectArrayItem(this.s[2].path.toString()).pipe().subscribe((res: ProjectDetails[]) => {
            if (res) {
              this.projectArray = res;

              // projectAppraisalNpv
              if (res[0].netPresentValue) { this.projectAppraisalNpv = res[0].netPresentValue; };
              // projectAppraisalPaybackPeriod
              if (res[0].paybackPeriod) { this.projectAppraisalPaybackPeriod = res[0].paybackPeriod; };
              // projectAppraisalDiscountedPaybackPeriod
              if (res[0].paybackPeriodDiscounted) { this.projectAppraisalDiscountedPaybackPeriod = res[0].paybackPeriodDiscounted; };
              // projectAppraisalAccountingRateOfReturn
              if (res[0].internalRateOfReturn) { this.projectAppraisalAccountingRateOfReturn = res[0].internalRateOfReturn; };


              if (res[0].name) { this.projectName = res[0].name };
              if (res[0].locationDescription) { this.projectLocationDescription = res[0].locationDescription };
              if (res[0].description) { this.projectDescription = res[0].description };
              if (res[0].constructionRenovationStartDate) { this.projectStartDate = res[0].constructionRenovationStartDate };
              if (res[0].constructionRenovationEndDate) { this.projectEndDate = res[0].constructionRenovationEndDate };




            }

          }
          )
        )

      }
    }

  }

  loadingAnimationFourSeconds() {
    this.loading = true;
    setTimeout(() => {
      this.loading = false;
    }, 4000);
  }

  navigateTo(type: string) {

    if (type === "postInvestmentPDF") {
      // this.toastr.info(`PDF Download Feature under development`, "Info!");
      this.printPostInvestmentSummaryPDF();
    }

  }

  showProfitAndLossEditButton(a: boolean) {
    this.showProfitAndLossEdit = a;
    this.scrollToElement("stepThree");
  }

  showProductSalesVolumeEditButton(a: boolean) {
    this.showProductSalesVolumeEdit = a;
    this.scrollToElement("stepTwo");
  }

  showEconomicEnvironmentEditButton(a: boolean) {
    this.showEconomicEnvironmentEdit = a;
    this.scrollToElement("stepOne");
  }

  scrollToElement(a: string) {

    this.scroller.scrollToAnchor(a);

    setTimeout(() => {
      window.scrollBy(0, -75);
    }, 500);

  }

  /**
   * convenience getter for easy access to form fields
   */

  get yearPickerFormValues() {
    return this.yearPickerForm.controls;
  }

  get profitAndLossActualFormValues() {
    return this.profitAndLossActualForm.controls;
  }

  get productSalesActualFormValues() {
    return this.productSalesActualForm.controls;
  }

  get economicEnvironmentActualFormValues() {
    return this.economicEnvironmentActualForm.controls;
  }

  ngOnInit(): void {

    this.yearPickerForm = this.fb.group({
      year: ['', Validators.required],
    });

    this.productSalesActualForm = this.fb.group({
      fuelAndLubricantVolumeActual: [''],
      gasVolumeActual: [''],
    });

    this.profitAndLossActualForm = this.fb.group({
      netFuelMargin: [''],
      diversification: [''],
      otherRevenue: [''],
      operatingExpenses: [''],
      rentalsAndFeesDue: [''],
      ebitda: [''],
      investments: [''],
      changeInWorkingCapital: [''],
      taxes: [''],
      otherCashItems: [''],
      netCashAfterTax: [''],
      FinancialResult: [''],
      netCashAfterTaxDiscounted: [''],
      netCashAfterTaxRealAndDiscounted: [''],
    });

    this.economicEnvironmentActualForm = this.fb.group({
      inflation: [''],
      interestRate: [''],
      corporateTaxRate: [''],
      exchangeRate: [''],
    });

    this.subscription.add(
      this.yearPickerForm.get("year").valueChanges.subscribe(x => {
        this.loadYearData(x);
        this.pickedYear = x;
      }));

    this.yearArray = [
      {
        "name": "Year 1",
        "year": 1
      },
      {
        "name": "Year 2",
        "year": 2
      },
      {
        "name": "Year 3",
        "year": 3
      },
      {
        "name": "Year 4",
        "year": 4
      },
      {
        "name": "Year 5",
        "year": 5
      },
    ]
  }

  loadYearData(x: any) {
    if (this.projectArray) {
      if (this.projectArray[0]) {
        
        if (this.projectArray[0].fuelVolumeAndMarginGrowth) {
          if (this.projectArray[0].fuelVolumeAndMarginGrowth.gasVolume) {
            if (this.projectArray[0].fuelVolumeAndMarginGrowth.gasVolume[x]) {
              this.pickedYearGasVolume = this.projectArray[0].fuelVolumeAndMarginGrowth.gasVolume[x]
            };
          };
        };

        if (this.projectArray[0].fuelVolumeAndMarginGrowth) {
          if (this.projectArray[0].fuelVolumeAndMarginGrowth.fuelAndLubricantVolume) {
            if (this.projectArray[0].fuelVolumeAndMarginGrowth.fuelAndLubricantVolume[x]) {
              this.pickedYearFuelAndLubricantVolume = this.projectArray[0].fuelVolumeAndMarginGrowth.fuelAndLubricantVolume[x]
            };
          };
        };

        if (this.projectArray[0].economicEnvironment) {
          if (this.projectArray[0].economicEnvironment.yearlyEscalation) {
            if (this.projectArray[0].economicEnvironment.yearlyEscalation.inflationRate) {
              if (this.projectArray[0].economicEnvironment.yearlyEscalation.inflationRate[x]) {

                this.pickedYearInflationRate = Number(this.projectArray[0].economicEnvironment.yearlyEscalation.inflationRate[x]);

              }
            }
          }
        }

        if (this.projectArray[0].country) {
          if (this.projectArray[0].country.corporateTaxRate) {
            this.pickedYearCorporateTaxRate = Number(this.projectArray[0].country.corporateTaxRate);
          }

          if (this.projectArray[0].country.usdFxRate) {
            this.pickedYearExchangeRate = Number(this.projectArray[0].country.usdFxRate);
          }
        }
        if (this.projectArray[0].companyFinancing.interestRate) {
          this.pickedYearInterestRate = Number(this.projectArray[0].companyFinancing.interestRate);
        }

        if (this.projectArray[0].companySummary) {
          if (this.projectArray[0].companySummary.yearlyEscalation) {
            if (this.projectArray[0].companySummary.yearlyEscalation.netFuelMargin) {
              if (this.projectArray[0].companySummary.yearlyEscalation.netFuelMargin[x]) {
                this.pickedYearNetFuelMargin = this.projectArray[0].companySummary.yearlyEscalation.netFuelMargin[x];
              }
            }
            if (this.projectArray[0].companySummary.yearlyEscalation.companyDiversificationMargin) {
              if (this.projectArray[0].companySummary.yearlyEscalation.companyDiversificationMargin[x]) {
                this.pickedYearDiversification = this.projectArray[0].companySummary.yearlyEscalation.companyDiversificationMargin[x];
              }
            }
            if (this.projectArray[0].companySummary.yearlyEscalation.companyOtherRevenue) {
              if (this.projectArray[0].companySummary.yearlyEscalation.companyOtherRevenue[x]) {
                this.pickedYearOtherRevenue = this.projectArray[0].companySummary.yearlyEscalation.companyOtherRevenue[x];
              }
            }
            if (this.projectArray[0].companySummary.yearlyEscalation.companyOperatingExpenses) {
              if (this.projectArray[0].companySummary.yearlyEscalation.companyOperatingExpenses[x]) {
                this.pickedYearOperatingExpenses = this.projectArray[0].companySummary.yearlyEscalation.companyOperatingExpenses[x];
              }
            }
            if (this.projectArray[0].companySummary.yearlyEscalation.companyRentalsAndFeesDue) {
              if (this.projectArray[0].companySummary.yearlyEscalation.companyRentalsAndFeesDue[x]) {
                this.pickedYearRentalsAndFeesDue = this.projectArray[0].companySummary.yearlyEscalation.companyRentalsAndFeesDue[x];
              }
            }
            if (this.projectArray[0].companySummary.yearlyEscalation.ebitda) {
              if (this.projectArray[0].companySummary.yearlyEscalation.ebitda[x]) {
                this.pickedYearEBITDA = this.projectArray[0].companySummary.yearlyEscalation.ebitda[x];
              }
            }
            if (this.projectArray[0].companySummary.yearlyEscalation.companyInvestment) {
              if (this.projectArray[0].companySummary.yearlyEscalation.companyInvestment[x]) {
                this.pickedYearInvestments = this.projectArray[0].companySummary.yearlyEscalation.companyInvestment[x];
              }
            }
            if (this.projectArray[0].companySummary.yearlyEscalation.changeInWorkingCapital) {
              if (this.projectArray[0].companySummary.yearlyEscalation.changeInWorkingCapital[x]) {
                this.pickedYearChangeInWorkingCapital = this.projectArray[0].companySummary.yearlyEscalation.changeInWorkingCapital[x];
              }
            }
            if (this.projectArray[0].companySummary.yearlyEscalation.taxAmount) {
              if (this.projectArray[0].companySummary.yearlyEscalation.taxAmount[x]) {
                this.pickedYearTaxes = this.projectArray[0].companySummary.yearlyEscalation.taxAmount[x];
              }
            }

            if (this.projectArray[0].companySummary.yearlyEscalation.otherCashItems) {
              if (this.projectArray[0].companySummary.yearlyEscalation.otherCashItems[x]) {
                this.pickedYearOtherCashItems = this.projectArray[0].companySummary.yearlyEscalation.otherCashItems[x];
              }
            }
            if (this.projectArray[0].companySummary.yearlyEscalation.netCashAfterTax) {
              if (this.projectArray[0].companySummary.yearlyEscalation.netCashAfterTax[x]) {
                this.pickedYearNetCashAfterTax = this.projectArray[0].companySummary.yearlyEscalation.netCashAfterTax[x];
              }
            }
            if (this.projectArray[0].companySummary.yearlyEscalation.financialResult) {
              if (this.projectArray[0].companySummary.yearlyEscalation.financialResult[x]) {
                this.pickedYearFinancialResult = this.projectArray[0].companySummary.yearlyEscalation.financialResult[x];
              }
            }
            if (this.projectArray[0].companySummary.yearlyEscalation.netCashAfterTaxDiscounted) {
              if (this.projectArray[0].companySummary.yearlyEscalation.netCashAfterTaxDiscounted[x]) {
                this.pickedYearNetCashAfterTaxDiscounted = this.projectArray[0].companySummary.yearlyEscalation.netCashAfterTaxDiscounted[x];
              }
            }
            if (this.projectArray[0].companySummary.yearlyEscalation.netCashAfterTaxReal) {
              if (this.projectArray[0].companySummary.yearlyEscalation.netCashAfterTaxReal[x]) {
                this.pickedYearNetCashAfterTaxRealAndDiscounted = this.projectArray[0].companySummary.yearlyEscalation.netCashAfterTaxReal[x];
              }
            }
          }
        }

        // Post-Investment
        if (this.projectArray[0].actualPerformance) {
          if (this.projectArray[0].actualPerformance.yearlyEscalation) {
            if (this.projectArray[0].actualPerformance.yearlyEscalation.netFuelMargin) {
              if (this.projectArray[0].actualPerformance.yearlyEscalation.netFuelMargin[x]) {
                this.pickedYearNetFuelMarginActual = this.projectArray[0].actualPerformance.yearlyEscalation.netFuelMargin[x];
              }
            }
            if (this.projectArray[0].actualPerformance.yearlyEscalation.companyDiversificationMargin) {
              if (this.projectArray[0].actualPerformance.yearlyEscalation.companyDiversificationMargin[x]) {
                this.pickedYearDiversificationActual = this.projectArray[0].actualPerformance.yearlyEscalation.companyDiversificationMargin[x];
              }
            }
            if (this.projectArray[0].actualPerformance.yearlyEscalation.companyOtherRevenue) {
              if (this.projectArray[0].actualPerformance.yearlyEscalation.companyOtherRevenue[x]) {
                this.pickedYearOtherRevenueActual = this.projectArray[0].actualPerformance.yearlyEscalation.companyOtherRevenue[x];
              }
            }
            if (this.projectArray[0].actualPerformance.yearlyEscalation.companyOperatingExpenses) {
              if (this.projectArray[0].actualPerformance.yearlyEscalation.companyOperatingExpenses[x]) {
                this.pickedYearOperatingExpensesActual = this.projectArray[0].actualPerformance.yearlyEscalation.companyOperatingExpenses[x];
              }
            }
            if (this.projectArray[0].actualPerformance.yearlyEscalation.companyRentalsAndFeesDue) {
              if (this.projectArray[0].actualPerformance.yearlyEscalation.companyRentalsAndFeesDue[x]) {
                this.pickedYearRentalsAndFeesDueActual = this.projectArray[0].actualPerformance.yearlyEscalation.companyRentalsAndFeesDue[x];
              }
            }
            if (this.projectArray[0].actualPerformance.yearlyEscalation.ebitda) {
              if (this.projectArray[0].actualPerformance.yearlyEscalation.ebitda[x]) {
                this.pickedYearEBITDA = this.projectArray[0].actualPerformance.yearlyEscalation.ebitda[x];
              }
            }
            if (this.projectArray[0].actualPerformance.yearlyEscalation.companyInvestment) {
              if (this.projectArray[0].actualPerformance.yearlyEscalation.companyInvestment[x]) {
                this.pickedYearInvestmentsActual = this.projectArray[0].actualPerformance.yearlyEscalation.companyInvestment[x];
              }
            }
            if (this.projectArray[0].actualPerformance.yearlyEscalation.changeInWorkingCapital) {
              if (this.projectArray[0].actualPerformance.yearlyEscalation.changeInWorkingCapital[x]) {
                this.pickedYearChangeInWorkingCapitalActual = this.projectArray[0].actualPerformance.yearlyEscalation.changeInWorkingCapital[x];
              }
            }
            if (this.projectArray[0].actualPerformance.yearlyEscalation.taxAmount) {
              if (this.projectArray[0].actualPerformance.yearlyEscalation.taxAmount[x]) {
                this.pickedYearTaxesActual = this.projectArray[0].actualPerformance.yearlyEscalation.taxAmount[x];
              }
            }

            if (this.projectArray[0].actualPerformance.yearlyEscalation.otherCashItems) {
              if (this.projectArray[0].actualPerformance.yearlyEscalation.otherCashItems[x]) {
                this.pickedYearOtherCashItemsActual = this.projectArray[0].actualPerformance.yearlyEscalation.otherCashItems[x];
              }
            }
            if (this.projectArray[0].actualPerformance.yearlyEscalation.netCashAfterTax) {
              if (this.projectArray[0].actualPerformance.yearlyEscalation.netCashAfterTax[x]) {
                this.pickedYearNetCashAfterTaxActual = this.projectArray[0].actualPerformance.yearlyEscalation.netCashAfterTax[x];
              }
            }
            if (this.projectArray[0].actualPerformance.yearlyEscalation.financialResult) {
              if (this.projectArray[0].actualPerformance.yearlyEscalation.financialResult[x]) {
                this.pickedYearFinancialResultActual = this.projectArray[0].actualPerformance.yearlyEscalation.financialResult[x];
              }
            }
            if (this.projectArray[0].actualPerformance.yearlyEscalation.netCashAfterTaxDiscounted) {
              if (this.projectArray[0].actualPerformance.yearlyEscalation.netCashAfterTaxDiscounted[x]) {
                this.pickedYearNetCashAfterTaxDiscountedActual = this.projectArray[0].actualPerformance.yearlyEscalation.netCashAfterTaxDiscounted[x];
              }
            }
            if (this.projectArray[0].actualPerformance.yearlyEscalation.netCashAfterTaxReal) {
              if (this.projectArray[0].actualPerformance.yearlyEscalation.netCashAfterTaxReal[x]) {
                this.pickedYearNetCashAfterTaxRealAndDiscountedActual = this.projectArray[0].actualPerformance.yearlyEscalation.netCashAfterTaxReal[x];
              }
            }
          }
        }

        // Product Volume - Actual
        // if (this.projectArray[0].actualVolume) {

        // }

        // Product Volume
        if (this.projectArray[0].fuelProduct) {
          let xy: any = [];

          xy = this.projectArray[0].fuelProduct;

          if (xy[0]) {
            let industryAbbreviation = "";
            let name = "";
            let displayName = "";
            let category = "";
            let volume = 0;

            if (xy[0].yearlyVolume) {
              volume = xy[0].yearlyVolume;
            }
            if (xy[0].yearlyVolume) {
              category = xy[0].petroleumProductCategory_name;
            }
            if (xy[0].industryAbbreviation) {
              industryAbbreviation = xy[0].industryAbbreviation;
            }
            if (xy[0].name) {
              name = xy[0].name;
            }

            displayName = `${name} (${industryAbbreviation})`;

            this.productOne = {
              "volume": volume,
              "name": displayName,
              "category": category
            };
          }

          if (xy[1]) {
            let industryAbbreviation = "";
            let name = "";
            let displayName = "";
            let category = "";
            let volume = 0;

            if (xy[1].yearlyVolume) {
              volume = xy[1].yearlyVolume;
            }
            if (xy[1].yearlyVolume) {
              category = xy[1].petroleumProductCategory_name;
            }
            if (xy[1].industryAbbreviation) {
              industryAbbreviation = xy[1].industryAbbreviation;
            }
            if (xy[1].name) {
              name = xy[1].name;
            }

            displayName = `${name} (${industryAbbreviation})`;

            this.productTwo = {
              "volume": volume,
              "name": displayName,
              "category": category
            };
          }

          if (xy[2]) {
            let industryAbbreviation = "";
            let name = "";
            let displayName = "";
            let category = "";
            let volume = 0;

            if (xy[2].yearlyVolume) {
              volume = xy[2].yearlyVolume;
            }
            if (xy[2].yearlyVolume) {
              category = xy[2].petroleumProductCategory_name;
            }
            if (xy[2].industryAbbreviation) {
              industryAbbreviation = xy[2].industryAbbreviation;
            }
            if (xy[2].name) {
              name = xy[2].name;
            }

            displayName = `${name} (${industryAbbreviation})`;

            this.productThree = {
              "volume": volume,
              "name": displayName,
              "category": category
            };
          }


        };


      }


    }
  };

  saveEconomicEnvironmentActual(form: any) {
    this.updateInProgress = true;

    // Check
    if (!this.pickedYear) {
      this.show_alert = true;
      this.updateInProgress = false;
      this.submitInProgress = false;
      this.alert_message = "Pick a year 1st!";
      this.alert_type = "danger";

      this.toastr.error('Pick a year 1st!', 'Error!');

      setTimeout(() => {
        this.show_alert = false;
        this.updateInProgress = false;
        this.submitInProgress = false;
        this.selfClosingAlert.close();
      }, 3000);
      return;
    }

    
  }

  saveProductSalesVolumeActual(form: any) {
    this.updateInProgress = true;

    // Check
    if (!this.pickedYear) {
      this.show_alert = true;
      this.updateInProgress = false;
      this.submitInProgress = false;
      this.alert_message = "Pick a year 1st!";
      this.alert_type = "danger";

      this.toastr.error('Pick a year 1st!', 'Error!');

      setTimeout(() => {
        this.show_alert = false;
        this.updateInProgress = false;
        this.submitInProgress = false;
        this.selfClosingAlert.close();
      }, 3000);
      return;
    }

  }

  printPostInvestmentSummaryPDF() {

    let date = new Date();
    let year = date.getFullYear();
    let month = ((date.getMonth() + 1).toString()).padStart(2, '0');
    let day = ((date.getDate()).toString()).padStart(2, '0');

    //
    this.dateToday = `${day}-${month}-${year}`;

    let tableOfContentArray = [];

    this.notify("Downloading Post Investment Summary ...");

    const param = {
      outputType: "save",
      returnJsPDFDocObject: false,
      fileName: `${this.projectName} - Post Investment Summary - ${this.dateToday}.pdf`,
      orientationLandscape: false,
      compress: true,
      pageEnable: true,
      pageLabel: "Page",
    };

    var doc = new jsPDF(param.orientationLandscape ? "landscape" : "p", "mm", "a4", param.compress);

    // add the font to jsPDF
    doc.addFileToVFS("Volkhov.ttf", Volkhov);
    doc.addFont('Volkhov.ttf', 'Volkhov', 'normal');

    var docWidth = doc.internal.pageSize.width;
    var docHeight = doc.internal.pageSize.height;

    var colorBlack = "#000000";
    var colorGray = "#4d4e53";
    var colorYellow = "#CDAE59";
    var colorBlue = "#353E59";
    var colorRed = "#fa5c7c";
    var colorGreen = "#0acf97";
    //starting at 15mm
    var currentHeight = 15;
    //var startPointRectPanel1 = currentHeight + 6;

    var pdfConfig = {
      headerTextSize: 16,
      labelTextSize: 12,
      fieldTextSize: 11,
      lineHeight: 9,
      subLineHeight: 5,
      headerLineHeight: 6,
      headerSubLineHeight: 4,
      headerFieldTextSize: 10,
    };

    var addHeader = function () {
      currentHeight = 15;
      // Logo Image
      var imageHeader = new Image();
      imageHeader.src = "../../../../assets/images/logo/logo_315_x_315.png";
      doc.addImage(
        imageHeader,
        "JPEG",
        10 + 0,
        currentHeight - 5 + 0,
        35,
        35
      );

      doc.setTextColor(colorGray);
      doc.setFontSize(pdfConfig.headerFieldTextSize);

      currentHeight += pdfConfig.headerSubLineHeight;
      doc.text("Delta Office Suites 2nd floor,", docWidth - 10, currentHeight, { align: 'right' });
      currentHeight += pdfConfig.headerSubLineHeight;
      doc.text("Block B Off Waiyaki Way &", docWidth - 10, currentHeight, { align: 'right' });
      currentHeight += pdfConfig.headerSubLineHeight;
      doc.text("Manyani West Road, Muthangari.", docWidth - 10, currentHeight, { align: 'right' });
      currentHeight += pdfConfig.headerSubLineHeight;
      currentHeight += pdfConfig.headerSubLineHeight;
      doc.text("+254 709 497 000", docWidth - 10, currentHeight, { align: 'right' });
      doc.setFontSize(pdfConfig.headerFieldTextSize);
      // doc.setTextColor(colorGray);
      currentHeight += pdfConfig.headerSubLineHeight;
      doc.text("info@galanaenergies.com", docWidth - 10, currentHeight, { align: 'right' });

      currentHeight += pdfConfig.headerSubLineHeight;
      doc.text("www.galanaenergies.com", docWidth - 10, currentHeight, { align: 'right' });

      //line breaker after logo & business info
      doc.line(10, 45, docWidth - 10, 45);
      currentHeight = 45;
    }

    addHeader();

    // Start
    doc.setFont('Volkhov', 'normal');
    doc.setTextColor(colorBlack);
    doc.setFontSize(12);

    currentHeight += pdfConfig.lineHeight;

    doc.setFontSize(16);
    doc.text(`Post Investment Summary`, docWidth / 2, currentHeight, { align: 'center' },);
    currentHeight += pdfConfig.subLineHeight;

    doc.line(25, currentHeight, docWidth - 25, currentHeight);

    doc.setFontSize(12);







    //#region Add num of pages at the bottom
    if (doc.getNumberOfPages() > 1) {
      for (let i = 1; i <= doc.getNumberOfPages(); i++) {
        doc.setPage(i);
        doc.setFontSize(pdfConfig.headerFieldTextSize);
        doc.setTextColor(colorGray);

        if (param.pageEnable) {
          var imageHeader = new Image();
          imageHeader.src = "../../../../assets/images/logo/accent_210_x_30.png";
          doc.addImage(
            imageHeader,
            "JPEG",
            1,
            doc.internal.pageSize.height - 30,
            210,
            30
          );

          // doc.line(10, docHeight - 15, docWidth - 10, docHeight - 15);
          if (this.userName) { doc.text(`Printed By: ${this.userName}`, 40, docHeight - 10, { align: 'left' }); };
          if (this.dateToday) { doc.text(`${this.dateToday}`, 40, docHeight - 5, { align: 'left' }); };
          doc.text("From: www.financialforecast.cloud", (docWidth / 2), docHeight - 5, { align: 'center' });
          doc.text(
            param.pageLabel + " " + i + " / " + doc.getNumberOfPages(),
            docWidth - 30,
            doc.internal.pageSize.height - 7,
            { align: 'left' }
          );
        }

      }
    }

    //#region Add num of first page at the bottom
    if (doc.getNumberOfPages() === 1 && param.pageEnable) {

      var imageHeader = new Image();
      imageHeader.src = "../../../../assets/images/logo/accent_210_x_30.png";
      doc.addImage(
        imageHeader,
        "JPEG",
        1,
        doc.internal.pageSize.height - 30,
        210,
        30
      );

      doc.setFontSize(pdfConfig.fieldTextSize);
      doc.setTextColor(colorGray);
      // doc.line(10, docHeight - 15, docWidth - 10, docHeight - 15);
      if (this.userName) { doc.text(`Printed By: ${this.userName}`, 40, docHeight - 10, { align: 'left' }); };
      if (this.dateToday) { doc.text(`${this.dateToday}`, 40, docHeight - 5, { align: 'left' }); };
      doc.text("www.financialforecast.cloud", (docWidth / 2), docHeight - 5, { align: 'center' });
      doc.text(
        param.pageLabel + " 1 / 1",
        docWidth - 30,
        doc.internal.pageSize.height - 7,
        { align: 'left' }
      );
    }
    //#endregion


    let returnObj = {
      pagesNumber: doc.getNumberOfPages(),
    };



    if (param.outputType === "save") {
      // this.disableDownloadButton = false;
      doc.save(param.fileName);
    }

    return returnObj;
  }


  saveProfitAndLossActual(form: any) {
    this.updateInProgress = true;

    // Check
    if (!this.pickedYear) {
      this.show_alert = true;
      this.updateInProgress = false;
      this.submitInProgress = false;
      this.alert_message = "Pick a year 1st!";
      this.alert_type = "danger";

      this.toastr.error('Pick a year 1st!', 'Error!');

      setTimeout(() => {
        this.show_alert = false;
        this.updateInProgress = false;
        this.submitInProgress = false;
        this.selfClosingAlert.close();
      }, 3000);
      return;
    }

  }

  ngAfterViewInit(): void {
    
    
  };

  notify(message: string) {
    this.toastr.info(`${message}`, "Info!");
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  };

}
